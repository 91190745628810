import { Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import IssuedCardForm from "../../form";
import { BsCardHeading } from "react-icons/bs";

const IssuedCardHeader = () => {
  const _IssuedCard = useSelector((state) => state.issuedCard);

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            طلبات اصدار البطاقات
          </Typography>
        }
        avatar={<BsCardHeading size={20} />}

      />
      {_IssuedCard.issuedCard.dialog ? <IssuedCardForm /> : null}
    </Card>
  );
};

export default IssuedCardHeader;
