import { Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { MdSettingsSuggest } from "react-icons/md";
import SettingsForm from "../../from";

const NonePosHeader = () => {
  const _Settings = useSelector((state) => state.settings.setting.dialog);

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            الاعدادات
          </Typography>
        }
        avatar={<MdSettingsSuggest size={20} />}
      />
      {_Settings && <SettingsForm />}
    </Card>
  );
};

export default NonePosHeader;
