import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  resetForm,
  Wallet,
  setReceiveCashSupplierDialog,
} from "../../../../../app/slices/walletSlice";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useParams } from "react-router";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReceiveCashForm = () => {
  const {supplier_id} = useParams();
  const _ReceiveCashDialog = useSelector((state) => state.wallet.receiveCashSupplierDialog);
  
  const [disableButton, setDisableButton] = useState(false);

  const { control, reset, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: {amount:0},
  });


  const onSubmit = (data) => {
    setDisableButton(true)
    data.supplier_id = supplier_id
    dispatch(Wallet.receiveCashSupplier(data)).then(() => setDisableButton(false));
  };
  // useEffect(() => {
  //   reset({ ..._ReceiveCashDialog.form });
  // }, [_ReceiveCashDialog.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  const dispatch = useDispatch();

  const handlDialog = () => {
    dispatch(setReceiveCashSupplierDialog());
  };

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref,
  ) {
    const { onChange, ...other } = props;
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });

  return (
    <Dialog
      open={_ReceiveCashDialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handlDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth='sm'
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"استلام حساب الفواتير"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="المبلغ"
                    fullWidth
                    {...field}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    multiline
                    rows={4}
                    label="ملاحظات"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary" disabled={disableButton}>
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ReceiveCashForm;
