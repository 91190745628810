import { Grid } from "@mui/material";
import React from "react";
import ExternalRevenueTable from "./home/table";
import ExternalRevenueHeader from "./home/header";


const ExternalRevenue = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ExternalRevenueHeader />
      </Grid>
      <Grid item xs={12}>
        <ExternalRevenueTable />
      </Grid>
    </Grid>
  );
};

export default ExternalRevenue;
