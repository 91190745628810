import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  Box,
  Stack,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  JSubCategories,
  resetForm,
  setDialog,
} from "../../../../../app/slices/jomla/subcategorySlice";
import FilePondInput from "../../../../../helpers/FilePondInput";
import { SelectJCategory } from "../../../../../components/Selectors/jomla/SelectJCategory";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JSubCategoriesForm = () => {
  const _JSubCategories = useSelector(
    (state) => state.JSubCategories.JSubCategory
  );
  const _SearchBody = useSelector((state) => state.JSubCategories.searchBody);
  const JCategory = useSelector(
    (state) => state.JCategories.JCategoriesList.data
  );
  // const _provinces = useSelector((state) => state.provinces.provinces.data);

  const isAdd = _JSubCategories.form.id ? false : true;
  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _JSubCategories.form,
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("j_category_id", data.j_category_id);
    formData.append("is_active", data.is_active ? 1 : 0);
    if (!isAdd) formData.append("_method", "PUT");
    formData.append("name", data.name);
    if (data.logo && data.logo[0].name) formData.append("logo", data.logo[0]);
    if (isAdd) {
      dispatch(JSubCategories.create(formData));
    } else {
      dispatch(JSubCategories.update(data.id, formData, _SearchBody));
    }
  };
  useEffect(() => {
    reset({ ..._JSubCategories.form });
  }, [_JSubCategories.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_JSubCategories.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="j_category_id"
                control={control}
                render={({ field }) => (
                  <SelectJCategory
                    {...field}
                    required
                    onChange={(e, newValue) => {
                      setValue("j_category_id", newValue ? newValue.id : null);
                    }}
                    value={
                      JCategory.filter((x) => x.id === field.value)[0] ?? null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label=" الاسم"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="is_active"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("is_active", e.target.checked, {
                            shouldDirty: true,
                          });
                        }}
                      />
                    }
                    label="فعال"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="logo"
                control={control}
                render={({ field }) => (
                  <Box>
                    {!isAdd ? (
                      <Stack direction="row" sx={{ justifyContent: "center" }}>
                        <Box
                          component="img"
                          src={field.value}
                          sx={{ width: 200 }}
                        />
                      </Stack>
                    ) : null}
                    <FilePondInput field={field} required={isAdd} />
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default JSubCategoriesForm;
