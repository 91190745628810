import { Dialog, DialogContent, Grid, Slide } from "@mui/material";
import React, { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import JSubCategoriesTable from "./home/table";
import JSubCategoriesHeader from "./home/header";
import { setPageDialog } from "../../../../app/slices/jomla/subcategorySlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JSubCategories = () => {
  const _JSubCategories = useSelector((state) => state.JSubCategories);

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setPageDialog());
  };

  return (
    <Dialog
      open={_JSubCategories.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      maxWidth="lg"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <JSubCategoriesHeader />
          </Grid>
          <Grid item xs={12}>
            <JSubCategoriesTable />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default JSubCategories;
