import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  JSafe,
  resetForm,
  setSafeTransferPageDialog,
} from "../../../../../app/slices/jomla/safeSlice";
import { SelectJSafe } from "../../../../../components/Selectors/jomla/SelectJSafe";
import { NumericFormat } from "react-number-format";
import { toNumber } from "../../../../../helpers/toNumber";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JSafeTransferForm = () => {
  const _JSafeTransfer = useSelector((state) => state.jSafe.jSafeTransfer);
  const _SearchBody = useSelector(
    (state) => state.jSafe.jSafeTransfer.searchBody
  );
  const jSafe = useSelector((state) => state.jSafe.jSafes.data);

  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _JSafeTransfer.form,
  });

  const onSubmit = (data) => {
    dispatch(
      JSafe.safeTransfer(
        {
          from_safe_id: data.from_safe_id,
          safe_id: data.safe_id,
          amount: data.amount,
        },
        _SearchBody
      )
    );
  };
  useEffect(() => {
    reset({ ..._JSafeTransfer.form });
  }, [_JSafeTransfer.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setSafeTransferPageDialog());
  };

  return (
    <Dialog
      open={_JSafeTransfer.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"تحويل كاش بين الخزنات"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="from_safe_id"
                control={control}
                render={({ field }) => (
                  <SelectJSafe
                    {...field}
                    required
                    noapirequest
                    label="من خزنة"
                    onChange={(e, newValue) => {
                      setValue("from_safe_id", newValue ? newValue.id : null);
                    }}
                    value={jSafe.filter((x) => x.id === field.value)[0] ?? null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="safe_id"
                control={control}
                render={({ field }) => (
                  <SelectJSafe
                    {...field}
                    required
                    label="الى خزنة"
                    onChange={(e, newValue) => {
                      setValue("safe_id", newValue ? newValue.id : null);
                    }}
                    value={jSafe.filter((x) => x.id === field.value)[0] ?? null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    value={field.value}
                    onChange={(e) => {
                      setValue("amount", toNumber(e.target.value));
                    }}
                    valueIsNumericString={true}
                    allowNegative={false}
                    fullWidth
                    label="المبلغ"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default JSafeTransferForm;
