import dayjs from "dayjs";
import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const itemsApi = factory.get("items");
const initialState = {
  searchBody: {},
  item: {
    loading: false,
    dialog: false,
    active: false,
    form: {
      id: "",
      sub_category_id: null,
      province_id: "",
      favorite: false,
      selling_price: "",
      description: "",
      sequence: "",
      discount_percentage: 0,
      max_discount_price: 0,
      groups: [],
      logo: "",
      sub_category_logo: "",
      sub_category: null,
      max_selling_per_day: 0,
      is_hidden: false,
      ussd: "",
      credit_amount: "",
      sim_card: [],
      is_transfer: true,
      province_ids:[]
    },
  },
  items: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
  itemsList: {
    loading: false,
    data: [],
    total: 0,
  },
};

export const itemsSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.item.dialog = !state.item.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.items.data = payload.data.data;
      state.items.total = payload.data.total;
      state.items.total_amount = payload.data.total_amount;
    },
    setDataList: (state, { payload }) => {
      state.itemsList.data = payload.data.data;
    },
    setById: (state, { payload }) => {
      state.item.form = {
        ...payload,
        sim_card: payload.sim_card?.map((x) => x.id),
        province_id: payload.province_id ?? "",
      };
      // state.item.form.id = payload.id
      // state.item.form.name = payload.name
      // state.item.form.sub_category_id = payload.sub_category_id
      // state.item.form.description = payload.description
      // state.item.form.favorite = payload.favorite
      // state.item.form.logo = payload.logo
      // state.item.form.price = payload.price
      // state.item.form.selling_price = payload.selling_price
      // state.item.form.sequence = payload.sequence
      // state.item.form.discount_percentage = payload.discount_percentage
      // state.item.form.max_discount_price = payload.max_discount_price
      // state.item.form.sub_category = payload.sub_category
      // state.item.form.sub_category_logo = payload.sub_category_logo
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.item.form = initialState.item.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDataList,
} = itemsSlice.actions;

export default itemsSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("items"));
    const res = await itemsApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("items"));
  } catch (err) {
    dispatch(setLoading("items"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("items"));
    const res = await itemsApi.getAllList(params);
    dispatch(setDataList(res));
    dispatch(setLoading("items"));
  } catch (err) {
    dispatch(setLoading("items"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await itemsApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("items"));
    const res = await itemsApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("items"));
  } catch (err) {
    dispatch(setLoading("items"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await itemsApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await itemsApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم تعديل المنتج بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const active = (id, _SearchBody) => async (dispatch) => {
  try {
    await itemsApi.active(id);
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const Items = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  active,
  getAllList,
};
