import { Button, Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { MdOutlineDevicesOther } from "react-icons/md";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { setAddDialog } from "../../../../../app/slices/productsOrdersSlice";
import AddProductsOrdersForm from "../../addForm";

const ProductsOrdersHeader = () => {
  const dispatch = useDispatch();

  const _ProductsOrder = useSelector(
    (state) => state.productsOrders.addProductsOrder.dialog
  );
  const userInfo = useSelector((state) => state.user.userInfo);
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            طلبات الاجهزة
          </Typography>
        }
        avatar={<MdOutlineDevicesOther size={20} />}
        action={
          userInfo.user_roles[0] && (
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoIosAddCircleOutline />}
              onClick={() => dispatch(setAddDialog())}
            >
              أضافة طلب
            </Button>
          )
        }
      />
      {_ProductsOrder && <AddProductsOrdersForm />}
    </Card>
  );
};

export default ProductsOrdersHeader;
