import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
  Divider,
  LinearProgress,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  JSupplier,
  resetForm,
  setDialog,
} from "../../../../app/slices/jomla/supplierSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JSupplierForm = () => {
  const dispatch = useDispatch();

  const _JSupplier = useSelector((state) => state.jSupplier.jSupplier);
  const _SearchBody = useSelector((state) => state.jSupplier.searchBody);

  const [loading, setLoading] = useState(false);

  const isAdd = _JSupplier.form.id ? false : true;
  const { control, reset, handleSubmit } = useForm({
    defaultValues: _JSupplier.form,
  });

  const onSubmit = (data) => {
    setLoading(true);
    if (isAdd) {
      dispatch(
        JSupplier.create(
          {
            name: data.name,
            note: data.note,
          },
          _SearchBody
        )
      )
        .then((x) => setLoading(false))
        .catch((e) => setLoading(false));
    } else {
      dispatch(
        JSupplier.update(
          data.id,
          {
            name: data.name,
            note: data.note,
          },
          _SearchBody
        )
      )
        .then(() => setLoading(false))
        .catch((e) => setLoading(false));
    }
  };

  useEffect(() => {
    reset({ ..._JSupplier.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_JSupplier.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_JSupplier.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="sm"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          {_JSupplier.loading && <LinearProgress sx={{ marginBottom: 1 }} />}
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الاسم"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="ملاحظات"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading ? true : false}
                endIcon={loading ? <CircularProgress size={25} /> : null}
              >
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default JSupplierForm;
