import dayjs from "dayjs";
import {
  createSlice
} from "@reduxjs/toolkit";
import {
  showNotification
} from "./notificationSlice";
import {
  factory
} from "../../api/apiFactory";
const productApi = factory.get("product");
const initialState = {
  searchBody: {},
  product: {
    loading: false,
    dialog: false,
    form: {
      id: "",
      name: "",
      logo: "",
      sub_category_id: null,
      description: "",
      sequence: "",
      is_hidden: false,
      // count: "",
      buying_price: "",
      revenue: "",
      revenue_percent: "",
      selling_price: "",
      daily_payment: "",
      // max_selling_per_day: "",
      discount_percentage: "",
      max_discount_price: "",
    },
  },
  products: {
    data: [],
    total: 0,
  },
}

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.product.dialog = !state.product.dialog
    },
    setDataTable: (state, {
      payload
    }) => {
      state.products.data = payload.data.data
      state.products.total = payload.data.total
    },
    setDataList: (state, {
      payload
    }) => {
      state.subCategoriesList.data = payload.data.data
    },
    setById: (state, {
      payload
    }) => {
      state.product.form.id = payload.id
      state.product.form.name = payload.name
      state.product.form.sub_category_id = payload.sub_category_id
      state.product.form.description = payload.description
      state.product.form.sequence = payload.sequence
      state.product.form.logo = payload.logo
      state.product.form.is_hidden = payload.is_hidden
      // state.product.form.count = payload.count
      state.product.form.buying_price = payload.buying_price
      state.product.form.revenue = payload.revenue
      state.product.form.revenue_percent =  payload.revenue * 100 / payload.buying_price
      state.product.form.selling_price = payload.selling_price
      state.product.form.daily_payment = payload.daily_payment
      // state.product.form.max_selling_per_day = payload.max_selling_per_day
      state.product.form.discount_percentage = payload.discount_percentage
      state.product.form.max_discount_price = payload.max_discount_price
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetForm: (state, action) => {
      state.product.form = initialState.product.form
    },
    setSearchBody: (state, {
      payload
    }) => {
      state.searchBody = payload;
    },
  }
})

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDataList
} = productSlice.actions

export default productSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('product'));
    const res = await productApi.getAll(params)
    dispatch(setDataTable(res));
    dispatch(setLoading('product'));

  } catch (err) {
    dispatch(setLoading('product'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('product'));
    const res = await productApi.getAllList(params)
    dispatch(setDataList(res));
    dispatch(setLoading('product'));
  } catch (err) {
    dispatch(setLoading('product'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await productApi.delete(id)
    dispatch(showNotification({
      message: "تم الحذف بنجاح",
      type: "success"
    }));
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading('product'));
    const res = await productApi.getById(id)
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog())
    dispatch(setLoading('product'));
  } catch (err) {
    dispatch(setLoading('product'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await productApi.create(data)
    dispatch(showNotification({
      message: "تمت الاضافة  بنجاح",
      type: "success"
    }));
    dispatch(setDialog())
    dispatch(getAll())
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await productApi.update(id, data)
    dispatch(showNotification({
      message: "تم التعديل بنجاح",
      type: "success"
    }));
    dispatch(setDialog())
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const activeProduct = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await productApi.active(id, data)
    dispatch(showNotification({
      message: "تم التعديل بنجاح",
      type: "success"
    }));
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};

export const Product = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  getAllList,
  activeProduct
}