import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import CashFromPosForm from "./cashFromPosForm";
import CashFromRepForm from "./cashFromRepForm";

const TransactionForm = () => {
  const _User = useSelector((state) => state.user.user.form);
  const _Wallet = useSelector((state) => state.jClearance);

  return (
    <Box>
      {_Wallet.cashFromPos.dialog && <CashFromPosForm userData={_User} />}
      {_Wallet.cashFromRep.dialog && <CashFromRepForm userData={_User} />}
    </Box>
  );
};

export default TransactionForm;
