import { Card, CardHeader, Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../../app/slices/bannerSlice";
import BannerForm from "../../form";
import { RiAdvertisementLine } from "react-icons/ri";



const BannerHeader = () => {
  const  _Banner  = useSelector((state) => state.banner);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
              الاعلانات
          </Typography>
        }
        avatar={<RiAdvertisementLine size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            إضافة اعلان جديد
          </Button>
        }
      />
      {_Banner.banner.dialog ? <BannerForm /> : null}
    </Card>
  );
};

export default BannerHeader;
