import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
import dayjs from "dayjs";
const issuedCardApi = factory.get("issuedCard");
const initialState = {
  searchBody: {},
  issuedCard: {
    loading: false,
    dialog: false,
    active: false,
    form: {
      id: null,
      holder_name: "",
      number: "",
      type: null,
      order_issued_id: "",
      issued_at: dayjs().format("YYYY-MM-DD"),
      status_id: null,
    },
  },
  updateForm: {
    dialog: false,
    form: {
      id: null,
      ar_name: "",
      en_name: "",
      id_no: "",
      mobile: "",
      note: "",
      front_id: null,
      back_id: null,
      status_id: null,
      attachment: [],
    },
  },
  issuedCards: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
  statusList: [],
  issuedOrderId: {
    loading: false,
    dialog: false,
    form: {
      ids: [],
      order_issued_id: "",
    },
  },
};

export const issuedCardSlice = createSlice({
  name: "issuedCard",
  initialState,
  reducers: {
    setDialog: (state, { payload }) => {
      state.issuedCard.dialog = !state.issuedCard.dialog;
      state.issuedCard.form = {
        ...state.issuedCard.form,
        status_id: payload.status_id,
        id: payload.id,
      };
    },
    setUpdateDialog: (state, action) => {
      state.issuedOrderId.dialog = !state.issuedOrderId.dialog;
    },
    setDialogForUpdate: (state, action) => {
      state.updateForm.dialog = !state.updateForm.dialog;
    },
    resetUpdateForm: (state, action) => {
      state.updateForm.form = initialState.updateForm.form;
    },
    setDataTable: (state, { payload }) => {
      state.issuedCards.data = payload.data.data;
      state.issuedCards.total = payload.data.total;
      state.issuedCards.total_amount = payload.data.total_amount;
    },
    setStatusData: (state, { payload }) => {
      state.statusList = payload.data.data;
    },
    setById: (state, { payload }) => {
      state.issuedCard.form = payload;
    },
    setByIdForUpdate: (state, { payload }) => {
      state.updateForm.form = payload;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.issuedCard.form = initialState.issuedCard.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setStatusData,
  resetUpdateForm,
  setUpdateDialog,
  setByIdForUpdate,
  setDialogForUpdate,
} = issuedCardSlice.actions;

export default issuedCardSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("issuedCard"));
    const res = await issuedCardApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("issuedCard"));
  } catch (err) {
    dispatch(setLoading("issuedCard"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await issuedCardApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("issuedCard"));
    const res = await issuedCardApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(
      setDialog({
        status_id: null,
        id: null,
      })
    );
    dispatch(setLoading("issuedCard"));
  } catch (err) {
    dispatch(setLoading("issuedCard"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getByIdForUpdate = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("issuedCard"));
    const res = await issuedCardApi.getById(id);
    dispatch(setByIdForUpdate(res.data.data[0]));
    dispatch(setDialogForUpdate());
    dispatch(setLoading("issuedCard"));
  } catch (err) {
    dispatch(setLoading("issuedCard"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await issuedCardApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة بنجاح",
        type: "success",
      })
    );
    dispatch(
      setDialog({
        status_id: null,
        id: null,
      })
    );
    dispatch(getAll());
  } catch (err) {
    dispatch(
      setDialog({
        status_id: null,
        id: null,
      })
    );
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await issuedCardApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    dispatch(setDialogForUpdate());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const updateStatus = (id, data, _SearchBody, openDialog) => async (dispatch) => {
  try {
    await issuedCardApi.updateStatus(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    if (openDialog) {
      dispatch(setDialog({
        status_id: null,
        id: null,
      }));
    }
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const getStatus = () => async (dispatch) => {
  try {
    const res = await issuedCardApi.getStatus();
    dispatch(setStatusData(res));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const updateNumber = (data, _SearchBody) => async (dispatch) => {
  try {
    await issuedCardApi.updateNumber(data);
    dispatch(
      showNotification({
        message: "تم تعديل بنجاح",
        type: "success",
      })
    );
    dispatch(setUpdateDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0] || err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const IssuedCards = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  getStatus,
  updateStatus,
  updateNumber,
  getByIdForUpdate,
};
