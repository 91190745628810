import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ProductSupplier, setInitialBalanceDialog, setSearchBody,
} from "../../../../../app/slices/productsSupplierSlice";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import { MdOutlineClear } from "react-icons/md";
import dayjs from "dayjs";
import { TbTransferIn } from "react-icons/tb";
import InitialSupplierProductBalanceForm from "../../form/InitialBalance";
import { useNavigate } from "react-router";
import { AiOutlineTransaction } from "react-icons/ai";
import PaidIcon from '@mui/icons-material/Paid';

const ProductSupplierTable = () => {
  const dispatch = useDispatch();

  const _ProductSupplier = useSelector((state) => state.productSupplier.productsSupplier);
  const loading = useSelector((state) => state.productSupplier.loading);
  const _SearchBody = useSelector((state) => state.productSupplier.searchBody);
  const _InitialSupplierProductBalance = useSelector((state) => state.productSupplier.initialBalance.dialog);

  const [search, setSearch] = useState({
    search: '',
  });
  const [page, setPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState('');
  const [openActive, setOpenActive] = useState(false);
  const [dialogActiveData, setDialogActiveData] = useState({});

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params)
  };

  const handleConfirmDelete = () => {
    dispatch(ProductSupplier.deleteById(dialogData.id, _SearchBody))
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const handleActiveClick = (params) => {
    setOpenActive(true);
    setDialogActiveData(params)
  };

  const handleConfirmActive = () => {
    dispatch(ProductSupplier.activeProduct(dialogActiveData.id, {
      is_active: !dialogActiveData.is_active
    }, _SearchBody))
    setOpenActive(false);
  };

  const handleCancelActive = () => {
    setOpenActive(false);
  };

  const navigate = useNavigate();

  const goTo = (path) => {
    navigate(path);
  };


  const columns = [
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row?.name}>
            <Box>
              {params.row?.name}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "balance",
      headerName: "الرصيد (IQD)",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {parseInt(params.row?.balance)?.toLocaleString()}
          </>
        );
      },
    },
    {
      field: "total_sold_devices",
      headerName: "عدد الاجهزة المباعة",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row?.total_sold_devices?.toLocaleString()}
          </>
        );
      },
    },
    {
      field: "created_at",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {dayjs(params.row.created_at).format('YYYY-MM-DD')}
          </>
        );
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="كشف الحساب">
              <span>
                <IconButton
                  onClick={() => goTo("/app/platform/product_supplier/transactions/" + params.row.id)}
                >
                  <PaidIcon color="success" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="تعديل">
              <IconButton
                onClick={() => dispatch(ProductSupplier.getById(params.row.id))}
              >
                <BiEdit />
              </IconButton>
            </Tooltip>
            <Tooltip title="حذف">
              <IconButton
                onClick={() => handleDeleteClick(params.row)}
              >
                <BiTrash />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];


  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(ProductSupplier.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody]);

  useEffect(() => {
    dispatch(setSearchBody({
      search: search.search,
      skip: currentPage * page,
      take: page,
    }))
  }, [currentPage, page, search])

  return (
    <Card>
      <CardContent>
        <Box>
          {/* {_InitialSupplierProductBalance && <InitialSupplierProductBalanceForm />} */}
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
          <ConfirmationDialog
            open={openActive}
            title="تاكيد"
            message={`هل انت متاكد من ${dialogActiveData.is_active ? 'الغاء تفعيل المنتج' : 'تفعيل المنتج'}`}
            name={dialogActiveData.user_name}
            confirmText="تاكيد"
            cancelText="الغاء"
            onConfirm={() => handleConfirmActive()}
            onCancel={handleCancelActive}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              onChange={(e) => {
                setSearch({
                  ...search,
                  search: e.target.value
                });
              }}
              value={search.search}
              label="بحث عن الاسم "
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    search: '',
                  });
                  setPage(15)
                  setCurrentPage(0)
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={_ProductSupplier.data}
                columns={columns}
                pageSize={page}
                page={currentPage}
                rowCount={_ProductSupplier.total}
                loading={loading}
                rowHeight={50}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) =>
                  setCurrentPage(newPage)
                }
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPage(newPageSize)
                }
                }
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card >
  );
};

export default ProductSupplierTable;
