import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const projectsApi = factory.get("projects");
const initialState = {
  searchBody: {},
  loading: false,
  projects: {
    data: [],
    total: 0,
  },
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setDataTable: (state, { payload }) => {
      state.projects.data = payload.data.data;
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const { setLoading, setDataTable, setSearchBody } =
  projectsSlice.actions;

export default projectsSlice.reducer;

//axios
const getAll = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("projects"));
    const res = await projectsApi.getAll(data);
    dispatch(setDataTable(res));
    dispatch(setLoading("projects"));
  } catch (err) {
    dispatch(setLoading("projects"));
    throw new Error(err);
  }
};

export const Projects = {
  getAll,
};
