import { Grid } from "@mui/material";
import React from "react";
import ProductSupplierHeader from "./home/header";
import ProductSupplierTable from "./home/table";

const ProductSupplier = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ProductSupplierHeader />
      </Grid>
      <Grid item xs={12}>
        <ProductSupplierTable />
      </Grid>
    </Grid>
  );
};

export default ProductSupplier;
