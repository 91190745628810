import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { TbTransferIn } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Supplier,
  setInitialBalanceDialog,
  setSearchBody,
} from "../../../../../app/slices/supplierSlice";
import dayjs from "dayjs";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import { AiOutlineTransaction } from "react-icons/ai";
import InitialSupplierBalanceForm from "../../form/InitialBalance";

const SupplierTabel = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const _Supplier = useSelector((state) => state.supplier.suppliers);
  const loading = useSelector((state) => state.supplier.supplier.loading);
  const _SearchBody = useSelector((state) => state.supplier.searchBody);
  const _InitialSupplierBalance = useSelector(
    (state) => state.supplier.initialBalance.dialog
  );

  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(Supplier.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "province_name",
      headerName: "المحافظة",
      flex: 0.6,
    },
    {
      field: "credit",
      headerName: "الرصيد",
      flex: 1,
      renderCell: (params) => {
        return <>{parseInt(params.row.balance).toLocaleString()}</>;
      },
    },
    {
      field: "created_at",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack spacing={0.5}>
            <Typography variant="body">
              {dayjs(params.row.created_at).format("YYYY-MM-DD")}
            </Typography>
            <Typography variant="body">
              {dayjs(params.row.created_at).format("hh:mm:ss a")}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="المناقلات">
              <IconButton
                onClick={() =>
                  navigate(
                    "/app/suppliers&bills/suppliers/transactions/" +
                    params.row.id
                  )
                }
              >
                <AiOutlineTransaction />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="الرصيد">
              <IconButton
                variant="contained"
                color="info"
                onClick={() => dispatch(setInitialBalanceDialog(params.row.id))}
              >
                <TbTransferIn />
              </IconButton>
            </Tooltip> */}
            <Tooltip title="تعديل">
              <IconButton
                onClick={() => dispatch(Supplier.getById(params.row.id))}
              >
                <BiEdit />
              </IconButton>
            </Tooltip>
            <Tooltip title="حذف">
              <IconButton onClick={() => handleDeleteClick(params.row)}>
                <BiTrash />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Supplier.getAll({ search: search, skip: 0, take: 15 }));
      dispatch(setSearchBody({ search: search, skip: 0, take: 15 }));
    }, 500);
    return () => clearTimeout(timeout);
  }, [search]);

  const navigate = useNavigate();

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          المجموع الكلي : {_Supplier.total_amount.toLocaleString()} (IQD)
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  return (
    <Card>
      <CardContent>
        <Box>
          {_InitialSupplierBalance && <InitialSupplierBalanceForm />}
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={{}}>
          <Grid item xs={12}>
            <TextField
              size="small"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              label="بحث عن الاسم "
            />
            <Typography>
              * اذا كان رصيد المورد اكبر من صفر معناه ان الشركة مديونة للمورد
              واذا كان رصيد المورد اصغر من صفر معناه ان المورد مطلوب للشركة
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={
                  _Supplier.data?.map((x, index) => ({
                    ...x,
                    index: index + 1,
                  })) ?? []
                }
                columns={columns}
                pageSize={page}
                page={currentPage}
                rowCount={_Supplier.total}
                loading={loading}
                rowHeight={50}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) => {
                  dispatch(
                    Supplier.getAll({
                      search: search,
                      skip: newPage * 15,
                      take: 15,
                    })
                  );
                  setCurrentPage(newPage);
                }}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  dispatch(
                    Supplier.getAll({
                      search: search,
                      skip: 0,
                      take: newPageSize,
                    })
                  );
                  setPage(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                  Footer: CustomFooter,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SupplierTabel;
