import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
  Divider,
  LinearProgress,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setUpdateDialog,
  resetUpdateForm,
  OrderRechargeCards,
} from "../../../../app/slices/orderRechargeCardsSlice";
import { Controller, useForm } from "react-hook-form";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const OrderRechargeCardNumberForm = ({ ids }) => {
  const [loading, setLoading] = useState(false);

  const _OrderrRechargeCard = useSelector(
    (state) => state.orderRechargeCards.orderRechargeCard
  );

  const { control, reset, handleSubmit } = useForm({
    defaultValues: _OrderrRechargeCard.form,
  });

  const onSubmit = (data) => {
    setLoading(true);
    data.ids = ids;
    dispatch(OrderRechargeCards.updateNumber(data))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    reset({ ..._OrderrRechargeCard.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_OrderrRechargeCard.form]);

  useEffect(() => {
    return () => {
      dispatch(resetUpdateForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const handlDialog = () => {
    dispatch(setUpdateDialog());
  };

  return (
    <Dialog
      open={_OrderrRechargeCard.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="md"
      onClose={() => {
        handlDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          تحديث
        </Typography>
        <Divider sx={{ marginBottom: 1 }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          {_OrderrRechargeCard.loading && (
            <LinearProgress sx={{ marginBottom: 1 }} />
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="order_recharge_number"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="رقم الطلب"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading ? true : false}
                endIcon={loading ? <CircularProgress size={25} /> : null}
              >
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default OrderRechargeCardNumberForm;
