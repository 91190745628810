import { Grid } from "@mui/material";
import React from "react";
import JTransactionTable from "./home/table";
import JTransactionHeader from "./home/header";

const JTransaction = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <JTransactionHeader />
      </Grid>
      <Grid item xs={12}>
        <JTransactionTable />
      </Grid>
    </Grid>
  );
};

export default JTransaction;
