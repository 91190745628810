import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
import { PointOfSales } from "./posSlice";
const serviceApi = factory.get("service");
const initialState = {
  searchBody: {},
  transactionsSearchBody: {},
  service: {
    loading: false,
    dialog: false,
    sendSmsDialog: false,
    simPort: "",
    form: {
      id: null,
      name: "",
      fee: "",
      is_active: true,
    },
  },
  services: {
    loading: false,
    data: [],
    total: 0,
  },
  addService: {
    pos: null,
    dialog: false,
    form: {
      user_id: null,
      service_id: 1,
      supplier_id: 97,
      monthly_fees: 0,
      deduct_fees: true,
    },
  },
};

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setDialog: (state) => {
      state.service.dialog = !state.service.dialog;
    },
    setAddServiceDialog: (state, { payload }) => {
      state.addService.dialog = !state.addService.dialog;
      if (payload) {
        state.addService.pos = payload;
      } else {
        state.addService.pos = null;
      }
    },
    setDataTable: (state, { payload }) => {
      state.services.data = payload.data.data;
      state.services.total = payload.data.total;
      state.addService.form.service_id = payload.data.data[0]?.id ?? null;
      state.addService.form.monthly_fees = payload.data.data[0]?.fee ?? 0;
    },
    setById: (state, { payload }) => {
      state.service.form.id = payload.id;
      state.service.form.name = payload.name;
      state.service.form.fee = payload.fee;
      state.service.form.is_active = payload.is_active;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.service.form = initialState.service.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setAddServiceDialog,
} = serviceSlice.actions;

export default serviceSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("service"));
    const res = await serviceApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("service"));
  } catch (err) {
    dispatch(setLoading("service"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await serviceApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("service"));
    await dispatch(setById(data));

    dispatch(setDialog());
    dispatch(setLoading("service"));
  } catch (err) {
    dispatch(setLoading("service"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await serviceApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await serviceApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const addServiceToPos = (data, _SearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("service"));
    await serviceApi.addServiceToPos(data);
    dispatch(
      showNotification({
        message: "تم اضافة الخدمة بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("service"));
    dispatch(setAddServiceDialog());
    dispatch(PointOfSales.getAll(_SearchBody));
  } catch (err) {
    dispatch(setLoading("service"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const removeServiceFromPos = (data, _SearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("service"));
    await serviceApi.removeServiceFromPos(data);
    dispatch(
      showNotification({
        message: "تم حذف الخدمة بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("service"));
    dispatch(PointOfSales.getAll(_SearchBody));
  } catch (err) {
    dispatch(setLoading("service"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const PosService = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  addServiceToPos,
  removeServiceFromPos,
};
