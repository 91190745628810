import { Card, CardHeader, Typography, Button, Stack } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setDialog, setPageDialog } from "../../../../../app/slices/jomla/safeSlice";
import JSafeForm from "../../form";
import { BsFillSafeFill } from "react-icons/bs";
import { IoAddSharp } from "react-icons/io5";
import JSafeTransfer from "../../transfare";

const JSafeHeader = () => {
  const dispatch = useDispatch();
  const JSafe = useSelector((state) => state.jSafe);

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            الخزنات
          </Typography>
        }
        avatar={<BsFillSafeFill size={20} />}
        action={
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoAddSharp />}
              onClick={() => dispatch(setDialog())}
            >
              اضافة خزنة جديدة
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<BsFillSafeFill />}
              onClick={() => dispatch(setPageDialog())}
            >
              تحويل بين الخزنات
            </Button>
          </Stack>
        }
      />
      {JSafe.jSafe.dialog ? <JSafeForm /> : null}
      {JSafe?.dialog ? <JSafeTransfer /> : null}
    </Card>
  );
};

export default JSafeHeader;
