import dayjs from "dayjs";
import {
  createSlice
} from "@reduxjs/toolkit";
import {
  showNotification
} from "./notificationSlice";
import {
  factory
} from "../../api/apiFactory";
const requestPointsApi = factory.get("requestPoints");
const initialState = {
  searchBody: {},
  requestPoint: {
    loading: false,
    dialog: false,
    form: {
      id: "",
    },
  },
  requestPoints: {
    loading: false,
    data: [],
    total: 0,
  },
}

export const requestPointsSlice = createSlice({
  name: "requestPoints",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.requestPoint.dialog = !state.requestPoint.dialog
    },
    setDataTable: (state, {
      payload
    }) => {
      state.requestPoints.data = payload.data.data
      state.requestPoints.total = payload.data.total
    },
    setById: (state, {
      payload
    }) => {
      state.requestPoint.form.id = payload.id
      state.requestPoint.form.name = payload.name
    },
    setLoading: (state, action) => {
      state.requestPoint.loading = !state.requestPoint.loading;
    },
    resetForm: (state, action) => {
      state.requestPoint.form = initialState.requestPoint.form
    },
    setSearchBody: (state, {
      payload
    }) => {
      state.searchBody = payload;
    },
  }
})

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody
} = requestPointsSlice.actions

export default requestPointsSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('requestPoints'));
    const res = await requestPointsApi.getAll(params)
    dispatch(setDataTable(res));
    dispatch(setLoading('requestPoints'));
  } catch (err) {
    dispatch(setLoading('requestPoints'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const deleteById = (id, searchBody) => async (dispatch) => {
  try {
    await requestPointsApi.delete(id)
    dispatch(showNotification({
      message: "تم الحذف بنجاح",
      type: "success"
    }));
    dispatch(getAll(searchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const approve = (id, data, searchBody) => async (dispatch) => {
  try {
    const res = await requestPointsApi.approve(id, data);
    dispatch(showNotification({
      message: 'تمت العملية بنجاح',
      type: "success"
    }));
    dispatch(getAll(searchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};


export const RequestPoints = {
  getAll,
  deleteById,
  approve,
}