import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "../notificationSlice";
import { factory } from "../../../api/apiFactory";
import { GiSeaTurtle } from "react-icons/gi";
const ExternalOrderApi = factory.get("externalOrder");
const initialState = {

  ExternalOrders: {
    loading: false,
    data: [],
    total: 0,
    form:{
      amount:0,
      note:'',
    }
  },
  
};

export const externalOrderSlice = createSlice({
  name: "ExternalOrder",
  initialState,
  reducers: {
    setDataTable: (state, { payload }) => {
      state.ExternalOrders.data = payload.data.data;
      state.ExternalOrders.total = payload.data.total;
    },

    setLoading: (state, action) => {
      state.ExternalOrders.loading = !state.ExternalOrders.loading;
    },
  },
});

export const {
  setLoading,
  setDataTable,
  resetForm,

} = externalOrderSlice.actions;

export default externalOrderSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("ExternalOrders"));
    const res = await ExternalOrderApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("ExternalOrders"));
  } catch (err) {
    dispatch(setLoading("ExternalOrders"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const create = (data) => async (dispatch) => {
  try {
    const res = await ExternalOrderApi.create(data);
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};


export const ExternalOrder = {
  getAll,
  create,
};
