import {
  createSlice
} from "@reduxjs/toolkit";
import {
  showNotification
} from "./notificationSlice";
import {
  factory
} from "../../api/apiFactory";
const provincesApi = factory.get("provinces");
const initialState = {
  searchBody: {},
  loading: false,
  provinces: {
    data: [],
    total: 0,
  },
  openCityDialog: false,
  selectedProvince: {
    id: null,
    name: ""
  },
  cities: {
    dialog: false,
    data: [],
    total: 0,
    form: {
      id: null,
      provinceId: "",
      name: "",
    },
  },
}

export const provincesSlice = createSlice({
  name: "provinces",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.cities.dialog = !state.cities.dialog
    },
    setCityDialog: (state, action) => {
      state.openCityDialog = !state.openCityDialog
    },
    setSelectedProvince: (state, {
      payload
    }) => {
      state.selectedProvince = payload
    },
    setDataTable: (state, {
      payload
    }) => {
      state.provinces.data = payload.data.data
    },
    setCityTable: (state, {
      payload
    }) => {
      state.cities.data = payload
    },
    setById: (state, {
      payload
    }) => {
      state.cities.form = payload
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetForm: (state, action) => {
      state.cities.form = initialState.cities.form
    },
    setSearchBody: (state, {
      payload
    }) => {
      state.searchBody = payload;
    },
  }
})

export const {
  setLoading,
  setDialog,
  setCityDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setCityTable,
  setSelectedProvince,
} = provincesSlice.actions

export default provincesSlice.reducer;

//axios
const getAll = (data) => async (dispatch) => {
  try {
    dispatch(setLoading('provinces'));
    const res = await provincesApi.getAll(data);
    dispatch(setDataTable(res));
    dispatch(setLoading('provinces'));
  } catch (err) {
    dispatch(setLoading('provinces'));
    throw new Error(err);
  }
};
const deleteById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading('provinces'));
    await provincesApi.delete(id)
    dispatch(showNotification({
      message: "City deleted successfully",
      type: "success"
    }));
    dispatch(getAll());
    dispatch(setLoading('provinces'));
  } catch (err) {
    dispatch(setLoading('provinces'));
    dispatch(showNotification({
      message: err.response.data.message,
      type: "error"
    }));
    throw new Error(err);
  }
};

const create = (data, searchBody) => async (dispatch) => {
  try {
    dispatch(setLoading('provinces'));
    await provincesApi.create(data, searchBody)
    dispatch(showNotification({
      message: "City added successfully",
      type: "success"
    }));
    dispatch(setDialog(searchBody))
    dispatch(getAll())
    dispatch(setLoading('provinces'));
  } catch (err) {
    dispatch(setLoading('provinces'));
    dispatch(showNotification({
      message: err.response.data.message,
      type: "error"
    }));
    throw new Error(err);
  }
};
const update = (id, data, searchBody) => async (dispatch) => {
  try {
    dispatch(setLoading('provinces'));
    await provincesApi.update(id, data, searchBody)
    dispatch(showNotification({
      message: "City updated successfully",
      type: "success"
    }));
    dispatch(setDialog(searchBody))
    dispatch(getAll())
    dispatch(setLoading('provinces'));
  } catch (err) {
    dispatch(setLoading('provinces'));
    dispatch(showNotification({
      message: err.response.data.message,
      type: "error"
    }));
    throw new Error(err);
  }
};

export const Provinces = {
  getAll,
  deleteById,
  create,
  update
}