import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  useMediaQuery,
  Typography,
  TablePagination,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash } from "react-icons/bi";
import { MdNotificationImportant } from "react-icons/md";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Notification,
  setSearchBody,
} from "../../../../app/slices/appNotificationSlice";
import dayjs from "dayjs";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import { useTheme } from "@emotion/react";

const NotificationTabel = () => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const _Notification = useSelector(
    (state) => state.appNotification.notifications
  );
  const loading = useSelector(
    (state) => state.appNotification.notification.loading
  );
  const _SearchBody = useSelector((state) => state.appNotification.searchBody);
  const _User = useSelector((state) => state.user.userInfo);

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(Notification.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "title",
      headerName: "العنوان",
      flex: 1,
    },
    {
      field: "description",
      headerName: "الوصف",
      flex: 2,
    },
    {
      field: "target_users",
      headerName: "المستخدمين المستهدفين",
      flex: 0.7,
      hide: _User.is_representative,
    },
    {
      field: "created_at",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => {
        return (
          <>{dayjs(params.row.created_at).format("HH:mm / YYYY-MM-DD ")}</>
        );
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => dispatch(Notification.getById(params.row.id))}
            >
              <MdNotificationImportant />
            </IconButton>
            <IconButton onClick={() => handleDeleteClick(params.row)}>
              <BiTrash />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Notification.getAll({ search: search, skip: 0, take: 15 }));
      dispatch(setSearchBody({ search: search, skip: 0, take: 15 }));
    }, 500);
    return () => clearTimeout(timeout);
  }, [search]);

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.title}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={{}}>
          {smallScreen ? (
            _Notification?.data?.length > 0 ? (
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : null}
                {_Notification.data.map((data, index) => (
                  <Card sx={{ marginBottom: 0.5 }}>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography>
                            العنوان: <strong>{data?.title}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            الوصف: <strong>{data?.description}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <>
                            <IconButton
                              onClick={() =>
                                dispatch(Notification.getById(data.id))
                              }
                            >
                              <MdNotificationImportant />
                            </IconButton>
                            <IconButton onClick={() => handleDeleteClick(data)}>
                              <BiTrash />
                            </IconButton>
                          </>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
                <Card sx={{ mt: 2, width: "100%" }}>
                  <TablePagination
                    component="div"
                    count={_Notification.total}
                    rowsPerPage={page}
                    page={currentPage}
                    onPageChange={(e, newPage) => {
                      dispatch(
                        Notification.getAll({
                          search: search,
                          skip: newPage * 15,
                          take: 15,
                        })
                      );
                      setCurrentPage(newPage);
                    }}
                    rowsPerPageOptions={[15, 25, 50, 100]}
                    onRowsPerPageChange={(event) => {
                      dispatch(
                        Notification.getAll({
                          search: search,
                          skip: 0,
                          take: event.target.value,
                        })
                      );
                      setPage(event.target.value);
                    }}
                  />
                </Card>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Card>
                  <Typography m={2} variant="h5" color="primary" align="center">
                    لا توجد بيانات
                  </Typography>
                </Card>
              </Grid>
            )
          ) : (
            <Grid item xs={12}>
              <Box sx={{ height: "70vh", width: "100%" }}>
                {_Notification.data ? (
                  <DataGrid
                    rows={_Notification.data}
                    columns={columns}
                    pageSize={page}
                    rowCount={_Notification.total}
                    loading={loading}
                    rowHeight={50}
                    pagination
                    paginationMode="server"
                    selectionModel={[]}
                    onPageChange={(newPage) =>
                      dispatch(
                        Notification.getAll({
                          search: search,
                          skip: newPage * 15,
                          take: 15,
                        })
                      )
                    }
                    rowsPerPageOptions={[15, 25, 50, 100]}
                    onPageSizeChange={(newPageSize) => {
                      dispatch(
                        Notification.getAll({
                          search: search,
                          skip: 0,
                          take: newPageSize,
                        })
                      );
                      setPage(newPageSize);
                    }}
                    components={{
                      LoadingOverlay: LinearProgress,
                    }}
                  />
                ) : (
                  <LinearProgress />
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default NotificationTabel;
