import dayjs from "dayjs";
import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const supplierApi = factory.get("supplier");
const initialState = {
  searchBody: {},
  supplier: {
    loading: false,
    dialog: false,
    form: {
      id: "",
      name: "",
      province_id: "",
      province_name: "",
    },
  },
  suppliers: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
  suppliersList: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
  initialBalance: {
    dialog: false,
    supplier_id: null,
    form: {
      balance: "",
    },
  },
};

export const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.supplier.dialog = !state.supplier.dialog;
    },

    setDataTable: (state, { payload }) => {
      state.suppliers.data = payload.data.data;
      state.suppliers.total = payload.data.total;
      state.suppliers.total_amount = payload.data.total_amount;
    },
    setDatalist: (state, { payload }) => {
      state.suppliersList.data = payload.data.data;
    },
    setById: (state, { payload }) => {
      state.supplier.form.id = payload.id;
      state.supplier.form.name = payload.name;
      state.supplier.form.balance = payload.balance;
      state.supplier.form.province_id = payload.province_id || "";
      state.supplier.form.province_name = payload.province_name || "";
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.supplier.form = initialState.supplier.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
    setInitialBalanceDialog: (state, { payload }) => {
      state.initialBalance.dialog = !state.initialBalance.dialog;
      state.initialBalance.supplier_id = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setReceiveCashDialog,
  setPayCashDialog,
  setDatalist,
  setInitialBalanceDialog,
} = supplierSlice.actions;

export default supplierSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("supplier"));
    const res = await supplierApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("supplier"));
  } catch (err) {
    dispatch(setLoading("supplier"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("supplier"));
    const res = await supplierApi.getAllList(params);
    dispatch(setDatalist(res));
    dispatch(setLoading("supplier"));
  } catch (err) {
    dispatch(setLoading("supplier"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await supplierApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("supplier"));
    const res = await supplierApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("supplier"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("supplier"));
    throw new Error(err);
  }
};
const getByIdData = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("supplier"));
    const res = await supplierApi.getById(id);
    dispatch(setById(res.data.data[0]));
    dispatch(setLoading("supplier"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("supplier"));
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await supplierApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await supplierApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const initialBalance = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await supplierApi.initialBalance(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    dispatch(setInitialBalanceDialog(null));
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const Supplier = {
  getAll,
  deleteById,
  getById,
  getByIdData,
  create,
  update,
  getAllList,
  initialBalance,
};
