import Typography from "@mui/material/Typography";
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  Slide,
  InputAdornment,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { ProductSupplier, setTransactionsDialog } from "../../../../../app/slices/productsSupplierSlice";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useParams } from "react-router";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PayCashForm = (props) => {
  const dispatch = useDispatch();
  const { product_supplier_id } = useParams();
  const _ProductSupplierPayCash = useSelector((state) => state.productSupplier.transactions);
  const _SearchBody = useSelector((state) => state.productSupplier.transactions.searchBody);

  const { control, reset, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: {},
  });

  const onSubmit = (data) => {
    dispatch(ProductSupplier.payCash({
      product_supplier_id: parseInt(product_supplier_id),
      wage_amount: data.wage_amount,
      amount: data.amount,
      note: data.note
    }, _SearchBody))
  };

  const handleDialog = () => {
    dispatch(setTransactionsDialog());
  };

  useEffect(() => {
    setValue('wage_amount', watch('amount') * watch('wage_amount_percent') / 100);
  }, [watch('amount'), watch('wage_amount_percent')])

  return (
    <Dialog
      open={_ProductSupplierPayCash.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={handleDialog}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {'تسديد مبالغ المجهز'}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="amount"
                control={control}
                render={({ field: { onChange, ...renderProps } }) => {
                  return (
                    <NumericFormat
                      customInput={TextField}
                      label='المبلغ'
                      size="small"
                      required
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                      }}
                      {...renderProps}
                      onValueChange={target => {
                        onChange();
                        setValue('amount', target.floatValue);
                      }}
                      isNumericString
                      thousandSeparator
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="wage_amount_percent"
                control={control}
                render={({ field: { onChange, ...renderProps } }) => {
                  return (
                    <NumericFormat
                      customInput={TextField}
                      label='نسبة الشركة'
                      size="small"
                      required
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                      }}
                      {...renderProps}
                      onValueChange={target => {
                        onChange();
                        setValue('wage_amount_percent', target.floatValue);
                      }}
                      isNumericString
                      thousandSeparator
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="wage_amount"
                control={control}
                render={({ field: { onChange, ...renderProps } }) => {
                  return (
                    <NumericFormat
                      customInput={TextField}
                      label='المبلغ'
                      size="small"
                      disabled
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                      }}
                      {...renderProps}
                      isNumericString
                      thousandSeparator
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="الملاحظة"
                    fullWidth
                    {...field}
                    sx={{ marginBottom: 1 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PayCashForm;
