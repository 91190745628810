import {
  Grid,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
  IconButton,
  Dialog,
  Typography,
  DialogContent,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Reports } from "../../../../../app/slices/reportsSlice";
import dayjs from "dayjs";
import { IoBarcodeOutline } from "react-icons/io5";
import DateRangepicker from "../../../../../helpers/dateRangePicker";
import { SelectPos } from "../../../../../components/Selectors/SelectPos";
import { SelectItem } from "../../../../../components/Selectors/SelectItems";
import { SelectRepresentative } from "../../../../../components/Selectors/SelectRepresentative";
import { MdOutlineClear, MdOutlineSearch } from "react-icons/md";
import { SelectGroup } from "../../../../../components/Selectors/SelectGroup";
import { SelectSubCategory } from "../../../../../components/Selectors/SelectSubCategory";
import { SelectProvince } from "../../../../../components/Selectors/SelectProvince";
import { SelectCity } from "../../../../../components/Selectors/SelectCity";
import { setCityTable } from "../../../../../app/slices/provincesSlice";
const OrdersTabel = () => {
  const [reportType, setReportType] = useState("basic");

  const handleChange = (event, newType) => {
    setReportType(newType);
  };
  const [search, setSearch] = useState({
    start_date: dayjs().format("YYYY-MM-01"),
    end_date: dayjs().format("YYYY-MM-DD"),
    pos: [],
    items: [],
    rep: [],
    group_id: null,
    province_id: null,
  });
  const [soldPinsFilter, setSoldPinsFilter] = useState({
    pos_user_id: null,
    item_id: null,
  });

  const [result, setResult] = useState({ total_amounts: 0, total_revenus: 0 });
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const _Orders = useSelector((state) => state.reports.orders);
  const _OrdersDetailed = useSelector((state) => state.reports.ordersDetailed);
  const _PosOrders = useSelector((state) => state.reports.posOrders);
  const _OrdersPinsDetailed = useSelector(
    (state) => state.reports.ordersPinsDetails
  );
  const loading = useSelector((state) => state.reports.reports.loading);
  const _items = useSelector((state) => state.items.itemsList.data);
  const _provinces = useSelector((state) => state.provinces.provinces.data);
  const _Cities = useSelector((state) => state.provinces.cities.data);
  const _PointOfsales = useSelector(
    (state) => state.pointOfsales.posesList.data
  );
  const _SoldPins = useSelector((state) => state.reports.soldPins);
  const _Group = useSelector((state) => state.group.groupsList.data);

  var is_admin = localStorage.getItem("role") == "super_admin";
  var is_team_leader = localStorage.getItem("role") == "team_leader";

  const [provinceId, setProvinceId] = useState(false);

  useEffect(() => {
    const selectedCities = _provinces?.filter((x) =>
      x.id === provinceId ? x.city : null
    )[0];
    dispatch(setCityTable(selectedCities?.city));
  }, [provinceId, _provinces, dispatch]);

  var columns = [
    {
      field: "id",
      headerName: "#",
      flex: 0.4,
    },
    {
      field: "pos_name",
      headerName: "اسم نقطة البيع",
      flex: 1,
    },
    {
      field: "item_name",
      headerName: "اسم المنتج",
      flex: 1,
    },
    {
      field: "count",
      headerName: "العدد",
      flex: 1,
      renderCell: (params) => {
        return <>{parseInt(params.row.count)}</>;
      },
    },
    {
      field: "total_amount",
      headerName: "المبلغ الكلي (IQD)",
      flex: 1,
      renderCell: (params) => {
        return <>{parseInt(params.row.total_amount).toLocaleString()}</>;
      },
    },
    {
      field: "revenue",
      headerName: "الارباح (IQD)",
      flex: 1,
      renderCell: (params) => {
        return <>{parseInt(params.row.revenue).toLocaleString()}</>;
      },
    },
    {
      field: "pins_details",
      headerName: "تفاصيل الكودات",
      flex: 1,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              setOpenDialog(true);
              setSoldPinsFilter({
                pos_user_id: params.row.pos_user_id,
                item_id: params.row.item_id,
              });
            }}
          >
            <IoBarcodeOutline />
          </IconButton>
        );
      },
    },
  ];

  columns =
    is_admin || is_team_leader
      ? columns
      : columns.filter((x) => x.field != "pins_details");
  columns =
    is_admin || is_team_leader
      ? columns
      : columns.filter((x) => x.field != "total_amount");
  columns = is_admin ? columns : columns.filter((x) => x.field != "revenue");
  columns =
    reportType == "detailed"
      ? columns
      : columns.filter((x) => x.field != "pos_name");
  columns =
    reportType == "detailed"
      ? columns
      : columns.filter((x) => x.field != "pins_details");
  const pinsColumns = [
    {
      field: "id",
      headerName: "#",
      flex: 1,
    },
    {
      field: "serial_number",
      headerName: "الرقم التسلسلي",
      flex: 1,
    },
    {
      field: "pin_number",
      headerName: "الرقم السري",
      flex: 1,
    },
    {
      field: "order_date",
      headerName: "تاريخ البيع",
      flex: 1,
      valueGetter: (params) =>
        params.row.order_date
          ? dayjs(params.row.order_date).format(" YYYY-MM-DD")
          : "",
      renderCell: (params) => {
        return <>{dayjs(params.row.order_date).format("YYYY-MM-DD")}</>;
      },
    },
    {
      field: "order_id",
      headerName: "رقم الطلب",
      flex: 1,
    },
  ];

  const ordersPinsColumns = [
    {
      field: "id",
      headerName: "#",
      flex: 1,
    },
    {
      field: "serial_number",
      headerName: "الرقم التسلسلي",
      flex: 1,
    },
    {
      field: "pin_number",
      headerName: "الرقم السري",
      flex: 1,
    },
    {
      field: "order_date",
      headerName: "تاريخ البيع",
      flex: 1,
      valueGetter: (params) =>
        params.row.order_date
          ? dayjs(params.row.order_date).format(" YYYY-MM-DD")
          : "",
      renderCell: (params) => {
        return <>{dayjs(params.row.order_date).format("YYYY-MM-DD")}</>;
      },
    },
    {
      field: "order_id",
      headerName: "رقم الطلب",
      flex: 1,
    },
    {
      field: "item_name",
      headerName: "اسم المنتج",
      flex: 1,
    },
    {
      field: "pos_name",
      headerName: "اسم نقطة البيع",
      flex: 1,
    },
  ];

  const posOrdersColumns = [
    {
      field: "id",
      headerName: "#",
      flex: 0.5,
    },
    {
      field: "name",
      headerName: "اسم النقطة",
      flex: 1,
    },
    {
      field: "pos_number",
      headerName: "رقم النقطة",
      flex: 1,
    },
    {
      field: "total_amount",
      headerName: "مجموع المبيعات",
      flex: 1,
      renderCell: (params) => {
        return <>{parseInt(params.row.total_amount).toLocaleString()}</>;
      },
    },
  ];
  useEffect(() => {
    var total_amount = 0;
    var total_revenu = 0;
    _Orders.data.forEach((element) => {
      total_amount += parseInt(element.total_amount);
      total_revenu += parseInt(element.revenue);
    });
    setResult({ total_amounts: total_amount, total_revenus: total_revenu });
  }, [_Orders.data]);

  useEffect(() => {
    var total_amount = 0;
    var total_revenu = 0;
    _OrdersDetailed.data.forEach((element) => {
      total_amount += parseInt(element.total_amount);
      total_revenu += parseInt(element.revenue);
    });
    setResult({ total_amounts: total_amount, total_revenus: total_revenu });
  }, [_OrdersDetailed.data]);

  useEffect(() => {
    if (reportType == "detailed") {
      dispatch(Reports.getOrdersDetailed(search));
    } else if (reportType == "basic") {
      dispatch(Reports.getOrders(search));
    } else if (reportType === "pinsDetailes") {
      dispatch(
        Reports.getordersPinsDetails({
          start_date: search.start_date,
          end_date: search.end_date,
          pos_id: search.pos,
          item_id: search.items,
        })
      );
    } else if (reportType === "posOrders") {
      dispatch(
        Reports.getPosOrders({
          start_date: search.start_date,
          end_date: search.end_date,
          min_orders_amount: search.min_orders_amount,
        })
      );
    }
  }, [search, reportType]);

  useEffect(() => {
    if (openDialog) {
      dispatch(
        Reports.getSoldPins({
          start_date: search.start_date,
          end_date: search.end_date,
          pos_user_id: soldPinsFilter.pos_user_id,
          item_id: soldPinsFilter.item_id,
          // skip: 0,
          // take: 15
        })
      );
    }
  }, [openDialog]);

  const customToolbar = () => (
    <GridToolbarContainer>
      <Stack direction={"row"} spacing={3}>
        <GridToolbarExport
          csvOptions={{
            fileName: "تقرير المبيعات | نظام دجلة",
            utf8WithBom: true,
          }}
        />
        {(is_admin || is_team_leader) &&
          (reportType == "basic" || reportType == "detailed") && (
            <Typography sx={{ marginTop: 1 }}>
              مجموع المبيعات الكلي: {result.total_amounts.toLocaleString()}
            </Typography>
          )}
        {is_admin &&
          reportType &&
          (reportType == "basic" || reportType == "detailed") && (
            <Typography sx={{ marginTop: 1 }}>
              مجموع الارباح الكلي: {result.total_revenus.toLocaleString()}
            </Typography>
          )}
      </Stack>
    </GridToolbarContainer>
  );

  return (
    <Card>
      <CardContent>
        <Dialog
          open={openDialog}
          keepMounted
          fullWidth={true}
          dir="rtl"
          onClose={() => {
            setOpenDialog(false);
          }}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="md"
        >
          <DialogContent>
            <Typography
              mb={3}
              variant="h4"
              color={"purple"}
              gutterBottom
              component="div"
            >
              تفاصيل الكودات
            </Typography>
            <Box sx={{ height: "70vh" }}>
              {_SoldPins.data ? (
                <DataGrid
                  rows={_SoldPins.data.map((data, index) => {
                    return { ...data, id: index + 1 };
                  })}
                  columns={pinsColumns}
                  rowCount={_SoldPins.total}
                  loading={loading}
                  rowHeight={30}
                  components={{
                    LoadingOverlay: LinearProgress,
                    Toolbar: customToolbar,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </DialogContent>
        </Dialog>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12}>
            <ToggleButtonGroup
              color="primary"
              value={reportType}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              {!is_team_leader && (
                <ToggleButton
                  value="basic"
                  onClick={() =>
                    setSearch({
                      ...search,
                      start_date: dayjs().format("YYYY-MM-01"),
                      end_date: dayjs().format("YYYY-MM-DD"),
                    })
                  }
                >
                  التقرير العادي
                </ToggleButton>
              )}
              {!is_team_leader && (
                <ToggleButton
                  value="detailed"
                  onClick={() =>
                    setSearch({
                      ...search,
                      start_date: dayjs().format("YYYY-MM-DD"),
                      end_date: dayjs().format("YYYY-MM-DD"),
                    })
                  }
                >
                  التقرير المفصل
                </ToggleButton>
              )}
              {(is_admin || is_team_leader) && (
                <ToggleButton
                  value="pinsDetailes"
                  onClick={() =>
                    setSearch({
                      ...search,
                      start_date: dayjs().format("YYYY-MM-DD"),
                      end_date: dayjs().format("YYYY-MM-DD"),
                    })
                  }
                >
                  تفاصيل الكودات
                </ToggleButton>
              )}
              {(is_admin || is_team_leader) && (
                <ToggleButton
                  value="posOrders"
                  onClick={() =>
                    setSearch({
                      ...search,
                      start_date: dayjs().format("YYYY-MM-DD"),
                      end_date: dayjs().format("YYYY-MM-DD"),
                    })
                  }
                >
                  مبيعات النقاط
                </ToggleButton>
              )}
            </ToggleButtonGroup>
          </Grid>
          {!is_team_leader && (
            <Grid item xs={12} sx={{ marginBottom: 2 }}>
              <Divider />
            </Grid>
          )}
          <Grid item xs={3}>
            <DateRangepicker
              disabled={loading}
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          {(is_admin || is_team_leader) && reportType != "posOrders" ? (
            <Grid item xs={2}>
              <SelectPos
                disabled={loading}
                multiple
                onChange={(event, value) => {
                  var ids = value.map((x) => x.id);
                  setSearch({
                    ...search,
                    pos: value ? ids : null,
                  });
                }}
                value={
                  _PointOfsales?.filter((x) => search.pos?.includes(x.id)) ?? []
                }
              />
            </Grid>
          ) : null}
          {is_admin || is_team_leader
            ? (reportType == "detailed" || reportType == "basic") && (
                <Grid item xs={2}>
                  <SelectGroup
                    disabled={loading}
                    onChange={(e, newValue) => {
                      setSearch({
                        ...search,
                        group_id: newValue ? newValue.id : null,
                      });
                    }}
                    value={
                      _Group.filter(
                        (group) => group.id == search.group_id
                      )[0] ?? null
                    }
                  />
                </Grid>
              )
            : null}
          {is_admin || is_team_leader
            ? (reportType == "detailed" || reportType == "basic") && (
                <Grid item xs={2}>
                  <SelectProvince
                    disabled={loading}
                    onChange={(e, newValue) => {
                      setProvinceId(newValue ? newValue.id : null);
                      setSearch({
                        ...search,
                        province_id: newValue ? newValue.id : null,
                      });
                    }}
                    value={
                      _provinces?.filter((x) => x.id === provinceId)[0] ?? null
                    }
                  />
                </Grid>
              )
            : null}
          {is_admin || is_team_leader
            ? reportType == "basic" && (
                <Grid item xs={2}>
                  <SelectCity
                    onChange={(e, newValue) => {
                      setSearch({
                        ...search,
                        city_id: newValue ? newValue.id : null,
                      });
                    }}
                    value={
                      _Cities?.filter((x) => x.id === search.city_id)[0] ?? null
                    }
                  />
                </Grid>
              )
            : null}
          {is_admin && reportType != "posOrders" && (
            <Grid item xs={2}>
              <SelectItem
                disabled={loading}
                multiple
                onChange={(event, value) => {
                  var ids = value.map((x) => x.id);
                  setSearch({
                    ...search,
                    items: value ? ids : null,
                  });
                }}
                value={
                  _items?.filter((x) => search.items?.includes(x.id))
                    ? _items?.filter((x) => search.items?.includes(x.id))
                    : []
                }
              />
            </Grid>
          )}
          {is_admin && reportType != "posOrders" && (
            <Grid item xs={2}>
              <SelectSubCategory
                disabled={loading}
                onChange={(event, value) => {
                  setSearch({
                    ...search,
                    sub_category_id: value ? value.id : null,
                  });
                }}
              />
            </Grid>
          )}
          {(is_admin || is_team_leader) &&
            (reportType == "detailed" || reportType == "basic") && (
              <Grid item xs={2}>
                <SelectRepresentative
                  disabled={loading}
                  onChange={(event, value) => {
                    setSearch({
                      ...search,
                      rep: value ? value.id : null,
                    });
                  }}
                />
              </Grid>
            )}
          {is_admin || is_team_leader
            ? reportType == "posOrders" && (
                <Grid item xs={2}>
                  <TextField
                    label="اقل قيمة للمبيعات"
                    size="small"
                    value={search.min_orders_amount ?? ""}
                    onChange={(e) => {
                      setSearch({
                        ...search,
                        min_orders_amount: e.target.value,
                      });
                    }}
                  />
                </Grid>
              )
            : null}
          <Grid item xs={1}>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <IconButton
                disabled={loading}
                onClick={() => {
                  setSearch({ ...search });
                }}
              >
                <Tooltip title="بحث">
                  <span>
                    <MdOutlineSearch />
                  </span>
                </Tooltip>
              </IconButton>
              <IconButton
                disabled={loading}
                onClick={() => {
                  setSearch({
                    start_date:
                      reportType == "detailed"
                        ? dayjs().format("YYYY-MM-DD")
                        : dayjs().format("YYYY-MM-01"),
                    end_date: dayjs().format("YYYY-MM-DD"),
                    pos: [],
                    items: [],
                    rep: [],
                  });
                  setProvinceId(null);
                }}
              >
                <Tooltip title="الغاء الفلتر">
                  <span>
                    <MdOutlineClear />
                  </span>
                </Tooltip>
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {reportType == "detailed" ? (
              <Box sx={{ height: "64vh", width: "100%" }}>
                {_OrdersDetailed.data ? (
                  <DataGrid
                    rows={_OrdersDetailed.data?.map((data, index) => {
                      return { ...data, id: index + 1 };
                    })}
                    getRowId={(row) => row.id}
                    columns={columns}
                    rowCount={_OrdersDetailed.total}
                    loading={loading}
                    rowHeight={30}
                    selectionModel={[]}
                    components={{
                      LoadingOverlay: LinearProgress,
                      Toolbar: customToolbar,
                    }}
                  />
                ) : (
                  <LinearProgress />
                )}
              </Box>
            ) : reportType == "basic" ? (
              <Box sx={{ height: "64vh", width: "100%" }}>
                {_Orders.data ? (
                  <DataGrid
                    rows={_Orders.data?.map((data, index) => {
                      return { ...data, id: index + 1 };
                    })}
                    getRowId={(row) => row.id}
                    columns={columns}
                    rowCount={_Orders.total}
                    loading={loading}
                    rowHeight={30}
                    selectionModel={[]}
                    components={{
                      LoadingOverlay: LinearProgress,
                      Toolbar: customToolbar,
                    }}
                  />
                ) : (
                  <LinearProgress />
                )}
              </Box>
            ) : reportType == "posOrders" ? (
              <Box sx={{ height: "64vh", width: "100%" }}>
                {_PosOrders.data ? (
                  <DataGrid
                    rows={_PosOrders.data?.map((data, index) => {
                      return { ...data, id: index + 1 };
                    })}
                    getRowId={(row) => row.id}
                    columns={posOrdersColumns}
                    rowCount={_PosOrders.total}
                    loading={loading}
                    rowHeight={30}
                    selectionModel={[]}
                    components={{
                      LoadingOverlay: LinearProgress,
                      Toolbar: customToolbar,
                    }}
                  />
                ) : (
                  <LinearProgress />
                )}
              </Box>
            ) : (
              <Box sx={{ height: "64vh", width: "100%" }}>
                <DataGrid
                  rows={_OrdersPinsDetailed.data?.map((data, index) => {
                    return { ...data, id: index + 1 };
                  })}
                  getRowId={(row) => row.id}
                  columns={ordersPinsColumns}
                  rowCount={_Orders.total}
                  loading={loading}
                  rowHeight={30}
                  selectionModel={[]}
                  components={{
                    LoadingOverlay: LinearProgress,
                    Toolbar: customToolbar,
                  }}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OrdersTabel;
