import { Card, CardHeader, CardContent, Typography, Grid } from "@mui/material";
import React from "react";
import BannerHeader from "./home/header";
import BannerTabel from "./home/table";

const Banner = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <BannerHeader />
      </Grid>
      <Grid item xs={12}>
        <BannerTabel />
      </Grid>
    </Grid>
  );
};

export default Banner;
