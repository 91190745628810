import { Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";

const AsiaBillsHeader = () => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            فواتير الاسيا
          </Typography>
        }
        avatar={<HiOutlineClipboardDocumentList size={20} />}
      />
    </Card>
  );
};

export default AsiaBillsHeader;
