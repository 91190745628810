import { Grid } from "@mui/material";
import React from "react";
import JPosHeader from "./home/header";
import JPosTable from "./home/table";

const JPos = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <JPosHeader />
      </Grid>
      <Grid item xs={12}>
        <JPosTable />
      </Grid>
    </Grid>
  );
};

export default JPos;
