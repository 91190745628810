import Typography from "@mui/material/Typography";
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  Slide,
  Switch,
  InputAdornment,
  FormControlLabel,
  Box,
  Stack,
} from "@mui/material";
import { forwardRef } from "react";
import { Tickets, setAddReplyDialog } from "../../../app/slices/ticketsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import FilePondInput from "../../../helpers/FilePondInput";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddReplyForm = (props) => {
  const dispatch = useDispatch();
  const _Ticket = useSelector((state) => state.tickets);

  const { control, reset, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: {},
  });

  const onSubmit = (data) => {
    const info = new FormData();
    info.append("ticket_id", props.id);
    info.append("content", data.content);
    info.append("is_public", Number(data.is_public));
    data.attachments &&
      data.attachments.map((a) => info.append("attachments[]", a));

    dispatch(Tickets.addReply(info)).then((x) => {
      dispatch(setAddReplyDialog());
    });
  };

  const handleDialog = () => {
    dispatch(setAddReplyDialog());
  };

  return (
    <Dialog
      open={_Ticket.addReplyDialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={handleDialog}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"اضافة تعليق"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="content"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="التعليق"
                    fullWidth
                    {...field}
                    sx={{ marginBottom: 1 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="attachments"
                control={control}
                render={({ field }) => (
                  <Box>
                    <FilePondInput
                      field={field}
                      required={false}
                      multiple={true}
                    />
                  </Box>
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="is_public"
                control={control}
                defaultValue={true}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) =>
                          setValue("is_public", e.target.checked)
                        }
                      />
                    }
                    label={field.value ? "عام" : "خاص"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddReplyForm;
