import {
  createSlice
} from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
const notificationApi = factory.get("notification");
const initialState = {
  open: {

  },
  data: {
    show: false,
    message: "test",
    type: "success"
  },

}

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification: (state, {
      payload
    }) => {
      state.data.show = payload.show;
      state.data.message = payload.message;
      state.data.type = payload.type;
    },
    setNotificationOpen: (state, {
      payload
    }) => {
      state.open = payload
    },
  }
})

export const {
  setNotification,
  setNotificationOpen
} = notificationSlice.actions

export default notificationSlice.reducer;

//axios 
export const showNotification = (data) => async (dispatch) => {
  try {
    data.show = true
    dispatch(setNotification(data));
  } catch (err) {
    throw new Error(err);
  }
};

export const hideNotification = (data) => async (dispatch) => {
  try {
    dispatch(setNotification({
      message: "",
      type: data.type,
      show: false
    }));
  } catch (err) {
    throw new Error(err);
  }

};

export const openStatistics = (data) => async (dispatch) => {
  try {
    const res = await notificationApi.getNotificationOpen();
    dispatch(setNotificationOpen(res.data.data));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};