import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/investor?${params}`);
  },
  getAllList() {
    return api.post(`/api/v1/investor/list`);
  },
  getById(id) {
    return api.get(`/api/v1/investor/${id}`);
  },
  update(id, data) {
    return api.put(`/api/v1/investor/${id}`, data);
  },
  create(data) {
    return api.post(`/api/v1/investor`, data);
  },
  delete(id) {
    return api.delete(`/api/v1/investor/${id}`);
  },
  deposit(id, data) {
    return api.put(`/api/v1/investor/deposit/${id}`, data);
  },
  withdraw(id, data) {
    return api.put(`/api/v1/investor/withdraw/${id}`, data);
  },
  investorTransaction(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/transaction-investor?${params}`);
  },
};
