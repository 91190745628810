import {
  Card,
  Typography,
  Grid,
  Stack,
  Avatar,
  Box,
  CircularProgress,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  LinearProgress,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { User } from "../../../app/slices/userSlice";
import { CgProfile } from "react-icons/cg";
import { HiOutlineMail } from "react-icons/hi";
import { BiMobileAlt, BiTransferAlt } from "react-icons/bi";
import { SiOpenaccess } from "react-icons/si";
import { MdOutlineMoneyOffCsred } from "react-icons/md";
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineUser,
  AiOutlineDollarCircle,
} from "react-icons/ai";
import ConfirmationDialog from "../../../helpers/ConfirmationDialog";
import { Controller, useForm } from "react-hook-form";
import { showNotification } from "../../../app/slices/notificationSlice";

const Profile = () => {
  const userInfo = useSelector((state) => state.user.userInfo);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const passwordForm = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const { control, reset, handleSubmit } = useForm({
    defaultValues: passwordForm,
  });

  const onSubmit = (data) => {
    if (data.newPassword !== data.confirmPassword)
      dispatch(
        showNotification({
          message: "كلمة المرور الجديد وتاكيدها غير متطابقتين",
          type: "error",
        })
      );
    else {
      setDialogData(data);
      setConfirmOpen(true);
    }
  };

  const handleConfirmChangePassword = () => {
    dispatch(
      User.updatePassowrd({
        user_id: userInfo.id,
        old_password: dialogData.oldPassword,
        new_password: dialogData.confirmPassword,
      })
    )
      .then((x) => {
        setLoading(false);
        reset({ ...passwordForm });
      })
      .catch((error) => {
        setLoading(false);
        setDialogData({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      });
    setConfirmOpen(false);
  };

  const handleCancelChangePassword = () => {
    setConfirmOpen(false);
  };

  return (
    <Box>
      {userInfo ? (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                p: 2,
                background: "linear-gradient(#6b4a7c, #206F6E)",
                height: 400,
              }}
            >
              <Stack
                direction="column"
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Avatar sx={{ width: 100, height: 100, marginBottom: 3 }}>
                  {userInfo.logo ? userInfo.logo : <CgProfile size={80} />}
                </Avatar>
              </Stack>
              <Stack
                direction="column"
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="start"
                  justifyContent="center"
                >
                  <AiOutlineUser color="white" size={20} />
                  <Typography variant="h4" color="white">
                    {userInfo.user_name}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="start"
                  justifyContent="center"
                >
                  <HiOutlineMail color="white" size={20} />
                  <Typography variant="h4" color="white">
                    {userInfo.email}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="start"
                  justifyContent="center"
                >
                  <BiMobileAlt color="white" size={20} />
                  <Typography variant="h4" color="white">
                    {userInfo.mobile}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="start"
                  justifyContent="center"
                >
                  <SiOpenaccess color="white" size={20} />
                  <Typography variant="h4" color="white">
                    الصلاحيات : {userInfo.user_roles[0]}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="start"
                  justifyContent="center"
                >
                  <AiOutlineDollarCircle color="white" size={20} />
                  <Typography variant="h4" color="white">
                    {" "}
                    الرصيد : {`${userInfo.wallet?.balance} IQD`}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="start"
                  justifyContent="center"
                >
                  <MdOutlineMoneyOffCsred color="white" size={20} />
                  <Typography variant="h4" color="white">
                    {" "}
                    الديون : {`${userInfo.wallet?.debt} IQD`}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="start"
                  alignItems="center"
                >
                  <BiTransferAlt color="white" size={20} />
                  <Typography variant="h4" color="white">
                    {" "}
                    امكانية تحويل الاموال :
                  </Typography>
                  <Typography
                    variant="h4"
                    color={userInfo.can_transfer ? "white" : "error"}
                  >
                    {userInfo.can_transfer ? "نعم" : "كلا"}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              {loading ? <LinearProgress /> : null}
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack
                  sx={{ height: 400, p: 2 }}
                  direction="column"
                  spacing={2}
                  alignItems="end"
                  justifyContent="center"
                >
                  <Controller
                    name="oldPassword"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type={showOldPassword ? "text" : "password"}
                        {...field}
                        required
                        fullWidth
                        value={field.value}
                        label="كلمة المرور القديمة"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setShowOldPassword(!showOldPassword)
                                }
                              >
                                {showOldPassword ? (
                                  <AiFillEye size={22} />
                                ) : (
                                  <AiFillEyeInvisible size={22} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="newPassword"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type={showNewPassword ? "text" : "password"}
                        {...field}
                        required
                        fullWidth
                        label="كلمة المرور الجديدة"
                        value={field.value}
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setShowNewPassword(!showNewPassword)
                                }
                              >
                                {showNewPassword ? (
                                  <AiFillEye size={22} />
                                ) : (
                                  <AiFillEyeInvisible size={22} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type={showConfirmPassword ? "text" : "password"}
                        {...field}
                        required
                        fullWidth
                        value={field.value}
                        label="تاكيد كلمة المرور الجديدة"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                              >
                                {showConfirmPassword ? (
                                  <AiFillEye size={22} />
                                ) : (
                                  <AiFillEyeInvisible size={22} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  <Box sx={{ paddingTop: 2 }}>
                    <Button type="submit" color="primary" variant="contained">
                      حفظ
                    </Button>
                  </Box>
                </Stack>
              </form>
              <ConfirmationDialog
                open={openConfirm}
                title="تاكيد"
                message="هل انت متاكد من كلمة المرور !"
                confirmText="تأكيد"
                cancelText="الغاء"
                onConfirm={() => handleConfirmChangePassword()}
                onCancel={handleCancelChangePassword}
              />
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Stack
          direction="column"
          spacing={4}
          alignItems="center"
          justifyContent="center"
          sx={{ height: 300 }}
        >
          <CircularProgress />
        </Stack>
      )}
    </Box>
  );
};

export default Profile;
