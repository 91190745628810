import { Card, CardHeader, Typography, Button } from "@mui/material";
import React from "react";
import { GiTakeMyMoney } from "react-icons/gi";

const ExternalOrderHeader = () => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            فواتير خارجية
          </Typography>
        }
        avatar={<GiTakeMyMoney size={20} />}
      />
    </Card>
  );
};

export default ExternalOrderHeader;
