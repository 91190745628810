import { Grid } from "@mui/material";
import React from "react";
import ExternalOrderTable from "./table";
import ExternalOrderHeader from "./header";


const ExternalOrder = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ExternalOrderHeader />
      </Grid>
      <Grid item xs={12}>
        <ExternalOrderTable />
      </Grid>
    </Grid>
  );
};

export default ExternalOrder;
