import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
  LinearProgress,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { IssuedCards, resetForm, setDialog } from "../../../../app/slices/issuedCardSlice";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const IssuedCardForm = () => {

  const _IssuedCard = useSelector((state) => state.issuedCard.issuedCard);
  const _SearchBody = useSelector((state) => state.issuedCard.searchBody);
  const loading = useSelector((state) => state.issuedCard.loading);

  const isAdd = _IssuedCard.form.id ? false : true;
  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _IssuedCard.form,
  });

  const onSubmit = (data) => {
    dispatch(IssuedCards.updateStatus(data.id, {
      holder_name: data.holder_name,
      number: data.number,
      type: data.type,
      order_issued_id: data.order_issued_id,
      issued_at: data.issued_at,
      status_id: _IssuedCard.form.status_id,
    }, _SearchBody, true))

  };
  useEffect(() => {
    reset({ ..._IssuedCard.form });
  }, [_IssuedCard.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setDialog({
      status_id: null,
      id: null
    }));
  };


  return (
    <Dialog
      open={_IssuedCard.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="md"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          {_IssuedCard.loading && <LinearProgress sx={{ marginBottom: 1 }} />}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                name="holder_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="اسم حامل البطاقة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel>نوع البطاقة</InputLabel>
                    <Select
                      value={field.value}
                      label="نوع البطاقة"
                      size="small"
                      onChange={(e, newValue) => setValue("type", newValue)}
                      {...field}
                    >
                      <MenuItem value="master">Master Card</MenuItem>
                      <MenuItem value="visa">Visa Card</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="number"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="رقم البطاقة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="order_issued_id"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="رقم طلب الاصدار"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="issued_at"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="YYYY-MM-DD"
                      label="التاريخ"
                      value={field.value}
                      onChange={(e, newValue) => {
                        setValue("date", dayjs(e).format("YYYY-MM-DD"));
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          fullWidth
                          {...params}
                          inputProps={{ ...params.inputProps, placeholder: "يوم/شهر/سنة" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading ? true : false}
                endIcon={loading ? <CircularProgress size={25} /> : null}
              >
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default IssuedCardForm;
