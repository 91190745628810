import { Card, CardHeader, Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../../app/slices/orderSlice";
import { BiImport } from "react-icons/bi";
import OrdersForm from "../../form";


const BillsHeader = () => {
  const  _Bills  = useSelector((state) => state.orders.order);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
              الفواتير
          </Typography>
        }
        avatar={<BiImport size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            إضافة فاترورة جديدة
          </Button>
        }
      />
      {_Bills.dialog ? <OrdersForm /> : null}
    </Card>
  );
};

export default BillsHeader;
