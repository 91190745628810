import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  InputLabel,
  Select,
  CircularProgress,
  Divider,
  FormControl,
  LinearProgress,
  MenuItem,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { setDialog, resetForm, Cards } from "../../../../app/slices/cardsSlice";
import { Controller, useForm } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const CardForm = () => {
  const [loading, setLoading] = useState(false);

  const _Card = useSelector((state) => state.cards.card);

  const isAdd = _Card.form.id ? false : true;
  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _Card.form,
  });

  const onSubmit = (data) => {
    if (isAdd) {
      setLoading(true);
      dispatch(Cards.create(data))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    } else {
      setLoading(true);
      dispatch(Cards.update(_Card.form.id, data))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  };

  useEffect(() => {
    reset({ ..._Card.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_Card.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_Card.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="sm"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          {_Card.loading && <LinearProgress sx={{ marginBottom: 1 }} />}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="holder_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="اسم صاحب البطاقة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel>نوع البطاقة</InputLabel>
                    <Select
                      value={field.value}
                      fullWidth
                      label="نوع البطاقة"
                      onChange={(e, newValue) => setValue("type", newValue)}
                      {...field}
                    >
                      <MenuItem value="master">Master Card</MenuItem>
                      <MenuItem value="visa">Visa Card</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="number"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="رقم البطاقة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="order_issued_id"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="رقم طلب الاصدار"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="issued_at"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      inputFormat="YYYY-MM-DD"
                      label="تاريخ الاصدار"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          required
                        />
                      )}
                      {...field}
                      onChange={(newValue) => {
                        field.onChange(dayjs(newValue).format("YYYY-MM-DD"));
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading ? true : false}
                endIcon={loading ? <CircularProgress size={25} /> : null}
              >
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CardForm;
