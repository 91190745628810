import {
  createSlice
} from "@reduxjs/toolkit";
import {
  showNotification
} from "./notificationSlice";
import {
  factory
} from "../../api/apiFactory";
const rolesApi = factory.get("roles");
const initialState = {
  roles: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0
  },
}

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    setDataTable: (state, {
      payload
    }) => {
      state.roles.data = payload.data.data
      state.roles.total = payload.data.total
      state.roles.total_amount = payload.data.total_amount
    },
    setLoading: (state, action) => {
      state.roles.loading = !state.roles.loading;
    },
  }
})

export const {
  setLoading,
  setDataTable,
} = rolesSlice.actions

export default rolesSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('roles'));
    const res = await rolesApi.getAll(params)
    dispatch(setDataTable(res));
    dispatch(setLoading('roles'));

  } catch (err) {
    dispatch(setLoading('roles'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
export const Roles = {
  getAll,
}