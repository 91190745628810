import Typography from "@mui/material/Typography";
import {
  Button,
  Grid,
  Dialog,
  DialogContent,
  Slide,
} from "@mui/material";
import { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { SelectProductsSupplier } from "../../../../../components/Selectors/SelectProductsSupplier";
import { ProductsOrder, setSupplierChangeDialog } from "../../../../../app/slices/productsOrdersSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SupplierChangeForm = () => {
  const dispatch = useDispatch();
  const _SupplierChange = useSelector((state) => state.productsOrders.supplierChange);
  const _SearchBody = useSelector((state) => state.productsOrders.searchBody);
  const _ProductsSupplier = useSelector((state) => state.productSupplier.productsSupplier.data);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: _SupplierChange,
  });

  const onSubmit = (data) => {
    dispatch(ProductsOrder.supplierChange({
      order_id: _SupplierChange.order_id,
      supplier_id: data.supplier_id,
    }, _SearchBody))
  };

  const handleDialog = () => {
    dispatch(setSupplierChangeDialog({
      order_id: null,
      supplier_id: null,
    }));
  };

  return (
    <Dialog
      open={_SupplierChange.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={handleDialog}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {'تغيير المجهز'}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="supplier_id"
                control={control}
                render={({ field }) => (
                  <SelectProductsSupplier
                    required
                    onChange={(e, newValue) => {
                      setValue("supplier_id", newValue.id);
                    }}
                    value={_ProductsSupplier?.filter((supplier) => supplier.name === field.value)[0] ||
                      _ProductsSupplier?.filter((supplier) => supplier.id === field.value)[0] || null}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SupplierChangeForm;
