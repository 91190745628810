import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
  Divider,
  LinearProgress,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  JSafe,
  resetForm,
  setDialog,
} from "../../../../app/slices/jomla/safeSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JSafeForm = () => {
  const dispatch = useDispatch();

  const _JSafe = useSelector((state) => state.jSafe.jSafe);
  const loading = useSelector((state) => state.jSafe.jSafes.loading);
  const _SearchBody = useSelector((state) => state.jSafe.searchBody);

  const isAdd = _JSafe.form.id ? false : true;
  const { control, reset, handleSubmit } = useForm({
    defaultValues: _JSafe.form,
  });

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        JSafe.create(
          {
            name: data.name,
          },
          _SearchBody
        )
      );
    } else {
      dispatch(
        JSafe.update(
          data.id,
          {
            name: data.name,
          },
          _SearchBody
        )
      );
    }
  };

  useEffect(() => {
    reset({ ..._JSafe.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_JSafe.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_JSafe.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="sm"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          {_JSafe.loading && <LinearProgress sx={{ marginBottom: 1 }} />}
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField size="small" label="الاسم" fullWidth {...field} />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading ? true : false}
                endIcon={loading ? <CircularProgress size={25} /> : null}
              >
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default JSafeForm;
