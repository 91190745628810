import {
  Grid,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
  IconButton,
  Typography,
  Divider,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Reports,
} from "../../../../../app/slices/reportsSlice";
import dayjs from "dayjs";
import DateRangepicker from "../../../../../helpers/dateRangePicker";
import { MdOutlineClear } from "react-icons/md"
import { SelectTransactionTypes } from "../../../../../components/Selectors/SelectTransactionTypes";
import { BiInfoCircle } from "react-icons/bi";

const CashTable = () => {
  const [search, setSearch] = useState({
    startDate: dayjs().format("2023-01-01"),
    endDate: dayjs().format("YYYY-MM-DD"),
    type: null,
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const _Cash = useSelector((state) => state.reports.cash);
  const loading = useSelector((state) => state.reports.reports.loading);

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "number",
      headerName: "رقم العملية",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "القيمة (IQD)",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography color={params.row.is_in ? 'green' : 'error'}>
            {params.row.amount?.toLocaleString()}
          </Typography>
        );
      },
    },
    {
      field: "name",
      headerName: "المصدر",
      flex: 1,
    },

    {
      field: "balance",
      headerName: "الرصيد",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>
            {params.row.balance?.toLocaleString()}
          </Typography>
        );
      },
    },

    {
      field: "by_user",
      headerName: "بواسطة",
      flex: 1,
    },
    {
      field: "type",
      headerName: "النوع",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <Typography>
              {(params.row.type)}
            </Typography>
            {params.row.note && <Tooltip title={params.row.note}>
              <IconButton size="small"><BiInfoCircle /></IconButton>
            </Tooltip>}
          </Stack>
        );
      },
    },
    {
      field: "created_at",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {dayjs(params.row.created_at).format("YYYY-MM-DD / HH:mm")}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Reports.cash({
        start_date: search.startDate,
        end_date: search.endDate,
        type: search.type ? search.type.key : "",
        skip: currentPage * pageSize,
        take: pageSize,
      }));
    }, 500);
    return () => clearTimeout(timeout);
  }, [search, currentPage, pageSize, dispatch]);

  const customToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: "تقرير الكاش | نظام دجلة",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">
              المجموع الكلي للكاش: {_Cash.cash?.toLocaleString()} IQD
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
          </Grid>
          <Grid item xs={2}>
            <SelectTransactionTypes
              value={search.type}
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  type: newValue ? newValue : null
                })
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <DateRangepicker
              startDate={search.startDate}
              endDate={search.endDate}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    startDate: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    endDate: dayjs(searchDate[0].endDate).format(
                      "YYYY-MM-DD"
                    ),
                  })
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    startDate: dayjs().format("2023-01-01"),
                    endDate: dayjs().format("YYYY-MM-DD"),
                    type: null,
                  });
                  setPageSize(15)
                  setCurrentPage(0)
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={_Cash.data?.map((x, index) => ({ ...x, index: index + 1 })) ?? []}
                getRowId={(row) => row.id}
                columns={columns}
                pageSize={pageSize}
                page={currentPage}
                rowCount={_Cash.total}
                loading={loading}
                rowHeight={40}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) => setCurrentPage(newPage)}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: customToolbar
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CashTable;
