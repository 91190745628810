import { useState } from "react";
import {
  Menu,
  MenuItem,
  Chip,
  Typography,
  ListItemText,
} from "@mui/material";

export function ChangeStatusByChip(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const status = [
    {
      status_id: "1",
      id: "open",
      name: "Open",
      color: "primary",
    },
    {
      status_id: "2",
      id: "inprogress",
      name: "In Progress",
      color: "warning",
    },
    {
      status_id: "3",
      id: "closed",
      name: "Closed",
      color: "secondary",
    },
    {
      status_id: "4",
      id: "cancelled",
      name: "Cancelled",
      color: "error",
    },
  ];

  const filterdStatus = status.filter((item) => item.id === props.status)[0];

  return (
    <>
      <Menu
        id={`actions-${props.id}`}
        anchorEl={anchorEl}
        keepMounted
        disableAutoFocusItem
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {status.map((item, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              props.onChangeStatus(item);
              handleClose();
            }}
          >
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Menu>

      <Chip
        color={filterdStatus?.color}
        variant="outlined"
        size="small"
        label={
          <Typography fontSize={"small"} color={filterdStatus?.color}>
            {filterdStatus?.name}
          </Typography>
        }
        onClick={(e) => {
          handleMenuClick(e);
        }}
      />
    </>
  );
}
