import { Card, CardHeader, Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../../app/slices/productsSlice";
import { MdOutlineDevices } from "react-icons/md";
import ProductsForm from "../../form";

const ProductHeader = () => {
  const _Product = useSelector((state) => state.product.product);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            الاجهزة
          </Typography>
        }
        avatar={<MdOutlineDevices size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            إضافة
          </Button>
        }
      />
      {_Product.dialog ? <ProductsForm /> : null}
    </Card>
  );
};

export default ProductHeader;
