import dayjs from "dayjs";
import {
  createSlice
} from "@reduxjs/toolkit";
import {
  showNotification
} from "./notificationSlice";
import {
  factory
} from "../../api/apiFactory";
const externalRevenueApi = factory.get("externalRevenue");
const initialState = {
  searchBody: {},
  loading: false,
  externalRevenue: {
    dialog: false,
    form: {
      id: null,
      amount: "",
      note: "",
    },
  },
  externalRevenues: {
    data: [],
    total: 0,
    total_amount: 0,
  },
}

export const externalRevenueSlice = createSlice({
  name: "externalRevenue",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.externalRevenue.dialog = !state.externalRevenue.dialog
    },
    setDataTable: (state, {
      payload
    }) => {
      state.externalRevenues.data = payload.data.data
      state.externalRevenues.total = payload.data.total
      state.externalRevenues.total_amount = payload.data.total_amount
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetForm: (state, action) => {
      state.externalRevenue.form = initialState.externalRevenue.form
    },
    setSearchBody: (state, {
      payload
    }) => {
      state.searchBody = payload;
    },
  }
})

export const {
  setLoading,
  setDialog,
  setDataTable,
  resetForm,
  setSearchBody,
} = externalRevenueSlice.actions

export default externalRevenueSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('externalRevenue'));
    const res = await externalRevenueApi.getAll(params)
    dispatch(setDataTable(res));
    dispatch(setLoading('externalRevenue'));

  } catch (err) {
    dispatch(setLoading('externalRevenue'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const deleteById = (id, searchBody) => async (dispatch) => {
  try {
    await externalRevenueApi.delete(id)
    dispatch(showNotification({
      message: "تم الحذف بنجاح",
      type: "success"
    }));
    dispatch(getAll(searchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const create = (data, searchBody) => async (dispatch) => {
  try {
    await externalRevenueApi.create(data)
    dispatch(showNotification({
      message: "تمت الاضافة بنجاح",
      type: "success"
    }));
    dispatch(setDialog())
    dispatch(getAll(searchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};

export const ExternalRevenue = {
  getAll,
  deleteById,
  create,
}