import { Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { AiFillShop } from "react-icons/ai";

const RequestPointsHeader = () => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            طلبات نقاط البيع
          </Typography>
        }
        avatar={<AiFillShop size={20} />}
      />
    </Card>
  );
};

export default RequestPointsHeader;
