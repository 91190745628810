import {
  Grid,
  LinearProgress,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Reports } from "../../../../../app/slices/reportsSlice";

const TotalsTable = () => {
  const dispatch = useDispatch();

  const _Totals = useSelector((state) => state.reports.totals);
  const loading = useSelector((state) => state.reports.reports.loading);
  const _Role = useSelector((state) => state.user.userInfo.user_roles[0]);

  const columns = [
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "المبلغ (IQD)",
      flex: 1,
    },
  ];

  var rows = [
    {
      id: 1,
      name: "مبلغ الكودات المتوفرة",
      amount: _Totals?.total_pins?.toLocaleString(),
    },
    {
      id: 2,
      name: "مبلغ خزنة الكاش",
      amount: _Totals?.total_cash?.toLocaleString(),
    },
    {
      id: 3,
      name: "مبلغ كاش المندوبين",
      amount: _Totals?.total_rep_cash?.toLocaleString(),
    },
    {
      id: 6,
      name: "مجموع ديون نقاط البيع",
      amount: _Totals?.total_pos_debt?.toLocaleString(),
    },
    {
      id: 5,
      name: "مجموع رصيد نقاط البيع",
      amount: _Totals?.total_pos_balance?.toLocaleString(),
    },
    {
      id: 8,
      name: "مجموع رصيد الموردين",
      amount: _Totals?.total_supplier?.toLocaleString(),
    },
    {
      id: 14,
      name: "مجموع رصيد الممولين",
      amount: _Totals?.total_investors?.toLocaleString(),
    },
    {
      id: 9,
      name: "مجموع رصيد المجهزين",
      amount: _Totals?.product_supplier_balance?.toLocaleString(),
    },
    {
      id: 13,
      name: " رصيد التحويل المتوفر",
      amount: _Totals?.total_sim_card_balance?.toLocaleString(),
    },
    {
      id: 4,
      name: "مجموع الارباح",
      amount: _Totals?.total_revenue?.toLocaleString(),
    },
    {
      id: 11,
      name: "مجموع ارباح المنصة",
      amount: _Totals?.product_supplier_wages?.toLocaleString(),
    },
    {
      id: 12,
      name: "مجموع ارباح التحويل",
      amount: _Totals?.total_transfer_revenue?.toLocaleString(),
    },
    {
      id: 10,
      name: "الناتج",
      amount: (
        _Totals.result
        // (_Totals?.total_pins || 0) +
        // (_Totals?.total_rep_cash || 0) +
        // (_Totals?.total_cash || 0) -
        // (_Totals?.total_revenue || 0) -
        // (_Totals?.total_pos_balance || 0) +
        // (_Totals?.total_pos_debt || 0) -
        // (_Totals?.total_supplier || 0) -
        // (_Totals?.product_supplier_balance || 0) -
        // (_Totals?.product_supplier_wages || 0) -
        // (_Totals?.total_transfer_revenue || 0) +
        // (_Totals?.total_sim_card_balance || 0) -
        // (_Totals?.total_investors || 0) 
      )?.toLocaleString(),
    },
  ];

  rows =
    _Role === "admin"
      ? rows.filter(
          (row) =>
            _Role === "admin" &&
            row.id !== 4 &&
            _Role === "admin" &&
            row.id !== 11 &&
            _Role === "admin" &&
            row.id !== 10 &&
            _Role === "admin" &&
            row.id !== 12
        )
      : rows;

  useEffect(() => {
    dispatch(Reports.totals());
  }, [dispatch]);

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_Totals ? (
                <DataGrid
                  sx={{
                    ".total-amount": {
                      fontWeight: "bold",
                      bgcolor: "#e0e0e0",
                      "&:hover": {
                        bgcolor: "#e0e0e0",
                      },
                    },
                  }}
                  rows={rows}
                  columns={columns}
                  loading={loading}
                  pageSize={15}
                  rowHeight={40}
                  selectionModel={[]}
                  getRowClassName={(params) => {
                    return params.row.id === 10 ? "total-amount" : "";
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <CircularProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TotalsTable;
