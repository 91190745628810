import { Grid } from "@mui/material";
import React from "react";
import JCategoriesTable from "./home/table";
import JCategoriesHeader from "./home/header";

const JCategories = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <JCategoriesHeader />
      </Grid>
      <Grid item xs={12}>
        <JCategoriesTable />
      </Grid>
    </Grid>
  );
};

export default JCategories;
