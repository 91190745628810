import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Typography,
  Dialog,
  Slide,
  DialogContent,
  Divider,
  Button,
  CircularProgress,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  JOrder,
  setAddItemToOrderDialog,
  setAdminDiscountDialog,
  setAssignDriverDialog,
  setChangeAdminNoteDialog,
  setChangePosDialog,
  setLoading,
  setOrderItemDiscountDialog,
  setOrderPrintDialog,
  setOrderStatusHistoryDialog,
} from "../../../../../app/slices/jomla/orderSlice";
import dayjs from "dayjs";
import { ChangeStatusByChip } from "./ChangeStatusByChip";
import { useParams } from "react-router";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdList, MdOutlineCancel } from "react-icons/md";
import {
  FaCartPlus,
  FaEye,
  FaMoneyBillWave,
  FaTicketAlt,
} from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import EditAdminDiscountForm from "../../form/editAdminDiscountForm";
import { NumericFormat } from "react-number-format";
import { toNumber } from "../../../../../helpers/toNumber";
import EditOrderItemDiscountForm from "../../form/orderItemDiscountForm";
import { PointOfSales } from "../../../../../app/slices/posSlice";
import PosForm from "../../../../POS/form";
import AddItemToOrderForm from "../../form/addItemToOrder";
import AssignOrderToDriverForm from "../../form/assignDriver";
import { Carousel } from "react-responsive-carousel";
import OrderStatusHistoryDialog from "../../form/orderStatusHistory";
import OrderPrintDialog from "../../form/orderPrint";
import { useTheme } from "@emotion/react";
import ChangePosForm from "../../form/changePos";
import ChangeAdminNoteForm from "../../form/changeAdminNote";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JOrderDetails = () => {
  const { order_id } = useParams();
  const dispatch = useDispatch();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const _Role = useSelector((state) => state.user.userInfo.user_roles[0]);
  const adminDiscountDialog = useSelector(
    (state) => state.jOrder.adminDiscount.dialog
  );

  const orderItemDiscountDialog = useSelector(
    (state) => state.jOrder.orderItemDiscount.dialog
  );
  const addItemToOrderDialog = useSelector(
    (state) => state.jOrder.addItemToOrder.dialog
  );
  const assignDriverDialog = useSelector(
    (state) => state.jOrder.assignDriver.dialog
  );
  const changePosDialog = useSelector((state) => state.jOrder.changePos.dialog);
  const _JOrderStatusDialog = useSelector(
    (state) => state.jOrder.orderStatusHistory.dialog
  );
  const _JOrderPrintDialog = useSelector(
    (state) => state.jOrder.orderPrint.dialog
  );
  const changeAdminNoteDialog = useSelector(
    (state) => state.jOrder.changeAdminNote.dialog
  );
  const _JOrder = useSelector((state) => state.jOrder.jOrder.details);
  const loading = useSelector((state) => state.jOrder.jOrders.loading);
  const _SearchBody = useSelector((state) => state.jOrder.searchBody);
  const _PointsOfSales = useSelector((state) => state.pointOfsales);

  useEffect(() => {
    dispatch(JOrder.getOrderDetails(order_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_id]);

  const [itemCount, setItemCount] = useState("");
  const [itemNote, setItemNote] = useState("");
  const [discountedItemPrice, setDiscountedItemPrice] = useState("");
  const [openItemDialog, setOpenItemDialog] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [dialogType, setDialogType] = useState("");
  const [openCarouselDialog, setOpenCarouselDialog] = useState(false);
  const [attachmentsData, setAttachmentsData] = useState([]);

  const changeOrderStatus = (id, status) => {
    let params = {
      j_order_status_id: status?.id,
    };
    dispatch(JOrder.updateStatus(id, params, _SearchBody, "getById"));
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "logo",
      headerName: "الصورة",
      flex: 1,
      renderCell: (params) => (
        <Box
          component="img"
          src={params.row.logo[0]?.path}
          width={100}
          height={100}
          sx={{ objectFit: "contain" }}
          onClick={() => {
            setAttachmentsData(params.row.logo);
            setOpenCarouselDialog(true);
          }}
        />
      ),
    },
    {
      field: "j_item_name",
      headerName: "اسم المنتج",
      flex: 2,
      renderCell: (params) => (
        <Stack spacing={2}>
          <Typography
            variant="body1"
            color="primary"
            component="div"
            style={{
              whiteSpace: "normal",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textDecoration: params.row.is_canceled
                ? "line-through red"
                : "none",
              textDecorationThickness: params.row.is_canceled
                ? "1.5px"
                : "initial",
            }}
          >
            {params.row.j_item_name}
          </Typography>
          <Typography
            style={{
              textDecoration: params.row.is_canceled
                ? "line-through red"
                : "none",
              textDecorationThickness: params.row.is_canceled
                ? "1.5px"
                : "initial",
            }}
          >
            {params.row.j_sub_category_name}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "price_before_discount",
      headerName: "السعر قبل الخصم",
      flex: 1,
      renderCell: (params) => (
        <Typography
          style={{
            textDecoration: params.row.is_canceled
              ? "line-through red"
              : "none",
            textDecorationThickness: params.row.is_canceled
              ? "1.5px"
              : "initial",
          }}
        >
          {params.row.price_before_discount?.toLocaleString()}
        </Typography>
      ),
    },
    {
      field: "discount_for_one",
      headerName: "الخصم لقطعة واحدة",
      flex: 1,
      renderCell: (params) => (
        <Typography
          style={{
            textDecoration: params.row.is_canceled
              ? "line-through red"
              : "none",
            textDecorationThickness: params.row.is_canceled
              ? "1.5px"
              : "initial",
          }}
        >
          {params.row.discount_for_one?.toLocaleString()}
        </Typography>
      ),
    },
    {
      field: "price",
      headerName: "سعر المنتج",
      flex: 1,
      renderCell: (params) => (
        <Typography
          style={{
            textDecoration: params.row.is_canceled
              ? "line-through red"
              : "none",
            textDecorationThickness: params.row.is_canceled
              ? "1.5px"
              : "initial",
          }}
        >
          {params.row.price?.toLocaleString()}
        </Typography>
      ),
    },
    {
      field: "count",
      headerName: "العدد",
      flex: 0.5,
      renderCell: (params) => (
        <Typography
          style={{
            textDecoration: params.row.is_canceled
              ? "line-through red"
              : "none",
            textDecorationThickness: params.row.is_canceled
              ? "1.5px"
              : "initial",
          }}
        >
          {params.row.count?.toLocaleString()}
        </Typography>
      ),
    },
    {
      field: "total_discount",
      headerName: "الخصم الكلي",
      flex: 1,
      renderCell: (params) => (
        <Typography
          style={{
            textDecoration: params.row.is_canceled
              ? "line-through red"
              : "none",
            textDecorationThickness: params.row.is_canceled
              ? "1.5px"
              : "initial",
          }}
        >
          {params.row.total_discount?.toLocaleString()}
        </Typography>
      ),
    },
    {
      field: "total",
      headerName: "المبلغ الكلي",
      flex: 1,
      renderCell: (params) => (
        <Typography
          style={{
            textDecoration: params.row.is_canceled
              ? "line-through red"
              : "none",
            textDecorationThickness: params.row.is_canceled
              ? "1.5px"
              : "initial",
          }}
        >
          {params.row.total?.toLocaleString()}
        </Typography>
      ),
    },
    {
      field: "revenue",
      headerName: "الربح",
      flex: 1,
      renderCell: (params) => (
        <Typography
          style={{
            textDecoration: params.row.is_canceled
              ? "line-through red"
              : "none",
            textDecorationThickness: params.row.is_canceled
              ? "1.5px"
              : "initial",
          }}
        >
          {params.row.revenue?.toLocaleString()}
        </Typography>
      ),
    },

    // {
    //   field: "main_j_balance",
    //   headerName: "الخزنة (IQD)",
    //   flex: 1,
    //   renderCell: (params) =>
    //     <Stack alignItems='start'>
    //       <Typography variant="body" textAlign='center'>قبل: {params.row.main_j_balance?.toLocaleString()}</Typography>
    //       <Typography variant="body" textAlign='center'>بعد: {params.row.new_main_j_balance?.toLocaleString()}</Typography>
    //     </Stack>,
    // },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => (
        <Stack
          direction="row"
          spacing={0}
          alignItems="center"
          justifyContent="center"
        >
          {params.row.is_canceled ? (
            <Tooltip title={"ملغي: " + params.row.note}>
              <Typography color="error">
                <IoIosInformationCircleOutline size={25} />
              </Typography>
            </Tooltip>
          ) : null}
          {params.row.is_canceled ? null : (
            <Tooltip title="تعديل الكمية">
              <IconButton
                onClick={() => {
                  setDialogType("update");
                  setItemCount(params.row.count);
                  setOpenItemDialog(true);
                  setDialogData(params.row);
                }}
              >
                <FaCartPlus />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="تعديل خصم العنصر">
            <IconButton
              color="success"
              onClick={() => {
                dispatch(
                  setOrderItemDiscountDialog({
                    orderId: order_id,
                    id: params.row.id,
                    price: params.row.price,
                    price_before_discount: params.row.price_before_discount,
                    total_discount: 0,
                    total_discount_percent: 0,
                    // total_discount: params.row.total_discount,
                    // total_discount_percent:
                    //   Math.round(
                    //     (params.row.total_discount /
                    //       params.row.price_before_discount) *
                    //     100 *
                    //     100
                    //   ) / 100,
                  })
                );
              }}
            >
              <FaTicketAlt />
            </IconButton>
          </Tooltip>
          {params.row.is_canceled ? null : (
            <Tooltip title="الغاء المادة">
              <IconButton
                color="error"
                onClick={() => {
                  setDialogType("cancel");
                  setItemCount(params.row.count);
                  setOpenItemDialog(true);
                  setDialogData(params.row);
                }}
              >
                <MdOutlineCancel />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    dispatch(JOrder.getStatuses());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardContent>
        {adminDiscountDialog ? <EditAdminDiscountForm /> : null}
        {orderItemDiscountDialog ? <EditOrderItemDiscountForm /> : null}
        {addItemToOrderDialog ? <AddItemToOrderForm /> : null}
        {assignDriverDialog ? (
          <AssignOrderToDriverForm orderDetails={true} />
        ) : null}
        {changePosDialog ? <ChangePosForm orderDetails={true} /> : null}
        {changeAdminNoteDialog ? (
          <ChangeAdminNoteForm orderDetails={true} />
        ) : null}
        {_PointsOfSales.pos.dialog ? (
          <PosForm from={"JOrderDetails"} jOrderId={order_id} />
        ) : null}
        {_JOrderStatusDialog ? <OrderStatusHistoryDialog /> : null}
        {_JOrderPrintDialog ? <OrderPrintDialog /> : null}
        <Dialog
          open={openCarouselDialog}
          TransitionComponent={Transition}
          keepMounted
          fullWidth={true}
          maxWidth="md"
          onClose={() => {
            setOpenCarouselDialog(false);
          }}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <Typography
              mb={3}
              variant="h4"
              color={"purple"}
              gutterBottom
              component="div"
              sx={{ textAlign: "end" }}
            >
              {"ًصور المنتج"}
            </Typography>
            <Divider />
            <Box display="flex" justifyContent="center" zIndex={999}>
              <Carousel
                swipeable
                emulateTouch
                infiniteLoop
                width={400}
                renderThumbs={(children) =>
                  children.map((child, index) => (
                    <img
                      key={index}
                      src={child.props.src}
                      alt=""
                      style={{
                        height: "50px",
                        width: "auto",
                        objectFit: "contain",
                      }}
                    />
                  ))
                }
              >
                {attachmentsData?.map((attachment, index) => (
                  <Box
                    key={index}
                    component="img"
                    src={attachment.path}
                    alt=""
                    style={{
                      objectFit: "contain",
                    }}
                  />
                ))}
              </Carousel>
            </Box>
          </DialogContent>
        </Dialog>
        <Dialog
          open={openItemDialog}
          TransitionComponent={Transition}
          keepMounted
          fullWidth={true}
          dir="rtl"
          maxWidth="sm"
          onClose={() => {
            setOpenItemDialog(false);
            setItemNote("");
            setItemCount("");
          }}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <Typography
              mb={3}
              variant="h4"
              color={"purple"}
              gutterBottom
              component="div"
            >
              {dialogType === "update"
                ? "تعديل"
                : dialogType === "cancel"
                ? "هل انت متاكد من الغاء العنصر؟"
                : dialogType === "discount"
                ? "تعديل سعر العنصر"
                : null}
            </Typography>
            <Divider sx={{ my: 2 }} />
            {loading && <LinearProgress sx={{ marginBottom: 1 }} />}
            <Grid container spacing={2}>
              {dialogType === "update" ? (
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    label="العدد"
                    value={itemCount}
                    type="number"
                    onChange={(e) => {
                      setItemCount(e.target.value);
                    }}
                    fullWidth
                  />
                </Grid>
              ) : null}
              {dialogType === "discount" ? null : (
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    label="الملاحظات"
                    value={itemNote}
                    onChange={(e) => {
                      setItemNote(e.target.value);
                    }}
                    fullWidth
                    multiline
                    rows={4}
                  />
                </Grid>
              )}

              {dialogType === "discount" ? (
                <Grid item xs={12}>
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    value={discountedItemPrice}
                    onChange={(e) => {
                      setDiscountedItemPrice(toNumber(e.target.value));
                    }}
                    valueIsNumericString={true}
                    allowNegative={false}
                    fullWidth
                    label="سعر العنصر الجديد"
                    size="small"
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} md={12}>
                <Button
                  onClick={() => {
                    if (dialogType === "update") {
                      dispatch(
                        JOrder.updateOrderItemCount(
                          dialogData.id,
                          {
                            count: parseInt(itemCount),
                            note: itemNote,
                          },
                          order_id
                        )
                      ).then(() => {
                        setOpenItemDialog(false);
                        setItemNote("");
                        setItemCount("");
                        setDiscountedItemPrice("");
                      });
                    } else if (dialogType === "cancel") {
                      dispatch(
                        JOrder.cancelOrderItem(
                          dialogData.id,
                          {
                            note: itemNote,
                          },
                          order_id
                        )
                      ).then(() => {
                        setOpenItemDialog(false);
                        setItemNote("");
                        setItemCount("");
                        setDiscountedItemPrice("");
                      });
                    } else if (dialogType === "discount") {
                      dispatch(
                        JOrder.editOrderItemDiscount(
                          dialogData.id,
                          {
                            new_price: discountedItemPrice,
                          },
                          order_id
                        )
                      ).then(() => {
                        setOpenItemDialog(false);
                        setItemNote("");
                        setItemCount("");
                        setDiscountedItemPrice("");
                      });
                    }
                  }}
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loading ? true : false}
                  endIcon={loading ? <CircularProgress size={25} /> : null}
                >
                  حفظ
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" color="primary" component="div" py={1}>
                  {_JOrder.user_name}
                </Typography>
                {_Role === "wholesale" ? null : (
                  <Tooltip title="معلومات نقطة البيع" followCursor>
                    <IconButton
                      onClick={() => {
                        dispatch(setLoading("jOrders"));
                        dispatch(
                          PointOfSales.getByUserId(_JOrder.user_id)
                        ).then(() => dispatch(setLoading("jOrders")));
                      }}
                    >
                      <FaEye />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="كشف الحساب" followCursor>
                  <IconButton
                    onClick={() => {
                      localStorage.setItem("isWholeSale", "1");
                      // navigate("/app/wallets/transactions/" + _JOrder.user_id);
                      window.open(
                        `/app/wallets/transactions/${_JOrder.user_id}`,
                        "_blank"
                      );
                    }}
                  >
                    <FaMoneyBillWave />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={() => {
                    dispatch(setOrderPrintDialog(_JOrder));
                  }}
                >
                  طباعة فاتورة بيع
                </Button>
                <Button
                  sx={{ mx: 1 }}
                  variant="contained"
                  onClick={() => {
                    dispatch(
                      setAssignDriverDialog({
                        order_id: order_id,
                        driver_id: _JOrder.driver_id ?? null,
                      })
                    );
                  }}
                >
                  {_JOrder.driver_name
                    ? "السائق: " + _JOrder.driver_name
                    : "تعيين سائق"}
                </Button>
                {_JOrder?.j_order_status_id === 1 && (
                  <Button
                    sx={{ mx: 1 }}
                    variant="contained"
                    onClick={() => {
                      dispatch(setAddItemToOrderDialog(order_id));
                    }}
                  >
                    اضافة منتجات للطلب
                  </Button>
                )}
                {(_JOrder?.j_order_status_id === 1 ||
                  _JOrder?.j_order_status_id === 2 ||
                  _JOrder?.j_order_status_id === 3 ||
                  _JOrder?.j_order_status_id === 4) && (
                  <Button
                    sx={{ mx: 1 }}
                    variant="contained"
                    onClick={() => {
                      dispatch(
                        setChangePosDialog({
                          order_id: _JOrder.id,
                          user_id: _JOrder.user_id,
                          note: "",
                        })
                      );
                    }}
                  >
                    تغيير نقطة البيع
                  </Button>
                )}
                <Button
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={() => {
                    dispatch(
                      setChangeAdminNoteDialog({
                        order_id: _JOrder.id,
                        admin_note: _JOrder.admin_note ?? "",
                      })
                    );
                  }}
                >
                  ملاحظات الادمن
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" color="primary" component="div" py={1}>
              طلب رقم: {_JOrder.number}
            </Typography>
            <Typography variant="h4" component="div" py={1}>
              المبلغ قبل الخصم: {_JOrder.amount?.toLocaleString()} (IQD)
            </Typography>
            <Typography variant="h4" color="green" component="div" py={1}>
              المبلغ المدفوع: {_JOrder.total_paid?.toLocaleString()} (IQD)
            </Typography>
            <Typography
              variant="h4"
              color="warning.main"
              component="div"
              py={1}
            >
              المبلغ المتبقي: {_JOrder.remaining?.toLocaleString()} (IQD)
            </Typography>
            <Typography variant="h4" component="div" py={1}>
              ديون النقطة: {_JOrder.pos_j_balance?.toLocaleString()} (IQD)
            </Typography>
          </Grid>
          <Grid item xs={13} md={4}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h4" color="primary" component="div" py={1}>
                الحالة:
              </Typography>
              <ChangeStatusByChip
                id={_JOrder.j_order_status_id}
                status={_JOrder.j_order_status_name}
                onChangeStatus={(item) => {
                  changeOrderStatus(_JOrder.id, item);
                }}
              />
              <Tooltip title="حالات الطلب" followCursor>
                <IconButton
                  onClick={() => {
                    dispatch(
                      setOrderStatusHistoryDialog(_JOrder.status_history)
                    );
                  }}
                >
                  <MdList size={25} />
                </IconButton>
              </Tooltip>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography variant="h4" color="error" component="div" py={1}>
                الخصم: {_JOrder.discount?.toLocaleString()}{" "}
                {_JOrder.admin_discount > 0
                  ? ` + ${_JOrder.admin_discount?.toLocaleString()} `
                  : ""}
                {_JOrder.admin_discount > 0 ? (
                  <Tooltip title={"خصم الشركة"}>
                    <span>
                      <IoIosInformationCircleOutline size={15} />
                    </span>
                  </Tooltip>
                ) : null}{" "}
                (IQD)
              </Typography>
              <Tooltip title="تعديل الخصم">
                <IconButton
                  onClick={() => {
                    dispatch(
                      setAdminDiscountDialog({
                        id: _JOrder.id,
                        net_amount: _JOrder.net_amount,
                        amount: _JOrder.amount,
                        admin_discount: _JOrder.admin_discount,
                        admin_discount_percent:
                          Math.round(
                            (_JOrder.admin_discount / _JOrder.amount) *
                              100 *
                              100
                          ) / 100,
                      })
                    );
                  }}
                >
                  <BiEdit />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xxs={12} md={4}>
            <Typography variant="h4" color="primary" component="div" py={1}>
              تاريخ الطلب: {dayjs(_JOrder.created_at).format("YYYY-MM-DD")}
            </Typography>
            <Typography variant="h4" color="primary" component="div" py={1}>
              المبلغ الكلي: {_JOrder.net_amount?.toLocaleString()} (IQD)
            </Typography>
            <Typography
              variant="h4"
              color="primary"
              component="div"
              py={1}
              style={{ wordWrap: "break-word" }}
            >
              الملاحظات: {_JOrder.note}
            </Typography>
          </Grid>
          {smallScreen ? (
            <Grid item xs={12}>
              {_JOrder.loading ? <LinearProgress /> : null}
              <Stack spacing={2}>
                {_JOrder.items?.map((item, index) => (
                  <Card key={index}>
                    <CardContent>
                      <Stack spacing={2}>
                        <Typography variant="h6">#{index + 1}</Typography>
                        <Box
                          component="img"
                          src={item.logo[0]?.path}
                          width={100}
                          height={100}
                          sx={{ objectFit: "contain" }}
                          onClick={() => {
                            setAttachmentsData(item.logo);
                            setOpenCarouselDialog(true);
                          }}
                        />
                        <Stack spacing={1}>
                          <Typography
                            variant="body1"
                            color="primary"
                            component="div"
                            style={{
                              whiteSpace: "normal",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              textDecoration: item.is_canceled
                                ? "line-through red"
                                : "none",
                              textDecorationThickness: item.is_canceled
                                ? "1.5px"
                                : "initial",
                            }}
                          >
                            <strong>{item.j_item_name}</strong>
                          </Typography>
                          <Typography
                            style={{
                              textDecoration: item.is_canceled
                                ? "line-through red"
                                : "none",
                              textDecorationThickness: item.is_canceled
                                ? "1.5px"
                                : "initial",
                            }}
                          >
                            <strong>{item.j_sub_category_name}</strong>
                          </Typography>
                        </Stack>
                        <Typography
                          style={{
                            textDecoration: item.is_canceled
                              ? "line-through red"
                              : "none",
                            textDecorationThickness: item.is_canceled
                              ? "1.5px"
                              : "initial",
                          }}
                        >
                          السعر قبل الخصم:{" "}
                          <strong>
                            {item.price_before_discount?.toLocaleString()}
                          </strong>
                        </Typography>
                        <Typography
                          style={{
                            textDecoration: item.is_canceled
                              ? "line-through red"
                              : "none",
                            textDecorationThickness: item.is_canceled
                              ? "1.5px"
                              : "initial",
                          }}
                        >
                          الخصم لقطعة واحدة:{" "}
                          <strong>
                            {" "}
                            {item.discount_for_one.toLocaleString()}
                          </strong>
                        </Typography>
                        <Typography
                          style={{
                            textDecoration: item.is_canceled
                              ? "line-through red"
                              : "none",
                            textDecorationThickness: item.is_canceled
                              ? "1.5px"
                              : "initial",
                          }}
                        >
                          سعر المنتج:{" "}
                          <strong>{item.price?.toLocaleString()}</strong>
                        </Typography>
                        <Typography
                          style={{
                            textDecoration: item.is_canceled
                              ? "line-through red"
                              : "none",
                            textDecorationThickness: item.is_canceled
                              ? "1.5px"
                              : "initial",
                          }}
                        >
                          العدد: <strong>{item.count}</strong>
                        </Typography>
                        <Typography
                          style={{
                            textDecoration: item.is_canceled
                              ? "line-through red"
                              : "none",
                            textDecorationThickness: item.is_canceled
                              ? "1.5px"
                              : "initial",
                          }}
                        >
                          الخصم الكلي:{" "}
                          <strong>
                            {item.total_discount.toLocaleString()}
                          </strong>
                        </Typography>
                        <Typography
                          style={{
                            textDecoration: item.is_canceled
                              ? "line-through red"
                              : "none",
                            textDecorationThickness: item.is_canceled
                              ? "1.5px"
                              : "initial",
                          }}
                        >
                          المبلغ الكلي:{" "}
                          <strong> {item.total?.toLocaleString()}</strong>
                        </Typography>
                        <Typography
                          style={{
                            textDecoration: item.is_canceled
                              ? "line-through red"
                              : "none",
                            textDecorationThickness: item.is_canceled
                              ? "1.5px"
                              : "initial",
                          }}
                        >
                          الربح:{" "}
                          <strong>{item.revenue?.toLocaleString()}</strong>
                        </Typography>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          justifyContent="center"
                        >
                          {item.is_canceled ? (
                            <Typography color="error" fontWeight="bold">
                              ملغي
                            </Typography>
                          ) : null}
                          {_Role === "representative" ? null : (
                            <>
                              {item.is_canceled ? null : (
                                <Tooltip title="تعديل الكمية">
                                  <IconButton
                                    onClick={() => {
                                      setDialogType("update");
                                      setItemCount(item.count);
                                      setOpenItemDialog(true);
                                      setDialogData(item);
                                    }}
                                  >
                                    <FaCartPlus />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {item.is_canceled ? null : (
                                <Tooltip title="تعديل خصم العنصر">
                                  <IconButton
                                    color="success"
                                    onClick={() => {
                                      dispatch(
                                        setOrderItemDiscountDialog({
                                          orderId: order_id,
                                          id: item.id,
                                          price: item.price,
                                          price_before_discount:
                                            item.price_before_discount,
                                          total_discount: 0,
                                          total_discount_percent: 0,
                                        })
                                      );
                                    }}
                                  >
                                    <FaTicketAlt />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {item.is_canceled ? null : (
                                <Tooltip title="الغاء المادة">
                                  <IconButton
                                    color="error"
                                    onClick={() => {
                                      setDialogType("cancel");
                                      setItemCount(item.count);
                                      setOpenItemDialog(true);
                                      setDialogData(item);
                                    }}
                                  >
                                    <MdOutlineCancel />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                ))}
              </Stack>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <DataGrid
                rows={
                  _JOrder?.items?.map((x, i) => {
                    return {
                      ...x,
                      index: i + 1,
                    };
                  }) || []
                }
                columns={columns}
                rowCount={_JOrder?.total}
                loading={loading}
                rowHeight={160}
                autoHeight
                pagination
                paginationMode="client"
                selectionModel={[]}
                rowsPerPageOptions={[15, 25, 50, 100]}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default JOrderDetails;
