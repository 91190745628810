import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const investorsApi = factory.get("investors");
const initialState = {
  searchBody: {},
  investor: {
    loading: false,
    dialog: false,
    form: {
      id: "",
      name: "",
      note: "",
    },
  },
  investorTransactions: {
    investor_id: null,
    searchBody: {},
    loading: false,
    dialog: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
  investors: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
  investorsList: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
  balance: {
    dialog: false,
    investor_id: null,
    type: null,
    form: {
      amount: "",
    },
  },
};

export const investorsSlice = createSlice({
  name: "investors",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.investor.dialog = !state.investor.dialog;
    },
    setInvestorsTransactionsDialog: (state, { payload }) => {
      state.investorTransactions.dialog = !state.investorTransactions.dialog;
      state.investorTransactions.investor_id = payload;
    },
    setInvestorsTransactionsDataTable: (state, { payload }) => {
      state.investorTransactions.data = payload.data.data;
      state.investorTransactions.total = payload.data.total;
      state.investorTransactions.total_amount = payload.data.total_amount;
    },
    setDataTable: (state, { payload }) => {
      state.investors.data = payload.data.data;
      state.investors.total = payload.data.total;
      state.investors.total_amount = payload.data.total_amount;
    },
    setDatalist: (state, { payload }) => {
      state.investorsList.data = payload.data.data;
    },
    setById: (state, { payload }) => {
      state.investor.form.id = payload.id;
      state.investor.form.name = payload.name;
      state.investor.form.balance = payload.balance;
      state.investor.form.note = payload.note;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.investor.form = initialState.investor.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
    setInvestorsTransactionsSearchBody: (state, { payload }) => {
      state.investorTransactions.searchBody = payload;
    },
    setBalanceDialog: (state, { payload }) => {
      state.balance.dialog = !state.balance.dialog;
      state.balance.investor_id = payload.id;
      state.balance.type = payload.type;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setReceiveCashDialog,
  setPayCashDialog,
  setDatalist,
  setBalanceDialog,
  setInvestorsTransactionsDataTable,
  setInvestorsTransactionsDialog,
  setInvestorsTransactionsSearchBody,
} = investorsSlice.actions;

export default investorsSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("investors"));
    const res = await investorsApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("investors"));
  } catch (err) {
    dispatch(setLoading("investors"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllInvestorTransactions = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("investors"));
    const res = await investorsApi.investorTransaction(params);
    dispatch(setInvestorsTransactionsDataTable(res));
    dispatch(setLoading("investors"));
  } catch (err) {
    dispatch(setLoading("investors"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("investors"));
    const res = await investorsApi.getAllList(params);
    dispatch(setDatalist(res));
    dispatch(setLoading("investors"));
  } catch (err) {
    dispatch(setLoading("investors"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await investorsApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("investors"));
    const res = await investorsApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("investors"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("investors"));
    throw new Error(err);
  }
};
const getByIdData = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("investors"));
    const res = await investorsApi.getById(id);
    dispatch(setById(res.data.data[0]));
    dispatch(setLoading("investors"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("investors"));
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await investorsApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await investorsApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deposit = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await investorsApi.deposit(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    dispatch(
      setBalanceDialog({
        id: null,
        type: null,
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const withdraw = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await investorsApi.withdraw(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    dispatch(
      setBalanceDialog({
        id: null,
        type: null,
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const Investors = {
  getAll,
  deleteById,
  getById,
  getByIdData,
  create,
  update,
  getAllList,
  deposit,
  withdraw,
  getAllInvestorTransactions,
};
