import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, styled } from "@mui/material";
import { forwardRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import { setListSearchBody } from "../../../app/slices/jomla/itemsSlice";

export const SelectJItem = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const _JItem = useSelector((state) => state.JItems.JItemsList);

  const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    borderBottom: "1px solid #e0e0e0",
    color: "white",
    backgroundColor: theme.palette.primary.main,
  }));
  const GroupItems = styled("ul")({
    padding: 0,
  });

  return (
    <Autocomplete
      ref={ref}
      options={_JItem.data || []}
      {...props}
      getOptionLabel={(option) => {
        return option.name;
      }}
      renderInput={(params) => (
        <TextField
          required={props.required}
          {...params}
          label={"المنتجات"}
          size="small"
          onChange={(e) =>
            dispatch(setListSearchBody({ search: e.target.value }))
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {_JItem.loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderGroup={(params, key) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
});
