import { lazy } from "react";
import { Navigate } from "react-router";

// project imports
import MinimalLayout from "../layout/MinimalLayout";
import Loadable from "./../components/Loadable";

// auth routing
const AuthLogin = Loadable(lazy(() => import("../views/login/index")));
const AuthPosLogin = Loadable(
  lazy(() => import("../views/pos_dashboard/posLogin"))
);

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/login",
      element: <AuthLogin />,
    },
    {
      path: "pos/login",
      element: <AuthPosLogin />,
    },
    {
      path: "/",
      element: getRedirectElement(),
    },
  ],
};

function getRedirectElement() {
  const hostname = window.location.hostname;
  if (hostname === "pos.dejlah.net" || hostname === "pos-test.dejlah.net") {
    return <Navigate to="/pos/login" />;
  } else {
    // Default redirect
    return <Navigate to="/login" />;
  }
}

export default AuthenticationRoutes;
