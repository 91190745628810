import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const transferBalanceApi = factory.get("transferBalance");
const initialState = {
  searchBody: {},
  transferBalance: {
    loading: false,
    dialog: false,
    form: {
      id: null,
      item_id: null,
      receiver_mobile: "",
    },
  },
  transferBalances: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
  getAllSms: {
    data: [],
    total: 0,
    total_amount: 0,
  },
  types: [
    { key: "zain_transfer", name: "تحويل زين العراق" },
    { key: "asia_transfer", name: "تحويل اسيا سيل" },
    { key: "korek_transfer", name: "تحويل كورك" },
    { key: "received_sms", name: "رسائل مستلمة" },
    { key: "received_ussd", name: "رصيد مستلم" },
  ],
};

export const transferBalanceSlice = createSlice({
  name: "transferBalance",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.transferBalance.dialog = !state.transferBalance.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.transferBalances.data = payload.data.data;
      state.transferBalances.total = payload.data.total;
      state.transferBalances.total_amount = payload.data.total_amount;
    },
    setSmsTable: (state, { payload }) => {
      state.getAllSms.data = payload.data.data;
      state.getAllSms.total = payload.data.total;
      state.getAllSms.total_amount = payload.data.total_amount;
    },
    setById: (state, { payload }) => {
      state.transferBalance.form = payload;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.transferBalance.form = initialState.transferBalance.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setSmsTable,
} = transferBalanceSlice.actions;

export default transferBalanceSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("transferBalance"));
    const res = await transferBalanceApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("transferBalance"));
  } catch (err) {
    dispatch(setLoading("transferBalance"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllSms = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("transferBalance"));
    const res = await transferBalanceApi.getAllSms(params);
    dispatch(setSmsTable(res));
    dispatch(setLoading("transferBalance"));
  } catch (err) {
    dispatch(setLoading("transferBalance"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, _SearchBody) => async (dispatch) => {
  try {
    await transferBalanceApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const refunded =
  (data, isCanceled = false, _SearchBody) =>
  async (dispatch) => {
    try {
      if (isCanceled) await transferBalanceApi.cancelRefunded(data);
      else await transferBalanceApi.refunded(data);
      dispatch(
        showNotification({
          message: "تمت العملية  بنجاح",
          type: "success",
        })
      );
      dispatch(getAll(_SearchBody));
    } catch (err) {
      dispatch(
        showNotification({
          message: err.response.data.errors[0],
          type: "error",
        })
      );
      throw new Error(err);
    }
  };
export const TransferBalance = {
  getAll,
  create,
  getAllSms,
  refunded,
};
