import api from "../../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getPosTransactionsAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/j-order-transaction?${params}`);
  },
  getAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/j-clearance?${params}`);
  },
  getById(id) {
    return api.get(`/api/v1/j-clearance/${id}`);
  },
  cashFromRep(data) {
    return api.post(`/api/v1/j-clearance/cash-from-rep`, data);
  },
  cashFromPos(data) {
    return api.post(`/api/v1/j-clearance/cash-from-pos`, data);
  },
};
