import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  InputAdornment,
  Stack,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import {
  PosService,
  resetForm,
  setAddServiceDialog,
} from "../../../../app/slices/serviceSlice";
import { SelectService } from "../../../../components/Selectors/SelectService";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PosServiceForm = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.service.service.loading);
  const _Pos = useSelector((state) => state.service.addService.pos);
  const _PosService = useSelector((state) => state.service.addService);
  const _Service = useSelector((state) => state.service.services.data);
  const _SearchBody = useSelector((state) => state.pointOfsales.searchBody);

  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: _PosService.form,
  });

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });

  const onSubmit = (data) => {
    dispatch(
      PosService.addServiceToPos(
        {
          user_id: _Pos.user_id,
          service_id: data.service_id,
          supplier_id: 97,
          monthly_fees: parseInt(data.monthly_fees),
          deduct_fees: data.deduct_fees,
        },
        _SearchBody
      )
    ).then(() => {
      dispatch(resetForm());
      reset({ ..._PosService.form });
    });
  };

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  useEffect(() => {
    reset({ ..._PosService.form });
  }, [reset, _PosService.form]);

  const handleDialog = () => {
    dispatch(setAddServiceDialog());
  };

  return (
    <Dialog
      open={_PosService.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          الخدمات
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography>
                  النقطة: <strong>{_Pos.name}</strong>
                </Typography>
                <Typography>({_Pos.pos_number})</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="service_id"
                control={control}
                render={({ field }) => (
                  <SelectService
                    required
                    onChange={(e, newValue) => {
                      setValue("service_id", newValue ? newValue.id : null);
                    }}
                    value={
                      _Service.filter((x) => x.id === field.value)[0] ?? null
                    }
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <Controller
                name="supplier_id"
                control={control}
                render={({ field }) => (
                  <SelectSupplier
                    required
                    onChange={(e, newValue) => {
                      setValue("supplier_id", newValue ? newValue.id : null);
                    }}
                    value={
                      _Supplier.filter((x) => x.id === field.value)[0] ?? null
                    }
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={12} md={12}>
              <Controller
                name="monthly_fees"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="المبلغ الشهري"
                    fullWidth
                    {...field}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="deduct_fees"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={(e) => {
                          setValue("deduct_fees", e.target.checked);
                        }}
                      />
                    }
                    label="استقطاع المبلغ الان؟"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack direction="row" justifyContent="start" spacing={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  {loading ? <CircularProgress  /> : "حفظ"}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PosServiceForm;
