import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Stack,
  Switch,
  FormControlLabel,
  Radio
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  Notification,
} from "../../../app/slices/appNotificationSlice";
import { Controller, useForm } from "react-hook-form";
import { SelectUser } from "../../../components/Selectors/SelectUser";
import { SelectGroup } from "../../../components/Selectors/SelectGroup";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NotificationForm = () => {
  const _Notification = useSelector((state) => state.appNotification.notification);
  const _SearchBody = useSelector((state) => state.appNotification.searchBody);
  const _User = useSelector((state) => state.user.usersList.data);
  const _Group = useSelector((state) => state.group.groupsList.data);

  const [isUsers, setIsUsers] = useState(false);
  const [isGroups, setIsGroups] = useState(false);

  const isAdd = _Notification.form.id ? false : true;
  const { control, reset, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: _Notification.form,
  });

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(Notification.create({
        title: data.title,
        description: data.description,
        is_all: data.is_all,
        is_all_rep: data.is_all_rep,
        is_all_pos: data.is_all_pos,
        groups: isGroups ? data.groups : null,
        users: isUsers ? data.users : null
      }));
    } else {
      dispatch(Notification.update(data.id, data, _SearchBody));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  const dispatch = useDispatch();

  const handlDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_Notification.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handlDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "المعلومات"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    value={field.value}
                    size="small"
                    label="العنوان"
                    fullWidth
                    onChange={(e) => {
                      if (isAdd) setValue('title', e.target.value)
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    value={field.value}
                    multiline
                    rows='7'
                    size="small"
                    label="الوصف"
                    fullWidth
                    onChange={(e) => {
                      if (isAdd) setValue('description', e.target.value)
                    }}
                  />
                )}
              />
            </Grid>
            {isAdd && (
              <Grid item xs={12} md={12}>
                <FormControlLabel control={
                  <Radio
                    value={isUsers}
                    checked={isUsers}
                    onChange={() => {
                      setIsUsers(true);
                      setIsGroups(false)
                    }}
                  />
                } label="المستخدمين" />
                <FormControlLabel control={
                  <Radio
                    value={isGroups}
                    checked={isGroups}
                    onChange={() => {
                      setIsUsers(false);
                      setIsGroups(true)
                    }}
                  />
                } label="المجموعات" />
              </Grid>
            )}
            {isAdd & isUsers ? (
              <Grid item xs={12} md={12}>
                <Controller
                  name="users"
                  control={control}
                  render={({ field }) => (
                    <SelectUser
                      multiple
                      onChange={(event, value) => {
                        var ids = value.map((x) => x.id);
                        setValue("users", value ? ids : null,);
                      }}
                      value={_User?.filter((x) => field.value?.includes(x.id) ? field.value : null)}
                    />
                  )}
                />
              </Grid>
            ) : null}
            {isAdd & isGroups ? (
              <Grid item xs={12} md={12}>
                <Controller
                  name="groups"
                  control={control}
                  render={({ field }) => (
                    <SelectGroup
                      multiple
                      onChange={(event, value) => {
                        var ids = value.map((x) => x.id);
                        setValue("groups", value ? ids : null,);
                      }}
                      value={_Group?.filter((x) => field.value?.includes(x.id) ? field.value : null)}
                    />
                  )}
                />
              </Grid>
            ) : null}
            {isAdd && (
              <Grid item xs={12} md={4}>
                <Controller
                  name="is_all"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          setValue('is_all', e.target.checked)
                        }}
                      />
                    } label="ارسال للكل !" />
                  )}
                />
              </Grid>
            )}
            {isAdd && (
              <Grid item xs={12} md={4}>
                <Controller
                  name="is_all_rep"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          setValue('is_all_rep', e.target.checked)
                        }}
                      />
                    } label="ارسال لكل المندوبين !" />
                  )}
                />
              </Grid>
            )}
            {isAdd && (
              <Grid item xs={12} md={4}>
                <Controller
                  name="is_all_pos"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          setValue('is_all_pos', e.target.checked)
                        }}
                      />
                    } label="ارسال لكل نقاط البيع !" />
                  )}
                />
              </Grid>
            )}
            {!isAdd && (
              <Grid item xs={12} md={4}>
                <Controller
                  name="target_users"
                  control={control}
                  render={({ field }) => (
                    <Typography>المستخدمين المستهدفين: {field.value}</Typography>
                  )}
                />

              </Grid>
            )}
            <Grid item xs={12} md={12}>
              {isAdd && (
                <Button type="submit" variant="contained" color="primary">
                  حفظ
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default NotificationForm;
