import {
  Grid,
  LinearProgress,
  Card,
  CardContent,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Reports,
} from "../../../../../app/slices/reportsSlice";
import { SelectProvince } from "../../../../../components/Selectors/SelectProvince";

const StockTabel = () => {
  const _Stock = useSelector((state) => state.reports.stock);
  const loading = useSelector((state) => state.reports.reports.loading);
  const _provinces = useSelector((state) => state.provinces.provinces.data);

  const dispatch = useDispatch();
  const [search, setSearch] = useState({
    province_id: null,
    // startDate: dayjs().format("2023-01-01"),
    // endDate: dayjs().format("YYYY-MM-DD"),
  });
  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(0);

  var is_admin = localStorage.getItem('role') == "super_admin" || localStorage.getItem('role') == "admin";
  var is_team_leader = localStorage.getItem('role') == "team_leader";

  var columns = [
    {
      field: "id",
      headerName: "#",
      flex: 0.1,
    },
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "available_pins",
      headerName: "الكودات المتوفرة",
      flex: 1,
      renderCell: (params) => <>{params.row.available_pins?.toLocaleString()}</>
    },
    {
      field: "sold_pins",
      headerName: "الكودات المباعة",
      flex: 1,
      renderCell: (params) => <>{params.row.sold_pins?.toLocaleString()}</>
    },
    {
      field: "total_availabe_pins",
      headerName: "مجموع مبلغ المتوفر",
      flex: 1,
      renderCell: (params) => <>{params.row.total_availabe_pins?.toLocaleString()}</>
    },
    {
      field: "total_sold_pins",
      headerName: "مجموع مبلغ المباع",
      flex: 1,
      renderCell: (params) => <>{params.row.total_sold_pins?.toLocaleString()}</>
    },
    {
      field: "average_sold_item",
      headerName: "معدل البيع في اليوم",
      flex: 1,
      renderCell: (params) => <>{params.row.average_sold_item?.toLocaleString()}</>
    },
  ];

  useEffect(() => {
    dispatch(Reports.stock({
      province_id: search.province_id ?? "",
      // start_date: search.startDate,
      // end_date: search.endDate,
    }));
  }, [search, currentPage, pageSize, dispatch]);

  const customToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: "تقرير المخزن | نظام دجلة",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )

  columns = (is_admin || is_team_leader) ? columns : columns.filter(x => x.field != 'average_sold_item')
  columns = (is_admin || is_team_leader) ? columns : columns.filter(x => x.field != 'total_availabe_pins')
  columns = (is_admin || is_team_leader) ? columns : columns.filter(x => x.field != 'total_sold_pins')
  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2.5}>
            <SelectProvince
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  province_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _provinces?.filter((x) => x.id === search.province_id)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_Stock.data ? (
                <DataGrid
                  rows={_Stock.data.map((data, index) => { return { ...data, id: index + 1 } })}
                  getRowId={(row) => row.id}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_Stock.total}
                  loading={loading}
                  rowHeight={30}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  // onPageChange={(newPage) => setCurrentPage(newPage)}
                  rowsPerPageOptions={[1000]}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  components={{
                    LoadingOverlay: LinearProgress,
                    Toolbar: customToolbar
                  }}
                />
              ) : <LinearProgress />}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StockTabel;
