import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const simCardApi = factory.get("simCard");
const initialState = {
  searchBody: {},
  transactionsSearchBody: {},
  simCard: {
    loading: false,
    dialog: false,
    sendSmsDialog: false,
    simPort: "",
    form: {
      id: null,
      name: "",
      phone: "",
      imei: "",
      type: "",
      port: "",
      code: "",
      daily_limit: "",
      balance: "",
      is_active: true,
    },
  },
  simCards: {
    loading: false,
    data: [],
    total: 0,
  },
  simCardTransactions: {
    simCardId: null,
    dialog: false,
    data: [],
    total: 0,
  },
};

export const simCardSlice = createSlice({
  name: "simCard",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.simCard.dialog = !state.simCard.dialog;
    },
    setTransactionsDialog: (state, { payload }) => {
      state.simCardTransactions.dialog = !state.simCardTransactions.dialog;
      state.simCardTransactions.simCardId = payload;
    },
    setSendSmsDialog: (state, { payload }) => {
      state.simCard.sendSmsDialog = !state.simCard.sendSmsDialog;
      state.simCard.simPort = payload;
    },
    setDataTable: (state, { payload }) => {
      state.simCards.data = payload.data.data;
      state.simCards.total = payload.data.total;
    },
    setTransactionsDataTable: (state, { payload }) => {
      state.simCardTransactions.data = payload.data.data;
      state.simCardTransactions.total = payload.data.total;
    },
    setById: (state, { payload }) => {
      state.simCard.form.id = payload.id;
      state.simCard.form.name = payload.name;
      state.simCard.form.phone = payload.phone;
      state.simCard.form.imei = payload.imei;
      state.simCard.form.type = payload.type;
      state.simCard.form.port = payload.port;
      state.simCard.form.code = payload.code;
      state.simCard.form.balance = payload.balance;
      state.simCard.form.daily_limit = payload.daily_limit;
      state.simCard.form.is_active = payload.is_active;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.simCard.form = initialState.simCard.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
    setTransactionsSearchBody: (state, { payload }) => {
      state.transactionsSearchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setTransactionsDataTable,
  setTransactionsDialog,
  setTransactionsSearchBody,
  setSendSmsDialog,
} = simCardSlice.actions;

export default simCardSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("simCard"));
    const res = await simCardApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("simCard"));
  } catch (err) {
    dispatch(setLoading("simCard"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllTransactions = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("simCard"));
    const res = await simCardApi.getSimCardTransactions(params);
    dispatch(setTransactionsDataTable(res));
    // dispatch(setTransactionsDialog(null));
    dispatch(setLoading("simCard"));
  } catch (err) {
    dispatch(setLoading("simCard"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await simCardApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("simCard"));
    await dispatch(setById(data));

    dispatch(setDialog());
    dispatch(setLoading("simCard"));
  } catch (err) {
    dispatch(setLoading("simCard"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await simCardApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const sendSms = (data) => async (dispatch) => {
  try {
    await simCardApi.sendSms(data);
    dispatch(
      showNotification({
        message: "تم الارسال بنجاح",
        type: "success",
      })
    );
    dispatch(setSendSmsDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await simCardApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const changeStatus = (id, data, _SearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("simCard"));
    await simCardApi.changeStatus(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("simCard"));
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(setLoading("simCard"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const SimCard = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  changeStatus,
  getAllTransactions,
  sendSms,
};
