import {
  LinearProgress,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Operation,
  setDuplicatePinsDialog,
} from "../../../../../app/slices/operationSlice";
import dayjs from "dayjs";

const DuplicatePinsTabel = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.operation.operation.loading);
  const _DuplicatePins = useSelector((state) => state.operation.duplicatePins);

  const columns = [
    {
      field: "serial_number",
      headerName: "الرقم التسلسلي",
      flex: 1,
    },
    {
      field: "pin_number",
      headerName: "الرقم السري",
      flex: 1,
    },
    {
      field: "operation_id",
      headerName: "رقم العملية",
      flex: 1,
    },
    {
      field: "status",
      headerName: "الحالة",
      flex: 1,
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => {
        return <>{dayjs(params.row.date).format("YYYY-MM-DD")}</>;
      },
    },
  ];

  useEffect(() => {
    if (_DuplicatePins.dialog) {
      dispatch(
        Operation.getDuplicatePins({
          operation_id: props.operationId,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.operationId]);

  const customToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: "  الكودات المكررة | نظام دجلة",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );

  return (
    <Box>
      <Dialog
        open={_DuplicatePins.dialog}
        keepMounted
        fullWidth={true}
        dir="rtl"
        onClose={() => {
          dispatch(setDuplicatePinsDialog());
        }}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogContent>
          <Typography
            mb={3}
            variant="h4"
            color={"purple"}
            gutterBottom
            component="div"
          >
            الكودات المكررة
          </Typography>
          <Box sx={{ height: "70vh" }}>
            {_DuplicatePins.data ? (
              <DataGrid
                rows={_DuplicatePins.data.map((row, index) => {
                  return { ...row, id: index + 1 };
                })}
                columns={columns}
                rowCount={_DuplicatePins.total}
                loading={loading}
                rowHeight={40}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: customToolbar,
                }}
              />
            ) : (
              <LinearProgress />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default DuplicatePinsTabel;
