import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../../api/apiFactory";
import { showNotification } from "../notificationSlice";
const jReportsApi = factory.get("jReport");
const initialState = {
  searchBody: {},
  loading: false,
  jCash: {
    loading: false,
    data: [],
    total: 0,
    jCash: 0,
  },
  jRep: {
    loading: false,
    data: [],
    total: 0,
  },
  reportTypes: [
    { type: "الكاش", name: "cash", isSelected: false },
    { type: "تفاصيل المندوبين", name: "rep", isSelected: false },
  ],
};

export const jReportsSlice = createSlice({
  name: "jReports",
  initialState,
  reducers: {
    setJCashTable: (state, { payload }) => {
      state.jCash.data = payload.data.data;
      state.jCash.total = payload.data.total;
      state.jCash.jCash = payload.data.total_amount;
    },
    setJRepTable: (state, { payload }) => {
      state.jRep.data = payload.data.data;
      state.jRep.total = payload.data.total;
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
    setSelectedReport: (state, { payload }) => {
      state.reportTypes = state.reportTypes.map((type) =>
        type.name === payload
          ? { ...type, isSelected: true }
          : { ...type, isSelected: false }
      );
    },
  },
});

export const { setLoading, setSearchBody, setJCashTable, setSelectedReport } =
  jReportsSlice.actions;

export default jReportsSlice.reducer;

//axios
const jCash = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("jReports"));
    const res = await jReportsApi.jCash(data);
    dispatch(setJCashTable(res));
    dispatch(setLoading("jReports"));
  } catch (err) {
    dispatch(setLoading("jReports"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const jRep = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("jReports"));
    const res = await jReportsApi.jRep(data);
    dispatch(setJCashTable(res));
    dispatch(setLoading("jReports"));
  } catch (err) {
    dispatch(setLoading("jReports"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const JReports = {
  jCash,
  jRep
};
