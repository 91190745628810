import { Grid } from "@mui/material";
import React from "react";
import CashHeader from "./home/header";
import CashTable from "./home/table";

const CashReport = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CashHeader />
      </Grid>
      <Grid item xs={12}>
        <CashTable />
      </Grid>
    </Grid>
  );
};

export default CashReport;
