import { Grid } from "@mui/material";
import React from "react";
import SimCardHeader from "./home/header";
import SimCardTable from "./home/table";

const SimCard = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SimCardHeader />
      </Grid>
      <Grid item xs={12}>
        <SimCardTable />
      </Grid>
    </Grid>
  );
};

export default SimCard;
