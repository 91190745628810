import { Card, CardHeader, Typography, Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../../app/slices/productsSupplierSlice";
import { TbBuildingWarehouse } from "react-icons/tb";
import ProductSupplierForm from "../../form";

const ProductSupplierHeader = () => {
  const _ProductSupplier = useSelector((state) => state.productSupplier.productSupplier);

  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            المجهزين
          </Typography>
        }
        avatar={<TbBuildingWarehouse size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            إضافة
          </Button>
        }
      />
      {_ProductSupplier.dialog ? <ProductSupplierForm /> : null}
    </Card>
  );
};

export default ProductSupplierHeader;
