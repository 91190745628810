import {
  Grid,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
  IconButton,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Reports } from "../../../../../app/slices/reportsSlice";
import dayjs from "dayjs";
import DateRangepicker from "../../../../../helpers/dateRangePicker";
import { MdOutlineClear } from "react-icons/md";

const TotalRevenueTabel = () => {
  const [search, setSearch] = useState({
    startDate: dayjs().format("2023-01-01"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const _TotalRevenue = useSelector((state) => state.reports.totalRevenue);
  const loading = useSelector((state) => state.reports.reports.loading);

  const revenuePercentage = (obj = {}) => {
    if (obj.total_amount > 0)
      return parseFloat(
        (obj?.revenue / obj?.total_amount) * 100
      ).toLocaleString();
    return 0;
  };

  const columns = [
    {
      field: "name",
      headerName: "",
      flex: 1,
    },
    {
      field: "totalAmount",
      headerName: "القيمة الكلية (IQD)",
      flex: 1,
    },
    {
      field: "count",
      headerName: "العدد",
      flex: 1,
    },
    {
      field: "revenue",
      headerName: "الارباح (IQD)",
      flex: 1,
    },
    {
      field: "revenue_percentage",
      headerName: "نسبة الارباح (%)",
      flex: 1,
    },
  ];

  const rows = [
    {
      id: 2,
      name: "مبياعات نقاط البيع",
      totalAmount:
        _TotalRevenue.data.selling_pins_pos?.total_amount?.toLocaleString(),
      count: _TotalRevenue.data.selling_pins_pos?.count,
      revenue: _TotalRevenue.data.selling_pins_pos?.revenue?.toLocaleString(),
      revenue_percentage: revenuePercentage(
        _TotalRevenue.data.selling_pins_pos
      ),
    },
    {
      id: 3,
      name: "مبيعات الموردين",
      totalAmount:
        _TotalRevenue.data.selling_pins_supplier?.total_amount?.toLocaleString(),
      count: _TotalRevenue.data.selling_pins_supplier?.count,
      revenue:
        _TotalRevenue.data.selling_pins_supplier?.revenue?.toLocaleString(),
      revenue_percentage: revenuePercentage(
        _TotalRevenue.data.selling_pins_supplier
      ),
    },
    {
      id: 4,
      name: "تحويل الرصيد بين النقاط",
      totalAmount: _TotalRevenue.data.wage?.total_amount?.toLocaleString(),
      count: _TotalRevenue.data.wage?.count,
      revenue: _TotalRevenue.data.wage?.revenue?.toLocaleString(),
      revenue_percentage: revenuePercentage(_TotalRevenue.data.wage),
    },
    {
      id: 8,
      name: "تحويل الرصيد",
      totalAmount: _TotalRevenue.data.transfer_balance?.total_amount?.toLocaleString(),
      count: _TotalRevenue.data.transfer_balance?.count,
      revenue: _TotalRevenue.data.transfer_balance?.revenue?.toLocaleString(),
      revenue_percentage: _TotalRevenue.data.transfer_balance?.revenue_rate?.toLocaleString(),
    },
    {
      id: 9,
      name: "الارباح الخارجية",
      totalAmount: _TotalRevenue.data.external_revenue?.total_amount?.toLocaleString(),
      count: _TotalRevenue.data.external_revenue?.count,
      revenue: _TotalRevenue.data.external_revenue?.revenue?.toLocaleString(),
      revenue_percentage: 100,
    },
    {
      id: 5,
      name: "المجموع",
      cellClassName: "custom-cell-class",
      totalAmount: (
        (_TotalRevenue.data.selling_pins_supplier?.total_amount || 0) +
        (_TotalRevenue.data.selling_pins_pos?.total_amount || 0) +
        (_TotalRevenue.data.wage?.total_amount || 0) + 
        (_TotalRevenue.data.transfer_balance?.total_amount || 0) +
        (_TotalRevenue.data.external_revenue?.total_amount || 0) 

      )?.toLocaleString(),
      count: (
        (_TotalRevenue.data.selling_pins_supplier?.count || 0) +
        (_TotalRevenue.data.selling_pins_pos?.count || 0) +
        (_TotalRevenue.data.wage?.count || 0) + (_TotalRevenue.data.transfer_balance?.count || 0)
      )?.toLocaleString(),
      revenue: (
        (_TotalRevenue.data.selling_pins_supplier?.revenue || 0) +
        (_TotalRevenue.data.selling_pins_pos?.revenue || 0) +
        (_TotalRevenue.data.wage?.revenue || 0) + 
        (_TotalRevenue.data.transfer_balance?.revenue || 0)+
        (_TotalRevenue.data.external_revenue?.revenue || 0)
      )?.toLocaleString(),
    },
    {
      id: 6,
      name: "المصاريف الكلية",
      totalAmount: _TotalRevenue.data.spending?.total_amount?.toLocaleString(),
      count: _TotalRevenue.data.spending?.count,
    },
    {
      id: 7,
      name: "صافي الارباح",
      cellClassName: "totalRevenue",
      totalAmount: "",
      count: "",
      revenue: _TotalRevenue.data.net?.total_revenue?.toLocaleString(),
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        Reports.totalRevenue({
          start_date: search.startDate,
          end_date: search.endDate,
        })
      );
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, search]);

  const customToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: "تقرير الارباح | نظام دجلة",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={3}>
            <DateRangepicker
              startDate={search.startDate}
              endDate={search.endDate}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    startDate: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    endDate: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    startDate: dayjs().format("2023-01-01"),
                    endDate: dayjs().format("YYYY-MM-DD"),
                  });
                  setPageSize(15);
                  setCurrentPage(0);
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_TotalRevenue.data ? (
                <DataGrid
                  sx={{
                    "& .total-revenue": {
                      fontWeight: "bold",
                      color: "green",
                    },
                    ".total-amount": {
                      fontWeight: "bold",
                      bgcolor: "#e0e0e0",
                      "&:hover": {
                        bgcolor: "#e0e0e0",
                      },
                    },
                  }}
                  rows={rows}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_TotalRevenue.total}
                  loading={loading}
                  rowHeight={50}
                  selectionModel={[]}
                  getRowClassName={(params) => {
                    return params.row.id == 5
                      ? "total-amount"
                      : params.row.id == 7
                        ? "total-revenue"
                        : "";
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                    Toolbar: customToolbar,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TotalRevenueTabel;
