import { Grid } from "@mui/material";
import React from "react";
import SettingsTable from "./home/table";
import SettingsHeader from "./home/header";

const Settings = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SettingsHeader />
      </Grid>
      <Grid item xs={12}>
        <SettingsTable />
      </Grid>
    </Grid>
  );
};

export default Settings;
