import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const operationApi = factory.get("operation");
const initialState = {
  searchBody: {
    pin_number: "",
    serial_number: "",
    supplier_id: null,
    operation_id: "",
    skip: 0,
    take: 15,
    page: 0,
  },
  operation: {
    loading: false,
    dialog: false,
    form: {
      id: "",
      name: "",
    },
  },
  changeSupplier: {
    dialog: false,
    form: {
      supplier_id: null,
      operation_id: null,
    },
  },
  operations: {
    loading: false,
    data: [],
    total: 0,
  },
  duplicatePins: {
    loading: false,
    dialog: false,
    data: [],
    total: 0,
  },
  asiaBill: {
    loading: false,
    dialog: false,
    data: [],
    total: 0,
  },
};

export const operationSlice = createSlice({
  name: "operation",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.operation.dialog = !state.operation.dialog;
    },
    setDuplicatePinsDialog: (state, action) => {
      state.duplicatePins.dialog = !state.duplicatePins.dialog;
    },
    setChangeSupplierDialog: (state, { payload }) => {
      state.changeSupplier.dialog = !state.changeSupplier.dialog;
      state.changeSupplier.form.operation_id = payload;
    },
    setDataTable: (state, { payload }) => {
      state.operations.data = payload.data.data;
      state.operations.total = payload.data.total;
    },
    setDuplicatePinsDataTable: (state, { payload }) => {
      state.duplicatePins.data = payload.data.data;
      state.duplicatePins.total = payload.data.total;
      state.duplicatePins.total_amount = payload.data.total_amount;
    },
    setAsiaBillsDataTable: (state, { payload }) => {
      state.asiaBill.data = payload.data.data;
      state.asiaBill.total = payload.data.total;
    },
    setById: (state, { payload }) => {
      state.operation.form.id = payload.id;
      state.operation.form.name = payload.name;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.operation.form = initialState.operation.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDuplicatePinsDialog,
  setDuplicatePinsDataTable,
  setChangeSupplierDialog,
  setAsiaBillsDataTable,
} = operationSlice.actions;

export default operationSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("operation"));
    const res = await operationApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("operation"));
  } catch (err) {
    dispatch(setLoading("operation"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getDuplicatePins = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("operation"));
    const res = await operationApi.duplicatePins(params);
    dispatch(setDuplicatePinsDataTable(res));
    dispatch(setLoading("operation"));
  } catch (err) {
    dispatch(setLoading("operation"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAsiaBills = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("operation"));
    const res = await operationApi.asiaBills(params);
    dispatch(setAsiaBillsDataTable(res));
    dispatch(setLoading("operation"));
  } catch (err) {
    dispatch(setLoading("operation"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await operationApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("operation"));
    const res = await operationApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("operation"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("operation"));
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await operationApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data) => async (dispatch) => {
  try {
    await operationApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const changeSupplier = (data, searchBody) => async (dispatch) => {
  try {
    await operationApi.changeSupplier(data);
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    dispatch(setChangeSupplierDialog(null));
    dispatch(getAll(searchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const Operation = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  getDuplicatePins,
  changeSupplier,
  getAsiaBills,
};
