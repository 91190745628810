import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const posFieldApi = factory.get("posField");
const initialState = {
  searchBody: {},
  pos_id: null,
  item_id: null,
  posField: {
    loading: false,
    dialog: false,
    form: {
      id: null,
      name: "",
    },
  },
  posFields: {
    data: [],
    total: 0,
  },
};

export const posFieldSlice = createSlice({
  name: "posField",
  initialState,
  reducers: {
    setDialog: (state, { payload }) => {
      state.posField.dialog = !state.posField.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.posFields.data = payload.data.data;
      state.posFields.total = payload.data.total;
    },
    setData: (state, { payload }) => {
      state.posFields.data = payload.data.data;
    },
    setById: (state, { payload }) => {
      state.posField.form.id = payload.id;
      state.posField.form.name = payload.name;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.posField.form = initialState.posField.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDataList,
} = posFieldSlice.actions;

export default posFieldSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("posFields"));
    const res = await posFieldApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("posFields"));
  } catch (err) {
    dispatch(setLoading("posFields"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, searchBody) => async (dispatch) => {
  try {
    await posFieldApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(searchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("posField"));
    const res = await posFieldApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setLoading("posField"));
  } catch (err) {
    dispatch(setLoading("posField"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, searchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("posField"));
    await posFieldApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("posField"));
    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(getAll(searchBody));
  } catch (err) {
    dispatch(setLoading("posField"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, searchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("posField"));
    await posFieldApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    dispatch(resetForm());
    dispatch(setLoading("posField"));
    dispatch(setDialog());
    dispatch(getAll(searchBody));
  } catch (err) {
    dispatch(setLoading("posField"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const PosFieldActions = {
  getAll,
  deleteById,
  getById,
  create,
  update,
};
