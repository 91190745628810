import {
  LinearProgress,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ProductsOrder, setTransactionsDialog
} from "../../../../../app/slices/productsOrdersSlice";
import { BiInfoCircle } from "react-icons/bi";
import dayjs from "dayjs";

const ProductOrdersTransactionsTable = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.productsOrders.loading);
  const _Transactions = useSelector((state) => state.productsOrders.transactions);

  const columns = [
    {
      field: "number",
      headerName: 'رقم المناقلة',
      flex: 1,
    },
    {
      field: "amount",
      headerName: "القيمة (IQD)",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>
            {params.row.amount?.toLocaleString()}
          </Typography>
        );
      },
    },
    {
      field: "from_user_name",
      headerName: "من المحفظة",
      flex: 1,
    },
    {
      field: "to_user_name",
      headerName: " الى المحفظة",
      flex: 1,
    },
    {
      field: "from_user",
      headerName: "حساب المرسل (IQD)",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction='column' spacing={1} justifyContent='center' alignItems='center'>
            <Typography>
              الرصيد: {params.row.from_current_balance?.toLocaleString()}
            </Typography>
            <Typography>
              الديون: {params.row.from_current_debt?.toLocaleString()}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "to_user",
      headerName: "حساب المستلم (IQD)",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction='column' spacing={1} justifyContent='center' alignItems='center'>
            <Typography>
              الرصيد: {params.row.to_current_balance?.toLocaleString()}
            </Typography>
            <Typography>
              الديون :{params.row.to_current_debt?.toLocaleString()}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "created_at",
      headerName: "تاريخ العملية",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <Typography sx={{ WebkitLineBreak: 'after-white-spaces', whiteSpace: 'break-spaces' }}>
            {dayjs(params.row.created_at).format("HH:mm:ss / YYYY-MM-DD ")}
          </Typography>
        );
      },
    },
    {
      field: "type",
      headerName: "نوع العملية",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <Typography>
              {(params.row.type)}
            </Typography>
            {params.row.note && <Tooltip title={params.row.note}>
              <IconButton size="small"><BiInfoCircle /></IconButton>
            </Tooltip>}
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    if (_Transactions.dialog && _Transactions.order_id) {
      dispatch(ProductsOrder.transactions(_Transactions.order_id));;
    }
  }, [_Transactions.order_id]);

  const customToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: " المناقلات المالية لمجهزين الاجهزة | نظام دجلة",
          utf8WithBom: true,

        }}
      />
    </GridToolbarContainer>
  )

  return (
    <Box>
      <Dialog
        open={_Transactions.dialog}
        keepMounted
        fullWidth={true}
        dir="rtl"
        onClose={() => {
          dispatch(setTransactionsDialog(null))
        }}
        aria-describedby="alert-dialog-slide-description"
        maxWidth='lg'
      >
        <DialogContent>
          <Typography
            mb={3}
            variant="h4"
            color={"purple"}
            gutterBottom
            component="div"
          >
            المناقلات المالية
          </Typography>
          <Box sx={{height: '50vh'}}>
            {_Transactions.data ? (
              <DataGrid
                rows={_Transactions.data?.map((data, index) => {
                  return { ...data, sequence: index + 1 }
                })}
                columns={columns}
                rowCount={_Transactions.total}
                loading={loading}
                rowHeight={65}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: customToolbar
                }}
              />
            ) : <LinearProgress />}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ProductOrdersTransactionsTable;
