import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  Box,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  Banner,
} from "../../../../app/slices/bannerSlice";
import { Controller, useForm } from "react-hook-form";
import FilePondInput from "../../../../helpers/FilePondInput";
import { SelectProvince } from "../../../../components/Selectors/SelectProvince";
import { useState } from "react";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BannerForm = () => {
  const _Banner = useSelector((state) => state.banner.banner);
  const _SearchBody = useSelector((state) => state.banner.searchBody);
  const _provinces = useSelector((state) => state.provinces.provinces.data);

  const [isAllProviances, setIsAllProviances] = useState(
    _Banner.form.province_ids?.length === 0 ? true : false
  );

  const isAdd = _Banner.form.id ? false : true;
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: _Banner.form,
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("url", data.url ?? "");
    if (!isAdd) formData.append("_method", "PUT");
    formData.append("title", data.title);
    if (!isAllProviances) {
      data.province_ids.forEach((id) => {
        formData.append("province_ids[]", id);
      });
    } else {
      formData.append("province_ids", "[]");
    }
    formData.append("is_hidden", data.is_hidden);
    if (data.logo && data.logo[0].name) formData.append("logo", data.logo[0]);
    if (isAdd) {
      dispatch(Banner.create(formData));
    } else {
      dispatch(Banner.update(data.id, formData, _SearchBody));
    }
  };
  useEffect(() => {
    reset({ ..._Banner.form });
  }, [_Banner.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const handlDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_Banner.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handlDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الاسم"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="url"
                control={control}
                render={({ field }) => (
                  <TextField size="small" label="الرابط" fullWidth {...field} />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="is_hidden"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          setValue("is_hidden", e.target.checked);
                        }}
                      />
                    }
                    label="اخفاء الاعلان !"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isAllProviances}
                    onChange={(e) => {
                      setIsAllProviances(e.target.checked);
                      setValue(
                        "province_ids",
                        e.target.checked ? [] : _Banner.form.province_ids,
                        { shouldDirty: true }
                      );
                    }}
                  />
                }
                label="كل المحافظات"
              />
            </Grid>
            {isAllProviances ? null : (
              <Grid item xs={12} md={8}>
                <Controller
                  name="province_ids"
                  control={control}
                  render={({ field }) => (
                    <SelectProvince
                      multiple
                      onChange={(e, newValue) => {
                        var ids = newValue.map((x) => x.id);
                        setValue("province_ids", newValue ? ids : [], {
                          shouldDirty: true,
                        });
                      }}
                      value={_provinces?.filter((x) =>
                        field.value?.includes(x.id)
                      )}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <Controller
                name="logo"
                control={control}
                render={({ field }) => (
                  <Box>
                    <FilePondInput field={field} required={isAdd} />
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                disabled={!isDirty}
                type="submit"
                variant="contained"
                color="primary"
              >
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default BannerForm;
