const Logo = ({ vertical = false, style = {} }) => (
  <img
    alt="Logo"
    src={
      vertical
        ? "/images/Logo/dejlah.png"
        : "/images/Logo/dejlah.png"
    }
    style={style}


  />
);

export default Logo;