import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const reportsApi = factory.get("reports");
const initialState = {
  reports: {
    loading: false,
  },
  repDetails: {
    loading: false,
    data: [],
    total: 0,
  },
  orders: {
    loading: false,
    data: [],
    total: 0,
  },
  soldPins: {
    loading: false,
    data: [],
    total: 0,
  },
  totalRevenue: {
    loading: false,
    data: [],
    total: 0,
  },
  cash: {
    loading: false,
    data: [],
    total: 0,
    cash: 0,
  },
  totals: {},
  stock: {
    data: [],
    total: 0,
  },
  ordersDetailed: {
    data: [],
    total: 0,
  },
  posOrders: {
    data: [],
    total: 0,
  },
  ordersPinsDetails: {
    data: [],
    total: 0,
  },
  platformPayments: {
    data: [],
    total: 0,
    total_amount: 0,
  },
  posOrdersPerWeek: {
    data: [],
    total: 0,
    rep_id: null,
  },
  repOrdersPerWeek: {
    data: [],
    total: 0,
  },
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setRepDetailsDataTable: (state, { payload }) => {
      state.repDetails.data = payload.data.data;
      state.repDetails.total = payload.data.total;
    },
    setOrdersTable: (state, { payload }) => {
      state.orders.data = payload.data.data;
      state.orders.total = payload.data.total;
    },
    setSoldPinsTable: (state, { payload }) => {
      state.soldPins.data = payload.data.data;
      state.soldPins.total = payload.data.total;
    },
    setTotalRevenueTable: (state, { payload }) => {
      state.totalRevenue.data = payload.data.data;
    },
    setCashTable: (state, { payload }) => {
      state.cash.data = payload.data.data.data;
      state.cash.total = payload.data.total;
      state.cash.cash = payload.data.data.cash;
    },
    setTotalsTable: (state, { payload }) => {
      state.totals = payload.data.data;
    },
    setStockTable: (state, { payload }) => {
      state.stock.data = payload.data.data;
      state.stock.total = payload.data.total;
    },
    setOrderDetailedTable: (state, { payload }) => {
      state.ordersDetailed.data = payload.data.data;
      state.ordersDetailed.total = payload.data.total;
    },
    setPosOrdersTable: (state, { payload }) => {
      state.posOrders.data = payload.data.data;
      state.posOrders.total = payload.data.total;
    },
    setOrdersPinsDetailsTable: (state, { payload }) => {
      state.ordersPinsDetails.data = payload.data.data;
      state.ordersPinsDetails.total = payload.data.total;
    },
    setPlatformPaymentsTable: (state, { payload }) => {
      state.platformPayments.data = payload.data.data;
      state.platformPayments.total = payload.data.total;
      state.platformPayments.total_amount = payload.data.total_amount;
    },
    setPosOrdersPerWeekTable: (state, { payload }) => {
      state.posOrdersPerWeek.data = payload.data.data;
      state.posOrdersPerWeek.total = payload.data.total;
    },
    setRepOrdersPerWeekTable: (state, { payload }) => {
      state.repOrdersPerWeek.data = payload.data.data;
      state.repOrdersPerWeek.total = payload.data.total;
    },
    setRepId: (state, { payload }) => {
      state.posOrdersPerWeek.rep_id = payload;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
  },
});

export const {
  setLoading,
  setRepDetailsDataTable,
  setOrdersTable,
  setSoldPinsTable,
  setTotalRevenueTable,
  setCashTable,
  setTotalsTable,
  setStockTable,
  setOrderDetailedTable,
  setPosOrdersTable,
  setOrdersPinsDetailsTable,
  setPlatformPaymentsTable,
  setPosOrdersPerWeekTable,
  setRepOrdersPerWeekTable,
  setRepId,
} = reportsSlice.actions;

export default reportsSlice.reducer;

//axios
const getAll = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("reports"));
    const res = await reportsApi.getAllRep(data);
    dispatch(setRepDetailsDataTable(res));
    dispatch(setLoading("reports"));
  } catch (err) {
    dispatch(setLoading("reports"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getOrders = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("reports"));
    const res = await reportsApi.orders(data);
    dispatch(setOrdersTable(res));
    dispatch(setLoading("reports"));
  } catch (err) {
    dispatch(setLoading("reports"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getSoldPins = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("reports"));
    const res = await reportsApi.soldPins(data);
    dispatch(setSoldPinsTable(res));
    dispatch(setLoading("reports"));
  } catch (err) {
    dispatch(setLoading("reports"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const totalRevenue = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("reports"));
    const res = await reportsApi.totalRevenue(data);
    dispatch(setTotalRevenueTable(res));
    dispatch(setLoading("reports"));
  } catch (err) {
    dispatch(setLoading("reports"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const cash = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("reports"));
    const res = await reportsApi.cash(data);
    dispatch(setCashTable(res));
    dispatch(setLoading("reports"));
  } catch (err) {
    dispatch(setLoading("reports"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const totals = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("reports"));
    const res = await reportsApi.totals(data);
    dispatch(setTotalsTable(res));
    dispatch(setLoading("reports"));
  } catch (err) {
    dispatch(setLoading("reports"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const stock = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("reports"));
    const res = await reportsApi.stock(data);
    dispatch(setStockTable(res));
    dispatch(setLoading("reports"));
  } catch (err) {
    dispatch(setLoading("reports"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getOrdersDetailed = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("reports"));
    const res = await reportsApi.ordersDetailed(data);
    dispatch(setOrderDetailedTable(res));
    dispatch(setLoading("reports"));
  } catch (err) {
    dispatch(setLoading("reports"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getordersPinsDetails = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("reports"));
    const res = await reportsApi.ordersPinsDetails(data);
    dispatch(setOrdersPinsDetailsTable(res));
    dispatch(setLoading("reports"));
  } catch (err) {
    dispatch(setLoading("reports"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getPosOrders = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("reports"));
    const res = await reportsApi.posOrders(data);
    dispatch(setPosOrdersTable(res));
    dispatch(setLoading("reports"));
  } catch (err) {
    dispatch(setLoading("reports"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getPlatformPayments = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("reports"));
    const res = await reportsApi.platformPayments(data);
    dispatch(setPlatformPaymentsTable(res));
    dispatch(setLoading("reports"));
  } catch (err) {
    dispatch(setLoading("reports"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getPosOrdersPerWeek = () => async (dispatch) => {
  try {
    dispatch(setLoading("reports"));
    const res = await reportsApi.posOrdersPerWeek();
    dispatch(setPosOrdersPerWeekTable(res));
    dispatch(setLoading("reports"));
  } catch (err) {
    dispatch(setLoading("reports"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getRepOrdersPerWeek = () => async (dispatch) => {
  try {
    dispatch(setLoading("reports"));
    const res = await reportsApi.repOrdersPerWeek();
    dispatch(setRepOrdersPerWeekTable(res));
    dispatch(setLoading("reports"));
  } catch (err) {
    dispatch(setLoading("reports"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const Reports = {
  getAll,
  getOrders,
  getSoldPins,
  totalRevenue,
  cash,
  totals,
  stock,
  getOrdersDetailed,
  getPosOrders,
  getordersPinsDetails,
  getPlatformPayments,
  getPosOrdersPerWeek,
  getRepOrdersPerWeek,
};
