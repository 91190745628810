import { Grid } from "@mui/material";
import React from "react";
import ProductsTable from "./home/table";
import ProductHeader from "./home/header";

const Products = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ProductHeader />
      </Grid>
      <Grid item xs={12}>
        <ProductsTable />
      </Grid>
    </Grid>
  );
};

export default Products;
