import { Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { GiProfit } from "react-icons/gi";

const TotalRevenueHeader = () => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            تقرير الارباح
          </Typography>
        }
        avatar={<GiProfit size={20} />}
      />
    </Card>
  );
};

export default TotalRevenueHeader;
