import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import AddReplyForm from "./addReplyForm";

const TicketsForm = (props) => {
  const _Tickets = useSelector((state) => state.tickets);

  return <Box>{_Tickets.addReplyDialog && <AddReplyForm id={props.id} />}</Box>;
};

export default TicketsForm;
