import axios from "axios";
import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/user?is_active=all&${params}`);
  },
  getAllList() {
    return api.post(`/api/v1/user/list`);
  },
  getAllDriversList() {
    return api.get(`/api/v1/user/driver-list`);
  },
  getRepList() {
    return api.get(`/api/v1/user/rep-list`);
  },
  getRepAndPos(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.post(`/api/v1/user/rep-or-pos?${params}`);
  },
  export(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.post(`/api/v1/user/rep-or-pos?${params}`, null, {
      responseType: "blob",
    });
  },

  login(data) {
    return api.post(`/api/v1/login-admin`, data);
  },
  loginPos(data) {
    return api.post(`/api/v1/login-mobile`, data);
  },
  confirmLogin(data) {
    return api.post(`/api/v1/login-confirm`, data);
  },
  logout() {
    return api.post(`/api/v1/logout`);
  },
  getById(id) {
    return api.get(`/api/v1/user/${id}`);
  },
  update(id, data) {
    return api.put(`/api/v1/user/${id}`, data);
  },
  create(data) {
    return api.post(`/api/v1/user`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  delete(id) {
    return api.delete(`/api/v1/user/${id}`);
  },
  userActivation(data) {
    return api.post(`/api/v1/user/activation/`, data);
  },
  WalletActivation(data) {
    return api.post(`/api/v1/user/wallet-activation/`, data);
  },
  userInfo() {
    return api.get(`/api/v1/me`);
  },
  changePassword(data) {
    return api.post(`/api/v1/change-password`, data);
  },
  getIp() {
    return axios.get(`https://api.hostip.info/get_html.php`);
  },
  resetPasswordByAdmin(data) {
    return api.post(`/api/v1/change-password`, data);
  },
  loginHistory(userId) {
    return api.get(`/api/v1/user/login-history?user_id=${userId}`);
  },
};
