import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach(key => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/supplier-product?${params}`);
  },
  getAllList() {
    return api.post(`/api/v1/supplier-product/list`);
  },
  initial(id, data) {
    return api.post(`/api/v1/supplier-product/initial/${id}`, data);
  },
  getById(id) {
    return api.get(`/api/v1/supplier-product/${id}`);
  },
  update(id, data) {
    return api.put(`/api/v1/supplier-product/${id}`, data);
  },
  create(data) {
    return api.post(`/api/v1/supplier-product`, data);
  },
  delete(id) {
    return api.delete(`/api/v1/supplier-product/${id}`);
  },
  transactions(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach(key => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/supplier-product/transactions?${params}`);
  },
  payCash(data) {
    return api.post(`/api/v1/wallet/supplier/product_pay-cash`, data);
  },
}