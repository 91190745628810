import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import ReceiveCashForm from "./receiveCash";
import TopupForm from "./topup";
import TransferForm from "./transfer";
import WithdrawForm from "./withdraw";
import ClearanceForm from "./clearance";
import WithdrawPosForm from "./withdrawPos";
import CorrectBalanceForm from "./correctBalance";

const TransactionForm = () => {
  const _User = useSelector((state) => state.user.user.form);
  const _Wallet = useSelector((state) => state.wallet);

  return (
    <Box>
      {_Wallet.recivedCashDialog && <ReceiveCashForm userData={_User} />}
      {_Wallet.topUpDialog && <TopupForm userData={_User} />}
      {_Wallet.transferDialog && <TransferForm userData={_User} />}
      {_Wallet.withdrawDialog && <WithdrawForm userData={_User} />}
      {_Wallet.clearanceDialog && <ClearanceForm />}
      {_Wallet.withdrawPosDialog && <WithdrawPosForm userData={_User} />}
      {_Wallet.correctBalanceDialog && <CorrectBalanceForm userData={_User} />}
    </Box>
  );
};

export default TransactionForm;
