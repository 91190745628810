import { Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { IoTicketOutline } from "react-icons/io5";

const TicketsHeader = () => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            التذاكر
          </Typography>
        }
        avatar={<IoTicketOutline size={20} />}
      />
    </Card>
  );
};

export default TicketsHeader;
