import {
  Box,
  Card,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectPos } from "../../components/Selectors/SelectPos";
import { SelectItem } from "../../components/Selectors/SelectItems";
import { SelectSubCategory } from "../../components/Selectors/SelectSubCategory";
import DateRangepicker from "../../helpers/dateRangePicker";
import { MdOutlineClear } from "react-icons/md";
import dayjs from "dayjs";
import { setSearchBody } from "../../app/slices/transferBalanceSlice";
import { setSearchBody as setSearchBodyOrders } from "../../app/slices/orderSlice";
import TransferBalanceTable from "./transferBalance";
import OrdersTable from "./orders";
import { SelectProvince } from "../../components/Selectors/SelectProvince";
import { SelectCity } from "../../components/Selectors/SelectCity";
import { setCityTable } from "../../app/slices/provincesSlice";
import { SelectRepresentative } from "../../components/Selectors/SelectRepresentative";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const OrdersAndTransferBalance = () => {
  const dispatch = useDispatch();

  const _provinces = useSelector((state) => state.provinces.provinces.data);
  const _Cities = useSelector((state) => state.provinces.cities.data);
  const _rep = useSelector((state) => state.user.usersList.data);

  const [search, setSearch] = useState({
    item: null,
    pos: null,
    subcategory: null,
    item_id: null,
    pos_id: null,
    sub_category_id: null,
    province_id: null,
    start_date: dayjs().format("2023-01-01"),
    end_date: dayjs().format("YYYY-MM-DD"),
    rep_id: null,
  });
  const [page, setPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [value, setValue] = useState(0);
  const [provinceId, setProvinceId] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearch({
      item: null,
      pos: null,
      subcategory: null,
      province_id: null,
      order_id: "",
      start_date: dayjs().format("2023-01-01"),
      end_date: dayjs().format("YYYY-MM-DD"),
    });
    setPage(15);
    setCurrentPage(0);
  };

  useEffect(() => {
    dispatch(
      setSearchBody({
        skip: currentPage * page,
        take: page,
        id: search.order_id ?? "",
        item_id: search.item ? search.item.id : "",
        rep_id: search.rep_id ?? "",
        pos_id: search.pos ? search.pos.id : "",
        sub_category_id: search.subcategory ? search.subcategory.id : "",
        start_date: search.start_date ?? "",
        end_date: search.end_date ?? "",
        province_id: search.province_id ?? "",
      })
    );
  }, [currentPage, dispatch, page, search]);

  useEffect(() => {
    dispatch(
      setSearchBodyOrders({
        skip: currentPage * page,
        take: page,
        id: search.order_id ?? "",
        item_id: search.item ? search.item.id : "",
        pos_id: search.pos ? search.pos.id : "",
        province_id: search.province_id ?? "",
        city_id: search.city_id ?? "",
        sub_category_id: search.subcategory ? search.subcategory.id : "",
        start_date: search.start_date ?? "",
        end_date: search.end_date ?? "",
      })
    );
  }, [currentPage, dispatch, page, search]);

  useEffect(() => {
    const selectedCities = _provinces?.filter((x) =>
      x.id === provinceId ? x.city : null
    )[0];
    dispatch(setCityTable(selectedCities?.city));
  }, [provinceId, _provinces, dispatch]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 1,
        }}
      >
        <Box sx={{ minWidth: { md: "100%", xs: 1800 } }}>
          <Card sx={{ mx: 2 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs"
                variant="fullWidth"
              >
                <Tab label="المبيعات" {...a11yProps(0)} />
                <Tab label="تحويل الارصدة" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Card>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={1.5}>
              <TextField
                size="small"
                value={search.order_id}
                onChange={(e) => {
                  setSearch({
                    ...search,
                    order_id: e.target.value,
                  });
                }}
                label="رقم الطلب"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <SelectPos
                value={search.pos}
                onChange={(e, newValue) => {
                  setSearch({
                    ...search,
                    pos: newValue ? newValue : null,
                  });
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectItem
                value={search.item}
                onChange={(e, newValue) => {
                  setSearch({
                    ...search,
                    item: newValue ? newValue : null,
                  });
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectSubCategory
                value={search.subcategory}
                onChange={(e, newValue) => {
                  setSearch({
                    ...search,
                    subcategory: newValue ? newValue : null,
                  });
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectProvince
                onChange={(e, newValue) => {
                  setProvinceId(newValue ? newValue.id : null);
                  setSearch({
                    ...search,
                    province_id: newValue ? newValue.id : null,
                  });
                }}
                value={
                  _provinces?.filter((x) => x.id === provinceId)[0] ?? null
                }
              />
            </Grid>
            {value === 0 ? (
              <Grid item xs={2}>
                <SelectCity
                  onChange={(e, newValue) => {
                    setSearch({
                      ...search,
                      city_id: newValue ? newValue.id : null,
                    });
                  }}
                  value={
                    _Cities?.filter((x) => x.id === search.city_id)[0] ?? null
                  }
                />
              </Grid>
            ) : null}
            {value === 1 ? (
              <Grid item xs={2}>
                <SelectRepresentative
                  onChange={(event, value) => {
                    setSearch({
                      ...search,
                      rep_id: value ? value.id : null,
                    });
                  }}
                  value={_rep.filter((x) => x.id === search.rep_id)[0] ?? null}
                />
              </Grid>
            ) : null}
            <Grid item xs={3}>
              <DateRangepicker
                startDate={search.start_date}
                endDate={search.end_date}
                handleOnChange={(searchDate) => {
                  const updatedFilter = {
                    from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                    to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  };
                  if (
                    updatedFilter.from !== searchDate.startDate ||
                    updatedFilter.to !== searchDate.endDate
                  ) {
                    setSearch({
                      ...search,
                      start_date: dayjs(searchDate[0].startDate).format(
                        "YYYY-MM-DD"
                      ),
                      end_date: dayjs(searchDate[0].endDate).format(
                        "YYYY-MM-DD"
                      ),
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={1.5}>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      item: null,
                      pos: null,
                      subcategory: null,
                      order_id: "",
                      province_id: null,
                      city_id: null,
                      start_date: dayjs().format("2023-01-01"),
                      end_date: dayjs().format("YYYY-MM-DD"),
                      rep_id: null,
                    });
                    setPage(15);
                    setCurrentPage(0);
                    setProvinceId(null);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <TabPanel value={value} index={0}>
                <OrdersTable
                  setPage={(newPage) => setPage(newPage)}
                  setCurrentPage={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                />
              </TabPanel>
            </Grid>
            <Grid item xs={12}>
              <TabPanel value={value} index={1}>
                <TransferBalanceTable
                  setPage={(newPage) => setPage(newPage)}
                  setCurrentPage={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                />
              </TabPanel>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default OrdersAndTransferBalance;
