import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  SubCategories,
} from "../../../../app/slices/subcategorySlice";
import { Controller, useForm } from "react-hook-form";
import { Stack } from "@mui/system";
import FilePondInput from "../../../../helpers/FilePondInput";
import { SelectCategory } from "../../../../components/Selectors/SelectCategory";
import { SelectProvince } from "../../../../components/Selectors/SelectProvince";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SubCategoriesForm = () => {
  const _SubCategories = useSelector(
    (state) => state.subCategories.subCategory
  );
  const _SearchBody = useSelector((state) => state.subCategories.searchBody);
  const _provinces = useSelector((state) => state.provinces.provinces.data);

  const [isAllProvinces, setIsAllProvinces] = useState(
    _SubCategories.form.province_ids?.length === 0 ? true : false
  );

  const isAdd = _SubCategories.form.id ? false : true;
  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _SubCategories.form,
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("category_id", data.category_id.id);
    if (!isAdd) formData.append("_method", "PUT");
    formData.append("name", data.name);
    if (!isAllProvinces) {
      data.province_ids.forEach((id) => {
        formData.append("province_ids[]", id);
      });
    } else {
      formData.append("province_ids", "[]");
    }
    formData.append("digit_length", data.digit_length);
    if (data.logo && data.logo[0].name) formData.append("logo", data.logo[0]);
    if (isAdd) {
      dispatch(SubCategories.create(formData));
    } else {
      dispatch(SubCategories.update(data.id, formData, _SearchBody));
    }
  };
  useEffect(() => {
    reset({ ..._SubCategories.form });
  }, [_SubCategories.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const handlDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_SubCategories.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handlDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="category_id"
                control={control}
                render={({ field }) => (
                  <SelectCategory
                    {...field}
                    required
                    value={field.value}
                    onChange={(e, newValue) => {
                      setValue("category_id", newValue ? newValue : null);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label=" الاسم"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="digit_length"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="عدد ارقام الكود"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isAllProvinces}
                    onChange={(e) => {
                      setIsAllProvinces(e.target.checked);
                      setValue(
                        "province_ids",
                        e.target.checked
                          ? []
                          : _SubCategories.form.province_ids,
                        { shouldDirty: true }
                      );
                    }}
                  />
                }
                label="كل المحافظات"
              />
            </Grid>
            {isAllProvinces ? null : (
              <Grid item xs={12} md={8}>
                <Controller
                  name="province_ids"
                  control={control}
                  render={({ field }) => (
                    <SelectProvince
                      multiple
                      onChange={(e, newValue) => {
                        var ids = newValue.map((x) => x.id);
                        setValue("province_ids", newValue ? ids : [], {
                          shouldDirty: true,
                        });
                      }}
                      value={_provinces?.filter((x) =>
                        field.value?.includes(x.id)
                      )}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <Controller
                name="logo"
                control={control}
                render={({ field }) => (
                  <Box>
                    {!isAdd ? (
                      <Stack direction="row" sx={{ justifyContent: "center" }}>
                        <Box
                          component="img"
                          src={field.value}
                          sx={{ width: 200 }}
                        />
                      </Stack>
                    ) : null}
                    <FilePondInput field={field} required={isAdd} />
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SubCategoriesForm;
