import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Chip,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Banner, setSearchBody } from "../../../../../app/slices/bannerSlice";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import { SelectProvince } from "../../../../../components/Selectors/SelectProvince";
import { MdOutlineClear } from "react-icons/md";
import dayjs from "dayjs";

const BannerTabel = () => {
  const dispatch = useDispatch();

  const _Banner = useSelector((state) => state.banner.banners);
  const loading = useSelector((state) => state.banner.banner.loading);
  const _SearchBody = useSelector((state) => state.banner.searchBody);
  const _provinces = useSelector((state) => state.provinces.provinces.data);

  const [search, setSearch] = useState({
    search: "",
    province_id: null,
  });
  const [page, setPage] = useState(15);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(Banner.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "title",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "url",
      headerName: "الرابط",
      flex: 1,
      renderCell: (params) => {
        return (
          <a rel="noreferrer" href={params.row.url} target="_blank">
            {params.row.url}
          </a>
        );
      },
    },
    {
      field: "logo",
      headerName: "الصورة",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box component="img" src={params.row.logo} sx={{ height: 90 }} />
        );
      },
    },
    {
      field: "provinces",
      headerName: "عدد المحافظات",
      flex: 1,
      renderCell: (params) =>
        params.row.provinces?.length === 0
          ? "كل المحافظات"
          : params.row.provinces.length,
    },
    {
      field: "is_hidden",
      headerName: "اخفاء المنتج !",
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.is_hidden ? "مخفي" : "مرئي"}
            color={params.row.is_hidden ? "default" : "success"}
          ></Chip>
        );
      },
    },
    {
      field: "created_at",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => dayjs(params.row.created_at).format("YYYY-MM-DD / hh:mm a"),
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton onClick={() => dispatch(Banner.getById(params.row.id))}>
              <BiEdit />
            </IconButton>
            <IconButton onClick={() => handleDeleteClick(params.row)}>
              <BiTrash />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        Banner.getAll({
          search: search.search,
          province_id: search.province_id ?? "",
          skip: 0,
          take: 15,
        })
      );
      dispatch(
        setSearchBody({
          search: search.search,
          province_id: search.province_id ?? "",
          skip: 0,
          take: 15,
        })
      );
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, search]);

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.title}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              onChange={(e) => {
                setSearch({
                  ...search,
                  search: e.target.value,
                });
              }}
              label="بحث عن الاسم "
              fullWidth
            />
          </Grid>
          <Grid item xs={2.5}>
            <SelectProvince
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  province_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _provinces?.filter((x) => x.id === search.province_id)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={1.5}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    search: "",
                    province_id: null,
                  });
                  setPage(15);
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_Banner.data ? (
                <DataGrid
                  rows={_Banner.data}
                  columns={columns}
                  pageSize={page}
                  rowCount={_Banner.total}
                  loading={loading}
                  rowHeight={100}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) =>
                    dispatch(
                      Banner.getAll({
                        search: search,
                        skip: newPage * 15,
                        take: 15,
                      })
                    )
                  }
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    dispatch(
                      Banner.getAll({
                        search: search,
                        skip: 0,
                        take: newPageSize,
                      })
                    );
                    setPage(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BannerTabel;
