import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== "" && data[key] !== null && data[key] !== undefined) {
          params += `${key}=${data[key]}&`;
        }
      });
    }
    return api.get(`/api/v1/operation?${params}`);
  },
  getById(id) {
    return api.get(`/api/v1/operation/${id}`);
  },
  update(id, data) {
    return api.post(`/api/v1/operation/${id}`, data);
  },
  create(data) {
    return api.post(`/api/v1/operation`, data);
  },
  delete(id) {
    return api.delete(`/api/v1/operation/${id}`);
  },
  duplicatePins(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/operation-duplicate?${params}`);
  },
  changeSupplier(data) {
    return api.post(`/api/v1/pin/change-supplier`, data);
  },
  asiaBills(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/operation-asia?${params}`);
  },
};
