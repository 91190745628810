import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
  Divider,
  LinearProgress,
  InputAdornment,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  JOrder,
  resetForm,
  setAdminDiscountDialog,
} from "../../../../app/slices/jomla/orderSlice";
import { NumericFormat } from "react-number-format";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditAdminDiscountForm = () => {
  const dispatch = useDispatch();

  const _JOrder = useSelector((state) => state.jOrder.adminDiscount);
  // const _SearchBody = useSelector((state) => state.jOrder.searchBody);
  const [loading, setLoading] = useState(false);

  const { control, reset, handleSubmit, setValue, } = useForm({
    defaultValues: _JOrder.form,
  });

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(
      JOrder.editAdminDiscount(
        _JOrder.form.id,
        {
          admin_discount: data.admin_discount ?? 0,
        },
        'orderDetails'
      )
    )
      .then((x) => setLoading(false))
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    reset({ ..._JOrder.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_JOrder.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialog = () => {
    dispatch(setAdminDiscountDialog(0))
  };

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: parseInt(values.value),
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });

  return (
    <Dialog
      open={_JOrder.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="sm"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"تعديل الخصم "}
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          {_JOrder.loading && <LinearProgress sx={{ marginBottom: 1 }} />}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"admin_discount_percent"}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="نسبة الخصم"
                    fullWidth
                    {...field}
                    onChange={(e) => {
                      setValue('admin_discount', (e.target.value / 100) * _JOrder.form.amount)
                      field.onChange(e);
                    }}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"admin_discount"}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="الخصم"
                    fullWidth
                    {...field}
                    onChange={(e) => {
                      setValue('admin_discount_percent', (e.target.value / _JOrder.form.amount) * 100)
                      field.onChange(e);
                    }}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading ? true : false}
                endIcon={loading ? <CircularProgress size={25} /> : null}
              >
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditAdminDiscountForm;
