import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAll(data) {
        let params = "";
        var rep = localStorage.getItem('role') == 'representative' || localStorage.getItem('role') == 'team_leader' ? 'user-notification' : "notification"
        if (data) {
            Object.keys(data).forEach(key => {
                params += `${key}=${data[key]}&`;
            });
        }
        return api.get(`/api/v1/${rep}?${params}`);
    },
    getById(id) {
        return api.get(`/api/v1/notification/${id}`);
    },
    update(id, data) {
        return api.post(`/api/v1/notification/${id}`, data);
    },
    create(data) {
        return api.post(`/api/v1/notification`, data);
    },
    delete(id) {
        return api.delete(`/api/v1/notification/${id}`);
    },
    getNotificationOpen() {
        return api.get(`/api/v1/notification/open`);
    },
}