import { Card, CardHeader, Typography, Button, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import {
  setDialog,
  setExcelDialog,
  setCsvDialog,
  setSelectedItem,
} from "../../../../app/slices/pinsSlice";
import { BiBarcodeReader } from "react-icons/bi";
import { BsTextCenter, BsTextRight, BsUpload } from "react-icons/bs";
import PinsForm from "../../form";
import PinsExcelForm from "../../../../components/ExcelDialog";
import PinsCsvForm from "../../../../components/CsvDialog";

const ItemsHeader = () => {
  const _Pins = useSelector((state) => state.pins);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            الارقام السرية
          </Typography>
        }
        avatar={<BiBarcodeReader size={20} />}
        action={
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoIosAddCircleOutline />}
              onClick={() => {
                dispatch(setDialog());
              }}
            >
              إضافة
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<BsUpload />}
              onClick={() => {
                dispatch(setSelectedItem(null));
                dispatch(setExcelDialog());
              }}
            >
              رفع مستند excel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<BsTextRight />}
              onClick={() => {
                dispatch(setSelectedItem(null))
                dispatch(setCsvDialog())
              }}
            >
              رفع مستند txt, csv
            </Button>
          </Stack>
        }
      />
      {_Pins.pin.dialog ? <PinsForm /> : null}
      {_Pins.pin.excelDialog ? <PinsExcelForm /> : null}
      {_Pins.pin.csvDialog ? <PinsCsvForm /> : null}
    </Card>
  );
};

export default ItemsHeader;
