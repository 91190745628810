import { Grid } from "@mui/material";
import TicketsHeader from "./home/header";
import TicketsTable from "./home/table";
const Tickets = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TicketsHeader />
      </Grid>
      <Grid item xs={12}>
        <TicketsTable />
      </Grid>
    </Grid>
  );
};

export default Tickets;
