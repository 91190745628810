import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const settingsApi = factory.get("settings");
const initialState = {
  searchBody: {
    key: "",
  },
  setting: {
    loading: false,
    dialog: false,
    form: {
      id: null,
      name: "",
      mobile: "",
      note: "",
      province_id: null,
      city_id: null,
      is_register: false,
      longitude: "",
      latitude: "",
    },
  },
  settings: {
    loading: false,
    data: [],
    total: 0,
  },
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setDialog: (state, { payload }) => {
      state.setting.dialog = !state.setting.dialog;
      if (payload) {
        state.setting.form = payload;
      } else {
        state.setting.form = initialState.setting.form;
      }
    },
    setDataTable: (state, { payload }) => {
      state.settings.data = payload.data.data;
      state.settings.total = payload.data.total;
    },
    setById: (state, { payload }) => {
      state.setting.form = payload;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.setting.form = initialState.setting.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
} = settingsSlice.actions;

export default settingsSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("settings"));
    const res = await settingsApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("settings"));
  } catch (err) {
    dispatch(setLoading("settings"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await settingsApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await settingsApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("settings"));
    await settingsApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("settings"));
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(setLoading("settings"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const SettingsActions = {
  getAll,
  deleteById,
  create,
  update,
};
