import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  resetForm,
  User,
  setResetPasswordByAdminDialog,
} from "../../../app/slices/userSlice";
import { Controller, useForm } from "react-hook-form";
import { showNotification } from "../../../app/slices/notificationSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ResetPasswordByAdminForm = () => {
  const _ResetPassword = useSelector((state) => state.user.resetPasswordByAdmin);

  const { control, reset, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: _ResetPassword.form,
  });

  const onSubmit = (data) => {
    if (watch().password !== watch().new_password) {
      dispatch(showNotification({
        message: "كلمة المرور وتاكيدها غير متطابقتان",
        type: "error"
      }));
    } else {
      dispatch(User.resetPasswordByAdmin({
        user_id: _ResetPassword.user_id,
        new_password: data.new_password,
      }));
    }
  };
  useEffect(() => {
    reset({ ..._ResetPassword.form });
  }, [_ResetPassword.form]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(resetForm());
  //   };
  // }, []);

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setResetPasswordByAdminDialog(null));
  };

  return (
    <Dialog
      open={_ResetPassword.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"تغيير كلمة المرور"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="كلمة المرور الجديدة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="new_password"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="تاكيد كلمة المرور"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ResetPasswordByAdminForm;
