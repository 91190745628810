import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  Box,
  InputAdornment,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  Product,
} from "../../../../app/slices/productsSlice";
import { Controller, useForm, useWatch } from "react-hook-form";
import FilePondInput from "../../../../helpers/FilePondInput";
import { SelectSubCategory } from "../../../../components/Selectors/SelectSubCategory";
import { NumericFormat } from "react-number-format";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProductsForm = () => {
  const _Product = useSelector((state) => state.product.product);
  const _SearchBody = useSelector((state) => state.product.searchBody);
  const _SubCategory = useSelector((state) => state.subCategories.subCategoriesList.data);

  const isAdd = _Product.form.id ? false : true;
  const { control, reset, watch, register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: _Product.form,
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    if (!isAdd) formData.append('_method', 'PUT');
    formData.append('name', data.name);
    formData.append('sub_category_id', data.sub_category_id);
    formData.append('description', data.description);
    formData.append('sequence', data.sequence);
    formData.append('is_hidden', data.is_hidden);
    // formData.append('count', data.count);
    formData.append('buying_price', data.buying_price);
    formData.append('revenue', data.revenue);
    formData.append('selling_price', data.selling_price);
    formData.append('daily_payment', data.daily_payment);
    // formData.append('max_selling_per_day', data.max_selling_per_day);
    formData.append('discount_percentage', data.discount_percentage);
    formData.append('max_discount_price', data.max_discount_price);
    if (data.logo && data.logo[0].name) formData.append('logo', data.logo[0]);
    if (isAdd) {
      dispatch(Product.create(formData));
    } else {
      dispatch(Product.update(data.id, formData, _SearchBody));
    }
  };
  useEffect(() => {
    reset({ ..._Product.form });
  }, [_Product.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setDialog());
  };

  useEffect(() => {
    setValue('revenue', watch('buying_price') * watch('revenue_percent') / 100)
  }, [watch('buying_price'), watch('revenue_percent')])

  return (
    <Dialog
      open={_Product.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="sub_category_id"
                control={control}
                render={({ field }) => (
                  <SelectSubCategory
                    isproduct
                    required
                    onChange={(e, newValue) => {
                      setValue("sub_category_id", newValue ? newValue.id : null);
                    }}
                    value={_SubCategory.filter((x) => x.id == field.value)[0] ?
                      _SubCategory.filter((x) => x.id == field.value)[0] :
                      field.value
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الاسم"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الوصف"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='selling_price'
                control={control}
                render={({ field: { onChange, ...renderProps } }) => {
                  return (
                    <NumericFormat
                      customInput={TextField}
                      label='سعر البيع'
                      size="small"
                      required
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                      }}
                      {...renderProps}
                      onValueChange={target => {
                        onChange();
                        setValue('selling_price', target.floatValue);
                      }}
                      isNumericString
                      thousandSeparator
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='buying_price'
                control={control}
                render={({ field: { onChange, ...renderProps } }) => {
                  return (
                    <NumericFormat
                      customInput={TextField}
                      label='سعر الشراء'
                      size="small"
                      required
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                      }}
                      {...renderProps}
                      onValueChange={target => {
                        onChange();
                        setValue('buying_price', target.floatValue);
                      }}
                      isNumericString
                      thousandSeparator
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='revenue_percent'
                control={control}
                render={({ field: { onChange, ...renderProps } }) => {
                  return (
                    <NumericFormat
                      customInput={TextField}
                      label='نسية الشركة'
                      size="small"
                      required
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                      }}
                      {...renderProps}
                      onValueChange={target => {
                        onChange();
                        setValue('revenue_percent', target.floatValue);
                      }}
                      isNumericString
                      thousandSeparator
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='revenue'
                control={control}
                render={({ field: { onChange, ...renderProps } }) => {
                  return (
                    <NumericFormat
                      disabled
                      customInput={TextField}
                      label='الربح'
                      size="small"
                      required
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                      }}
                      {...renderProps}
                      // onValueChange={target => {
                      //   onChange();
                      //   setValue('revenue', target.floatValue);
                      // }}
                      isNumericString
                      thousandSeparator
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='daily_payment'
                control={control}
                render={({ field: { onChange, ...renderProps } }) => {
                  return (
                    <NumericFormat
                      customInput={TextField}
                      label='الدفعات اليومية'
                      size="small"
                      required
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                      }}
                      {...renderProps}
                      onValueChange={target => {
                        onChange();
                        setValue('daily_payment', target.floatValue);
                      }}
                      isNumericString
                      thousandSeparator
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="sequence"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="التسلسل"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='discount_percentage'
                control={control}
                render={({ field: { onChange, ...renderProps } }) => {
                  return (
                    <NumericFormat
                      customInput={TextField}
                      label='نسبة الخصم'
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                      }}
                      {...renderProps}
                      onValueChange={target => {
                        onChange();
                        setValue('discount_percentage', target.floatValue);
                      }}
                      isNumericString
                      thousandSeparator
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='max_discount_price'
                control={control}
                render={({ field: { onChange, ...renderProps } }) => {
                  return (
                    <NumericFormat
                      customInput={TextField}
                      label='الحد الاعلى للخصم'
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                      }}
                      {...renderProps}
                      onValueChange={target => {
                        onChange();
                        setValue('max_discount_price', target.floatValue);
                      }}
                      isNumericString
                      thousandSeparator
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="is_hidden"
                control={control}
                render={({ field }) => (
                  <FormControlLabel control={
                    <Switch
                      {...field}
                      checked={field.value}
                      onChange={(e) => {
                        setValue('is_hidden', e.target.checked)
                      }}
                    />
                  } label="اخفاء المنتج !" />
                )}
              />
            </Grid>
            {!isAdd && (
              <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Controller
                  name="logo"
                  control={control}
                  render={({ field }) => (
                    <Box component='img' src={field.value} height={200} />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <Controller
                name="logo"
                control={control}
                render={({ field }) => (
                  <Box>
                    <FilePondInput field={field} required={isAdd} />
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ProductsForm;
