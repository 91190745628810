import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/order/pos-orders?${params}`);
  },
  getSupplierOrder(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/order?${params}`);
  },
  getById(id) {
    return api.get(`/api/v1/order/${id}`);
  },
  supplierOrder(data) {
    return api.post(`/api/v1/order/supplier`, data);
  },
  pinsOfOrder(id) {
    return api.get(`/api/v1/pins-order?order_id=${id}`);
  },
  checkSellingPrice(data) {
    return api.post(`/api/v1/order/check-selling-price`, data);
  },
  delete(id) {
    return api.delete(`/api/v1/order/${id}`);
  },
  deleteBill(id) {
    return api.delete(`/api/v1/order/supplier/${id}`);
  },
};
