import { forwardRef } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  InputAdornment,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { Supplier, setInitialBalanceDialog } from "../../../../app/slices/supplierSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InitialSupplierBalanceForm = () => {
  const _InitialSupplierBalance = useSelector((state) => state.supplier.initialBalance);
  const _SearchBody = useSelector((state) => state.supplier.searchBody);

  const { control, handleSubmit } = useForm({
    defaultValues: _InitialSupplierBalance.form,
  });


  const onSubmit = (data) => {
    dispatch(Supplier.initialBalance(_InitialSupplierBalance.supplier_id, data, _SearchBody));
  };

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setInitialBalanceDialog(null));
  };

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref,
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });

  return (
    <Dialog
      open={_InitialSupplierBalance.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth='sm'
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"أضافة رصيد للمورد"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="balance"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الرصيد"
                    fullWidth
                    {...field}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default InitialSupplierBalanceForm;
