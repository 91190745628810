import { forwardRef, useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  LinearProgress,
  InputAdornment,
  Divider,
  Box,
  IconButton,
  Stack,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { DataGrid } from "@mui/x-data-grid";
import { BiEdit, BiTrash } from "react-icons/bi";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import {
  PosDiscount,
  resetForm,
  setItemPosDiscountDialog,
} from "../../../../app/slices/posDiscountSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ItemPosDiscountData = (props) => {
  const dispatch = useDispatch();

  const _Pos_id = useSelector((state) => state.posDiscount.pos_id);
  const _PosDiscount = useSelector((state) => state.posDiscount.posDiscount);
  const _PosDiscountData = useSelector(
    (state) => state.posDiscount.posDiscounts
  );
  const loading = useSelector((state) => state.posDiscount.posDiscount.loading);

  const isAdd = _PosDiscount.form.id ? false : true;
  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: _PosDiscount.form,
  });

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });

  const onSubmit = (data) => {
    dispatch(
      PosDiscount.updateItemPosDiscount(
        data.id,
        {
          pos_id: data.pos_id,
          item_id: data.item_id,
          price: parseInt(data.price),
        },
        data.item_id,
        false
      )
    );
  };

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  useEffect(() => {
    reset({ ..._PosDiscount.form });
  }, [reset, _PosDiscount.form]);

  const handleDialog = () => {
    dispatch(setItemPosDiscountDialog());
  };

  // ---------------------- table logic --------------------------- //

  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(
      PosDiscount.deleteItemPosDiscount(
        dialogData.id,
        dialogData.item_id,
        false
      )
    ).then(() => {
      dispatch(resetForm());
      reset({ ..._PosDiscount.form });
    });
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "pos_name",
      headerName: "نقطة البيع",
      flex: 1,
    },
    {
      field: "price",
      headerName: "السعر بعد الخصم (IQD)",
      flex: 1,
      renderCell: (params) => parseInt(params.row.price)?.toLocaleString(),
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="primary"
              onClick={() => dispatch(PosDiscount.getById(params.row.id))}
            >
              <BiEdit />
            </IconButton>
            <IconButton
              color="error"
              onClick={() => handleDeleteClick(params.row)}
            >
              <BiTrash />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <ConfirmationDialog
          open={openConfirm}
          title="تاكيد"
          message="هل انت متاكد من حذف"
          name={dialogData.pos_name}
          confirmText="حذف"
          cancelText="الغاء"
          onConfirm={() => handleConfirmDelete()}
          onCancel={handleCancelDelete}
        />
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          الخصم
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="price"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="المبلغ"
                    fullWidth
                    {...field}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Stack direction="row" justifyContent="start" spacing={2}>
                <Button
                  // disabled={!isDirty}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isAdd}
                >
                  حفظ
                </Button>
                <Button
                  onClick={() => {
                    dispatch(resetForm());
                    reset({ ..._PosDiscount.form });
                  }}
                  variant="contained"
                  color="secondary"
                >
                  تفريغ الحقول
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ height: "60vh", width: "100%" }}>
                <DataGrid
                  rows={
                    _PosDiscountData.data?.map((x, index) => ({
                      ...x,
                      index: index + 1,
                    })) ?? []
                  }
                  columns={columns}
                  rowHeight={50}
                  pagination
                  selectionModel={[]}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ItemPosDiscountData;
