import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
  Divider,
  LinearProgress,
  InputAdornment,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  OrderRechargeCards,
  resetUpdateCreditCardBalanceForm,
  setUpdateCreditCardBalanceDialog,
} from "../../../../app/slices/orderRechargeCardsSlice";
import { Controller, useForm } from "react-hook-form";
import { SelectSupplier } from "../../../../components/Selectors/SelectSupplier";
import { NumericFormat } from "react-number-format";
import { toNumber } from "../../../../helpers/toNumber";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const UpdateCreditCardBalanceForm = () => {
  const dispatch = useDispatch();

  const _UpdateCreditCardBalance = useSelector(
    (state) => state.orderRechargeCards.updateCreditCardBalance
  );
  const _Supplier = useSelector((state) => state.supplier.suppliersList.data);

  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _UpdateCreditCardBalance.form,
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(OrderRechargeCards.updateCreditCardBalance(data))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    reset({ ..._UpdateCreditCardBalance.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_UpdateCreditCardBalance.form]);

  useEffect(() => {
    return () => {
      dispatch(resetUpdateCreditCardBalanceForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialog = () => {
    dispatch(setUpdateCreditCardBalanceDialog());
  };

  return (
    <Dialog
      open={_UpdateCreditCardBalance.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="sm"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          اضافة الرصيد
        </Typography>
        <Divider sx={{ marginBottom: 1 }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          {_UpdateCreditCardBalance.loading && (
            <LinearProgress sx={{ marginBottom: 1 }} />
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="supplier_id"
                control={control}
                render={({ field }) => (
                  <SelectSupplier
                    required
                    onChange={(e, newValue) => {
                      setValue("supplier_id", newValue ? newValue.id : null);
                    }}
                    value={
                      _Supplier.filter((x) => x.id === field.value)[0] ?? null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"amount"}
                render={({ field }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    value={field.value}
                    onChange={(e) => {
                      setValue("amount", toNumber(e.target.value));
                    }}
                    valueIsNumericString={true}
                    allowNegative={true}
                    fullWidth
                    label="المبلغ"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"note"}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ملاحظات"
                    multiline
                    rows={4}
                    fullWidth
                    size="small"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading ? true : false}
                endIcon={loading ? <CircularProgress size={25} /> : null}
              >
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateCreditCardBalanceForm;
