import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  InputAdornment,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  OperationTransfer,
} from "../../../../app/slices/operationTransferSlice";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { SelectSupplier } from "../../../../components/Selectors/SelectSupplier";
import { SelectSimCard } from "../../../../components/Selectors/SelectSimCard";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OperationTransferForm = () => {
  const dispatch = useDispatch();

  const _OperationTransfer = useSelector(
    (state) => state.operationTransfer.operationTransfer
  );
  const _SearchBody = useSelector(
    (state) => state.operationTransfer.searchBody
  );
  const _Supplier = useSelector((state) => state.supplier.suppliersList);
  const _SimCard = useSelector((state) => state.simCard.simCards);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: _OperationTransfer.form,
  });

  const onSubmit = (data) => {
    dispatch(
      OperationTransfer.create(
        {
          balance: data.balance,
          buying_price: data.buying_price,
          cash_paid: data.cash_paid,
          supplier_id: data.supplier_id,
          sim_card_id: data.sim_card_id,
          note: data.note,
        },
        _SearchBody
      )
    );
  };

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  const handleDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_OperationTransfer.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"أضافة "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                name="supplier_id"
                control={control}
                render={({ field }) => (
                  <SelectSupplier
                    required
                    value={
                      _Supplier.data.filter((x) => x.id === field.value)[0] ??
                      null
                    }
                    onChange={(e, newValue) => {
                      setValue("supplier_id", newValue ? newValue.id : null);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="sim_card_id"
                control={control}
                render={({ field }) => (
                  <SelectSimCard
                    onChange={(e, newValue) => {
                      setValue("sim_card_id", newValue ? newValue.id : null);
                    }}
                    value={
                      _SimCard.data?.filter((x) => x.id === field.value)[0] ??
                      null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="balance"
                control={control}
                render={({ field: { onChange, ...renderProps } }) => {
                  return (
                    <NumericFormat
                      customInput={TextField}
                      label="الرصيد"
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">IQD</InputAdornment>
                        ),
                      }}
                      {...renderProps}
                      onValueChange={(target) => {
                        onChange();
                        setValue("balance", target.floatValue);
                      }}
                      isNumericString
                      thousandSeparator
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="buying_price"
                control={control}
                render={({ field: { onChange, ...renderProps } }) => {
                  return (
                    <NumericFormat
                      customInput={TextField}
                      label="مبلغ الشراء"
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">IQD</InputAdornment>
                        ),
                      }}
                      {...renderProps}
                      onValueChange={(target) => {
                        onChange();
                        setValue("buying_price", target.floatValue);
                      }}
                      isNumericString
                      thousandSeparator
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="cash_paid"
                control={control}
                render={({ field: { onChange, ...renderProps } }) => {
                  return (
                    <NumericFormat
                      customInput={TextField}
                      label="المبلغ المدفوع"
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">IQD</InputAdornment>
                        ),
                      }}
                      {...renderProps}
                      onValueChange={(target) => {
                        onChange();
                        setValue("cash_paid", target.floatValue);
                      }}
                      isNumericString
                      thousandSeparator
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="الملاحظة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default OperationTransferForm;
