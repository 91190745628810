import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  JSafe,
  setDialog,
  setPageDialog,
  setSearchBody,
} from "../../../../../app/slices/jomla/safeSlice";
import { BiEdit } from "react-icons/bi";
import { GiMoneyStack } from "react-icons/gi";

const JSafeTable = () => {
  const dispatch = useDispatch();

  const _JSafe = useSelector((state) => state.jSafe.jSafes);
  const _SearchBody = useSelector((state) => state.jSafe.searchBody);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  // function CustomFooter() {
  //   return (
  //     <Stack
  //       direction="row"
  //       sx={{ justifyContent: "space-between", alignItems: "center" }}
  //     >
  //       <Typography color="primary" sx={{ marginX: 2 }}>
  //         القيمة الكلية : {_JSafe.total_amount.toLocaleString()} (IQD)
  //       </Typography>
  //       <GridPagination />
  //     </Stack>
  //   );
  // }

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "id",
      headerName: "رقم الخزنة",
      flex: 1,
    },
    {
      field: "name",
      headerName: "اسم الخزنة",
      flex: 1,
    },
    {
      field: "cash",
      headerName: "الكاش (IQD)",
      flex: 1,
      renderCell: (params) => params.row.cash?.toLocaleString(),
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 0.5,
      renderCell: (params) => (
        <>
          <Tooltip title="المناقلات">
            <IconButton onClick={() => dispatch(setPageDialog(params.row.id))}>
              <GiMoneyStack />
            </IconButton>
          </Tooltip>
          <IconButton onClick={() => dispatch(setDialog(params.row))}>
            <BiEdit />
          </IconButton>
        </>
      ),
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(JSafe.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize]);

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12}>
            <Box sx={{ height: "75vh", width: "100%" }}>
              {_JSafe.data ? (
                <DataGrid
                  rows={_JSafe.data.map((x, i) => {
                    return {
                      ...x,
                      index: i + 1 + pageSize * currentPage,
                    };
                  })}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_JSafe.total}
                  loading={_JSafe.loading}
                  rowHeight={60}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                    // Footer: CustomFooter,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default JSafeTable;
