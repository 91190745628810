import {
  LinearProgress,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Orders, setOrderPinsDialog } from "../../../../app/slices/orderSlice";

const OrderPinsTable = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.orders.order.loading);
  const _pinsOrder = useSelector((state) => state.orders.pinsOrder);

  const pinsColumns = [
    {
      field: "sequence",
      headerName: "#",
      flex: 0.4,
    },
    {
      field: "serial_number",
      headerName: "الرقم التسلسلي",
      flex: 1,
    },
    {
      field: "pin_number",
      headerName: "الرقم السري",
      flex: 1,
    },
    {
      field: "expiry_date",
      headerName: "تاريخ النفاذ",
      flex: 1,
    },
  ];

  useEffect(() => {
    if (_pinsOrder.dialog) {
      dispatch(Orders.getPinsOrder(_pinsOrder.order_id));
    }
  }, [_pinsOrder.order_id]);

  const customToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: " تفاصيل الفاتورة | نظام دجلة",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );

  return (
    <Box>
      <Dialog
        open={_pinsOrder.dialog}
        keepMounted
        fullWidth={true}
        dir="rtl"
        onClose={() => {
          dispatch(setOrderPinsDialog(null));
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Typography
            mb={3}
            variant="h4"
            color={"purple"}
            gutterBottom
            component="div"
          >
            تفاصيل الفاتورة
          </Typography>
          <Box>
            {_pinsOrder.data ? (
              <DataGrid
                rows={_pinsOrder.data?.map((data, index) => {
                  return { ...data, sequence: index + 1 };
                })}
                columns={pinsColumns}
                autoHeight
                rowCount={_pinsOrder.total}
                loading={loading}
                rowHeight={40}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: customToolbar,
                }}
              />
            ) : (
              <LinearProgress />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default OrderPinsTable;
