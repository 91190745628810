import { forwardRef } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Divider,
  Stack,
  DialogTitle,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { setOrderStatusHistoryDialog } from "../../../../app/slices/jomla/orderSlice";
import dayjs from "dayjs";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { MdCheckCircle } from "react-icons/md";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderStatusHistoryDialog = () => {
  const dispatch = useDispatch();

  const _JOrderStatus = useSelector((state) => state.jOrder.orderStatusHistory);

  const handleDialog = () => {
    dispatch(setOrderStatusHistoryDialog({}));
  };

  return (
    <Dialog
      open={_JOrderStatus.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="sm"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Typography variant="h4" color={"purple"} gutterBottom component="div">
          حالات الطلب
        </Typography>
        <Typography
          variant="body"
          color={"purple"}
          gutterBottom
          component="div"
        >
          خط زمني يوضح فيه تسلسل تغيير حالات الطلب
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Divider sx={{ marginBottom: 2 }} />
        <Timeline>
          {_JOrderStatus?.data?.map((item, index) => {
            return (
              <TimelineItem key={index}>
                <TimelineOppositeContent
                  sx={{ m: "auto 0" }}
                  align="right"
                  variant="body1"
                  color="text.secondary"
                >
                  <Stack>
                    <Typography variant="caption">
                      {dayjs(item?.created_at).format("YYYY-MM-DD ")}
                    </Typography>
                    <Typography variant="caption">
                      {dayjs(item?.created_at).format("hh:mm A")}
                    </Typography>
                  </Stack>
                  <Typography variant="caption">{item?.created_by}</Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot
                    sx={{
                      backgroundColor: "success.main",
                    }}
                  >
                    {/* <IconComponent
                          icon={item.status_types.icon}
                          color="white"
                          size={20}
                        /> */}
                    <MdCheckCircle />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent
                  sx={{
                    py: "12px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    fontWeight={"bold"}
                    component="span"

                  // color={colors[item.status_types.class]}
                  >
                    {item?.j_order_status_name}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </DialogContent>
    </Dialog>
  );
};

export default OrderStatusHistoryDialog;
