import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/order-recharge?${params}`);
  },
  delete(id) {
    return api.delete(`/api/v1/order-recharge/${id}`);
  },
  export(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/order-recharge?${params}`, {
      responseType: "blob",
    });
  },
  update(data) {
    return api.put(`/api/v1/order-recharge/update-number`, data);
  },
  postExport(data) {
    return api.post(`/api/v1/order-recharge/export`, data, {
      responseType: "blob",
    });
  },

  // =============== credit-card ===================

  getCreditCards(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/credit-card?${params}`);
  },

  updateCreditCardBalance(data) {
    return api.put(`/api/v1/credit-card/update-balance`, data);
  },

  getAllCreditCardTransactions(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (
          (data[key] !== "") & (data[key] !== null) &&
          data[key] !== undefined
        ) {
          params += `${key}=${data[key]}&`;
        }
      });
    }
    return api.get(`/api/v1/credit-card-transaction?${params}`);
  },
};
