import { Grid } from "@mui/material";
import OrderRechargeCardsHeader from "./home/header";
import OrderRechargeCardsTable from "./home/table";

const OrderRechargeCards = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <OrderRechargeCardsHeader />
      </Grid>
      <Grid item xs={12}>
        <OrderRechargeCardsTable />
      </Grid>
    </Grid>
  );
};

export default OrderRechargeCards;
