import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/sim-card?${params}`);
  },
  getById(id) {
    return api.get(`/api/v1/sim-card/${id}`);
  },
  update(id, data) {
    return api.put(`/api/v1/sim-card/${id}`, data);
  },
  changeStatus(id) {
    return api.put(`/api/v1/sim-card/change-status/${id}`);
  },
  create(data) {
    return api.post(`/api/v1/sim-card`, data);
  },
  delete(id) {
    return api.delete(`/api/v1/sim-card/${id}`);
  },
  getSimCardTransactions(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/sim-card-transaction?${params}`);
  },
  sendSms(data) {
    return api.post(`/api/v1/transfer/send-sms`, data);
  },
};
