import { Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { FaSms } from "react-icons/fa";

const SmsHeader = () => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            الرسائل المستلمة
          </Typography>
        }
        avatar={<FaSms size={20} />}
      />
    </Card>
  );
};

export default SmsHeader;
