import Typography from "@mui/material/Typography";
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  Slide,
  InputAdornment,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import {
  Wallet,
  setClearanceDialog,
} from "../../../../../app/slices/walletSlice";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { NumericFormat } from "react-number-format";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ClearanceForm = () => {
  const { user_id } = useParams();
  const dispatch = useDispatch();

  const [reload, setReload] = useState(false);

  const _Wallet = useSelector((state) => state.wallet);
  const transactionsSearchBody = useSelector(
    (state) => state.wallet.transactionsSearchBody
  );
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: { amount: 0, is_topup: true },
  });

  const onSubmit = (data) => {
    data.from_user_id = user_id;
    dispatch(Wallet.receiveCashRep(data, transactionsSearchBody)).then((x) => {
      setReload(!reload);
    });
    // if (user.is_representative) {
    // } else {
    //   dispatch(Wallet.receiveCashPos(data)).then((x) => {
    //     dispatch(setRecivedCashDialog());
    //   });
    // }
  };

  useEffect(() => {
    // dispatch(Wallet.getRepCash({ rep_id: user_id }))
  }, [reload]);

  const handlDialog = () => {
    dispatch(setClearanceDialog());
  };

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });
  return (
    <Dialog
      open={_Wallet.clearanceDialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      maxWidth={"md"}
      dir="rtl"
      onClose={handlDialog}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"حسابات المندوب"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={8} md={8}>
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="المبلغ"
                    fullWidth
                    {...field}
                    sx={{ marginBottom: 1 }}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Controller
                name="is_topup"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          setValue("is_topup", e.target.checked);
                        }}
                      />
                    }
                    label="اضافة رصيد للمندوب؟"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="ملاحظات"
                    fullWidth
                    multiline
                    rows={4}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                تسديد
              </Button>
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <div>
                {
                  _Wallet.repCash.data.map((item, index) => (
                    <Accordion
                      // expanded={expanded === index}
                      expanded={true}
                      onChange={handleChange(index)}>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography sx={item.debt ? { width: '33%', flexShrink: 0, textDecoration: "line-through" } : { width: '33%', flexShrink: 0 }}>
                          {`المبلغ: ${item.sum?.toLocaleString()} IQD`}
                        </Typography>
                        {item.debt && <Typography >{`المتبقي: ${item.debt?.toLocaleString()} IQD`}</Typography>}
                      </AccordionSummary>
                      <AccordionDetails>
                        <DataGrid
                          key={index + "0"}
                          rows={item?.transactions}
                          columns={columns}
                          // rowCount={item.data?.length}
                          rowHeight={50}
                          autoHeight
                          rowsPerPageOptions={[200]}
                          components={{
                            LoadingOverlay: LinearProgress,
                            Toolbar: customToolbar
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ))
                }
              </div>
            </Grid> */}
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ClearanceForm;
