import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
  Divider,
  LinearProgress,
  InputAdornment,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  JOrder,
  resetForm,
  setOrderItemDiscountDialog,
} from "../../../../app/slices/jomla/orderSlice";
import { NumericFormat } from "react-number-format";
import { toNumber } from "../../../../helpers/toNumber";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditOrderItemDiscountForm = () => {
  const dispatch = useDispatch();

  const _JOrder = useSelector((state) => state.jOrder.orderItemDiscount);

  const [loading, setLoading] = useState(false);

  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _JOrder.form,
  });

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(
      JOrder.editOrderItemDiscount(
        _JOrder.form.id,
        {
          discount: data.total_discount ?? 0,
        },
        _JOrder.form.orderId,
        "orderDetails"
      )
    )
      .then((x) => setLoading(false))
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    reset({ ..._JOrder.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_JOrder.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialog = () => {
    dispatch(setOrderItemDiscountDialog({}));
  };

  return (
    <Dialog
      open={_JOrder.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="sm"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography variant="h4" color={"purple"} gutterBottom component="div">
          {"تعديل الخصم "}
        </Typography>
        <Typography mb={3} variant="subtitle" gutterBottom component="div">
          {"ملاحظة: قيمة الخصم المدخلة هي قيمة الخصم للقطعة الواحدة"}
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          {_JOrder.loading && <LinearProgress sx={{ marginBottom: 1 }} />}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"total_discount_percent"}
                render={({ field }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    value={field.value}
                    onChange={(e) => {
                      setValue(
                        "total_discount",
                        (toNumber(e.target.value) / 100) * _JOrder.form.price
                      );
                      setValue(
                        "total_discount_percent",
                        toNumber(e.target.value)
                      );
                    }}
                    valueIsNumericString={true}
                    allowNegative={false}
                    fullWidth
                    label="الخصم"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"total_discount"}
                render={({ field }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    value={field.value}
                    onChange={(e) => {
                      setValue(
                        "total_discount_percent",
                        (toNumber(e.target.value) / _JOrder.form.price) * 100
                      );
                      setValue("total_discount", toNumber(e.target.value));
                    }}
                    valueIsNumericString={true}
                    allowNegative={false}
                    fullWidth
                    label="الخصم"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading ? true : false}
                endIcon={loading ? <CircularProgress size={25} /> : null}
              >
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditOrderItemDiscountForm;
