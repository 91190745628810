import { Card, CardHeader, Typography, Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../../app/slices/investorsSlice";
import InvestorsForm from "../../form";
import { GiTakeMyMoney } from "react-icons/gi";
import InvestorsTransactions from "../../form/transactions";

const InvestorsHeader = () => {
  const _Investors = useSelector((state) => state.investors);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            الممولين
          </Typography>
        }
        avatar={<GiTakeMyMoney size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            إضافة ممول جديد
          </Button>
        }
      />
      {_Investors.investor.dialog ? <InvestorsForm /> : null}
      {_Investors.investorTransactions.dialog ? (
        <InvestorsTransactions />
      ) : null}
    </Card>
  );
};

export default InvestorsHeader;
