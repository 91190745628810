import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  LinearProgress,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setSendSmsDialog,
  resetForm,
  SimCard,
} from "../../../../app/slices/simCardSlice";
import { Controller, useForm } from "react-hook-form";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SendSmsForm = () => {
  const _SimCard = useSelector((state) => state.simCard.simCard);
  const _port = useSelector((state) => state.simCard.simCard.simPort);
  const loading = useSelector((state) => state.simCard.loading);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      message: "",
      send_to: "",
      port: _SimCard.form.port,
    },
  });
  const onSubmit = (data) => {
    data.port = _port;
    dispatch(SimCard.sendSms(data));
  };
  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setSendSmsDialog());
  };
  return (
    <Dialog
      open={_SimCard.sendSmsDialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          اضافة رسالة
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          {loading && <LinearProgress sx={{ marginBottom: 1 }} />}
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="send_to"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    value={field.value}
                    size="small"
                    label="المرسل اليه"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="message"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    value={field.value}
                    multiline
                    rows={4}
                    size="small"
                    label="الرسالة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                disabled={!isDirty}
                type="submit"
                variant="contained"
                color="primary"
              >
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SendSmsForm;
