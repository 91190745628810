import Typography from "@mui/material/Typography";
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  Slide,
  InputAdornment,
} from "@mui/material";
import { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import {
  ProductsOrder,
  setDialog,
} from "../../../../app/slices/productsOrdersSlice";
import { SelectProductsSupplier } from "../../../../components/Selectors/SelectProductsSupplier";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProductsOrdersForm = (props) => {
  const dispatch = useDispatch();
  const _ProductsOrder = useSelector(
    (state) => state.productsOrders.productsOrder
  );
  const _ProductsOrders = useSelector(
    (state) => state.productsOrders.productsOrders.data
  );
  const _SearchBody = useSelector((state) => state.productsOrders.searchBody);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {},
  });

  const onSubmit = (data) => {
    data.status = props.isSuspend ? "suspend" : "daily_payments";
    data.order_id = _ProductsOrder.form.order_id;
    dispatch(ProductsOrder.changeStatus(data, _SearchBody)).then((x) => {
      dispatch(setDialog(null));
    });
  };

  const handleDialog = () => {
    dispatch(setDialog(null));
  };

  // const status = [
  //   { key: 'waiting', name: 'قيد الانتظار' },
  //   { key: 'reject', name: 'رفض' },
  //   { key: 'daily_payments', name: 'اقساط يومية' },
  // ]

  return (
    <Dialog
      open={_ProductsOrder.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={handleDialog}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"الحالة"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="supplier_id"
                control={control}
                render={({ field }) => (
                  <SelectProductsSupplier
                    onChange={(e, newValue) => {
                      setValue("supplier_id", newValue ? newValue.id : null);
                    }}
                    value={
                      _ProductsOrders?.filter((x) => x.id == field.value)[0]
                        ? _ProductsOrders?.filter((x) => x.id == field.value)[0]
                        : field.value
                    }
                  />
                )}
              />
            </Grid>
            {!props.isSuspend && (
              <Grid item xs={12} md={12}>
                <Controller
                  name="total_amount"
                  control={control}
                  render={({ field: { onChange, ...renderProps } }) => {
                    return (
                      <NumericFormat
                        customInput={TextField}
                        label="المبلغ الكلي - بدون نسبة"
                        placeholder={_ProductsOrder.form.total_amount?.toLocaleString()}
                        size="small"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              IQD
                            </InputAdornment>
                          ),
                        }}
                        {...renderProps}
                        onValueChange={(target) => {
                          onChange();
                          setValue("total_amount", target.floatValue);
                        }}
                        isNumericString
                        thousandSeparator
                      />
                    );
                  }}
                />
              </Grid>
            )}
            {!props.isSuspend && (
              <Grid item xs={12} md={12}>
                <Controller
                  name="revenue"
                  control={control}
                  render={({ field: { onChange, ...renderProps } }) => {
                    return (
                      <NumericFormat
                        customInput={TextField}
                        label="نسبة الشركة"
                        placeholder={_ProductsOrder.form.revenue?.toLocaleString()}
                        size="small"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              IQD
                            </InputAdornment>
                          ),
                        }}
                        {...renderProps}
                        onValueChange={(target) => {
                          onChange();
                          setValue("revenue", target.floatValue);
                        }}
                        isNumericString
                        thousandSeparator
                      />
                    );
                  }}
                />
              </Grid>
            )}
            {!props.isSuspend && (
              <Grid item xs={12} md={6}>
                <Controller
                  name="first_payment"
                  control={control}
                  render={({ field: { onChange, ...renderProps } }) => {
                    return (
                      <NumericFormat
                        customInput={TextField}
                        label="الدفعة الاولى"
                        size="small"
                        fullWidth
                        placeholder={_ProductsOrder.form.daily_payment?.toLocaleString()}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              IQD
                            </InputAdornment>
                          ),
                        }}
                        {...renderProps}
                        onValueChange={(target) => {
                          onChange();
                          setValue("first_payment", target.floatValue);
                        }}
                        isNumericString
                        thousandSeparator
                      />
                    );
                  }}
                />
              </Grid>
            )}
            {!props.isSuspend && (
              <Grid item xs={12} md={6}>
                <Controller
                  name="daily_payment"
                  control={control}
                  render={({ field: { onChange, ...renderProps } }) => {
                    return (
                      <NumericFormat
                        customInput={TextField}
                        label="القسط اليومي"
                        size="small"
                        fullWidth
                        placeholder={_ProductsOrder.form.daily_payment?.toLocaleString()}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              IQD
                            </InputAdornment>
                          ),
                        }}
                        {...renderProps}
                        onValueChange={(target) => {
                          onChange();
                          setValue("daily_payment", target.floatValue);
                        }}
                        isNumericString
                        thousandSeparator
                      />
                    );
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ProductsOrdersForm;
