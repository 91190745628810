import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Divider,
  InputAdornment,
  Box,
  Switch,
  FormControlLabel,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setExcelDialog,
  resetExcelForm,
  Pins,
} from "../../app/slices/pinsSlice";
import { Controller, useForm } from "react-hook-form";
import { SelectItem } from "../Selectors/SelectItems";
import FilePondInput from "../../helpers/FilePondInput";
import { SelectSupplier } from "../Selectors/SelectSupplier";
import { NumericFormat } from "react-number-format";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PinsExcelForm = () => {
  const _Pins = useSelector((state) => state.pins.pin);

  const { control, reset, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: _Pins.excelForm,
  });

  const [excelFormData, setExcelFormData] = useState(false);
  const [disableButton, setDisabelButton] = useState(false);

  const onSubmit = (data) => {
    setDisabelButton(true);
    const formData = new FormData();
    formData.append("upload_file", data.upload_file[0]);
    formData.append("buying_price", data.buying_price);
    formData.append("cash_paid", data.cash_paid ?? "");
    formData.append("is_initial", data.is_initial ?? "");
    formData.append("item_id", _Pins.selectedItem?.id ?? data.item_id.id);
    formData.append("supplier_id", data.supplier_id.id);
    formData.append("note", data.note);
    formData.append("is_private", data.is_private);
    setExcelFormData(formData);
    dispatch(Pins.importExcelFile(formData))
      .then(() => setDisabelButton(false))
      .catch(() => setDisabelButton(false));
  };

  useEffect(() => {
    reset({ ..._Pins.excelForm });
  }, [_Pins.excelForm]);

  useEffect(() => {
    return () => {
      dispatch(resetExcelForm());
    };
  }, []);

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setExcelDialog());
  };

  return (
    <Box>
      <Dialog
        open={_Pins.excelDialog}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        dir="rtl"
        maxWidth="sm"
        onClose={() => {
          handleDialog();
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Typography
            mb={3}
            variant="h4"
            color={"purple"}
            gutterBottom
            component="div"
          >
            {" رفع مستند excel"}
          </Typography>
          <Divider />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                {_Pins.selectedItem ? (
                  <Typography variant="h4">المنتج: {_Pins.selectedItem.name}</Typography>
                ) : (
                  <Controller
                    name="item_id"
                    control={control}
                    render={({ field }) => (
                      <SelectItem
                        required
                        value={field.value}
                        onChange={(e, newValue) => {
                          setValue("item_id", newValue);
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name="supplier_id"
                  control={control}
                  render={({ field }) => (
                    <SelectSupplier
                      required
                      value={field.value}
                      onChange={(e, newValue) => {
                        setValue("supplier_id", newValue);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name="buying_price"
                  control={control}
                  render={({ field: { onChange, ...renderProps } }) => {
                    return (
                      <NumericFormat
                        customInput={TextField}
                        label="سعر الشراء للكارت"
                        size="small"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              IQD
                            </InputAdornment>
                          ),
                        }}
                        {...renderProps}
                        onValueChange={(target) => {
                          onChange();
                          setValue("buying_price", target.floatValue);
                        }}
                        isNumericString
                        thousandSeparator
                        error={
                          watch("buying_price") >
                          watch("item_id")?.selling_price
                        }
                        helperText={
                          watch("buying_price") >
                          watch("item_id")?.selling_price
                            ? `سعر البيع (${
                                watch("item_id")?.selling_price
                              } IQD) يجب ان يكون اعلى من سعر الشراء`
                            : null
                        }
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name="note"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      multiline
                      size="small"
                      label="ملاحظات"
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name="is_private"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          {...field}
                          checked={field.value}
                          onChange={(e) =>
                            setValue("is_private", e.target.checked)
                          }
                        />
                      }
                      label={"اخفاء عن اسياسيل"}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name="upload_file"
                  control={control}
                  render={({ field }) => (
                    <Box>
                      <FilePondInput field={field} required={true} />
                    </Box>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={disableButton}
                >
                  حفظ
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default PinsExcelForm;
