import { Card, CardHeader, Typography, Button, Stack } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { BsFolder2Open } from "react-icons/bs";
import JCategoriesForm from "../../from";
import { setDialog } from "../../../../../app/slices/jomla/categorySlice";
import { setPageDialog } from "../../../../../app/slices/jomla/subcategorySlice";
import { MdCategory } from "react-icons/md";
import JSubCategories from "../../subCategory";

const JCategoriesHeader = () => {
  const dispatch = useDispatch();

  const _JCategory = useSelector((state) => state.JCategories);
  const jSubCategories = useSelector((state) => state.JSubCategories);

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            الاقسام الرئيسية
          </Typography>
        }
        avatar={<BsFolder2Open size={20} />}
        action={
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoIosAddCircleOutline />}
              onClick={() => dispatch(setDialog())}
            >
              إضافة قسم رئيسي
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<MdCategory />}
              onClick={() => dispatch(setPageDialog())}
            >
              الاقاسم الفرعية
            </Button>
          </Stack>
        }
      />
      {_JCategory.JCategory.dialog ? <JCategoriesForm /> : null}
      {jSubCategories.dialog ? <JSubCategories /> : null}
    </Card>
  );
};

export default JCategoriesHeader;
