import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAll(data) {
        // var team_leader = localStorage.getItem('role') =='team_leader' ? '/team':''
        var team_leader = ''
        let params = "";
        if (data) {
            Object.keys(data).forEach(key => {
                params += `${key}=${data[key]}&`;
            });
        }
        return api.get(`/api/v1/request-points${team_leader}?${params}`);
    },
    approve(id, data) {
        return api.post(`/api/v1/request-points/approve/${id}`, data);
    },
    delete(id) {
        return api.delete(`/api/v1/request-points/${id}`);
    },
}