import React, { forwardRef, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  LinearProgress,
  Slide,
  Grid,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { DataGrid } from "@mui/x-data-grid";
import {
  SimCard,
  setTransactionsDialog,
  setTransactionsSearchBody,
} from "../../../../../app/slices/simCardSlice";
import DateRangepicker from "../../../../../helpers/dateRangePicker";
import { MdOutlineClear } from "react-icons/md";
import { SelectTransactionTypes } from "../../../../../components/Selectors/SelectTransactionTypes";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SimCardTransactions = () => {
  const dispatch = useDispatch();

  const _TransactionData = useSelector(
    (state) => state.simCard.simCardTransactions
  );
  const _SearchBody = useSelector(
    (state) => state.simCard.transactionsSearchBody
  );
  const _TransactionTypes = useSelector(
    (state) => state.wallet.transactionType
  );
  const loading = useSelector((state) => state.simCard.simCard.loading);

  const initialSearch = {
    type: "",
    startDate: dayjs().format("2023-01-01"),
    endDate: dayjs().format("YYYY-MM-DD"),
  };
  const [search, setSearch] = useState(initialSearch);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "id",
      headerName: "رقم العملية",
      flex: 0.8,
    },
    {
      field: "sim_card_name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "sim_card_phone",
      headerName: "الرقم",
      flex: 1,
    },
    {
      field: "type",
      headerName: "النوع",
      flex: 1,
      renderCell: (params) => params.row.type_ar,
      // renderCell: (params) => `${params.row.type_ar} - ${params.row.type}`,
    },
    {
      field: "pos_name",
      headerName: "نقطة البيع",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "المبلغ",
      flex: 0.6,
      renderCell: (params) => params.row.amount.toLocaleString(),
    },
    {
      field: "current_balance",
      headerName: "الرصيد الحالي",
      flex: 0.6,
      renderCell: (params) => params.row.current_balance.toLocaleString(),
    },
    {
      field: "created_at",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) =>
        dayjs(params.row.created_at).format("YYYY-MM-DD / hh:mma"),
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(SimCard.getAllTransactions(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setTransactionsSearchBody({
        type: search.type,
        sim_card_id: _TransactionData.simCardId,
        start_date: search.startDate,
        end_date: search.endDate,
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [_TransactionData.simCardId, currentPage, dispatch, pageSize, search]);

  return (
    <Dialog
      open={_TransactionData.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      onClose={() => {
        dispatch(setTransactionsDialog());
      }}
      maxWidth="xl"
      aria-describedby="alert-dialog-slide-description"
      dir="rtl"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"المناقلات للسيم كارت"}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <SelectTransactionTypes
              onChange={(event, value) => {
                setSearch({
                  ...search,
                  type: value ? value.key : null,
                });
              }}
              value={
                _TransactionTypes?.filter((x) => x.key == search.type)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={3}>
            <DateRangepicker
              startDate={search.startDate}
              endDate={search.endDate}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    startDate: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    endDate: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch(initialSearch);
                  setPageSize(15);
                  setCurrentPage(0);
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={_TransactionData.data?.map((x, index) => ({
                  ...x,
                  index: index + 1,
                }))}
                columns={columns}
                pageSize={pageSize}
                rowCount={_TransactionData.total}
                loading={loading}
                rowHeight={50}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) => setCurrentPage(newPage)}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPageSize(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SimCardTransactions;
