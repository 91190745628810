import {
  Grid,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  JSafe,
  setSafeTransferSearchBody,
} from "../../../../../../app/slices/jomla/safeSlice";
import dayjs from "dayjs";
import { SelectJSafe } from "../../../../../../components/Selectors/jomla/SelectJSafe";
import DateRangepicker from "../../../../../../helpers/dateRangePicker";
import { MdOutlineClear } from "react-icons/md";

const JSafeTransferTable = () => {
  const dispatch = useDispatch();

  const JSafeTransfer = useSelector((state) => state.jSafe.jSafeTransfer);
  const _SearchBody = useSelector(
    (state) => state.jSafe.jSafeTransfer.searchBody
  );
  const jSafe = useSelector((state) => state.jSafe.jSafes.data);

  const [search, setSearch] = useState(_SearchBody);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  const columns = [
    {
      field: "id",
      headerName: "رقم المعاملة",
      flex: 1,
    },
    {
      field: "created_by_name",
      headerName: "بواسطة",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "المبلغ",
      flex: 1,
      renderCell: (params) => params.row.amount?.toLocaleString(),
    },
    {
      field: "safe_id",
      headerName: "الخزنة",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body">
            من: {params.row.from_safe_name}
          </Typography>
          <Typography variant="body">الى: {params.row.safe_name}</Typography>
        </Stack>
      ),
    },
    {
      field: "old_main_cash",
      headerName: "الكاش الرئيسي",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body" color="error">
            السابق: {params.row.old_main_cash?.toLocaleString()}
          </Typography>
          <Typography variant="body" color="green">
            الجديد: {params.row.new_main_cash?.toLocaleString()}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "old_safe_cash",
      headerName: "كاش الخزنة",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body" color="error">
            السابق: {params.row.old_safe_cash?.toLocaleString()}
          </Typography>
          <Typography variant="body" color="green">
            الجديد: {params.row.new_safe_cash?.toLocaleString()}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "type",
      headerName: "نوع العملية",
      flex: 1,
    },
    {
      field: "source",
      headerName: "المصدر",
      flex: 1,
      renderCell: (params) => (
        <Typography
          style={{
            wordBreak: "break-all",
          }}
        >
          {params.row.source}
        </Typography>
      ),
    },
    {
      field: "created_at",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body">
            {dayjs(params.row.created_at).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="body">
            {dayjs(params.row.created_at).format("hh:mm a")}
          </Typography>
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(JSafe.getAllSafeTransfer(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSafeTransferSearchBody({
        start_date: search.start_date,
        end_date: search.end_date,
        safe_id: search.safe_id,
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize, search]);

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} md={4}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={2.5}>
            <SelectJSafe
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  safe_id: newValue ? newValue.id : null,
                });
              }}
              value={jSafe?.filter((x) => x.id === search.safe_id)[0] ?? null}
            />
          </Grid>
          <Grid item xs={2.5}>
            <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      start_date: null,
                      end_date: null,
                      safe_id: null,
                    });
                    setPageSize(15);
                    setCurrentPage(0);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {JSafeTransfer.data ? (
                <DataGrid
                  rows={JSafeTransfer.data}
                  columns={columns}
                  pageSize={pageSize}
                  rowCount={JSafeTransfer.total}
                  loading={JSafeTransfer.loading}
                  rowHeight={100}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default JSafeTransferTable;
