import { Box, Grid } from "@mui/material";
import React from "react";
import JOrderTable from "./home/table";
import JOrderHeader from "./home/header";

const JOrders = () => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <JOrderHeader />
        </Grid>
        <Grid item xs={12}>
          <JOrderTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default JOrders;
