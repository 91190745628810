import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAll(data) {
        let params = "";
        if (data) {
            Object.keys(data).forEach(key => {
                params += `${key}=${data[key]}&`;
            });
        }
        return api.get(`/api/v1/role?${params}`);
    },
}