import { Grid } from "@mui/material";
import React from "react";
import OperationHeader from "./home/header";
import OperationTabel from "./home/table";


const Operation = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <OperationHeader />
      </Grid>
      <Grid item xs={12}>
        <OperationTabel />
      </Grid>
    </Grid>
  );
};

export default Operation;
