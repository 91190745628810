import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "../notificationSlice";
import { factory } from "../../../api/apiFactory";
const jTransactionApi = factory.get("jTransaction");
const initialState = {
  searchBody: {},
  jTransaction: {
    loading: false,
    dialog: false,
    active: false,
    type: null, // Pay Cash or Received Cash
    form: {
      id: null,
      j_supplier_id: null,
      amount: "",
      note: "",
      safe_id: null,
    },
  },
  jTransactions: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
  jTransactionTypes: {
    loading: false,
    data: [],
    total: 0,
  },
};

export const jTransactionSlice = createSlice({
  name: "jTransaction",
  initialState,
  reducers: {
    setDialog: (state, { payload }) => {
      state.jTransaction.dialog = !state.jTransaction.dialog;
      if (payload) {
        state.jTransaction.type = payload
      }
    },
    setDataTable: (state, { payload }) => {
      state.jTransactions.data = payload.data.data;
      state.jTransactions.total = payload.data.total;
      state.jTransactions.total_amount = payload.data.total_amount;
    },
    setDataList: (state, { payload }) => {
      state.jTransactionTypes.data = payload.data.data;
    },
    setById: (state, { payload }) => {
      state.jTransaction.form = payload;
    },
    setLoading: (state, action) => {
      state.jTransactions.loading = !state.jTransactions.loading;
    },
    resetForm: (state, action) => {
      state.jTransaction.form = initialState.jTransaction.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDataList,
} = jTransactionSlice.actions;

export default jTransactionSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("jTransaction"));
    const res = await jTransactionApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("jTransaction"));
  } catch (err) {
    dispatch(setLoading("jTransaction"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("jTransaction"));
    const res = await jTransactionApi.getTypes(params);
    dispatch(setDataList(res));
    dispatch(setLoading("jTransaction"));
  } catch (err) {
    dispatch(setLoading("jTransaction"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await jTransactionApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("jTransaction"));
    const res = await jTransactionApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("jTransaction"));
  } catch (err) {
    dispatch(setLoading("jTransaction"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const pay = (data, searchBody) => async (dispatch) => {
  try {
    const res = await jTransactionApi.pay(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(searchBody));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const receive = (id, data, _SearchBody) => async (dispatch) => {
  try {
    const res = await jTransactionApi.receive(id, data);
    dispatch(
      showNotification({
        message: "تم تعديل المنتج بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const JTransaction = {
  getAll,
  deleteById,
  getById,
  pay,
  receive,
  getAllList,
};
