import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Autocomplete,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineClear, MdOutlineInfo } from "react-icons/md";
import dayjs from "dayjs";
import { ExportButton } from "../../../../../components/ExportExcel/ExportButton";
import {
  EpaymentTransactions,
  setSearchBody,
} from "../../../../../app/slices/epaymentTransactionsSlice";

const EpaymentTransactionsTable = () => {
  const dispatch = useDispatch();

  const _EpaymentTransactions = useSelector(
    (state) => state.epaymentTransactions.epaymentTransactions
  );
  const _SearchBody = useSelector(
    (state) => state.epaymentTransactions.searchBody
  );

  const [search, setSearch] = useState({
    order_id: "",
    pos_id: null,
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  const status = [
    {
      status: "success",
      key: "Success",
    },
    {
      status: "failed",
      key: "Failed",
    },
  ];

  const columns = [
    {
      field: "sequence",
      headerName: "#",
      flex: 0.5,
    },
    {
      field: "order_id",
      headerName: "رقم الطلب",
      flex: 1,
    },
    {
      field: "type",
      headerName: "النوع",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "المبلغ (IQD)",
      flex: 1,
      renderCell: (params) => parseInt(params.row.amount)?.toLocaleString(),
    },
    {
      field: "gateway",
      headerName: "البوابة",
      flex: 1,
    },
    {
      field: "pos_name",
      headerName: "نقطة البيع",
      flex: 1,
    },
    {
      field: "status",
      headerName: "الحالة",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" spacing={3}>
          <Typography variant="body">{params.row.status}</Typography>
          <Tooltip title={params.row.failed_details ?? ""}>
            <Typography variant="body">
              {params.row.status === "failed" ? (
                <MdOutlineInfo size={22} />
              ) : null}
            </Typography>
          </Tooltip>
        </Stack>
      ),
    },
    {
      field: "created_at",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) =>
        dayjs(params.row.created_at).format("YYYY-MM-DD / HH:mm a"),
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(EpaymentTransactions.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        order_id: search.order_id ?? "",
        status: search.status ?? "",
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize, search]);

  const customToolbar = () => (
    <GridToolbarContainer>
      <ExportButton fileName={" طلبات اصدار البطاقات | نظام دجلة"} />
    </GridToolbarContainer>
  );

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={search.search}
              onChange={(e) => {
                setSearch({
                  ...search,
                  order_id: e.target.value,
                });
              }}
              label="رقم الطلب"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              options={status}
              getOptionLabel={(option) => option.key || ""}
              value={status.filter((x) => x.key === search.status)[0] ?? null}
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  status: newValue ? newValue.key : null,
                });
              }}
              isOptionEqualToValue={(option, value) => option === value}
              renderInput={(params) => (
                <TextField {...params} label="الحالة" size="small" />
              )}
            />
          </Grid>
          <Grid item xs={2.5}>
            <Stack
              direction="row"
              spacing={0}
              sx={{ alignEpaymentTransactions: "center" }}
            >
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      search: "",
                      status: null,
                      order_id: null,
                    });
                    setPageSize(15);
                    setCurrentPage(0);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "75vh", width: "100%" }}>
              {_EpaymentTransactions.data ? (
                <DataGrid
                  rows={_EpaymentTransactions.data.map((x) => {
                    return {
                      ...x,
                      sequence: _EpaymentTransactions.data.indexOf(x) + 1,
                    };
                  })}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_EpaymentTransactions.total}
                  loading={_EpaymentTransactions.loading}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                    // Footer: CustomFooter,
                    Toolbar: customToolbar,
                  }}
                  disableSelectionOnClick
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EpaymentTransactionsTable;
