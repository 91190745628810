import dayjs from "dayjs";
import {
  createSlice
} from "@reduxjs/toolkit";
import {
  showNotification
} from "./notificationSlice";
import {
  factory
} from "../../api/apiFactory";
const teamApi = factory.get("team");
const initialState = {
  searchBody: {},
  userData: null,
  loading: false,
  team: {
    dialog: false,
    form: {
      leader_id: null,
    },
  },
  leaders: {
    data: [],
    total: 0
  },
  teamByLeaderId: {
    data: [],
    total: 0
  }
}

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setTeamDialog: (state, {
      payload
    }) => {
      state.team.dialog = !state.team.dialog
    },
    setTeamByLeaderIdDataTable: (state, {
      payload
    }) => {
      state.teamByLeaderId.data = payload.data.data[0]?.my_team ? payload.data.data[0].my_team : payload.data.data
    },
    setLeadersTable: (state, {
      payload
    }) => {
      state.leaders.data = payload.data.data
      state.leaders.total = payload.data.total
    },
    setById: (state, {
      payload
    }) => {
      state.team.form.leader_id = payload.leader_id
      state.team.form.user_id = payload.user_id
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetForm: (state, action) => {
      state.team.form = initialState.team.form
    },
    setSearchBody: (state, {
      payload
    }) => {
      state.searchBody = payload;
    },
    setUserData: (state, {
      payload
    }) => {
      state.userData = payload;
    },
  }
})

export const {
  setLoading,
  setTeamDialog,
  resetForm,
  setSearchBody,
  setTeamByLeaderIdDataTable,
  setLeadersTable,
  setUserData
} = teamSlice.actions

export default teamSlice.reducer;

//axios
const getTeamByLeader = (params) => async (dispatch) => {
  try {
    const res = await teamApi.getTeamByLeader(params)
    dispatch(setTeamByLeaderIdDataTable(res));
  } catch (err) {
    throw new Error(err);
  }
};
const getAllTeamLeaders = () => async (dispatch) => {
  try {
    const res = await teamApi.getAllLeaders()
    dispatch(setLeadersTable(res));
  } catch (err) {
    throw new Error(err);
  }
};
const removeUserFromTeam = (id) => async (dispatch) => {
  try {
    dispatch(setLoading('team'));
    await teamApi.removeUserFromTeam(id)
    dispatch(showNotification({
      message: "تم الحذف بنجاح",
      type: "success"
    }));
    dispatch(setLoading('team'));
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    dispatch(setLoading('team'));
    throw new Error(err);
  }
};
const addUserToTeam = (data) => async (dispatch) => {
  try {
    dispatch(setLoading('team'));
    await teamApi.addUserToTeam(data)
    dispatch(showNotification({
      message: "تمت الاضافة  بنجاح",
      type: "success"
    }));
    dispatch(setTeamDialog())
    dispatch(setLoading('team'));
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    dispatch(setLoading('team'));
    throw new Error(err);
  }
};

export const Team = {
  removeUserFromTeam,
  addUserToTeam,
  getAllTeamLeaders,
  getTeamByLeader
}