import { forwardRef, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
  Divider,
  LinearProgress,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  JOrder,
  resetForm,
  setAssignDriverDialog,
} from "../../../../app/slices/jomla/orderSlice";
import { SelectDriver } from "../../../../components/Selectors/jomla/SelectDriver";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AssignOrderToDriverForm = (props) => {
  const dispatch = useDispatch();

  const _JOrder = useSelector((state) => state.jOrder.assignDriver);
  const drivers = useSelector((state) => state.user.driversList.data);
  const _SearchBody = useSelector((state) => state.jOrder.searchBody);

  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _JOrder.form,
  });

  const onSubmit = (data) => {
    dispatch(
      JOrder.assignDriver(
        _JOrder.form.order_id,
        {
          driver_id: data.driver_id,
        },
        _SearchBody,
        props.orderDetails
      )
    );
  };

  useEffect(() => {
    reset({ ..._JOrder.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_JOrder.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialog = () => {
    dispatch(setAssignDriverDialog({}));
  };

  return (
    <Dialog
      open={_JOrder.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="sm"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography variant="h4" color={"purple"} gutterBottom component="div">
          {"تعيين الطلب الى سائق"}
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          {_JOrder.loading && <LinearProgress sx={{ marginBottom: 1 }} />}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"driver_id"}
                render={({ field }) => (
                  <SelectDriver
                    {...field}
                    required
                    onChange={(e, newValue) => {
                      setValue("driver_id", newValue ? newValue.id : null);
                    }}
                    value={
                      drivers.filter((x) => x.id === field.value)[0] ?? null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={_JOrder.loading ? true : false}
                endIcon={
                  _JOrder.loading ? <CircularProgress size={25} /> : null
                }
              >
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AssignOrderToDriverForm;
