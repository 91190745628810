import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  Investors,
} from "../../../../app/slices/investorsSlice";
import { Controller, useForm } from "react-hook-form";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InvestorsForm = () => {
  const _Investors = useSelector((state) => state.investors.investor);
  const _SearchBody = useSelector((state) => state.investors.searchBody);

  const isAdd = _Investors.form.id ? false : true;
  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      ..._Investors.form,
    },
  });

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        Investors.create({
          name: data.name,
          note: data.note,
        })
      );
    } else {
      dispatch(
        Investors.update(
          data.id,
          {
            name: data.name,
            note: data.note,
          },
          _SearchBody
        )
      );
    }
  };
  useEffect(() => {
    reset({
      ..._Investors.form,
      province: {
        id: _Investors.form.province_id,
        name: _Investors.form.province_name,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_Investors.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_Investors.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الاسم"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={2}
                    size="small"
                    label="ملاحظات"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default InvestorsForm;
