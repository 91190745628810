import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./../notificationSlice";
import { factory } from "../../../api/apiFactory";
const jSafeApi = factory.get("jSafe");
const initialState = {
  searchBody: {},
  dialog: false,
  jSafe: {
    loading: false,
    dialog: false,
    form: {
      id: null,
      amount: 0,
    },
  },
  jSafes: {
    loading: false,
    data: [],
    total: 0,
  },
  jSafeTransfer: {
    searchBody: {
      start_date: null,
      end_date: null,
      safe_id: null,
    },
    loading: false,
    dialog: false,
    form: {
      safe_id: null,
      from_safe_id: 0,
      amount: 0,
    },
    data: [],
    total: 0,
  },
};

export const jSafeSlice = createSlice({
  name: "jSafe",
  initialState,
  reducers: {
    setDialog: (state, { payload }) => {
      state.jSafe.dialog = !state.jSafe.dialog;
      if (payload) {
        state.jSafe.form = payload;
      } else {
        state.jSafe.form = initialState.jSafe.form;
      }
    },
    setDataTable: (state, { payload }) => {
      state.jSafes.data = payload.data.data;
      state.jSafes.total = payload.data.total;
    },
    setLoading: (state, action) => {
      state.jSafes.loading = !state.jSafes.loading;
    },
    resetForm: (state, action) => {
      state.jSafe.form = initialState.jSafe.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
    // ================= safe transfer ===========================
    setPageDialog: (state, { payload }) => {
      state.dialog = !state.dialog;
      if (payload) {
        state.jSafeTransfer.searchBody = {
          ...state.jSafeTransfer.searchBody,
          safe_id: payload,
        };
      }
    },
    setSafeTransferPageDialog: (state, { payload }) => {
      state.jSafeTransfer.dialog = !state.jSafeTransfer.dialog;
    },
    setSafeTransferSearchBody: (state, { payload }) => {
      state.jSafeTransfer.searchBody = payload;
    },
    setSafeTransferLoading: (state, action) => {
      state.jSafeTransfer.loading = !state.jSafeTransfer.loading;
    },
    resetSafeTransferForm: (state, action) => {
      state.jSafeTransfer.form = initialState.jSafeTransfer.form;
    },
    setSafeTransferDataTable: (state, { payload }) => {
      state.jSafeTransfer.data = payload.data.data;
      state.jSafeTransfer.total = payload.data.total;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setDataTable,
  resetForm,
  setSearchBody,
  setPageDialog,
  setSafeTransferDataTable,
  resetSafeTransferForm,
  setSafeTransferLoading,
  setSafeTransferSearchBody,
  setSafeTransferPageDialog,
} = jSafeSlice.actions;

export default jSafeSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("jSafe"));
    const res = await jSafeApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("jSafe"));
  } catch (err) {
    dispatch(setLoading("jSafe"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, _SearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("jSafe"));
    await jSafeApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("jSafe"));
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(setLoading("jSafe"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("jSafe"));
    await jSafeApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل  بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("jSafe"));
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(setLoading("jSafe"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
// ================= safe transfer ==================
const getAllSafeTransfer = (params) => async (dispatch) => {
  try {
    dispatch(setSafeTransferLoading("jSafe"));
    const res = await jSafeApi.getAllSafeTransfer(params);
    dispatch(setSafeTransferDataTable(res));
    dispatch(setSafeTransferLoading("jSafe"));
  } catch (err) {
    dispatch(setSafeTransferLoading("jSafe"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const safeTransfer = (data, _SearchBody) => async (dispatch) => {
  try {
    dispatch(setSafeTransferLoading("jSafe"));
    await jSafeApi.createSafeTransfer(data);
    dispatch(
      showNotification({
        message: "تمت العملية بنجاح  بنجاح",
        type: "success",
      })
    );
    dispatch(setSafeTransferLoading("jSafe"));
    dispatch(setSafeTransferPageDialog());
    dispatch(getAllSafeTransfer(_SearchBody));
  } catch (err) {
    dispatch(setSafeTransferLoading("jSafe"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
export const JSafe = {
  getAll,
  create,
  update,
  getAllSafeTransfer,
  safeTransfer,
};
