import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllRep(data) {
    return api.post(`/api/v1/reports/rep`, data);
  },
  orders(data) {
    return api.post(`/api/v1/reports/orders`, data);
  },
  soldPins(data) {
    return api.post(`/api/v1/reports/orders/sold-pins`, data);
  },
  totalRevenue(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/reports/total-revenue?${params}`);
  },
  cash(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/reports/cash?${params}`);
  },
  totals() {
    return api.get(`/api/v1/reports/totals`);
  },
  stock(data) {
    return api.post(`/api/v1/reports/stock`, data);
  },
  ordersDetailed(data) {
    return api.post(`/api/v1/reports/orders-details`, data);
  },
  ordersPinsDetails(data) {
    return api.post(`/api/v1/reports/orders-pins-details`, data);
  },
  posOrders(data) {
    return api.post(`/api/v1/reports/pos-orders`, data);
  },
  platformPayments(data) {
    return api.post(`/api/v1/reports/pos-products-payments`, data);
  },
  posOrdersPerWeek() {
    return api.get(`/api/v1/reports/pos-orders-per-days`);
  },
  repOrdersPerWeek() {
    return api.get(`/api/v1/reports/rep-orders-per-days`);
  },
};
