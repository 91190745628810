import { Card, CardHeader, Typography, Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { CiCalendarDate, CiUser } from "react-icons/ci";
import { useSelector } from "react-redux";
import UserForm from "../../form";

const UserHeader = () => {
  const _User = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
             كشف الحساب
          </Typography>
        }
        avatar={<CiUser size={20} />}

      />
    </Card>
  );
};

export default UserHeader;
