import { Grid } from "@mui/material";
import React from "react";
import JTransactionTable from "./home/table";

const JTransactions = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <JTransactionTable />
      </Grid>
    </Grid>
  );
};

export default JTransactions;
