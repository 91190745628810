import { Grid } from "@mui/material";
import React from "react";
import SupplierTransactionsHeader from "./home/header";
import SupplierTransactionsTable from "./home/table";

const SupplierTransactions = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SupplierTransactionsHeader />
      </Grid>
      <Grid item xs={12}>
        <SupplierTransactionsTable />
      </Grid>
    </Grid>
  );
};

export default SupplierTransactions;
