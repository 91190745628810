import Typography from "@mui/material/Typography";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Divider,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridPagination, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ProductSupplier, setTransactionsDialog, setTransactionsSearchBody } from "../../../../../../app/slices/productsSupplierSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { MdOutlineClear } from "react-icons/md"
import TransactionForm from "../../form";
import DateRangepicker from "../../../../../../helpers/dateRangePicker";
import { SelectTransactionTypes } from "../../../../../../components/Selectors/SelectTransactionTypes";
import { BiInfoCircle } from "react-icons/bi";

const TransactionTable = () => {
  const { product_supplier_id } = useParams();
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState({
    order_id: "",
    startDate: dayjs().format("2023-01-01"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });
  const dispatch = useDispatch();
  const _Loading = useSelector((state) => state.productSupplier.loading);
  const _Transactions = useSelector((state) => state.productSupplier.transactions);
  const _SearchBody = useSelector((state) => state.productSupplier.transactions.searchBody);
  const productSupplierData = useSelector((state) => state.productSupplier.productSupplier.getById);

  const columns = [
    {
      field: "number",
      headerName: "رقم المناقلة",
      flex: 1,
    },
    {
      field: "order_id",
      headerName: "رقم الطلب",
      flex: 0.8,
    },
    {
      field: "amount",
      headerName: "القيمة (IQD)",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>
            {params.row.amount?.toLocaleString()}
          </Typography>
        );
      },
    },
    {
      field: "pos_name",
      headerName: "من المحفظة",
      flex: 1,
    },
    {
      field: "current_balance",
      headerName: "رصيد المجهز (IQD)",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>
            {params.row.current_balance?.toLocaleString()}
          </Typography>
        );
      },
    },
    // {
    //   field: "to_user_name",
    //   headerName: " الى المحفظة",
    //   flex: 1,
    // },
    // {
    //   field: "from_user",
    //   headerName: "حساب المرسل (IQD)",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <Stack direction='column' spacing={1} justifyContent='center' alignItems='center'>
    //         <Typography>
    //           الرصيد: {params.row.from_current_balance?.toLocaleString()}
    //         </Typography>
    //         <Typography>
    //           الديون :{params.row.from_current_debt?.toLocaleString()}
    //         </Typography>
    //       </Stack>
    //     );
    //   },
    // },
    // {
    //   field: "to_user",
    //   headerName: "حساب المستلم (IQD)",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <Stack direction='column' spacing={1} justifyContent='center' alignItems='center'>
    //         <Typography>
    //           الرصيد: {params.row.to_current_balance?.toLocaleString()}
    //         </Typography>
    //         <Typography>
    //           الديون :{params.row.to_current_debt?.toLocaleString()}
    //         </Typography>
    //       </Stack>
    //     );
    //   },
    // },
    {
      field: "created_at",
      headerName: "تاريخ العملية",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>
            {dayjs(params.row.created_at).format("HH:mm / YYYY-MM-DD ")}
          </Typography>
        );
      },
    },
    {
      field: "type",
      headerName: "نوع العملية",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <Typography>
              {(params.row.type)}
            </Typography>
            {/* {params.row.note && <Tooltip title={params.row.note}>
              <IconButton size="small"><BiInfoCircle /></IconButton>
            </Tooltip>} */}
          </Stack>
        );
      },
    },
    {
      field: "note",
      headerName: "الملاحظة",
      flex: 1,
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(ProductSupplier.transactions(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody]);

  useEffect(() => {
    dispatch(setTransactionsSearchBody({
      product_supplier_id: product_supplier_id,
      skip: currentPage * pageSize,
      take: pageSize,
      start_date: search.startDate,
      end_date: search.endDate,
      order_id: search.order_id,
    }));
  }, [search, product_supplier_id, currentPage, pageSize]);

  useEffect(() => {
    dispatch(ProductSupplier.getByIdData(product_supplier_id))
  }, [product_supplier_id])


  function CustomFooter() {
    return (
      <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography color='primary' sx={{ marginX: 2 }}>
          القيمة الكلية : {_Transactions.total_amount.toLocaleString()} (IQD)
        </Typography>
        <GridPagination
        />
      </Stack>
    );
  };

  const customToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: "تقرير كشف الحساب | نظام دجلة",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )

  return (
    <Card>
      <CardContent>
        <Box>
          <TransactionForm />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12}>
            <Stack direction={'row'} justifyContent={"space-between"}>
              <Stack spacing={1}>
                <Typography variant="h3" >{productSupplierData?.name}</Typography>
                <Typography variant="h4" color={"primary"}>الرصيد :  {productSupplierData ? `${parseInt(productSupplierData.balance)?.toLocaleString()} IQD` : ""}</Typography>
                <Stack direction={'row'} spacing={3}>
                <Typography variant="caption">المبالغ المسددة : {_Transactions ? _Transactions.total_pay_cash.toLocaleString() : ""} IQD</Typography>
                <Typography variant="caption">العمولة : {_Transactions ? _Transactions.total_wage.toLocaleString() : ""} IQD</Typography>
                </Stack>
              </Stack>
              <Stack>
                <Button variant="contained"
                  onClick={() => dispatch(setTransactionsDialog())}
                >
                  تسديد مبلغ
                </Button>

              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
          </Grid>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={search.order_id}
              onChange={(e) => {
                setSearch({
                  ...search,
                  order_id: e.target.value
                });
              }}
              label="رقم الطلب"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <DateRangepicker
              startDate={search.startDate}
              endDate={search.endDate}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    startDate: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    endDate: dayjs(searchDate[0].endDate).format(
                      "YYYY-MM-DD"
                    ),
                  })
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Stack direction='row' spacing={0} sx={{ alignItems: 'center' }}>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      order_id: '',
                      startDate: dayjs().format("2023-01-01"),
                      endDate: dayjs().format("YYYY-MM-DD"),
                      skip: 0,
                      take: 15,
                    });
                    setPageSize(15)
                    setCurrentPage(0)
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "57vh", width: "100%" }}>
              <DataGrid
                rows={_Transactions.data}
                columns={columns}
                pageSize={pageSize}
                page={currentPage}
                rowCount={_Transactions.total}
                loading={_Loading}
                rowHeight={45}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) => {
                  setCurrentPage(newPage)
                }}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPageSize(newPageSize)
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                  Footer: CustomFooter,
                  Toolbar: customToolbar
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TransactionTable;
