import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Chip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiTrash, BiEdit } from "react-icons/bi";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import {
  JCategories,
  setSearchBody,
} from "../../../../../app/slices/jomla/categorySlice";

const JCategoriesTable = () => {
  const dispatch = useDispatch();

  const _JCategory = useSelector((state) => state.JCategories.JCategories);
  const _SearchBody = useSelector((state) => state.JCategories.searchBody);

  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [openActive, setOpenActive] = useState(false);
  const [dialogActiveData, setDialogActiveData] = useState({});

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(JCategories.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const handleActiveClick = (params) => {
    setOpenActive(true);
    setDialogActiveData(params);
  };

  const handleConfirmActive = () => {
    dispatch(JCategories.toggleShown(dialogActiveData.id, _SearchBody)).then(
      (x) => {}
    );
    setOpenActive(false);
  };
  const handleCancelActive = () => {
    setOpenActive(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "sub_categories_count",
      headerName: "عدد الافسام الفرعية",
      flex: 1,
    },
    {
      field: "logo",
      headerName: "الصورة",
      flex: 1,
      renderCell: (params) => (
        <Box
          component="img"
          src={params.row.logo}
          width={150}
          sx={{ objectFit: "contain" }}
        />
      ),
    },
    {
      field: "is_shown",
      headerName: "حالة القسم",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.is_shown ? "فعال" : "غير فعال"}
            color={params.row.is_shown ? "success" : "error"}
            onClick={() => handleActiveClick(params.row)}
          />
        );
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => dispatch(JCategories.getById(params.row.id))}
            >
              <BiEdit />
            </IconButton>
            <IconButton onClick={() => handleDeleteClick(params.row)}>
              <BiTrash />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(JCategories.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        search: search,
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize, search]);

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
          <ConfirmationDialog
            open={openActive}
            title="تاكيد"
            message={
              <Typography>
                {dialogActiveData?.is_shown ? (
                  <>
                    هل انت متاكد من اخفاء{" "}
                    <span style={{ color: "red" }}>
                      <strong>({dialogActiveData.name})</strong>
                    </span>{" "}
                    ومنتجاته
                  </>
                ) : (
                  <>
                    هل انت متاكد من اظهار{" "}
                    <span style={{ color: "red" }}>
                      <strong>({dialogActiveData.name})</strong>
                    </span>{" "}
                    ومنتجاته
                  </>
                )}
              </Typography>
            }
            // name={dialogActiveData.name}
            confirmText="تاكيد"
            cancelText="الغاء"
            onConfirm={() => handleConfirmActive()}
            onCancel={handleCancelActive}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={{}}>
          <Grid item xs={2}>
            <TextField
              size="small"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              label="بحث عن الاسم "
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_JCategory.data ? (
                <DataGrid
                  rows={_JCategory.data}
                  columns={columns}
                  pageSize={pageSize}
                  rowCount={_JCategory.total}
                  loading={_JCategory.loading}
                  rowHeight={150}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default JCategoriesTable;
