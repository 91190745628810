import dayjs from "dayjs";
import {
    createSlice
} from "@reduxjs/toolkit";
import {
    showNotification
} from "./notificationSlice";
import {
    factory
} from "../../api/apiFactory";
const groupApi = factory.get("group");
const initialState = {
    searchBody: {},
    group: {
        loading: false,
        dialog: false,
        form: {
            id: "",
            name: "",
        },
    },
    groups: {
        loading: false,
        data: [],
        total: 0,
    },
    groupsList: {
        loading: false,
        data: [],
        total: 0,
    },
}

export const groupSlice = createSlice({
    name: "group",
    initialState,
    reducers: {
        setDialog: (state, action) => {
            state.group.dialog = !state.group.dialog
        },
        setDataTable: (state, {
            payload
        }) => {
            state.groups.data = payload.data.data
            state.groups.total = payload.data.total
        },
        setDataList: (state, {
            payload
        }) => {
            state.groupsList.data = payload.data.data
        },
        setById: (state, {
            payload
        }) => {
            state.group.form.id = payload.id
            state.group.form.name = payload.name
        },
        setLoading: (state, action) => {
            state[action.payload].loading = !state[action.payload].loading;
        },
        resetForm: (state, action) => {
            state.group.form = initialState.group.form
        },
        setSearchBody: (state, {
            payload
        }) => {
            state.searchBody = payload;
        },
    }
})

export const {
    setLoading,
    setDialog,
    setById,
    setDataTable,
    resetForm,
    setSearchBody,
    setDataList
} = groupSlice.actions

export default groupSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
    try {
        dispatch(setLoading('group'));
        const res = await groupApi.getAll(params)
        dispatch(setDataTable(res));
        dispatch(setLoading('group'));

    } catch (err) {
        dispatch(setLoading('group'));
        dispatch(showNotification({
            message: err.response.data.errors[0],
            type: "error"
        }));
        throw new Error(err);
    }
};
const getAllList = (params) => async (dispatch) => {
    try {
        dispatch(setLoading('group'));
        const res = await groupApi.getAllList(params)
        dispatch(setDataList(res));
        dispatch(setLoading('group'));

    } catch (err) {
        dispatch(setLoading('group'));
        dispatch(showNotification({
            message: err.response.data.errors[0],
            type: "error"
        }));
        throw new Error(err);
    }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
    try {
        await groupApi.delete(id)
        dispatch(showNotification({
            message: "تم الحذف بنجاح",
            type: "success"
        }));
        dispatch(getAll(_SearchBody))
    } catch (err) {
        dispatch(showNotification({
            message: err.response.data.errors[0],
            type: "error"
        }));
        throw new Error(err);
    }
};
const getById = (id) => async (dispatch) => {
    try {
        dispatch(setLoading('group'));
        const res = await groupApi.getById(id)
        dispatch(setById(res.data.data[0]));

        dispatch(setDialog())
        dispatch(setLoading('group'));
    } catch (err) {
        dispatch(showNotification({
            message: err.response.data.errors[0],
            type: "error"
        }));
        throw new Error(err);
    }
};
const create = (data) => async (dispatch) => {
    try {
        await groupApi.create(data)
        dispatch(showNotification({
            message: "تمت الاضافة  بنجاح",
            type: "success"
        }));
        dispatch(setDialog())
        dispatch(getAll())
    } catch (err) {
        dispatch(showNotification({
            message: err.response.data.errors[0],
            type: "error"
        }));
        throw new Error(err);
    }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
    try {
        await groupApi.update(id, data)
        dispatch(showNotification({
            message: "تم تعديل المجموعة بنجاح",
            type: "success"
        }));
        dispatch(setDialog())
        dispatch(getAll(_SearchBody))
    } catch (err) {
        dispatch(showNotification({
            message: err.response.data.errors[0],
            type: "error"
        }));
        throw new Error(err);
    }
};

export const Group = {
    getAll,
    deleteById,
    getById,
    create,
    update,
    getAllList
}