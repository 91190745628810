import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(pos_id, item_id) {
    return api.get(
      `/api/v1/pos-discount?pos_id=${pos_id ? pos_id : ""}&item_id=${
        item_id ? item_id : ""
      }`
    );
  },
  getById(id) {
    return api.get(`/api/v1/pos-discount/${id}`);
  },
  update(id, data) {
    return api.put(`/api/v1/pos-discount/${id}`, data);
  },
  create(data) {
    return api.post(`/api/v1/pos-discount`, data);
  },
  delete(id) {
    return api.delete(`/api/v1/pos-discount/${id}`);
  },
};
