import { useState, forwardRef, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Divider,
  Stack,
  Box,
  Grid,
  Tooltip,
  IconButton,
  LinearProgress,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  OrderRechargeCards,
  setCreditCardTransactionsSearchBody,
  setUpdateCreditCardTransactionsDialog,
} from "../../../../app/slices/orderRechargeCardsSlice";
import dayjs from "dayjs";
import { DataGrid } from "@mui/x-data-grid";
import { MdOutlineClear } from "react-icons/md";
import DateRangepicker from "../../../../helpers/dateRangePicker";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreditCardBalanceTransactions = () => {
  const dispatch = useDispatch();

  const _Transactions = useSelector(
    (state) => state.orderRechargeCards.updateCreditCardBalance.transactions
  );
  const _SearchBody = useSelector(
    (state) =>
      state.orderRechargeCards.updateCreditCardBalance.transactions.searchBody
  );
  const loading = useSelector(
    (state) => state.orderRechargeCards.updateCreditCardBalance.loading
  );
  const [search, setSearch] = useState(_SearchBody);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "id",
      headerName: "رقم المعاملة",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "المبلغ",
      flex: 1,
      renderCell: (params) => (
        <Typography
          variant="body"
          color={params.row.amount < 0 ? "red" : "green"}
        >
          {params.row.amount?.toLocaleString()}
        </Typography>
      ),
    },
    {
      field: "balance_for_name",
      headerName: "الى",
      flex: 1,
    },
    {
      field: "old_balance",
      headerName: "الرصيد قبل",
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body">
          {params.row.old_balance?.toLocaleString()}
        </Typography>
      ),
    },
    {
      field: "new_balance",
      headerName: "الرصيد بعد",
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body">
          {params.row.new_balance?.toLocaleString()}
        </Typography>
      ),
    },
    {
      field: "created_by_name",
      headerName: "بواسطة",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.created_by_name} followCursor>
          <Typography variant="body">{params.row.created_by_name}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "created_at",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Typography>
            {dayjs(params.row.created_at).format("YYYY-MM-DD")}
          </Typography>
          <Typography>
            {dayjs(params.row.created_at).format("hh:mm a")}
          </Typography>
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(OrderRechargeCards.getAllCreditCardTransactions(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_SearchBody]);

  useEffect(() => {
    dispatch(
      setCreditCardTransactionsSearchBody({
        start_date: search.start_date,
        end_date: search.end_date,
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, search]);

  const handleDialog = () => {
    dispatch(setUpdateCreditCardTransactionsDialog());
  };

  return (
    <Dialog
      open={_Transactions.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="lg"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          المناقلات
        </Typography>
        <Divider sx={{ marginBottom: 1 }} />
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={3}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].start_date).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].end_date).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.start_date ||
                  updatedFilter.to !== searchDate.end_date
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].start_date).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(searchDate[0].end_date).format(
                      "YYYY-MM-DD"
                    ),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    start_date: null,
                    end_date: null,
                    take: 15,
                    skip: 0,
                  });
                  setPageSize(15);
                  setCurrentPage(0);
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "63vh", width: "100%" }}>
              <DataGrid
                rows={_Transactions.data.map((x, i) => {
                  return {
                    ...x,
                    index: i + 1 + pageSize * currentPage,
                  };
                })}
                columns={columns}
                pageSize={pageSize}
                page={currentPage}
                rowCount={_Transactions.total}
                loading={loading}
                rowHeight={65}
                pagination
                paginationMode="server"
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPageSize(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
                selectionModel={[]}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CreditCardBalanceTransactions;
