import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const ticketsApi = factory.get("tickets");
const initialState = {
  addReplyDialog: false,
  searchBody: {},
  reload: false,
  ticket: {
    reload: false,
    loading: false,
    dialog: false,
    active: false,
    form: {
      id: "",
      title: "",
      description: "",
      ticket_type_id: null,
      ticket_type: "",
      ticket_status_id: null,
      ticket_status: "",
      is_public: false,
      created_by_id: "",
      created_by: "",
      attachments: [],
      replies: [],
      created_at: "",
      created_at_human: "",
    },
  },
  tickets: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
  openTickets: 0,
};

export const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.ticket.dialog = !state.ticket.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.tickets.data = payload.data.data;
      state.tickets.total = payload.data.total;
      state.tickets.total_amount = payload.data.total_amount;
    },
    setAddReplyDialog: (state, action) => {
      state.addReplyDialog = !state.addReplyDialog;
      state.ticket.reload = !state.addReplyDialog
        ? !state.ticket.reload
        : state.ticket.reload;
    },
    setById: (state, { payload }) => {
      state.ticket.form = {
        ...payload,
      };
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.ticket.form = initialState.ticket.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
    setOpenTickets: (state, { payload }) => {
      state.openTickets = payload.data.total;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setAddReplyDialog,
  setDataTable,
  resetForm,
  setSearchBody,
  setDataList,
  setOpenTickets,
} = ticketsSlice.actions;

export default ticketsSlice.reducer;

const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("tickets"));
    const res = await ticketsApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("tickets"));
  } catch (err) {
    dispatch(setLoading("tickets"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const getOpenTickets = () => async (dispatch) => {
  try {
    const res = await ticketsApi.openTickets();
    dispatch(setOpenTickets(res));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("tickets"));
    const res = await ticketsApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("tickets"));
  } catch (err) {
    dispatch(setLoading("tickets"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const addReply = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("tickets"));
    await ticketsApi.reply(data);
    dispatch(
      showNotification({
        message: "تمت العملية بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("tickets"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("tickets"));
    throw new Error(err);
  }
};

const updateStatus = (id, data, flag, searchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("tickets"));
    await ticketsApi.updateStatus(id, data, searchBody);
    dispatch(
      showNotification({
        message: "تم تحديث الحالة بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog(searchBody));
    flag ? dispatch(getAll(searchBody)) : dispatch(getById(id));
    dispatch(setLoading("tickets"));
  } catch (err) {
    dispatch(setLoading("tickets"));
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const Tickets = {
  getAll,
  getById,
  addReply,
  updateStatus,
  getOpenTickets,
};
