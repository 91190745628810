import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
  Stack,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash } from "react-icons/bi";
import { MdOutlineClear } from "react-icons/md";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExternalOrder } from "../../../../app/slices/pos/externalOrdersSlice";
import dayjs from "dayjs";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import DateRangepicker from "../../../../helpers/dateRangePicker";
import { Controller, useForm } from "react-hook-form";
import { useRef } from "react";
import { NumericFormat } from "react-number-format";

const ExternalOrderTable = () => {
  const dispatch = useDispatch();

  const _ExternalOrder = useSelector(
    (state) => state.PosExternalOrder.ExternalOrders
  );

  const { control, reset, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: _ExternalOrder.form,
  });

  // const [search, setSearch] = useState({
  //   start_date: dayjs().format("YYYY-MM-01"),
  //   end_date: dayjs().format("YYYY-MM-DD"),
  // });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);

  const columns = [
    {
      field: "id",
      headerName: "الرقم",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "المبلغ",
      flex: 1,
      renderCell: (params) => parseInt(params.row.amount)?.toLocaleString(),
    },
    {
      field: "status",
      headerName: "الحالة",
      flex: 1,
      renderCell: (params) =>
        params.row.status == "success"
          ? "نجحت العملية"
          : params.row.status == "need_confirmation"
          ? "تحتاج للتأكيد"
          : "فشلت العملية",
    },
    {
      field: "note",
      headerName: "الملاحظة",
      flex: 2,
    },
    {
      field: "created_at",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => {
        return (
          <>{dayjs(params.row.created_at).format("HH:mm / YYYY-MM-DD ")}</>
        );
      },
    },
  ];

  useEffect(() => {
    // Function to dispatch ExternalOrder.getAll() action
    const fetchData = () => {
      dispatch(ExternalOrder.getAll());
    };

    // Initial call when component mounts
    fetchData();

    // Set interval to call fetchData every 10 seconds
    const intervalId = setInterval(fetchData, 10000); // 10000 milliseconds = 10 seconds

    // Clean up function to clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
    inputRef.current.select();
  }, [inputRef.current]);

  const onSubmit = (data) => {
    dispatch(ExternalOrder.create(data)).then((res) => {
      if (res.data.status == 201 && res.data.payment_url) {
        javascript: window.open(
          res.data.payment_url,
          "_blank",
          "height=700,width=500"
        );
      }
    });
  };

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          {/* <WebView /> */}
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
            <Stack spacing={2} direction={"row"} sx={{ padding: 2 }}>
              <Grid item sm={3}>
                <Controller
                  name="amount"
                  control={control}
                  render={({ field: { onChange, ...renderProps } }) => {
                    return (
                      <NumericFormat
                        inputRef={inputRef}
                        customInput={TextField}
                        label="المبلغ"
                        size="large"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              IQD
                            </InputAdornment>
                          ),
                        }}
                        {...renderProps}
                        onValueChange={(target) => {
                          onChange();
                          setValue("amount", target.floatValue);
                        }}
                        valueIsNumericString
                        thousandSeparator
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size="large"
                      label="الملاحظات"
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  اضافة
                </Button>
              </Grid>
            </Stack>
          </form>
          {/* <Grid item xs={3}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format(
                      "YYYY-MM-DD"
                    ),
                  })
                }
              }}
            />
          </Grid>
          <Grid item xs={2.5}>
            <Stack direction='row' spacing={0} sx={{ alignItems: 'center' }}>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      pos_name: '',
                      pos_number: '',
                      status: null,
                      product_supplier_id: null,
                      start_date: dayjs().format("YYYY-MM-01"),
                      end_date: dayjs().format("YYYY-MM-DD"),
                    });
                    setPageSize(15)
                    setCurrentPage(0)
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid> */}
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_ExternalOrder.data ? (
                <DataGrid
                  rows={_ExternalOrder.data}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_ExternalOrder.total}
                  loading={_ExternalOrder.loading}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => setCurrentPage(newPage)}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ExternalOrderTable;
