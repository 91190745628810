import { Card, CardHeader, Typography, Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { TbSubtask } from "react-icons/tb";
import { setSafeTransferPageDialog } from "../../../../../../app/slices/jomla/safeSlice";
import JSafeTransferForm from "../../from";

const JSafeTransferHeader = () => {
  const JSafeTransfer = useSelector((state) => state.jSafe.jSafeTransfer);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            المناقلات
          </Typography>
        }
        avatar={<TbSubtask size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setSafeTransferPageDialog())}
          >
            تحويل كاش بين الخزنات
          </Button>
        }
      />
      {JSafeTransfer.dialog ? <JSafeTransferForm /> : null}
    </Card>
  );
};

export default JSafeTransferHeader;
