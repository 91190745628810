import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Typography,
  Stack,
  Tooltip,
  Autocomplete,
  TextField,
  useMediaQuery,
  TablePagination,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RequestPoints,
  setSearchBody,
} from "../../../../app/slices/request-points";
import dayjs from "dayjs";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import { showNotification } from "../../../../app/slices/notificationSlice";
import { MdOutlineClear } from "react-icons/md";
import { useTheme } from "@emotion/react";

const RequestPointsTabel = () => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [search, setSearch] = useState({
    pos: null,
    status: null,
    pos_number: "",
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const _RequestPoints = useSelector(
    (state) => state.requestPoints.requestPoints
  );
  const loading = useSelector(
    (state) => state.requestPoints.requestPoint.loading
  );
  const _SearchBody = useSelector((state) => state.requestPoints.searchBody);

  const [openConfirm, setConfirmOpen] = useState(false);
  const [openConfirmApprove, setOpenConfirmApprove] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const handleApproveClick = (params) => {
    if (params.status == "approved") {
      dispatch(
        showNotification({
          message: "الطلب تمت الموافقة عليه مسبقاً",
          type: "success",
        })
      );
      setOpenConfirmApprove(false);
      setIsApproved(false);
    } else {
      setOpenConfirmApprove(true);
      setDialogData(params);
      setIsApproved(true);
    }
  };

  const handleRejectClick = (params) => {
    if (params.status == "rejected") {
      dispatch(
        showNotification({
          message: "الطلب تم رفظه مسبقاً",
          type: "success",
        })
      );
      setOpenConfirmApprove(false);
      setIsApproved(false);
    } else {
      setOpenConfirmApprove(true);
      setDialogData(params);
      setIsApproved(false);
    }
  };

  const getStatus = (_status) => {
    if (_status.status == "approved")
      return <Typography color="green">تمت الموافقة</Typography>;
    else if (_status.status == "pending")
      return <Typography color="orange">قيد المراجعة</Typography>;
    else return <Typography color="error">مرفوض</Typography>;
  };

  const handleConfirmApprove = () => {
    if (isApproved) {
      dispatch(
        RequestPoints.approve(
          dialogData.id,
          {
            is_approved: true,
          },
          _SearchBody
        )
      ).then((x) => setIsApproved(false));
      setOpenConfirmApprove(false);
    } else {
      dispatch(
        RequestPoints.approve(
          dialogData.id,
          {
            is_approved: false,
          },
          _SearchBody
        )
      ).then((x) => setIsApproved(false));
      setOpenConfirmApprove(false);
    }
  };

  const handleCancelApprove = () => {
    setOpenConfirmApprove(false);
  };

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(RequestPoints.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const status = [
    { status: "pending", name: "قيد المراجعة" },
    { status: "approved", name: "تمت الموافقة" },
    { status: "rejected", name: "مرفوض" },
  ];

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "id",
      headerName: "رقم الطلب",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "المبلغ (IQD)",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>
            {parseInt(params.row.amount).toLocaleString()}
          </Typography>
        );
      },
    },
    {
      field: "pos",
      headerName: "معلومات نقطة البيع",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.pos ? (
              <Stack
                direction="column"
                spacing={0.5}
                justifyContent="center"
                alignItems="center"
              >
                <Typography>{params.row.pos.name}</Typography>
                <Typography variant="caption">
                  {params.row.pos.mobile}
                </Typography>
                <Typography variant="caption" color="primary">
                  الرقم: {params.row.pos.pos_number}
                </Typography>
              </Stack>
            ) : null}
          </>
        );
      },
    },
    {
      field: "rep",
      headerName: "المندوب المباشر",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.rep ? (
              <Stack
                direction="column"
                spacing={0.5}
                justifyContent="center"
                alignItems="center"
              >
                <Typography>{params.row.rep.name}</Typography>
                <Typography variant="caption">
                  {params.row.rep.mobile}
                </Typography>
              </Stack>
            ) : null}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "حالة الطلب",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.status == "pending" ? (
              <Typography color="orange">قيد المراجعة</Typography>
            ) : params.row.status == "approved" ? (
              <Typography color="green">تمت الموافقة</Typography>
            ) : (
              <Typography color="error">تم الرفض</Typography>
            )}
          </>
        );
      },
    },
    {
      field: "approved_by",
      headerName: "مدقق الطلب",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.status == "approved" ? (
              <Stack
                direction="column"
                spacing={0.5}
                justifyContent="center"
                alignItems="center"
              >
                <Typography>{params.row.approved_by.name}</Typography>
                <Typography variant="caption">
                  {dayjs(params.row.approved_by.created_at).format(
                    "HH:mm / YYYY-MM-DD "
                  )}
                </Typography>
              </Stack>
            ) : null}
          </>
        );
      },
    },
    {
      field: "created_at",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => {
        return (
          <>{dayjs(params.row.created_at).format("HH:mm / YYYY-MM-DD ")}</>
        );
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="موافقة الطلب">
              <span>
                <IconButton
                  disabled={
                    params.row.status == "approved" ||
                      params.row.status == "rejected"
                      ? true
                      : false
                  }
                  onClick={() => {
                    handleApproveClick(params.row);
                  }}
                >
                  <IoMdCheckmarkCircleOutline
                    color={
                      params.row.status == "approved" ||
                        params.row.status == "rejected"
                        ? null
                        : "green"
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="رفض الطلب">
              <span>
                <IconButton
                  disabled={
                    params.row.status == "approved" ||
                      params.row.status == "rejected"
                      ? true
                      : false
                  }
                  onClick={() => {
                    handleRejectClick(params.row);
                  }}
                >
                  <AiOutlineCloseCircle
                    color={
                      params.row.status == "approved" ||
                        params.row.status == "rejected"
                        ? null
                        : "red"
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>
            {/* <Tooltip title="حذف الطلب">
              <span>
                <IconButton
                  disabled={params.row.status == 'approved' || params.row.status == 'rejected' ? true : false}
                  onClick={() => { handleDeleteClick(params.row); }}
                >
                  <BiTrash />
                </IconButton>
              </span>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        RequestPoints.getAll({
          pos_id: search.pos?.id ?? "",
          status: search.status?.status ?? "",
          pos_number: search.pos_number,
          skip: currentPage * pageSize,
          take: pageSize,
        })
      );
    }, 500);
    dispatch(
      setSearchBody({
        pos_id: search.pos?.id ?? "",
        status: search.status?.status ?? "",
        search: search.pos_number,
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
    return () => clearTimeout(timeout);
  }, [search, pageSize, currentPage]);

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف ؟"
            name={dialogData.title}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={handleConfirmDelete}
            onCancel={handleCancelDelete}
          />
          <ConfirmationDialog
            type="confirm"
            open={openConfirmApprove}
            title="تاكيد"
            message={
              isApproved
                ? "هل انت متاكد من الموافقة على الطلب"
                : "هل انت متاكد من رفض الطلب"
            }
            name={dialogData.title}
            confirmText="تاكيد"
            cancelText="الغاء"
            onConfirm={handleConfirmApprove}
            onCancel={handleCancelApprove}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              value={search.pos_number}
              onChange={(e) => {
                setSearch({
                  ...search,
                  pos_number: e.target.value,
                });
              }}
              label="بحث عن رقم نقطة البيع "
              fullWidth
            />
          </Grid>
          <Grid item xs={10} md={2}>
            <Autocomplete
              options={status}
              getOptionLabel={(option) => {
                return option.name;
              }}
              value={search.status}
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  status: newValue ? newValue : null,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label="حالة الطلب" size="small" />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      pos: null,
                      status: null,
                      pos_number: "",
                    });
                    setPageSize(15);
                    setCurrentPage(0);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          {smallScreen ? (
            _RequestPoints?.data?.length > 0 ? (
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : null}
                {_RequestPoints.data.map((order, index) => (
                  <Card sx={{ marginBottom: 0.5 }}>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography>
                            رقم الطلب: <strong>{order?.id}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            المبلغ:{" "}
                            <strong>
                              {order?.amount?.toLocaleString()} IQD
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            نقطة البيع: <strong>{order?.pos?.name}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">
                            رقم النقطة:{" "}
                            <strong>{order?.pos?.pos_number}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">
                            رقم الهاتف: <strong>{order?.pos?.mobile}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            المندوب: <strong>{order?.rep?.name}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            هاتف المندوب: <strong>{order?.rep?.mobile}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            حالة الطلب:{" "}
                            {order.status === "pending" ? (
                              <strong style={{ color: "orange" }}>
                                قيد المراجعة
                              </strong>
                            ) : order.status === "approved" ? (
                              <strong style={{ color: "green" }}>
                                تمت الموافقة
                              </strong>
                            ) : (
                              <strong style={{ color: "red" }}>تم الرفض</strong>
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            مدقق الطلب:{" "}
                            <strong>{order?.approved_by?.name}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            تاريخ التدقيق:{" "}
                            <strong>
                              {dayjs(order.approved_by.created_at).format(
                                "YYYY-MM-DD "
                              )}
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography variant="caption" color="primary">
                            {dayjs(order?.created_at).format("YYYY-MM-DD")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <>
                            <Tooltip title="موافقة الطلب">
                              <span>
                                <IconButton
                                  disabled={
                                    order.status === "approved" ||
                                      order.status === "rejected"
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    handleApproveClick(order);
                                  }}
                                >
                                  <IoMdCheckmarkCircleOutline
                                    color={
                                      order.status === "approved" ||
                                        order.status === "rejected"
                                        ? null
                                        : "green"
                                    }
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Tooltip title="رفض الطلب">
                              <span>
                                <IconButton
                                  disabled={
                                    order.status === "approved" ||
                                      order.status === "rejected"
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    handleRejectClick(order);
                                  }}
                                >
                                  <AiOutlineCloseCircle
                                    color={
                                      order.status === "approved" ||
                                        order.status === "rejected"
                                        ? null
                                        : "red"
                                    }
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
                <Card sx={{ mt: 2, width: "100%" }}>
                  <TablePagination
                    component="div"
                    count={_RequestPoints.total}
                    rowsPerPage={pageSize}
                    page={currentPage}
                    onPageChange={(e, newPage) => setCurrentPage(newPage)}
                    rowsPerPageOptions={[15, 25, 50, 100]}
                    onRowsPerPageChange={(event) => {
                      const newRowsPerPage = parseInt(event.target.value, 10);
                      setPageSize(newRowsPerPage);
                    }}
                  />
                </Card>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Card>
                  <Typography m={2} variant="h5" color="primary" align="center">
                    لا توجد بيانات
                  </Typography>
                </Card>
              </Grid>
            )
          ) : (
            <Grid item xs={12}>
              <Box sx={{ height: "70vh", width: "100%" }}>
                <DataGrid
                  rows={
                    _RequestPoints.data?.map((x, index) => ({
                      ...x,
                      index: index + 1,
                    })) ?? []
                  }
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_RequestPoints.total}
                  loading={loading}
                  rowHeight={75}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RequestPointsTabel;
