import { Grid } from "@mui/material";
import React from "react";
import SupplierHeader from "./home/header";
import SupplierTabel from "./home/table";

const Supplier = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SupplierHeader />
      </Grid>
      <Grid item xs={12}>
        <SupplierTabel />
      </Grid>
    </Grid>
  );
};

export default Supplier;
