import { Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { MdSupportAgent } from "react-icons/md";

const RepDetailsHeader = () => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            تفاصيل المندوبين
          </Typography>
        }
        avatar={<MdSupportAgent size={20} />}
      />
    </Card>
  );
};

export default RepDetailsHeader;
