import { Card, CardHeader, Typography, Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { GiBoxUnpacking } from "react-icons/gi";
import { setDialog } from "../../../../../app/slices/jomla/orderSlice";
import JOrderForm from "../../form";
import EditAdminDiscountForm from "../../form/editAdminDiscountForm";
import AssignOrderToDriverForm from "../../form/assignDriver";
import OrderPrintDialog from "../../form/orderPrint";
import ChangePosForm from "../../form/changePos";

const JOrderHeader = () => {
  const dispatch = useDispatch();
  const JOrder = useSelector((state) => state.jOrder);
  const _JOrderPrintDialog = useSelector(
    (state) => state.jOrder.orderPrint.dialog
  );

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            طلبات الجملة
          </Typography>
        }
        avatar={<GiBoxUnpacking size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            إضافة
          </Button>
        }
      />
      {JOrder.jOrder.dialog ? <JOrderForm /> : null}
      {JOrder.adminDiscount.dialog ? <EditAdminDiscountForm /> : null}
      {JOrder.assignDriver.dialog ? <AssignOrderToDriverForm /> : null}
      {JOrder.changePos.dialog ? <ChangePosForm /> : null}
      {_JOrderPrintDialog ? <OrderPrintDialog /> : null}
    </Card>
  );
};

export default JOrderHeader;
