import api from "../../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== "" && data[key] !== undefined) {
          params += `${key}=${data[key]}&`;
        }
      });
    }
    return api.get(`/api/v1/j-item?${params}`);
  },
  getAllList(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== "" && data[key] !== undefined) {
          params += `${key}=${data[key]}&`;
        }
      });
    }
    return api.get(`/api/v1/j-item/list?${params}`);
  },
  getById(id) {
    return api.get(`/api/v1/j-item/${id}`);
  },
  update(id, data) {
    return api.post(`/api/v1/j-item/${id}`, data);
  },
  create(data) {
    return api.post(`/api/v1/j-item`, data);
  },
  delete(id) {
    return api.delete(`/api/v1/j-item/${id}`);
  },
  active(id) {
    return api.post(`/api/v1/j-item/active/${id}`);
  },
  deleteAttachment(id) {
    return api.delete(`/api/v1/attachment/${id}`);
  },
  export(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== "" && data[key] !== undefined) {
          params += `${key}=${data[key]}&`;
        }
      });
    }
    return api.get(`/api/v1/j-item?${params}`, { responseType: "blob" });
  },
};
