import Typography from "@mui/material/Typography";
import {
  Grid,
  Dialog,
  DialogContent,
  Slide,
  Stack,
  Divider,
  Box,
  Chip,
} from "@mui/material";
import { forwardRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Pins, setorderDetailsDialog } from "../../../../app/slices/pinsSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrdersDetails = () => {
  const dispatch = useDispatch();
  const _OrderDetails = useSelector(
    (state) => state.pins.orderDetails
  );

  const handleDialog = () => {
    dispatch(setorderDetailsDialog(null));
  };

  useEffect(() => {
    if (_OrderDetails.dialog) {
      dispatch(Pins.getOrderDetails(_OrderDetails.order_id))
    }
  }, [_OrderDetails.dialog])

  return (
    <Dialog
      open={_OrderDetails.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={handleDialog}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <DialogContent>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            mb={3}
            variant="h4"
            color={"purple"}
            gutterBottom
            component="div"
          >
            {"تفاصيل الطلب"}
          </Typography>
          {/* <Chip
            size="small"
            label={_OrderDetails.data?.status}
            color={
              _OrderDetails.data?.status == "تم التسديد"
                ? "success"
                : _OrderDetails.data?.status == "رفض"
                  ? "error"
                  : _OrderDetails.data?.status == "اقساط يومية"
                    ? "info"
                    : "warning"
            }
          /> */}
        </Stack>
        <Divider sx={{ marginBottom: 3 }} />
        <Grid container spacing={2}>
          <Grid item md={4} display="flex" alignItems="center">
            <Stack direction="row" spacing={1}>
              <Typography>اسم المشتري:</Typography>
              <Typography variant="h4">
                {_OrderDetails.data?.pos_name ?? _OrderDetails.data?.supplier}
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4} display="flex" alignItems="center">
            <Stack direction="row" spacing={1}>
              <Typography>اسم المنتج:</Typography>
              <Typography variant="h4">
                {_OrderDetails.data?.items ? _OrderDetails.data?.items[0].item_name : ""}
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <Typography>تاريخ الشراء :</Typography>
              <Typography variant="h4">
                {dayjs(_OrderDetails.data?.created_at).format(
                  "YYYY-MM-DD / HH:mm"
                )}
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <Typography>رقم الطلب:</Typography>
              <Typography variant="h4">
                {_OrderDetails.data?.id}
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4} display="flex" alignItems="center">
            <Stack direction="row" spacing={1}>
              <Typography>العدد:</Typography>
              <Typography variant="h4">
                {_OrderDetails.data?.items ? _OrderDetails.data?.items[0].count : ''}
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <Typography>السعر الكلي:</Typography>
              <Typography variant="h4">
                {_OrderDetails.data?.items ? _OrderDetails.data?.items[0].total_amount.toLocaleString() : ''} IQD
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <Typography>نسبة الخصم:</Typography>
              <Typography variant="h4">
                {_OrderDetails.data?.discount?.toLocaleString()} IQD
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={12}>
            <Divider />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default OrdersDetails;
