import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Link,
  Pagination,
  Stack,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  YAxis,
} from "recharts";
import dayjs from "dayjs";
import { MdOutlineClear } from "react-icons/md";
import { Reports } from "../../../app/slices/reportsSlice";
import { SelectRepresentative } from "../../../components/Selectors/SelectRepresentative";

const PosSalesPerWeekChart = () => {
  const dispatch = useDispatch();

  const { data, rep_id } = useSelector(
    (state) => state.reports.posOrdersPerWeek
  );
  const user = useSelector((state) => state.user.usersList);

  const [posData, setPosData] = useState([]);
  const [search, setSearch] = useState("");
  const [searchRep, setSearchRep] = useState(rep_id ?? null);

  useEffect(() => {
    dispatch(Reports.getPosOrdersPerWeek());
  }, [dispatch]);

  useEffect(() => {
    setPosData(
      data?.filter((pos) => {
        const isPosNameMatch =
          pos?.pos_name?.startsWith(search.toLowerCase()) || false;
        const isPosNumberMatch =
          typeof pos.pos_number === "string" &&
          pos.pos_number.startsWith(search.toLowerCase());
        const isRepIdMatch = searchRep ? pos.rep_id == searchRep : true;
        return (isPosNameMatch || isPosNumberMatch) && isRepIdMatch;
      })
    );
  }, [data, search, searchRep]);

  const itemsPerPage = 30;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = posData.slice(indexOfFirstItem, indexOfLastItem);

  const totalItems = posData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" color={"purple"}>
            احصائيات مبياع نقاط البيع
          </Typography>
        }
        subheader={
          <Stack direction="column" spacing={1}>
            <Typography variant="caption">
              {dayjs().subtract(1, "week").format("YYYY-MM-DD") +
                " - " +
                dayjs().format("YYYY-MM-DD")}
            </Typography>
            <Stack alignItems="start" direction={"row"} spacing={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    label="بحث عن الاسم او رقمها "
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <SelectRepresentative
                    onChange={(event, value) => {
                      setSearchRep(value ? value.id : null);
                    }}
                    value={
                      user.data?.filter((x) => x.id === searchRep)[0] ?? null
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setSearch("");
                      setSearchRep(null);
                    }}
                  >
                    <MdOutlineClear />
                  </IconButton>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        }
      ></CardHeader>
      <CardContent>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          {data.length !== 0
            ? currentItems.map((x, index) => (
              <Grid item xs={6} key={index}>
                <Card>
                  <Stack justifyContent="center" spacing={2}>
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="center"
                      sx={{ fontSize: 18 }}
                    >
                      <Link
                        onClick={() => {
                          localStorage.setItem("isWholeSale", "0");
                        }}
                        href={`/app/wallets/transactions/${x.user_id}`}
                        target="_blank"
                        underline="always"
                      >
                        {x.pos_name}
                      </Link>
                      <span>/</span>
                      <Link
                        onClick={() => {
                          localStorage.setItem("isWholeSale", "0");
                        }}
                        href={`/app/wallets/transactions/${x.user_id}`}
                        target="_blank"
                        underline="always"
                      >
                        <span style={{ color: "#206F6E" }}>
                          {x.pos_number}
                        </span>
                      </Link>
                    </Stack>
                    <Typography variant="h4">
                      الرصيد: {x.pos_balance.toLocaleString()} / الديون:{" "}
                      {x.pos_debt.toLocaleString()}
                    </Typography>
                  </Stack>
                  <Divider sx={{ m: 2 }} />
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                      style={{ direction: "ltr" }}
                      width={500}
                      height={300}
                      data={x.result?.map((x) => {
                        return {
                          ...x,
                          total_order_amount: parseInt(x.total_order_amount),
                        };
                      })}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="order_date" />
                      <YAxis
                        tickFormatter={(value) => value.toLocaleString()}
                      />
                      <Tooltip />
                      <Legend />
                      <Bar
                        type="monotone"
                        dataKey="total_order_amount"
                        name="المبيعات"
                        fill="#8884d8"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Card>
              </Grid>
            ))
            : null}
          <Grid item xs={12}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PosSalesPerWeekChart;
