import {
  Typography,
  Dialog,
  DialogContent,
  Slide,
  Grid,
  Button,
} from "@mui/material";
import { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Operation,
  setChangeSupplierDialog,
} from "../../../../../app/slices/operationSlice";
import { Controller, useForm } from "react-hook-form";
import { SelectSupplier } from "../../../../../components/Selectors/SelectSupplier";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChangeSupplierForm = (props) => {
  const dispatch = useDispatch();
  const _ChangeSupplier = useSelector(
    (state) => state.operation.changeSupplier
  );
  const _SearchBody = useSelector((state) => state.operation.searchBody);
  const _Supplier = useSelector((state) => state.supplier.suppliersList.data);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {},
  });

  const onSubmit = (data) => {
    data.operation_id = _ChangeSupplier.form?.operation_id;
    dispatch(Operation.changeSupplier(data, _SearchBody));
  };

  const handleDialog = () => {
    dispatch(setChangeSupplierDialog(null));
  };

  return (
    <Dialog
      open={_ChangeSupplier.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={handleDialog}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"تغيير المورد"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="supplier_id"
                control={control}
                render={({ field }) => (
                  <SelectSupplier
                    required
                    onChange={(e, newValue) => {
                      setValue("supplier_id", newValue ? newValue.id : null);
                    }}
                    value={
                      _Supplier.filter((x) => x.id === field.value)[0] ?? null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeSupplierForm;
