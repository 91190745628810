import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const posDiscountApi = factory.get("posDiscount");
const initialState = {
  searchBody: {},
  pos_id: null,
  item_id: null,
  posDiscount: {
    loading: false,
    dialog: false,
    form: {
      id: null,
      pos_id: null,
      item_id: null,
      price: "",
    },
  },
  itemPosDicount: {
    loading: false,
    dialog: false,
    form: {
      id: null,
      pos_id: null,
      item_id: null,
      price: "",
    },
  },
  posDiscounts: {
    data: [],
    total: 0,
  },
};

export const posDiscountSlice = createSlice({
  name: "posDiscount",
  initialState,
  reducers: {
    setDialog: (state, { payload }) => {
      state.posDiscount.dialog = !state.posDiscount.dialog;
      state.pos_id = payload;
    },
    setItemPosDiscountDialog: (state, { payload }) => {
      state.itemPosDicount.dialog = !state.itemPosDicount.dialog;
      state.item_id = payload;
    },
    setDataTable: (state, { payload }) => {
      state.posDiscounts.data = payload.data.data;
      state.posDiscounts.total = payload.data.total;
    },
    setData: (state, { payload }) => {
      state.posDiscounts.data = payload.data.data;
    },
    setById: (state, { payload }) => {
      state.posDiscount.form.id = payload.id;
      state.posDiscount.form.pos_id = payload.pos_id;
      state.posDiscount.form.item_id = payload.item_id;
      state.posDiscount.form.price = parseInt(payload.price).toLocaleString();
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.posDiscount.form = initialState.posDiscount.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setItemPosDiscountDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDataList,
} = posDiscountSlice.actions;

export default posDiscountSlice.reducer;

//axios
const getAll = (params, handleDialog) => async (dispatch) => {
  try {
    dispatch(setLoading("posDiscount"));
    const res = await posDiscountApi.getAll(params);
    dispatch(setDataTable(res));
    if (handleDialog) dispatch(setDialog(params));
    dispatch(setLoading("posDiscount"));
  } catch (err) {
    dispatch(setLoading("posDiscount"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllItemPosDiscount = (item_id, handleDialog) => async (dispatch) => {
  try {
    dispatch(setLoading("posDiscount"));
    const res = await posDiscountApi.getAll("", item_id);
    dispatch(setDataTable(res));
    if (handleDialog) dispatch(setItemPosDiscountDialog(item_id));
    dispatch(setLoading("posDiscount"));
  } catch (err) {
    dispatch(setLoading("posDiscount"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const deleteById = (id, pos_id, handleDialog) => async (dispatch) => {
  try {
    await posDiscountApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(pos_id, handleDialog));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteItemPosDiscount =
  (id, item_id, handleDialog) => async (dispatch) => {
    try {
      await posDiscountApi.delete(id);
      dispatch(
        showNotification({
          message: "تم الحذف بنجاح",
          type: "success",
        })
      );
      dispatch(getAllItemPosDiscount(item_id, handleDialog));
    } catch (err) {
      dispatch(
        showNotification({
          message: err.response.data.errors[0],
          type: "error",
        })
      );
      throw new Error(err);
    }
  };
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("posDiscount"));
    const res = await posDiscountApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setLoading("posDiscount"));
  } catch (err) {
    dispatch(setLoading("posDiscount"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, handleDialog) => async (dispatch) => {
  try {
    dispatch(setLoading("posDiscount"));
    await posDiscountApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("posDiscount"));
    dispatch(resetForm());
    dispatch(getAll(data.pos_id, handleDialog));
  } catch (err) {
    dispatch(setLoading("posDiscount"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, pos_id, handleDialog) => async (dispatch) => {
  try {
    dispatch(setLoading("posDiscount"));
    await posDiscountApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    dispatch(resetForm());
    dispatch(setLoading("posDiscount"));
    dispatch(getAll(pos_id, handleDialog));
  } catch (err) {
    dispatch(setLoading("posDiscount"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const updateItemPosDiscount =
  (id, data, item_id, handleDialog) => async (dispatch) => {
    try {
      dispatch(setLoading("posDiscount"));
      await posDiscountApi.update(id, data);
      dispatch(
        showNotification({
          message: "تم التعديل بنجاح",
          type: "success",
        })
      );
      dispatch(resetForm());
      dispatch(setLoading("posDiscount"));
      dispatch(getAllItemPosDiscount(item_id, handleDialog));
    } catch (err) {
      dispatch(setLoading("posDiscount"));
      dispatch(
        showNotification({
          message: err.response.data.errors[0],
          type: "error",
        })
      );
      throw new Error(err);
    }
  };

export const PosDiscount = {
  getAll,
  getAllItemPosDiscount,
  updateItemPosDiscount,
  deleteById,
  deleteItemPosDiscount,
  getById,
  create,
  update,
};
