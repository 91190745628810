import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const orderRechargeCardsApi = factory.get("orderRechargeCards");
const initialState = {
  searchBody: {},
  reload: false,
  orderRechargeCards: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
  orderRechargeCard: {
    loading: false,
    dialog: false,
    form: {
      ids: [],
      order_recharge_number: "",
    },
  },
  updateCreditCardBalance: {
    loading: false,
    dialog: false,
    data: [],
    form: {
      amount: "",
      credit_card_id: 1,
      supplier_id: null,
    },
    transactions: {
      searchBody: {
        start_date: null,
        end_date: null,
        take: 15,
        skip: 0,
      },
      dialog: false,
      data: [],
      total: 0,
    },
  },
};

export const orderRechargeCardsSlice = createSlice({
  name: "orderRechargeCards",
  initialState,
  reducers: {
    setDataTable: (state, { payload }) => {
      state.orderRechargeCards.data = payload.data.data;
      state.orderRechargeCards.total = payload.data.total;
      state.orderRechargeCards.total_amount = payload.data.total_amount;
    },
    setUpdateDialog: (state, action) => {
      state.orderRechargeCard.dialog = !state.orderRechargeCard.dialog;
    },
    resetUpdateForm: (state, action) => {
      state.orderRechargeCard.form = initialState.orderRechargeCard.form;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },

    // =============== Update Credit Card Balance ===================
    setCreditCardDataTable: (state, { payload }) => {
      state.updateCreditCardBalance.data = payload.data.data;
    },
    setUpdateCreditCardBalanceDialog: (state, action) => {
      state.updateCreditCardBalance.dialog =
        !state.updateCreditCardBalance.dialog;
    },
    resetUpdateCreditCardBalanceForm: (state, action) => {
      state.updateCreditCardBalance.form =
        initialState.updateCreditCardBalance.form;
    },
    setUpdateCreditCardTransactionsDialog: (state, action) => {
      state.updateCreditCardBalance.transactions.dialog =
        !state.updateCreditCardBalance.transactions.dialog;
    },
    setCreditCardTransactionsDataTable: (state, { payload }) => {
      state.updateCreditCardBalance.transactions.data = payload.data.data;
      state.updateCreditCardBalance.transactions.total = payload.data.total;
    },
    setCreditCardTransactionsSearchBody: (state, { payload }) => {
      state.updateCreditCardBalance.transactions.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDataTable,
  setSearchBody,
  setUpdateDialog,
  resetUpdateForm,
  setUpdateCreditCardBalanceDialog,
  resetUpdateCreditCardBalanceForm,
  setCreditCardDataTable,
  setCreditCardTransactionsDataTable,
  setUpdateCreditCardTransactionsDialog,
  setCreditCardTransactionsSearchBody,
} = orderRechargeCardsSlice.actions;

export default orderRechargeCardsSlice.reducer;

const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("orderRechargeCards"));
    const res = await orderRechargeCardsApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("orderRechargeCards"));
  } catch (err) {
    dispatch(setLoading("orderRechargeCards"));
    dispatch(
      showNotification({
        message: err.response.data?.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const updateNumber = (data, _SearchBody) => async (dispatch) => {
  try {
    await orderRechargeCardsApi.update(data);
    dispatch(
      showNotification({
        message: "تم تعديل بنجاح",
        type: "success",
      })
    );
    dispatch(setUpdateDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const exportExcel = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("orderRechargeCards"));
    const res = await orderRechargeCardsApi.export({
      ...params,
      is_excel: 1,
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = `order_recharge_cards.xlsx`;
    a.click();
    dispatch(setLoading("orderRechargeCards"));
  } catch (err) {
    dispatch(setLoading("orderRechargeCards"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("orderRechargeCards"));
    await orderRechargeCardsApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll());
    dispatch(setLoading("orderRechargeCards"));
  } catch (err) {
    dispatch(setLoading("orderRechargeCards"));
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const exportFile = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("orderRechargeCards"));
    const res = await orderRechargeCardsApi.postExport(params);
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = `order_recharge_cards.csv`;
    a.click();
    dispatch(setLoading("orderRechargeCards"));
    dispatch(getAll(params));
  } catch (err) {
    dispatch(setLoading("orderRechargeCards"));
    dispatch(
      showNotification({
        message: "حدثت مشكلة اثناء التصدير",
        type: "error",
      })
    );
    throw new Error(err);
  }
};
// =============== credit-card ===================
const getAllCreditCard = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("updateCreditCardBalance"));
    const res = await orderRechargeCardsApi.getCreditCards(params);
    dispatch(setCreditCardDataTable(res));
    dispatch(setLoading("updateCreditCardBalance"));
  } catch (err) {
    dispatch(setLoading("updateCreditCardBalance"));
    dispatch(
      showNotification({
        message: err.response.data?.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const updateCreditCardBalance = (data, _SearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("updateCreditCardBalance"));
    await orderRechargeCardsApi.updateCreditCardBalance(data);
    dispatch(
      showNotification({
        message: "تم التحديث بنجاح",
        type: "success",
      })
    );
    dispatch(setUpdateCreditCardBalanceDialog());
    dispatch(getAllCreditCard());
    dispatch(setLoading("updateCreditCardBalance"));
    // dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(setLoading("updateCreditCardBalance"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllCreditCardTransactions = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("updateCreditCardBalance"));
    const res = await orderRechargeCardsApi.getAllCreditCardTransactions(
      params
    );
    dispatch(setCreditCardTransactionsDataTable(res));
    dispatch(setLoading("updateCreditCardBalance"));
  } catch (err) {
    dispatch(setLoading("updateCreditCardBalance"));
    dispatch(
      showNotification({
        message: err.response.data?.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
export const OrderRechargeCards = {
  getAll,
  deleteById,
  exportExcel,
  exportFile,
  updateNumber,
  updateCreditCardBalance,
  getAllCreditCard,
  getAllCreditCardTransactions,
};
