import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  InputAdornment,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  ExternalRevenue,
} from "../../../app/slices/externalRevenueSlice";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ExternalRevenueForm = () => {
  const _ExternalRevenue = useSelector((state) => state.externalRevenue.externalRevenue);
  const _SearchBody = useSelector((state) => state.externalRevenue.searchBody);

  const { control, reset, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: _ExternalRevenue.form,
  });

  const onSubmit = (data) => {
    dispatch(ExternalRevenue.create({
      amount: data.amount,
      note: data.note,
    }, _SearchBody));
  };

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_ExternalRevenue.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"أضافة "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name='amount'
                control={control}
                render={({ field: { onChange, ...renderProps } }) => {
                  return (
                    <NumericFormat
                      customInput={TextField}
                      label='المبلغ'
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                      }}
                      {...renderProps}
                      onValueChange={target => {
                        onChange();
                        setValue('amount', target.floatValue);
                      }}
                      isNumericString
                      thousandSeparator
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الملاحظة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ExternalRevenueForm;
