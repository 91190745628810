import { Grid } from "@mui/material";
import React from "react";
import JItemsTable from "./home/table";
import JItemsHeader from "./home/header";

const JItems = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <JItemsHeader />
      </Grid>
      <Grid item xs={12}>
        <JItemsTable />
      </Grid>
    </Grid>
  );
};

export default JItems;
