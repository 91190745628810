import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const epaymentTransactionsApi = factory.get("epaymentTransactions");
const initialState = {
  searchBody: {},
  epaymentTransactions: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
};

export const epaymentTransactionsSlice = createSlice({
  name: "epaymentTransactions",
  initialState,
  reducers: {
    setDataTable: (state, { payload }) => {
      state.epaymentTransactions.data = payload.data.data;
      state.epaymentTransactions.total = payload.data.total;
      state.epaymentTransactions.total_amount = payload.data.total_amount;
    },

    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },

    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const { setLoading, setDataTable, setSearchBody } =
  epaymentTransactionsSlice.actions;

export default epaymentTransactionsSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("epaymentTransactions"));
    const res = await epaymentTransactionsApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("epaymentTransactions"));
  } catch (err) {
    dispatch(setLoading("epaymentTransactions"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
export const EpaymentTransactions = {
  getAll,
};
