import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Box,
  Switch,
  FormControlLabel,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  Categories,
} from "../../../../app/slices/categorySlice";
import { Controller, useForm } from "react-hook-form";

import FilePondInput from "../../../../helpers/FilePondInput";
import { SelectProvince } from "../../../../components/Selectors/SelectProvince";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CategoriesForm = () => {
  const _Categories = useSelector((state) => state.categories.category);
  const _SearchBody = useSelector((state) => state.categories.searchBody);
  const _provinces = useSelector((state) => state.provinces.provinces.data);

  const isAdd = _Categories.form.id ? false : true;
  const { control, reset, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: _Categories.form,
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("id", data.id);
    formData.append("is_product", data.is_product ? 1 : 0);
    formData.append("is_transfer", data.is_transfer ? 1 : 0);
    formData.append("province_ids", JSON.stringify(data.province_ids) );
    if (!isAdd) formData.append("_method", "PUT");
    formData.append("name", data.name);
    if (data.logo && data.logo[0].name) formData.append("logo", data.logo[0]);
    if (isAdd) {
      dispatch(Categories.create(formData));
    } else {
      dispatch(Categories.update(data.id, formData, _SearchBody));
    }
  };
  useEffect(() => {
    reset({ ..._Categories.form });
  }, [_Categories.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  const dispatch = useDispatch();

  const handlDialog = () => {
    dispatch(setDialog());
  };

  return (
    // <Typography>form test</Typography>
    <Dialog
      open={_Categories.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handlDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label=" الاسم"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="province_ids"
                control={control}
                render={({ field }) => (
                  <SelectProvince
                    multiple
                    onChange={(e, newValue) => {
                      var ids = newValue.map((x) => x.id);
                      setValue("province_ids", newValue ? ids : []);
                    }}
                    value={
                      _provinces?.filter((x) =>
                        field.value?.includes(x.id) ? field.value : null
                      )
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="is_transfer"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          setValue("is_transfer", e.target.checked);
                        }}
                      />
                    }
                    label="تحويل الرصيد !"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="is_product"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          setValue("is_product", e.target.checked);
                        }}
                      />
                    }
                    label="المنصة !"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="logo"
                control={control}
                render={({ field }) => (
                  <Box>
                    <FilePondInput field={field} required={false} />
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CategoriesForm;
