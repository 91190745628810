import dayjs from "dayjs";
import {
  createSlice
} from "@reduxjs/toolkit";
import {
  showNotification
} from "./notificationSlice";
import {
  factory
} from "../../api/apiFactory";
const ProductSupplierApi = factory.get("productSupplier");
const initialState = {
  searchBody: {},
  productSupplier: {
    loading: false,
    dialog: false,
    form: {
      id: "",
      name: "",
    },
  },
  productsSupplier: {
    data: [],
    total: 0,
  },
  initialBalance: {
    dialog: false,
    supplier_product_id: null,
    form: {
      balance: "",
    },
  },
  transactions: {
    dialog: false,
    product_supplier_id: null,
    form: {
      supplier_id: null,
      amount: "",
      wage_amount: "",
      wage_amount_percent: "",
      note: "",
    },
    data: [],
    total: 0,
    total_amount: 0,
    total_pay_cash: 0,
    total_wage: 0,
    total_amount: 0,
    searchBody: {}
  },
  getById: []
}

export const supplierProductsSlice = createSlice({
  name: "productSupplier",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.productSupplier.dialog = !state.productSupplier.dialog
    },
    setTransactionsDialog: (state, action) => {
      state.transactions.dialog = !state.transactions.dialog
    },
    setTransactionsDataTable: (state, {
      payload
    }) => {
      state.transactions.data = payload.data.data.data
      state.transactions.total = payload.data.total
      state.transactions.total_amount = payload.data.total_amount
      state.transactions.total_pay_cash = payload.data.data.total_pay_cash
      state.transactions.total_wage = payload.data.data.total_wage
    },
    setTransactionsSearchBody: (state, {
      payload
    }) => {
      state.transactions.searchBody = payload;
    },
    setDataTable: (state, {
      payload
    }) => {
      state.productsSupplier.data = payload.data.data
      state.productsSupplier.total = payload.data.total
    },
    setDataList: (state, {
      payload
    }) => {
      state.subCategoriesList.data = payload.data.data
    },
    setById: (state, {
      payload
    }) => {
      state.productSupplier.form.id = payload.id
      state.productSupplier.form.name = payload.name
    },
    setByIdData: (state, {
      payload
    }) => {
      state.productSupplier.getById = payload
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetForm: (state, action) => {
      state.productSupplier.form = initialState.productSupplier.form
    },
    setSearchBody: (state, {
      payload
    }) => {
      state.searchBody = payload;
    },
    setInitialBalanceDialog: (state, {
      payload
    }) => {
      state.initialBalance.dialog = !state.initialBalance.dialog
      state.initialBalance.supplier_product_id = payload
    },
  }
})

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDataList,
  setInitialBalanceDialog,
  setTransactionsDialog,
  setTransactionsDataTable,
  setTransactionsSearchBody,
  setByIdData
} = supplierProductsSlice.actions

export default supplierProductsSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('productSupplier'));
    const res = await ProductSupplierApi.getAll(params)
    dispatch(setDataTable(res));
    dispatch(setLoading('productSupplier'));

  } catch (err) {
    dispatch(setLoading('productSupplier'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('productSupplier'));
    const res = await ProductSupplierApi.getAllList(params)
    dispatch(setDataList(res));
    dispatch(setLoading('productSupplier'));
  } catch (err) {
    dispatch(setLoading('productSupplier'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await ProductSupplierApi.delete(id)
    dispatch(showNotification({
      message: "تم الحذف بنجاح",
      type: "success"
    }));
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading('productSupplier'));
    const res = await ProductSupplierApi.getById(id)
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog())
    dispatch(setLoading('productSupplier'));
  } catch (err) {
    dispatch(setLoading('productSupplier'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getByIdData = (id) => async (dispatch) => {
  try {
    dispatch(setLoading('productSupplier'));
    const res = await ProductSupplierApi.getById(id)
    dispatch(setByIdData(res.data.data[0]));
    dispatch(setLoading('productSupplier'));
  } catch (err) {
    dispatch(setLoading('productSupplier'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await ProductSupplierApi.create(data)
    dispatch(showNotification({
      message: "تمت الاضافة  بنجاح",
      type: "success"
    }));
    dispatch(setDialog())
    dispatch(getAll())
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await ProductSupplierApi.update(id, data)
    dispatch(showNotification({
      message: "تم التعديل بنجاح",
      type: "success"
    }));
    dispatch(setDialog())
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const initialBalance = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await ProductSupplierApi.initial(id, data)
    dispatch(showNotification({
      message: "تم التعديل بنجاح",
      type: "success"
    }));
    dispatch(setInitialBalanceDialog(null))
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const transactions = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('productsOrders'));
    const res = await ProductSupplierApi.transactions(params)
    dispatch(setTransactionsDataTable(res));
    dispatch(setLoading('productsOrders'));
  } catch (err) {
    dispatch(setLoading('productsOrders'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const payCash = (data, params) => async (dispatch) => {
  try {
    await ProductSupplierApi.payCash(data)
    dispatch(showNotification({
      message: "تمت الاضافة  بنجاح",
      type: "success"
    }));
    dispatch(setTransactionsDialog(null))
    dispatch(transactions(params))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};

export const ProductSupplier = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  getAllList,
  initialBalance,
  transactions,
  payCash,
  getByIdData
}