import Typography from "@mui/material/Typography";
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  Slide,
  Switch,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";
import { forwardRef } from "react";
import {
  Wallet,
  setCorrectBalanceDialog,
} from "../../../../../app/slices/walletSlice";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CorrectBalanceForm = (props) => {
  const dispatch = useDispatch();
  const _Wallet = useSelector((state) => state.wallet);
  const transactionsSearchBody = useSelector(
    (state) => state.wallet.transactionsSearchBody
  );
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {},
  });

  const onSubmit = (data) => {
    data.user_id = props.userData.id;
    dispatch(Wallet.correctBalance(data, transactionsSearchBody)).then((x) => {
      dispatch(setCorrectBalanceDialog());
    });
  };

  const handleDialog = () => {
    dispatch(setCorrectBalanceDialog());
  };

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });

  return (
    <Dialog
      open={_Wallet.correctBalanceDialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={handleDialog}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"تعديل العملية"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="المبلغ"
                    fullWidth
                    {...field}
                    sx={{ marginBottom: 1 }}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="ملاحظات"
                    fullWidth
                    multiline
                    rows={4}
                    {...field}
                    sx={{ marginBottom: 1 }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="is_deposit"
                control={control}
                defaultValue={true}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) =>
                          setValue("is_deposit", e.target.checked)
                        }
                      />
                    }
                    label={field.value ? "اضافة للرصيد" : "سحب من الرصيد"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CorrectBalanceForm;
