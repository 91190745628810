import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import { MdOutlineClear } from "react-icons/md";
import {
  JSupplier,
  setSearchBody,
} from "../../../../../app/slices/jomla/supplierSlice";
import { SelectJSubCategory } from "../../../../../components/Selectors/jomla/SelectJSubCategory";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { AiOutlineTransaction } from "react-icons/ai";

const JSupplierTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const _JSupplier = useSelector((state) => state.jSupplier.jSuppliers);
  const _SearchBody = useSelector((state) => state.jSupplier.searchBody);
  const _JSubCategory = useSelector(
    (state) => state.JSubCategories.JSubCategoriesList.data
  );

  const [search, setSearch] = useState({
    name: "",
    j_sub_category_id: null,
    group_id: null,
    province_id: null,
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          القيمة الكلية : {_JSupplier.total_amount.toLocaleString()} (IQD)
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(JSupplier.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "balance",
      headerName: "الرصيد (IQD)",
      flex: 1,
      renderCell: (params) => params.row.balance?.toLocaleString(),
    },
    {
      field: "created_at",
      headerName: "تاريخ الانشاء",
      flex: 1,
      renderCell: (params) => dayjs(params.row.created_at).format("YYYY-MM-DD"),
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="المناقلات">
              <IconButton
                onClick={() =>
                  navigate(
                    "/app/wholesale/supplier_transactions/" + params.row.id
                  )
                }
              >
                <AiOutlineTransaction />
              </IconButton>
            </Tooltip>
            <IconButton
              onClick={() => dispatch(JSupplier.getById(params.row.id, true))}
            >
              <BiEdit />
            </IconButton>
            <IconButton onClick={() => handleDeleteClick(params.row)}>
              <BiTrash />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(JSupplier.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        search: search.name,
        j_sub_category_id: search.j_sub_category_id ?? "",
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize, search]);

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={search.name}
              onChange={(e) => {
                setSearch({
                  ...search,
                  name: e.target.value,
                });
              }}
              label="بحث عن الاسم "
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <SelectJSubCategory
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  j_sub_category_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _JSubCategory.filter(
                  (x) => x.id === search.j_j_sub_category_id
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={2.5}>
            <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      name: "",
                      j_sub_category_id: null,
                    });
                    setPageSize(15);
                    setCurrentPage(0);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "63vh", width: "100%" }}>
              {_JSupplier.data ? (
                <DataGrid
                  rows={_JSupplier.data.map((x, i) => {
                    return {
                      ...x,
                      index: i + 1 + pageSize * currentPage,
                    };
                  })}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_JSupplier.total}
                  loading={_JSupplier.loading}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                    Footer: CustomFooter,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default JSupplierTable;
