import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Divider,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  Pins,
} from "../../../app/slices/pinsSlice";
import { Controller, useForm } from "react-hook-form";
import { SelectItem } from "../../../components/Selectors/SelectItems";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { SelectSupplier } from "../../../components/Selectors/SelectSupplier";
import { showNotification } from "../../../app/slices/notificationSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PinsForm = () => {
  const _Pins = useSelector((state) => state.pins.pin);
  const _SearchBody = useSelector((state) => state.pins.searchBody);

  const isAdd = _Pins.form.id ? false : true;
  const { control, reset, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: _Pins.form,
  });


  const onSubmit = (data) => {
    if (data.pin_number.length < watch().item_id?.digit_length || data.pin_number.length > watch().item_id?.digit_length) {
      dispatch(showNotification({
        message: `الرقم السري يجب ان يكون ${watch().item_id?.digit_length} رقماً`,
        type: "error"
      }));
    } else {
      if (isAdd) {
        dispatch(Pins.create({
          serial_number: data.serial_number,
          pin_number: data.pin_number,
          expiry_date: data.expiry_date,
          buying_price: data.buying_price,
          item_id: data.item_id.id,
          supplier_id: data.supplier_id.id,
          cash_paid: data.cash_paid,
        }));
      } else {
        dispatch(Pins.update(data.id, {
          serial_number: data.serial_number,
          pin_number: data.pin_number,
          expiry_date: data.expiry_date,
          buying_price: data.buying_price,
          item_id: data.item_id.id,
          status: data.status,
          supplier_id: data.supplier_id.id,
          cash_paid: data.cash_paid,
        }, _SearchBody));
      }
    }
  };
  useEffect(() => {
    reset({ ..._Pins.form });
  }, [_Pins.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  const dispatch = useDispatch();

  const handlDialog = () => {
    dispatch(setDialog());
  };

  const status = [
    { key: 'new', name: 'جديد' },
    { key: 'sold', name: 'مباع' },
    { key: 'disabled', name: 'معطل' },
  ]

  return (
    <Dialog
      open={_Pins.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth='sm'
      onClose={() => {
        handlDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="item_id"
                control={control}
                render={({ field }) => (
                  <SelectItem
                    required
                    value={field.value}
                    onChange={(e, newValue) => {
                      setValue("item_id", newValue);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="supplier_id"
                control={control}
                render={({ field }) => (
                  <SelectSupplier
                    required
                    value={field.value}
                    onChange={(e, newValue) => {
                      setValue("supplier_id", newValue);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="serial_number"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الرقم التسلسلي"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="pin_number"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الرقم السري"
                    fullWidth
                    helperText={watch().pin_number?.length < watch().item_id?.digit_length ||
                      watch().pin_number?.length > watch().item_id?.digit_length ?
                      `الرقم السري يجب ان يكون ${watch().item_id?.digit_length} رقم`
                      : null}
                    error={watch().pin_number?.length < watch().item_id?.digit_length ||
                      watch().pin_number?.length > watch().item_id?.digit_length}
                    {...field}
                  />
                )}
              />
            </Grid>
            {isAdd && <Grid item xs={12} md={12}>
              <Controller
                name="cash_paid"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="المبلغ المدفوع"
                    fullWidth
                    {...field}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                    }}
                  />
                )}
              />
            </Grid>}
            <Grid item xs={12} md={12}>
              <Controller
                name="buying_price"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="سعر الشراء للكارت"
                    fullWidth
                    {...field}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="expiry_date"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="YYYY-MM-DD"
                      label="تاريخ النفاذ"
                      value={field.value}
                      onChange={(e, newValue) => {
                        setValue("expiry_date", dayjs(e).format("YYYY-MM-DD"));
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          fullWidth
                          {...params}
                          inputProps={{ ...params.inputProps, placeholder: "يوم/شهر/سنة" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            {isAdd ? null : (
              <Grid item xs={12} md={12}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      options={status}
                      getOptionLabel={(option) => option.name || ""}
                      value={status.filter(x => x.key == field.value)[0]}
                      onChange={(e, newValue) => {
                        setValue("status", newValue ? newValue.key : null);
                      }}
                      isOptionEqualToValue={(option, value) => option == value}
                      renderInput={(params) => (
                        <TextField

                          {...params}
                          label="الحالة"
                          size="small"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PinsForm;