import { Card, CardHeader, Typography, Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../../app/slices/posType";
import PosTypeForm from "../../form";
import { BiCategoryAlt } from "react-icons/bi";

const PosTypeHeader = () => {
  const dispatch = useDispatch();
  const _PosType = useSelector((state) => state.posType);

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            الفئات
          </Typography>
        }
        avatar={<BiCategoryAlt size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            إضافة فئة
          </Button>
        }
      />
      {_PosType.posType.dialog ? <PosTypeForm /> : null}
    </Card>
  );
};

export default PosTypeHeader;
