import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  InputAdornment,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  Expense,
} from "../../../app/slices/expenseSlice";
import { Controller, useForm } from "react-hook-form";
import { SelectExpenseTypes } from "../../../components/Selectors/SelectExpensType";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ExpenseForm = () => {
  const _Expense = useSelector((state) => state.expense.expense);
  const _ExpenseTypes = useSelector((state) => state.expenseTypes.expenseTypesList.data);
  const _SearchBody = useSelector((state) => state.expense.searchBody);

  const isAdd = _Expense.form.id ? false : true;
  const { control, reset, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: _Expense.form,
  });

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(Expense.create(data, _SearchBody));
    } else {
      dispatch(Expense.update(data.id, data, _SearchBody));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  const dispatch = useDispatch();

  const handlDialog = () => {
    dispatch(setDialog());
  };

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref,
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });


  return (
    <Dialog
      open={_Expense.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handlDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {_ExpenseTypes && (
                <Controller
                  name="spending_type_id"
                  control={control}
                  render={({ field }) => (
                    <SelectExpenseTypes
                      required
                      onChange={(e, newValue) => {
                        setValue("spending_type_id", newValue.id);
                      }}
                      value={_ExpenseTypes.filter((x) => x.id == field.value)[0] ?
                        _ExpenseTypes.filter((x) => x.id == field.value)[0] :
                        field.value
                      }
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={!isAdd}
                    required
                    size="small"
                    label="المبلغ"
                    fullWidth
                    {...field}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="YYYY-MM-DD"
                      label="التاريخ"
                      value={field.value}
                      onChange={(e, newValue) => {
                        setValue("date", dayjs(e).format("YYYY-MM-DD"));
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          fullWidth
                          {...params}
                          inputProps={{ ...params.inputProps, placeholder: "يوم/شهر/سنة" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الملاحظة"
                    multiline
                    rows={3}
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ExpenseForm;
