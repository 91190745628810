import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { forwardRef, useEffect, useState } from "react";
import { Provinces, setById, setDialog } from "../../../../../../app/slices/provincesSlice";
import { useDispatch, useSelector } from "react-redux";

const CityTable = () => {
  const dispatch = useDispatch();

  const _Cities = useSelector((state) => state.provinces.cities);
  const loading = useSelector((state) => state.provinces.loading);
  const _Provinces = useSelector((state) => state.provinces);

  const [searchCity, setSearchCity] = useState('');
  const [CityData, setCityData] = useState(_Cities.data);

  useEffect(() => {
    if (searchCity == "") {
      setCityData(_Cities.data)
    } else {
      setCityData(
        _Cities.data?.filter(province => province.name.toLowerCase().startsWith(searchCity.toLowerCase()) || province.name.startsWith(searchCity))
      )
    }
  }, [searchCity, _Cities])

  const columns = [
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              variant="contained"
              size="small"
              style={{ marginLeft: 10 }}
              onClick={() => {
                dispatch(setById({
                  id: params.row.id,
                  name: params.row.name,
                }));
                dispatch(setDialog());
              }}
            >
              <BiEdit />
            </IconButton>
            <IconButton
              variant="contained"
              size="small"
              style={{ marginLeft: 10 }}
              onClick={() => dispatch(Provinces.deleteById(params.row.id))}
            >
              <BiTrash />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={4}>
            <TextField
              size="small"
              onChange={(e) => {
                setSearchCity(e.target.value)
              }}
              value={searchCity}
              label="بحث عن مدينة"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={CityData}
                columns={columns}
                loading={loading}
                rowHeight={45}
                pagination
                paginationMode="server"
                selectionModel={[]}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CityTable;
