import Typography from "@mui/material/Typography";
import {
  Grid,
  Dialog,
  DialogContent,
  Slide,
  Stack,
  Divider,
  Box,
  Chip,
} from "@mui/material";
import { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProductsOrdersDetailsDialog } from "../../../../../app/slices/productsOrdersSlice";
import dayjs from "dayjs";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProductsOrdersDetails = () => {
  const dispatch = useDispatch();
  const _ProductsOrderDetails = useSelector(
    (state) => state.productsOrders.productsOrdersDetails
  );

  const handleDialog = () => {
    dispatch(setProductsOrdersDetailsDialog({}));
  };

  return (
    <Dialog
      open={_ProductsOrderDetails.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={handleDialog}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <DialogContent>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            mb={3}
            variant="h4"
            color={"purple"}
            gutterBottom
            component="div"
          >
            {"تفاصيل الطلب"}
          </Typography>
          <Chip
            size="small"
            label={_ProductsOrderDetails.data?.status}
            color={
              _ProductsOrderDetails.data?.status == "تم التسديد"
                ? "success"
                : _ProductsOrderDetails.data?.status == "رفض"
                ? "error"
                : _ProductsOrderDetails.data?.status == "اقساط يومية"
                ? "info"
                : "warning"
            }
          />
        </Stack>
        <Divider sx={{ marginBottom: 3 }} />
        <Grid container spacing={2}>
          <Grid item md={4} display="flex" alignItems="center">
            <Stack direction="row" spacing={1}>
              <Typography>اسم المنتج:</Typography>
              <Typography variant="h4">
                {_ProductsOrderDetails.data?.product_name}
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4} display="flex" alignItems="center">
            <Stack direction="row" spacing={1}>
              <Typography>العدد:</Typography>
              <Typography variant="h4">
                {_ProductsOrderDetails.data?.count}
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <Typography>اسم المجهز:</Typography>
              <Typography variant="h4">
                {_ProductsOrderDetails.data?.supplier_name}
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <Typography>رقم الطلب:</Typography>
              <Typography variant="h4">
                {_ProductsOrderDetails.data?.id}
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <Typography>نقطة البيع:</Typography>
              <Stack direction="row" spacing={0.5}>
                <Typography variant="h4">
                  {_ProductsOrderDetails.data?.pos_name}
                </Typography>
                <Typography variant="caption">
                  {"(" + _ProductsOrderDetails.data?.pos_number + ")"}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <Typography>تاريخ الطلب:</Typography>
              <Typography variant="h4">
                {dayjs(_ProductsOrderDetails.data?.created_at).format(
                  "YYYY-MM-DD / HH:mm"
                )}
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={12}>
            <Divider />
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <Typography>سعر المنتج الكلي:</Typography>
              <Typography variant="h4">
                {_ProductsOrderDetails.data?.total_amount?.toLocaleString()} IQD
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <Typography>مبلغ التسديد اليومي:</Typography>
              <Typography variant="h4">
                {_ProductsOrderDetails.data?.daily_payment?.toLocaleString()}{" "}
                IQD
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <Typography>المبالغ المسددة:</Typography>
              <Typography variant="h4">
                {_ProductsOrderDetails.data?.total_payments?.toLocaleString()}{" "}
                IQD
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <Typography>المبالغ المتبقية:</Typography>
              <Typography variant="h4">
                {_ProductsOrderDetails.data?.remaining?.toLocaleString()} IQD
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <Typography>تاريخ التحديث:</Typography>
              <Typography variant="h4">
                {dayjs(_ProductsOrderDetails.data?.updated_at).format(
                  "YYYY-MM-DD / HH:mm"
                )}
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <Typography>نسبة الخصم:</Typography>
              <Typography variant="h4">
                {_ProductsOrderDetails.data?.discount?.toLocaleString()} IQD
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={12}>
            <Divider />
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <Typography>سعر الشراء:</Typography>
              <Typography variant="h4">
                {_ProductsOrderDetails.data?.buying_price?.toLocaleString()} IQD
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <Typography>سعر البيع:</Typography>
              <Typography variant="h4">
                {_ProductsOrderDetails.data?.selling_price?.toLocaleString()}{" "}
                IQD
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <Typography>نسبة الشركة:</Typography>
              <Typography variant="h4">
                {_ProductsOrderDetails.data?.revenue?.toLocaleString()} IQD
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={12}>
            <Divider />
          </Grid>
          <Grid item md={12}>
            <Stack direction="row" spacing={1}>
              <Typography> ملاحظات:</Typography>
              <Typography variant="h4">
                {_ProductsOrderDetails.data?.note}
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <Typography>وصف المنتج:</Typography>
              <Typography>
                {_ProductsOrderDetails.data?.product_description}
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <Typography>الصفحة الثانوية:</Typography>
              <Typography variant="h4">
                {_ProductsOrderDetails.data?.product_sub_category_name}
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Box
              sx={{ width: 130 }}
              component="img"
              src={_ProductsOrderDetails.data?.product_logo}
            />
          </Grid>
          <Grid item md={12}>
            <Divider />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ProductsOrdersDetails;
