import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
  Divider,
  LinearProgress,
  Box,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  IssuedCards,
  resetUpdateForm,
  setDialog,
  setDialogForUpdate,
} from "../../../../app/slices/issuedCardSlice";
import FilePondInput from "../../../../helpers/FilePondInput";
import { SelectIssuedCardStatus } from "../../../../components/Selectors/SelectIssuedCardStatus";
import IssuedCardForm from ".";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const IssuedCardUpdateForm = () => {
  const _IssuedCard = useSelector((state) => state.issuedCard.updateForm);
  const _SearchBody = useSelector((state) => state.issuedCard.searchBody);
  const loading = useSelector((state) => state.issuedCard.loading);
  const _IssuedCardStatus = useSelector((state) => state.issuedCard.statusList);
  const _IssuedCardStatusDialog = useSelector((state) => state.issuedCard.issuedCard.dialog);


  const isAdd = _IssuedCard.form.id ? false : true;
  const { control, reset, handleSubmit, setValue, watch } = useForm({
    defaultValues: _IssuedCard.form,
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    // formData.append("id", data.id);
    formData.append("ar_name", data.ar_name);
    formData.append("en_name", data.en_name);
    formData.append("id_no", data.id_no);
    formData.append("mobile", data.mobile);
    formData.append("note", data.note ?? "");
    formData.append("status_id", data.status_id);
    formData.append("_method", "PUT");
    if (data.front_id && data.front_id[0]?.name)
      formData.append("front_id", data.front_id[0]);
    if (data.back_id && data.back_id[0]?.name)
      formData.append("back_id", data.back_id[0]);
    dispatch(IssuedCards.update(data.id, formData, _SearchBody)).then(() => {
      reset();
      dispatch(resetUpdateForm());
    });
  };
  useEffect(() => {
    reset({ ..._IssuedCard.form });
  }, [_IssuedCard.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetUpdateForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setDialogForUpdate());
  };

  return (
    <Dialog
      open={_IssuedCard.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="md"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      {_IssuedCardStatusDialog ? <IssuedCardForm /> : null}
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          {_IssuedCard.loading && <LinearProgress sx={{ marginBottom: 1 }} />}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                name="ar_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الاسم بالعربي"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="en_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الاسم بالانجليزي"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="id_no"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="رقم العملية"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="mobile"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="رقم الهاتف"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="status_id"
                control={control}
                render={({ field }) => (
                  <SelectIssuedCardStatus
                    disabled
                    onChange={(event, value) => {
                      setValue("status_id", value ? value.id : null);
                      if (value.id === 2) {
                        dispatch(setDialog({
                          status_id: value.id,
                          id: _IssuedCard.form.id,
                        }))
                      }
                    }}
                    value={
                      _IssuedCardStatus.filter((x) => x.id === field.value)[0] ??
                      null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="ملاحظات"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                component="img"
                src={watch("attachment")[0]?.path}
                height={200}
                sx={{ objectFit: "contain" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                component="img"
                src={watch("attachment")[1]?.path}
                height={200}
                sx={{ objectFit: "contain" }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              {" "}
              <Controller
                name="front_id"
                control={control}
                render={({ field }) => (
                  <FilePondInput field={field} required={false} />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {" "}
              <Controller
                name="back_id"
                control={control}
                render={({ field }) => (
                  <FilePondInput field={field} required={false} />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading ? true : false}
                endIcon={loading ? <CircularProgress size={25} /> : null}
              >
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default IssuedCardUpdateForm;
