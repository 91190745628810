import Typography from "@mui/material/Typography";
import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Chip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { BiTrash, BiEdit, BiBarcodeReader, BiFileBlank } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Items, setSearchBody } from "../../../../app/slices/itemsSlice";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import { SelectSubCategory } from "../../../../components/Selectors/SelectSubCategory";
import { SelectGroup } from "../../../../components/Selectors/SelectGroup";
import { MdOutlineClear } from "react-icons/md";
import { SelectProvince } from "../../../../components/Selectors/SelectProvince";
import { BsFileExcel } from "react-icons/bs";
import {
  setCsvDialog,
  setExcelDialog,
  setSelectedItem,
} from "../../../../app/slices/pinsSlice";
import { TbDiscount2 } from "react-icons/tb";
import { PosDiscount } from "../../../../app/slices/posDiscountSlice";
import ItemPosDiscountData from "../itemPosDiscount";

const ItemsTabel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const _Items = useSelector((state) => state.items.items);
  const _SearchBody = useSelector((state) => state.items.searchBody);
  const _provinces = useSelector((state) => state.provinces.provinces.data);
  const _PosDiscount = useSelector(
    (state) => state.posDiscount.itemPosDicount.dialog
  );
  const [search, setSearch] = useState({
    name: "",
    sub_category_id: null,
    group_id: null,
    province_id: null,
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [dialogActiveData, setDialogActiveData] = useState({});
  const [openActive, setOpenActive] = useState(false);

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          القيمة الكلية : {_Items.total_amount.toLocaleString()} (IQD)
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(Items.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };
  const handleActiveClick = (params) => {
    setOpenActive(true);
    setDialogActiveData(params);
  };

  const handleConfirmActive = () => {
    dispatch(Items.active(dialogActiveData.id, _SearchBody)).then((x) => { });
    setOpenActive(false);
  };

  const handleCancelActive = () => {
    setOpenActive(false);
  };

  const columns = [
    {
      field: "sequence",
      headerName: "التسلسل",
      flex: 0.5,
    },
    {
      field: "province_name",
      headerName: "المحافظة",
      flex: 1,
    },
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "available_pins",
      headerName: "الكودات المتاحة",
      flex: 1,
    },

    {
      field: "sub_category_name",
      headerName: "الصفحة الثانوية",
      flex: 1,
      // renderCell: (params) => {
      //   return (
      //     <Typography sx={{ lineBreak: 'anywhere' }}>
      //       {params.row.sub_category ? params.row.sub_category.name : ""}
      //     </Typography>
      //   );
      // },
    },
    {
      field: "is_transfer",
      headerName: "تحويل رصيد !",
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.is_transfer ? "نعم" : "كلا"}
            color={params.row.is_transfer ? "success" : "default"}
          ></Chip>
        );
      },
    },
    {
      field: "is_hidden",
      headerName: "اخفاء المنتج !",
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.is_hidden ? "مخفي" : "مرئي"}
            color={params.row.is_hidden ? "default" : "success"}
          ></Chip>
        );
      },
    },
    {
      field: "is_active",
      headerName: "حالة المنتج",
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.is_active ? "فعال" : "غير فعال"}
            color={params.row.is_active ? "success" : "error"}
            onClick={() => {
              handleActiveClick(params.row);
            }}
          ></Chip>
        );
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="الارقام السرية">
              <IconButton
                onClick={() => {
                  navigate("/app/pins/pins/" + params.row.id);
                }}
              >
                <BiBarcodeReader />
              </IconButton>
            </Tooltip>
            <IconButton onClick={() => dispatch(Items.getById(params.row.id))}>
              <BiEdit />
            </IconButton>
            <IconButton
              onClick={() => {
                dispatch(
                  PosDiscount.getAllItemPosDiscount(params.row.id, true)
                );
              }}
            >
              <TbDiscount2 />
            </IconButton>
            <IconButton
              onClick={() => {
                dispatch(setExcelDialog());
                dispatch(
                  setSelectedItem({ id: params.row.id, name: params.row.name })
                );
              }}
            >
              <BsFileExcel />
            </IconButton>
            <IconButton
              onClick={() => {
                dispatch(setCsvDialog());
                dispatch(
                  setSelectedItem({ id: params.row.id, name: params.row.name })
                );
              }}
            >
              <BiFileBlank />
            </IconButton>
            <IconButton onClick={() => handleDeleteClick(params.row)}>
              <BiTrash />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Items.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_SearchBody]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        search: search.name,
        sub_category_id: search.sub_category_id
          ? search.sub_category_id.id
          : "",
        group_id: search.group_id ? search.group_id.id : "",
        province_id: search.province_id ?? "",
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize, search]);

  return (
    <Card>
      <CardContent>
        <Box>
          {_PosDiscount && <ItemPosDiscountData />}
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
          <ConfirmationDialog
            type="confirm"
            open={openActive}
            title="تاكيد"
            message={
              dialogActiveData.is_active
                ? "هل انت متاكد من الغاء تفعيل :"
                : "هل انت متاكد من تفعيل :"
            }
            name={dialogActiveData.name}
            confirmText="تاكيد"
            cancelText="الغاء"
            onConfirm={() => handleConfirmActive()}
            onCancel={handleCancelActive}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={search.name}
              onChange={(e) => {
                setSearch({
                  ...search,
                  name: e.target.value,
                });
              }}
              label="بحث عن الاسم "
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <SelectSubCategory
              value={search.sub_category_id}
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  sub_category_id: newValue ? newValue : null,
                });
              }}
            />
          </Grid>
          <Grid item xs={2.5}>
            <SelectGroup
              value={search.group_id}
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  group_id: newValue ? newValue : null,
                });
              }}
            />
          </Grid>
          <Grid item xs={2.5}>
            <SelectProvince
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  province_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _provinces?.filter((x) => x.id === search.province_id)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={2.5}>
            <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      name: "",
                      sub_category_id: null,
                      group_id: null,
                      province_id: null,
                    });
                    setPageSize(15);
                    setCurrentPage(0);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "63vh", width: "100%" }}>
              {_Items.data ? (
                <DataGrid
                  rows={_Items.data}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_Items.total}
                  loading={_Items.loading}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                    Footer: CustomFooter,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ItemsTabel;
