import { Card, CardHeader, Typography, Button } from "@mui/material";
import React from "react";
import { AiOutlineBarChart } from "react-icons/ai";



const OrdersHeader = () => {

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            المبيعات
          </Typography>
        }
        avatar={<AiOutlineBarChart size={20} />}
      />
    </Card>
  );
};

export default OrdersHeader;
