import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Typography,
  Stack,
  Tooltip,
  Autocomplete,
  TextField,
  Divider,
  Chip,
  Menu,
  MenuItem,
  ListItemIcon,
  Link,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BsStopwatch } from "react-icons/bs";
import { AiOutlineTransaction } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ProductsOrder,
  setDialog,
  setLoading,
  setProductsOrdersDetailsDialog,
  setSearchBody,
  setSupplierChangeDialog,
  setTransactionsDialog,
} from "../../../../../app/slices/productsOrdersSlice";
import dayjs from "dayjs";
import { showNotification } from "../../../../../app/slices/notificationSlice";
import {
  MdOutlineClear,
  MdOutlinePublishedWithChanges,
  MdPayments,
} from "react-icons/md";
import DateRangepicker from "../../../../../helpers/dateRangePicker";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import ProductsOrdersForm from "../../from";
import { RiLoader2Fill } from "react-icons/ri";
import { CgDanger } from "react-icons/cg";
import { HiMenu } from "react-icons/hi";
import { GiCardExchange } from "react-icons/gi";
import ProductOrdersTransactionsTable from "../transactions";
import { SelectProductsSupplier } from "../../../../../components/Selectors/SelectProductsSupplier";
import SupplierChangeForm from "../supplierChange";
import ProductsOrdersDetails from "../details";
import { SelectRepresentative } from "../../../../../components/Selectors/SelectRepresentative";
import { PointOfSales } from "../../../../../app/slices/posSlice";
import PosForm from "../../../../POS/form";

const ProductsOrdersTable = () => {
  const [search, setSearch] = useState({
    id: "",
    pos_name: "",
    pos_number: "",
    status: null,
    product_supplier_id: null,
    rep_id: null,
    start_date: dayjs().format("2023-01-01"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const _ProductsOrders = useSelector(
    (state) => state.productsOrders.productsOrders
  );
  const _ProductsOrder = useSelector(
    (state) => state.productsOrders.productsOrder
  );
  const loading = useSelector((state) => state.productsOrders.loading);
  const _SearchBody = useSelector((state) => state.productsOrders.searchBody);
  const _Transactions = useSelector(
    (state) => state.productsOrders.transactions
  );
  const _ProductsSupplier = useSelector(
    (state) => state.productSupplier.productsSupplier.data
  );
  const _ChangeSupplier = useSelector(
    (state) => state.productsOrders.supplierChange
  );
  const _ProductsOrderDetails = useSelector(
    (state) => state.productsOrders.productsOrdersDetails
  );
  const _PointsOfsales = useSelector((state) => state.pointOfsales.pos.dialog);

  const [openConfirm, setConfirmOpen] = useState(false);
  const [openConfirmApprove, setOpenConfirmApprove] = useState(false);
  const [openConfirmDailyPayments, setOpenConfirmDailyPayments] =
    useState(false);
  const [waiting, setWaiting] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [isSuspend, setIsSuspend] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [suspendDialogData, setSuspendDialogData] = useState("");
  const [dailyPaymentsData, setDailyPaymentsData] = useState("");

  const [activeItem, setActiveItem] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const handleMenuClick = (event, item) => {
    setOpenMenu(event.currentTarget);
    setActiveItem(item);
  };

  const handleClose = () => {
    setActiveItem(null);
    setOpenMenu(null);
  };

  const handleApproveClick = (params) => {
    if (params.status == "اقساط يومية") {
      dispatch(
        showNotification({
          message: "الطلب تمت الموافقة عليه مسبقاً",
          type: "success",
        })
      );
      setOpenConfirmApprove(false);
      setWaiting(false);
    } else {
      setOpenConfirmApprove(true);
      setDialogData(params);
      setWaiting(true);
    }
  };

  const handleRejectClick = (params) => {
    if (params.status == "رفض") {
      dispatch(
        showNotification({
          message: "الطلب تم رفضه مسبقاً",
          type: "success",
        })
      );
      setOpenConfirmApprove(false);
      setWaiting(false);
    } else {
      setOpenConfirmApprove(true);
      setDialogData(params);
      setWaiting(false);
    }
  };

  const handleCancelClick = (params) => {
    setOpenConfirmApprove(true);
    setDialogData(params);
    setCanceled(true);
  };

  const handleConfirmApprove = () => {
    if (waiting) {
      dispatch(
        ProductsOrder.changeStatus(
          {
            order_id: dialogData.id,
            status: "waiting",
          },
          _SearchBody
        )
      ).then((x) => setWaiting(false));
      setOpenConfirmApprove(false);
    } else if (canceled) {
      dispatch(
        ProductsOrder.cancelOrder(
          {
            order_id: dialogData.id,
          },
          _SearchBody
        )
      ).then((x) => setCanceled(false));
      setOpenConfirmApprove(false);
    } else {
      dispatch(
        ProductsOrder.changeStatus(
          {
            order_id: dialogData.id,
            status: "reject",
          },
          _SearchBody
        )
      ).then((x) => setWaiting(false));
      setOpenConfirmApprove(false);
    }
  };

  const handleCancelApprove = () => {
    setOpenConfirmApprove(false);
  };

  const handleSuspendClick = (params) => {
    setConfirmOpen(true);
    setSuspendDialogData(params);
  };

  const handleConfirmSuspend = () => {
    dispatch(
      ProductsOrder.changeStatus(
        {
          order_id: suspendDialogData.id,
          status:
            suspendDialogData.status == "suspend"
              ? "daily_payments"
              : "suspend",
        },
        _SearchBody
      )
    );
    setConfirmOpen(false);
  };

  const handleCancelSuspend = () => {
    setConfirmOpen(false);
  };

  const handleDailyPaymentsClick = (params) => {
    setOpenConfirmDailyPayments(true);
    setDailyPaymentsData(params);
  };

  const handleConfirmDailyPayments = () => {
    dispatch(
      ProductsOrder.orderDailyPayments(
        {
          order_id: dailyPaymentsData.id,
        },
        _SearchBody
      )
    );
    setOpenConfirmDailyPayments(false);
  };

  const handleCancelDailyPayments = () => {
    setOpenConfirmDailyPayments(false);
  };

  const type = [
    { type: "paid", name: "تم التسديد" },
    { type: "not", name: "لم يتم التسديد" },
  ];
  const columns = [
    {
      field: "id",
      headerName: "رقم الطلب",
      flex: 0.6,
    },
    {
      field: "pos_name",
      headerName: "النقطة",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            <Typography>
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  dispatch(setLoading("productsOrders"));
                  dispatch(PointOfSales.getById(params.row.pos_id)).then(() =>
                    dispatch(setLoading("productsOrders"))
                  );
                }}
              >
                {params.row?.pos_name}
              </Link>
            </Typography>
            <Typography variant="caption"> {params.row?.pos_number}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "product_name",
      headerName: "اسم المنتج",
      flex: 1.4,
      renderCell: (params) => {
        return (
          <Stack>
            <Typography>{params.row?.product_name}</Typography>
            <Typography variant="caption">
              {params.row?.supplier_name}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "count",
      headerName: "العدد",
      flex: 0.4,
    },
    {
      field: "total_amount",
      headerName: "سعر المنتج",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            <Typography>
              الكلي: {params.row?.total_amount?.toLocaleString()}
            </Typography>
            <Typography variant="caption">
              اليومي: {params.row?.daily_payment?.toLocaleString()}
            </Typography>
          </Stack>
        );
      },
    },

    {
      field: "total_payments",
      headerName: "التسديد ",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            <Typography>
              {" "}
              المسددة: {params.row?.total_payments?.toLocaleString()}
            </Typography>
            <Typography color="error">
              المتبقي: {params.row?.remaining?.toLocaleString()}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "revenue",
      headerName: "نسبة الشركة",
      flex: 0.7,
      renderCell: (params) => {
        return <Typography>{params.row?.revenue?.toLocaleString()}</Typography>;
      },
    },
    // {
    //   field: "remaining",
    //   headerName: "المبالغ المتبقية",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <Typography>{params.row?.remaining.toLocaleString()}</Typography>
    //     );
    //   },
    // },
    {
      field: "created_at",
      headerName: "تاريخ الطلب",
      flex: 1,
      renderCell: (params) => {
        return (
          <>{dayjs(params.row.created_at).format("HH:mm / YYYY-MM-DD ")}</>
        );
      },
    },
    {
      field: "status",
      headerName: "حالة الطلب",
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={params.row.status}
            color={
              params.row.status == "تم التسديد"
                ? "success"
                : params.row.status == "رفض" ||
                  params.row.status == "الغاء طلب منصة"
                ? "error"
                : params.row.status == "اقساط يومية"
                ? "info"
                : "warning"
            }
          />
        );
      },
    },
    {
      field: "updated_at",
      headerName: "تاريخ التحديث",
      flex: 1,
      renderCell: (params) => {
        return (
          <>{dayjs(params.row.updated_at).format("HH:mm / YYYY-MM-DD ")}</>
        );
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={(e) => handleMenuClick(e, params)}
            aria-controls={openMenu ? params.row.id : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
          >
            <HiMenu />
          </IconButton>
        );
      },
    },
  ];

  const operationMenu = (params) => (
    <Menu
      sx={{ direction: "ltr" }}
      anchorEl={openMenu}
      keepMountede
      id="account-menu"
      open={activeItem === params}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            left: "50%",
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem
        onClick={() => {
          dispatch(setProductsOrdersDetailsDialog(params.row));
        }}
      >
        <ListItemIcon>
          <RiLoader2Fill size={20} />
        </ListItemIcon>
        تفاصيل الطلب
      </MenuItem>
      <MenuItem
        disabled={
          params.row.status == "تحت المراجعة" ||
          params.row.status == "قيد الانتظار"
            ? false
            : true
        }
        onClick={() => {
          handleApproveClick(params.row);
        }}
      >
        <ListItemIcon>
          <RiLoader2Fill
            size={20}
            color={
              params.row.status == "تحت المراجعة" ||
              params.row.status == "قيد الانتظار"
                ? "orange"
                : null
            }
          />
        </ListItemIcon>
        انتظار الموافقة
      </MenuItem>
      <MenuItem
        disabled={
          params.row.status == "تحت المراجعة" ||
          params.row.status == "قيد الانتظار"
            ? false
            : true
        }
        onClick={() => {
          dispatch(
            setDialog({
              order_id: params.row.id,
              total_amount: params.row.total_amount,
              daily_payment: params.row.daily_payment,
            })
          );
          setIsSuspend(false);
        }}
      >
        <ListItemIcon>
          <MdOutlinePublishedWithChanges
            size={20}
            color={
              params.row.status == "تحت المراجعة" ||
              params.row.status == "قيد الانتظار"
                ? "green"
                : null
            }
          />
        </ListItemIcon>
        موافقة الطلب
      </MenuItem>
      <MenuItem
        disabled={params.row.status == "اقساط يومية" ? false : true}
        onClick={() => {
          dispatch(
            setDialog({
              order_id: params.row.id,
              total_amount: params.row.total_amount,
              daily_payment: params.row.daily_payment,
            })
          );
          setIsSuspend(true);
        }}
      >
        <ListItemIcon>
          <BsStopwatch
            size={20}
            color={params.row.status == "اقساط يومية" ? "purple" : null}
          />
        </ListItemIcon>
        ايقاف الاستقطاع
      </MenuItem>
      <MenuItem
        disabled={
          params.row.status == "متوقفة" ||
          params.row.status == "اقساط يومية" ||
          params.row.status == "قيد الانتظار"
            ? false
            : true
        }
        onClick={() => {
          dispatch(
            ProductsOrder.changeStatus(
              {
                order_id: params.row.id,
                status: "return_daily_payments",
              },
              _SearchBody
            )
          );
        }}
      >
        <ListItemIcon>
          <BsStopwatch size={20} />
        </ListItemIcon>
        استئناف الاستقطاع
      </MenuItem>
      <MenuItem
        disabled={
          params.row.status == "تحت المراجعة" ||
          params.row.status == "قيد الانتظار"
            ? false
            : true
        }
        onClick={() => {
          handleRejectClick(params.row);
        }}
      >
        <ListItemIcon>
          <CgDanger
            size={20}
            color={
              params.row.status == "تحت المراجعة" ||
              params.row.status == "قيد الانتظار"
                ? false
                : true
            }
          />
        </ListItemIcon>
        رفض الطلب
      </MenuItem>
      <MenuItem
        disabled={params.row.status != "متوقفة"}
        onClick={() => {
          handleCancelClick(params.row);
        }}
      >
        <ListItemIcon>
          <CgDanger
            size={20}
            color={params.row.status == "متوقفة" ? "red" : null}
          />
        </ListItemIcon>
        الغاء الطلب
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleDailyPaymentsClick(params.row);
        }}
        disabled={params.row.status == "اقساط يومية" ? false : true}
      >
        <ListItemIcon>
          <MdPayments size={20} />
        </ListItemIcon>
        تشغيل اقساط يومية
      </MenuItem>
      <MenuItem
        onClick={() => {
          dispatch(setTransactionsDialog(params.row.id));
        }}
      >
        <ListItemIcon>
          <AiOutlineTransaction size={20} />
        </ListItemIcon>
        المناقلات المالية
      </MenuItem>
      <MenuItem
        onClick={() => {
          dispatch(
            setSupplierChangeDialog({
              order_id: params.row.id,
              supplier_id: params.row.supplier_name,
            })
          );
        }}
      >
        <ListItemIcon>
          <GiCardExchange size={20} />
        </ListItemIcon>
        تغيير المجهز
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(ProductsOrder.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        id: search.id,
        pos_number: search.pos_number,
        pos_name: search.pos_name,
        rep_id: search.rep_id ?? "",
        start_date: search.start_date ?? "",
        end_date: search.end_date ?? "",
        type: search.status ?? "",
        product_supplier_id: search.product_supplier_id ?? "",
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize, search]);

  return (
    <Card>
      <CardContent>
        <Box>
          {_ProductsOrder.dialog && (
            <ProductsOrdersForm isSuspend={isSuspend} />
          )}
          {_Transactions.dialog && <ProductOrdersTransactionsTable />}
          {_ChangeSupplier.dialog && <SupplierChangeForm />}
          {_ProductsOrderDetails.dialog && <ProductsOrdersDetails />}
          {_PointsOfsales && <PosForm inNeedReload={false} />}
          {activeItem && operationMenu(activeItem)}
          <ConfirmationDialog
            type="confirm"
            open={openConfirmApprove}
            title="تاكيد"
            message={
              waiting
                ? "هل انت متاكد من تحويل الطلب الى الانتظار"
                : canceled
                ? "هل انت متاكد من الغاء الطلب المرقم"
                : "هل انت متاكد من رفض الطلب المرقم"
            }
            name={dialogData.id}
            confirmText="تاكيد"
            cancelText="الغاء"
            onConfirm={handleConfirmApprove}
            onCancel={handleCancelApprove}
          />
          <ConfirmationDialog
            type="confirm"
            open={openConfirmApprove}
            title="تاكيد"
            message={
              waiting
                ? "هل انت متاكد من تحويل الطلب الى الانتظار"
                : "هل انت متاكد من رفض الطلب المرقم"
            }
            name={dialogData.id}
            confirmText="تاكيد"
            cancelText="الغاء"
            onConfirm={handleConfirmApprove}
            onCancel={handleCancelApprove}
          />
          <ConfirmationDialog
            type="confirm"
            open={openConfirm}
            title="تاكيد"
            message={"هل انت متاكد من ايقاف الطلب المرقم"}
            name={suspendDialogData.id}
            confirmText="تاكيد"
            cancelText="الغاء"
            onConfirm={handleConfirmSuspend}
            onCancel={handleCancelSuspend}
          />
          <ConfirmationDialog
            type="confirm"
            open={openConfirmDailyPayments}
            title="تاكيد"
            message={"هل انت متاكد من تشغيل الاقساط اليومية للطلب المرقم"}
            name={dailyPaymentsData.id}
            confirmText="تاكيد"
            cancelText="الغاء"
            onConfirm={handleConfirmDailyPayments}
            onCancel={handleCancelDailyPayments}
          />
        </Box>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={search.pos_name || search.pos_number}
              onChange={(e) => {
                if (isNaN(e.target.value)) {
                  setSearch({
                    ...search,
                    pos_name: e.target.value,
                    pos_number: "",
                  });
                } else {
                  setSearch({
                    ...search,
                    pos_number: e.target.value,
                    pos_name: "",
                  });
                }
              }}
              label="رقم نقطة البيع او اسمها"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={search.id}
              onChange={(e) => {
                setSearch({
                  ...search,
                  id: e.target.value,
                });
              }}
              label="رقم الطلب"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <SelectProductsSupplier
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  product_supplier_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _ProductsSupplier?.filter(
                  (x) => x.id == search.product_supplier_id
                )[0]
                  ? _ProductsSupplier?.filter(
                      (x) => x.id == search.product_supplier_id
                    )[0]
                  : search.product_supplier_id
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              options={type}
              getOptionLabel={(option) => {
                return option.name;
              }}
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  status: newValue ? newValue.type : null,
                });
              }}
              value={
                type.filter((x) => x.type == search.status)[0]
                  ? type.filter((x) => x.type == search.status)[0]
                  : null
              }
              renderInput={(params) => (
                <TextField {...params} label="حالة الطلب" size="small" />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <SelectRepresentative
              disabled={loading}
              onChange={(event, value) => {
                setSearch({
                  ...search,
                  rep_id: value ? value.id : null,
                });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>

          <Grid item xs={2.5}>
            <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      id: "",
                      pos_name: "",
                      pos_number: "",
                      status: null,
                      product_supplier_id: null,
                      start_date: dayjs().format("2023-01-01"),
                      end_date: dayjs().format("YYYY-MM-DD"),
                    });
                    setPageSize(15);
                    setCurrentPage(0);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={4}>
              <Typography variant="h4">
                المجموع الكلي للطلبات :{" "}
                {_ProductsOrders.total_amount?.toLocaleString()} (IQD)
              </Typography>
              <Typography variant="h4">
                مجموع المبالغ المدفوعة :{" "}
                {_ProductsOrders.total_payments?.toLocaleString()} (IQD)
              </Typography>
              <Typography variant="h4">
                مجموع المبالغ المتبقية :{" "}
                {_ProductsOrders.total_remaining?.toLocaleString()} (IQD)
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "65vh", width: "100%" }}>
              {_ProductsOrders.data ? (
                <DataGrid
                  rows={_ProductsOrders.data}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_ProductsOrders.total}
                  loading={loading}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProductsOrdersTable;
