import { Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { BsCashStack } from "react-icons/bs";

const PlatformPaymentsHeader = () => {

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            دفعات المنصة
          </Typography>
        }
        avatar={<BsCashStack size={20} />}
      />
    </Card>
  );
};

export default PlatformPaymentsHeader;
