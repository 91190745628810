import { Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { FaWarehouse } from "react-icons/fa";



const StockHeader = () => {

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            المخزن
          </Typography>
        }
        avatar={<FaWarehouse size={20} />}
      />
    </Card>
  );
};

export default StockHeader;
