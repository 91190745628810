import dayjs from "dayjs";
import {
  createSlice
} from "@reduxjs/toolkit";
import {
  showNotification
} from "./notificationSlice";
import {
  factory
} from "../../api/apiFactory";
const orderApi = factory.get("orders");
const initialState = {
  searchBody: {
    id: "",
    type: "supplier",
    skip: 0,
    take: 15,
    item_id: "",
    supplier_id: "",
    sub_category_id: "",
  },
  order: {
    loading: false,
    dialog: false,
    form: {
      id: null,
      item_id: null,
      supplier_id: null,
      count: "",
      price: "",
      cash_paid: "",
      note: ""
    },
  },
  orders: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0
  },
  bills: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0
  },
  pinsOrder: {
    order_id: null,
    dialog: false,
    loading: false,
    data: [],
    total: 0,
    total_amount: 0
  },
  checkSellingPrice: {
    loading: false,
    total: "",
    single: "",
    count: ""
  }
}

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.order.dialog = !state.order.dialog
    },
    setOrderPinsDialog: (state, {
      payload
    }) => {
      state.pinsOrder.dialog = !state.pinsOrder.dialog
      state.pinsOrder.order_id = payload
    },
    setDataTable: (state, {
      payload
    }) => {
      state.orders.data = payload.data.data
      state.orders.total = payload.data.total
      state.orders.total_amount = payload.data.total_amount
    },
    setBillsDataTable: (state, {
      payload
    }) => {
      state.bills.data = payload.data.data
      state.bills.total = payload.data.total
      state.bills.total_amount = payload.data.total_amount
    },
    setPinsOrderDataTable: (state, {
      payload
    }) => {
      state.pinsOrder.data = payload.data.data
      state.pinsOrder.total = payload.data.total
      state.pinsOrder.total_amount = payload.data.total_amount
    },
    setCheckSellingPriceTable: (state, {
      payload
    }) => {
      state.checkSellingPrice.single = payload.data.data.single
      state.checkSellingPrice.total = payload.data.data.total
      state.checkSellingPrice.count = payload.data.data.count
    },
    setById: (state, {
      payload
    }) => {
      state.order.form.id = payload.id
      state.order.form.item_id = payload.item_id
      state.order.form.supplier_id = payload.supplier_id
      state.order.form.count = payload.count
      state.order.form.price = payload.price
      state.order.form.cash_paid = payload.cash_paid
      state.order.form.note = payload.note
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.order.form = initialState.order.form
    },
    resetCheckPrice: (state, action) => {
      state.checkSellingPrice.single = initialState.checkSellingPrice.single
      state.checkSellingPrice.total = initialState.checkSellingPrice.total
      state.checkSellingPrice.count = initialState.checkSellingPrice.count
    },
    setSearchBody: (state, {
      payload
    }) => {
      state.searchBody = payload;
    },
  }
})

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  resetCheckPrice,
  setBillsDataTable,
  setPinsOrderDataTable,
  setCheckSellingPriceTable,
  setOrderPinsDialog,
  setSearchBody
} = orderSlice.actions

export default orderSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('order'));
    const res = await orderApi.getAll(params)
    dispatch(setDataTable(res));
    dispatch(setLoading('order'));

  } catch (err) {
    dispatch(setLoading('order'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getAllBills = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('order'));
    const res = await orderApi.getSupplierOrder(params)
    dispatch(setBillsDataTable(res));
    dispatch(setLoading('order'));

  } catch (err) {
    dispatch(setLoading('order'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getPinsOrder = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('order'));
    const res = await orderApi.pinsOfOrder(params)
    dispatch(setPinsOrderDataTable(res));
    dispatch(setLoading('order'));

  } catch (err) {
    dispatch(setLoading('order'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const addSupplierOrder = (data) => async (dispatch) => {
  try {
    const res = await orderApi.supplierOrder(data)
    dispatch(showNotification({
      message: "تمت الاضافة بنجاح",
      type: "success"
    }));
    dispatch(setPinsOrderDataTable(res));
    dispatch(setDialog())
    dispatch(setOrderPinsDialog(null))
    dispatch(getAllBills({ type: 'supplier' }))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading('order'));
    const res = await orderApi.getById(id)
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog())
    dispatch(setLoading('order'));
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    dispatch(setLoading('order'));
    throw new Error(err);
  }
};
const checkSellingPrice = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('order'));
    const res = await orderApi.checkSellingPrice(params)
    dispatch(setCheckSellingPriceTable(res));
    dispatch(setLoading('order'));

  } catch (err) {
    dispatch(setLoading('order'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const deleteById = (id, searchBody) => async (dispatch) => {
  try {
    await orderApi.delete(id)
    dispatch(showNotification({
      message: "تم الحذف بنجاح",
      type: "success"
    }));
    dispatch(getAll(searchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const deleteBillById = (id, searchBody) => async (dispatch) => {
  try {
    await orderApi.deleteBill(id)
    dispatch(showNotification({
      message: "تم الحذف بنجاح",
      type: "success"
    }));
    dispatch(getAllBills(searchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
export const Orders = {
  getAll,
  getById,
  addSupplierOrder,
  getAllBills,
  getPinsOrder,
  checkSellingPrice,
  deleteById,
  deleteBillById
}