import {
  Grid,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
  IconButton,
  TextField,
  Typography,
  Chip,
  useMediaQuery,
  Stack,
  TablePagination,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TransferBalance,
  setSearchBody,
} from "../../../../../app/slices/transferBalanceSlice";
import dayjs from "dayjs";
import DateRangepicker from "../../../../../helpers/dateRangePicker";
import { MdOutlineClear } from "react-icons/md";
import { SelectSmsTypes } from "../../../../../components/Selectors/SelectReceivedSmsTypes";
import { BiCloset, BiInfoCircle, BiTrash, BiXCircle } from "react-icons/bi";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import { useTheme } from "@emotion/react";

const SmsTable = () => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const _Sms = useSelector((state) => state.transferBalance.getAllSms);
  const loading = useSelector(
    (state) => state.transferBalance.transferBalance.loading
  );
  const _SearchBody = useSelector((state) => state.transferBalance.searchBody);
  const _SmsTypes = useSelector((state) => state.transferBalance.types);

  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const initialSearch = {
    type: "",
    port: "",
    startDate: dayjs().format("2023-01-01"),
    endDate: dayjs().format("YYYY-MM-DD"),
    order_id: "",
  };
  const [search, setSearch] = useState(initialSearch);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(TransferBalance.refunded({ order_id: dialogData.order_id })).then(
      () => dispatch(TransferBalance.getAllSms(_SearchBody))
    );
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "order_id",
      headerName: "رقم الطلب",
      flex: 0.5,
    },
    {
      field: "phone",
      headerName: "رقم الهاتف",
      flex: 0.8,
    },
    {
      field: "port",
      headerName: "البورت",
      flex: 0.5,
    },
    {
      field: "type",
      headerName: "النوع",
      flex: 0.5,
    },
    {
      field: "message",
      headerName: "الرسالة",
      flex: 2,
      renderCell: (params) => (
        <Tooltip title={params.row.message}>
          <Typography noWrap>{params.row.message}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "created_at",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => (
        <>
          <Typography>
            {dayjs(params.row.created_at).format("HH:mm:ss / YYYY-MM-DD ")}
          </Typography>
          <Tooltip title={params.row.note}>
            <IconButton size="small">
              <BiInfoCircle />
            </IconButton>
          </Tooltip>
          {params.row.is_refunded ? (
            <>
              <Tooltip
                title={dayjs(params.row.updated_at).format(
                  "HH:mm:ss / YYYY-MM-DD "
                )}
              >
                <Chip label="تم الاسترجاع" />
              </Tooltip>
              <Tooltip title="الغاء الاسترجاع">
                <IconButton
                  onClick={() =>
                    dispatch(
                      TransferBalance.refunded(
                        {
                          order_id: params.row.order_id,
                        },
                        true
                      )).then(() =>
                        dispatch(TransferBalance.getAllSms(_SearchBody))
                      )
                  }
                >
                  <BiXCircle />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <Tooltip title="ارجاع المبلغ للنقطة">
              <IconButton
                size="small"
                color="error"
                onClick={() => handleDeleteClick(params.row)}
              >
                <BiTrash />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(TransferBalance.getAllSms(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        order_id: search.order_id,
        type: search.type,
        port: search.port,
        start_date: search.startDate,
        end_date: search.endDate,
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize, search]);

  return (
    <Card>
      <CardContent>
        <ConfirmationDialog
          open={openConfirm}
          title="تاكيد"
          message="هل انت متاكد من حذف الطلب المرقم"
          name={dialogData.order_id}
          confirmText="حذف"
          cancelText="الغاء"
          onConfirm={() => handleConfirmDelete()}
          onCancel={handleCancelDelete}
        />
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              value={search.order_id}
              onChange={(e) => {
                setSearch({
                  ...search,
                  order_id: e.target.value,
                });
              }}
              label="رقم الطلب"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              value={search.port}
              onChange={(e) => {
                setSearch({
                  ...search,
                  port: e.target.value,
                });
              }}
              label="البورت"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2.5}>
            <SelectSmsTypes
              onChange={(event, value) => {
                setSearch({
                  ...search,
                  type: value ? value.key : null,
                });
              }}
              value={_SmsTypes?.filter((x) => x.key == search.type)[0] ?? null}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DateRangepicker
              startDate={search.startDate}
              endDate={search.endDate}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    startDate: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    endDate: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch(initialSearch);
                  setPageSize(15);
                  setCurrentPage(0);
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          {smallScreen ? (
            _Sms?.data?.length > 0 ? (
              <Grid item xs={12}>
                {loading ? (
                  <LinearProgress />
                ) : null}
                {_Sms.data.map((row, index) => (
                  <Card sx={{ marginBottom: 0.5 }} key={index}>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Stack direction="row" spacing={2} justifyContent="space-between">
                            <Typography variant="h4" color="primary">
                              #{index + 1}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            رقم الطلب: <strong>{row.order_id}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            رقم الهاتف: <strong>{row.phone}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            البورت: <strong>{row.port}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            النوع: <strong>{row.type}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Tooltip title={row.message}>
                            <Typography>{row.message}</Typography>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>
                              {dayjs(row.created_at).format("HH:mm:ss / YYYY-MM-DD ")}
                            </Typography>
                            <Tooltip title={row.note}>
                              <IconButton size="small">
                                <BiInfoCircle />
                              </IconButton>
                            </Tooltip>
                            {row.is_refunded ? (
                              <>
                                <Tooltip
                                  title={dayjs(row.updated_at).format("HH:mm:ss / YYYY-MM-DD ")}
                                >
                                  <Chip label="تم الاسترجاع" />
                                </Tooltip>
                                <Tooltip title="الغاء الاسترجاع">
                                  <IconButton
                                    onClick={() =>
                                      dispatch(
                                        TransferBalance.refunded(
                                          {
                                            order_id: row.order_id,
                                          },
                                          true
                                        )).then(() =>
                                          dispatch(TransferBalance.getAllSms(_SearchBody))
                                        )
                                    }
                                  >
                                    <BiXCircle />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : (
                              <Tooltip title="ارجاع المبلغ للنقطة">
                                <IconButton
                                  size="small"
                                  color="error"
                                  onClick={() => handleDeleteClick(row)}
                                >
                                  <BiTrash />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Stack>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
                <Card sx={{ mt: 2, width: "100%" }}>
                  <TablePagination
                    component="div"
                    count={_Sms.total}
                    rowsPerPage={pageSize}
                    page={currentPage}
                    onPageChange={(e, newPage) => setCurrentPage(newPage)}
                    rowsPerPageOptions={[15, 25, 50, 100]}
                    onRowsPerPageChange={(event) => {
                      const newRowsPerPage = parseInt(event.target.value, 10);
                      setPageSize(newRowsPerPage);
                    }}
                  />
                </Card>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Card>
                  <Typography m={2} variant="h5" color="primary" align="center">
                    لا توجد بيانات
                  </Typography>
                </Card>
              </Grid>
            )
          ) : (
            <Grid item xs={12}>
              <Box sx={{ height: "75vh", width: "100%" }}>
                <DataGrid
                  rows={_Sms.data.map((x, index) => ({
                    ...x,
                    index: index + 1,
                  }))}
                  columns={columns}
                  pageSize={pageSize}
                  rowCount={_Sms.total}
                  loading={loading}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SmsTable;
