import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Pagination,
  Stack,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  YAxis,
} from "recharts";
import dayjs from "dayjs";
import { MdOutlineClear } from "react-icons/md";
import { Reports, setRepId } from "../../../app/slices/reportsSlice";
import { useNavigate } from "react-router";

const RepSalesPerWeekChart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data } = useSelector((state) => state.reports.repOrdersPerWeek);

  const [repData, setRepData] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(Reports.getRepOrdersPerWeek());
  }, [dispatch]);

  useEffect(() => {
    setRepData(
      data?.filter((rep) =>
        rep.rep_name?.toLowerCase().startsWith(search.toLowerCase())
      )
    );
  }, [data, search]);

  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = repData.slice(indexOfFirstItem, indexOfLastItem);

  const totalItems = repData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const goTo = (path) => {
    navigate(path);
  };

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" color={"purple"}>
            احصائيات مبياع المندوبين
          </Typography>
        }
        subheader={
          <Stack direction="column" spacing={1}>
            <Typography variant="caption">
              {dayjs().subtract(1, "week").format("YYYY-MM-DD") +
                " - " +
                dayjs().format("YYYY-MM-DD")}
            </Typography>
            <Stack alignItems="start" direction={"row"} spacing={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    label="بحث عن الاسم"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setSearch("");
                    }}
                  >
                    <MdOutlineClear />
                  </IconButton>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        }
      ></CardHeader>
      <CardContent>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          {data.length !== 0
            ? currentItems.map((x, index) => (
                <Grid item xs={6} key={index}>
                  <Card>
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="center"
                      sx={{ fontSize: 18 }}
                    >
                      <Button
                        onClick={() => {
                          goTo(`/app/statistics/pos_orders`);
                          dispatch(setRepId(x.rep_id));
                        }}
                      >
                        {x.rep_name}
                      </Button>
                    </Stack>
                    <Divider sx={{ m: 2 }} />
                    <ResponsiveContainer width="100%" height={400}>
                      <BarChart
                        style={{ direction: "ltr" }}
                        width={500}
                        height={300}
                        data={x.result?.map((x) => {
                          return {
                            ...x,
                            total_order_amount: parseInt(x.total_order_amount),
                          };
                        })}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="order_date" />
                        <YAxis
                          tickFormatter={(value) => value.toLocaleString()}
                        />
                        <Tooltip />
                        <Legend />
                        <Bar
                          type="monotone"
                          dataKey="total_order_amount"
                          name="المبيعات"
                          fill="#8884d8"
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </Card>
                </Grid>
              ))
            : null}
          <Grid item xs={12}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RepSalesPerWeekChart;
