import { Grid } from "@mui/material";
import React from "react";
import PlatformPaymentsTable from "./home/table";
import PlatformPaymentsHeader from "./home/header";

const PlatformPayments = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PlatformPaymentsHeader />
      </Grid>
      <Grid item xs={12}>
        <PlatformPaymentsTable />
      </Grid>
    </Grid>
  );
};

export default PlatformPayments;
