import Typography from "@mui/material/Typography";
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  Slide,
  Stack,
} from "@mui/material";
import { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  ProductsOrder,
  setAddDialog,
} from "../../../../app/slices/productsOrdersSlice";
import { SelectProducts } from "../../../../components/Selectors/SelectProducts";
import { SelectPos } from "../../../../components/Selectors/SelectPos";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddProductsOrdersForm = (props) => {
  const dispatch = useDispatch();
  const _ProductsOrder = useSelector(
    (state) => state.productsOrders.addProductsOrder
  );
  const _SearchBody = useSelector((state) => state.productsOrders.searchBody);
  const _PointOfsales = useSelector(
    (state) => state.pointOfsales.posesList.data
  );
  const _Products = useSelector((state) => state.product.products.data);

  const { control, watch, handleSubmit, setValue } = useForm({
    defaultValues: _ProductsOrder.form,
  });

  const onSubmit = (data) => {
    dispatch(
      ProductsOrder.create(
        {
          pos_id: data.pos_id,
          product_id: data.product_id,
          count: data.count,
          note: data.note,
        },
        _SearchBody
      )
    );
  };

  const handleDialog = () => {
    dispatch(setAddDialog(null));
  };

  return (
    <Dialog
      open={_ProductsOrder.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={handleDialog}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"أضافة منتج"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="pos_id"
                control={control}
                render={({ field }) => (
                  <SelectPos
                    required
                    onChange={(e, newValue) => {
                      setValue("pos_id", newValue ? newValue.id : null);
                    }}
                    value={
                      _PointOfsales.filter((x) => x.id === field.value)[0] ??
                      null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack>
                <Controller
                  name="product_id"
                  control={control}
                  render={({ field }) => (
                    <SelectProducts
                      required
                      onChange={(e, newValue) => {
                        setValue("product_id", newValue ? newValue.id : null);
                        setValue(
                          "total_price",
                          newValue ? newValue.total_price : 0
                        );
                      }}
                      value={
                        _Products.filter((x) => x.id === field.value)[0] ?? null
                      }
                    />
                  )}
                />
                <Typography variant="caption">
                  السعر الكلي:{" "}
                  {watch("count") &&
                    `${(
                      parseInt(watch("count")) * watch("total_price")
                    ).toLocaleString()} IQD`}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="count"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    type="number"
                    value={field.value}
                    size="small"
                    label="العدد"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    value={field.value}
                    size="small"
                    label="الملاحظات"
                    fullWidth
                    multiline
                    rows={4}
                    {...field}
                    inputProps={{ maxLength: 255 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddProductsOrdersForm;
