import { Grid, LinearProgress, Card, CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Operation, setSearchBody } from "../../../../app/slices/operationSlice";
import dayjs from "dayjs";

const AsiaBillsTable = () => {
  const dispatch = useDispatch();
  const _AsiaBills = useSelector((state) => state.operation.asiaBill);
  const loading = useSelector((state) => state.operation.operation.loading);
  const _SearchBody = useSelector((state) => state.operation.searchBody);

  const [page, setPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "item",
      headerName: "اسم المنتج",
      flex: 1,
    },
    {
      field: "pin_count",
      headerName: "العدد",
      flex: 0.8,
    },
    {
      field: "created_at",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) =>
        dayjs(params.row.created_at).format("YYYY-MM-DD"),
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Operation.getAsiaBills(_SearchBody));
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        skip: currentPage * page,
        take: page,
      })
    );
  }, [currentPage, dispatch, page]);

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12}>
            <Box sx={{ height: "80vh", width: "100%" }}>
              {_AsiaBills.data ? (
                <DataGrid
                  rows={
                    _AsiaBills.data?.map((x, index) => ({
                      ...x,
                      index: index + 1,
                    })) ?? []
                  }
                  columns={columns}
                  pageSize={page}
                  page={currentPage}
                  rowCount={_AsiaBills.total}
                  loading={loading}
                  rowHeight={60}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => setCurrentPage(newPage)}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPage(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AsiaBillsTable;
