import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const statisticApi = factory.get("statistic");
const initialState = {
  statistic: {
    loading: false,
  },
  active: {
    loading: false,
    data: [],
    total: 0,
  },
  revenue: {
    loading: false,
    data: [],
    total: 0,
  },
  topFivePos: {
    loading: false,
    data: [],
    total: 0,
  },
  topFiveItems: {
    loading: false,
    data: [],
    total: 0,
  },
  totalSalesMonth: {
    loading: false,
    data: [],
    total: 0,
  },
  totalSalesDay: {
    loading: false,
    data: [],
    total: 0,
  },
  dailyRevenue: {
    loading: false,
    data: [],
    total: 0,
  },
  totalPosSalesPerCitiesCurrentDay: {
    loading: false,
    data: [],
    total: 0,
  },
  totalPosSalesPerCitiesCurrentMonth: {
    loading: false,
    data: [],
    total: 0,
  },
};

export const statisticSlice = createSlice({
  name: "statistic",
  initialState,
  reducers: {
    setActiveTable: (state, { payload }) => {
      state.active.data = payload.data.data;
      state.active.total = payload.data.total;
    },
    setRevenueTable: (state, { payload }) => {
      state.revenue.data = payload.data.data;
      state.revenue.total = payload.data.total;
    },
    setDailyRevenueTable: (state, { payload }) => {
      state.dailyRevenue.data = payload.data.data;
      state.dailyRevenue.total = payload.data.total;
    },
    setTopFivePosTable: (state, { payload }) => {
      state.topFivePos.data = payload.data.data;
      state.topFivePos.total = payload.data.total;
    },
    setTopFiveItemsTable: (state, { payload }) => {
      state.topFiveItems.data = payload.data.data;
      state.topFiveItems.total = payload.data.total;
    },
    setTotalSalesMonth: (state, { payload }) => {
      state.totalSalesMonth.data = payload.data.data;
      state.totalSalesMonth.total = payload.data.total_amount;
    },
    setTotalSalesDay: (state, { payload }) => {
      state.totalSalesDay.data = payload.data.data;
      state.totalSalesDay.total = payload.data.total_amount;
    },
    setTotalPosSalesPerCitiesCurrentMonth: (state, { payload }) => {
      state.totalPosSalesPerCitiesCurrentMonth.data = payload.data.data.map(
        (item) => ({
          ...item,
          totals_amount: parseFloat(item.totals_amount),
        })
      );
      state.totalPosSalesPerCitiesCurrentMonth.total =
        payload.data.total_amount;
    },
    setTotalPosSalesPerCitiesCurrentDay: (state, { payload }) => {
      state.totalPosSalesPerCitiesCurrentDay.data = payload.data.data.map(
        (item) => ({
          ...item,
          totals_amount: parseFloat(item.totals_amount),
        })
      );
      state.totalPosSalesPerCitiesCurrentDay.total = payload.data.total_amount;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
  },
});

export const {
  setLoading,
  setActiveTable,
  setRevenueTable,
  setTopFivePosTable,
  setTopFiveItemsTable,
  setTotalSalesMonth,
  setTotalSalesDay,
  setDailyRevenueTable,
  setTotalPosSalesPerCitiesCurrentDay,
  setTotalPosSalesPerCitiesCurrentMonth,
} = statisticSlice.actions;

export default statisticSlice.reducer;

//axios
const getActive = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("statistic"));
    const res = await statisticApi.active(params);
    dispatch(setActiveTable(res));
    dispatch(setLoading("statistic"));
  } catch (err) {
    dispatch(setLoading("statistic"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getRevenue = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("statistic"));
    const res = await statisticApi.revenue(params);
    dispatch(setRevenueTable(res));
    dispatch(setLoading("statistic"));
  } catch (err) {
    dispatch(setLoading("statistic"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getTopFivePos = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("topFivePos"));
    const res = await statisticApi.topFivePos(params);
    dispatch(setTopFivePosTable(res));
    dispatch(setLoading("topFivePos"));
  } catch (err) {
    dispatch(setLoading("topFivePos"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getTopFiveItems = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("topFiveItems"));
    const res = await statisticApi.topFiveItems(params);
    dispatch(setTopFiveItemsTable(res));
    dispatch(setLoading("topFiveItems"));
  } catch (err) {
    dispatch(setLoading("topFiveItems"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getTotalSalesMonth = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("statistic"));
    const res = await statisticApi.totalSales(params);
    dispatch(setTotalSalesMonth(res));
    dispatch(setLoading("statistic"));
  } catch (err) {
    dispatch(setLoading("statistic"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getTotalSalesDay = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("statistic"));
    const res = await statisticApi.totalSales(params);
    dispatch(setTotalSalesDay(res));
    dispatch(setLoading("statistic"));
  } catch (err) {
    dispatch(setLoading("statistic"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getDailyRevenue = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("statistic"));
    const res = await statisticApi.dailyRevenue(params);
    dispatch(setDailyRevenueTable(res));
    dispatch(setLoading("statistic"));
  } catch (err) {
    dispatch(setLoading("statistic"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getTotalPosSalesPerCitiesCurrentDay = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("statistic"));
    const res = await statisticApi.totalPosSalesPerCities(params);
    dispatch(setTotalPosSalesPerCitiesCurrentDay(res));
    dispatch(setLoading("statistic"));
  } catch (err) {
    dispatch(setLoading("statistic"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getTotalPosSalesPerCitiesCurrentMonth = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("statistic"));
    const res = await statisticApi.totalPosSalesPerCities(params);
    dispatch(setTotalPosSalesPerCitiesCurrentMonth(res));
    dispatch(setLoading("statistic"));
  } catch (err) {
    dispatch(setLoading("statistic"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
export const Statistic = {
  getActive,
  getRevenue,
  getTopFivePos,
  getTopFiveItems,
  getTotalSalesMonth,
  getTotalSalesDay,
  getDailyRevenue,
  getTotalPosSalesPerCitiesCurrentMonth,
  getTotalPosSalesPerCitiesCurrentDay,
};
