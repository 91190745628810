import { Grid } from "@mui/material";
import CardsHeader from "./home/header";
import CardsTable from "./home/table";
const Cards = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CardsHeader />
      </Grid>
      <Grid item xs={12}>
        <CardsTable />
      </Grid>
    </Grid>
  );
};

export default Cards;
