import { Card, CardHeader, Typography, Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../app/slices/externalRevenueSlice";
import { GiTakeMyMoney } from "react-icons/gi";
import ExternalRevenueForm from "../../from";

const ExternalRevenueHeader = () => {
  const _ExternalRevenue = useSelector((state) => state.externalRevenue.externalRevenue);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            ارباح خارجية
          </Typography>
        }
        avatar={<GiTakeMyMoney size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            إضافة
          </Button>
        }
      />
      {_ExternalRevenue.dialog && <ExternalRevenueForm />}
    </Card>
  );
};

export default ExternalRevenueHeader;
