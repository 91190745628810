import { Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { CiUser } from "react-icons/ci";

const UserHeader = () => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            الارصدة والديون
          </Typography>
        }
        avatar={<CiUser size={20} />}
      />
      {/* {_User.dialog ? <UserForm /> : null} */}
    </Card>
  );
};

export default UserHeader;
