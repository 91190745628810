import dayjs from "dayjs";
import {
  createSlice
} from "@reduxjs/toolkit";
import {
  showNotification
} from "./notificationSlice";
import {
  factory
} from "../../api/apiFactory";
const notificationApi = factory.get("notification");
const initialState = {
  searchBody: {},
  notification: {
    loading: false,
    dialog: false,
    form: {
      id: null,
      title: "",
      description: "",
      users: [],
      groups: [],
      is_all: false,
      is_all_rep: false,
      is_all_pos: false,
      target_users: ""
    },
  },
  notifications: {
    loading: false,
    data: [],
    total: 0,
  },
}

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.notification.dialog = !state.notification.dialog
    },
    setDataTable: (state, {
      payload
    }) => {
      state.notifications.data = payload.data.data
      state.notifications.total = payload.data.total
    },
    setById: (state, {
      payload
    }) => {
      state.notification.form.id = payload.id
      state.notification.form.title = payload.title
      state.notification.form.description = payload.description
      state.notification.form.target_users = payload.target_users

    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.notification.form = initialState.notification.form
    },
    setSearchBody: (state, {
      payload
    }) => {
      state.searchBody = payload;
    },
  }
})

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody
} = notificationSlice.actions

export default notificationSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('notification'));
    const res = await notificationApi.getAll(params)
    dispatch(setDataTable(res));
    dispatch(setLoading('notification'));

  } catch (err) {
    dispatch(setLoading('notification'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await notificationApi.delete(id)
    dispatch(showNotification({
      message: "تم الحذف بنجاح",
      type: "success"
    }));
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading('notification'));
    const res = await notificationApi.getById(id)
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog())
    dispatch(setLoading('notification'));
  } catch (err) {
    dispatch(setLoading('notification'));
    dispatch(showNotification({
      message: err.response.data.message,
      type: "error"
    }));
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await notificationApi.create(data)
    dispatch(showNotification({
      message: "تمت الاضافة بنجاح",
      type: "success"
    }));
    dispatch(setDialog())
    dispatch(getAll())
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await notificationApi.update(id, data)
    dispatch(showNotification({
      message: "تم تعديل المنتج بنجاح",
      type: "success"
    }));
    dispatch(setDialog(_SearchBody))
    dispatch(getAll())
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};

export const Notification = {
  getAll,
  deleteById,
  getById,
  create,
  update
}