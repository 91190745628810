import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./../notificationSlice";
import { factory } from "../../../api/apiFactory";
const JSubCategoryApi = factory.get("jSubCategory");
const initialState = {
  searchBody: {
    name: "",
  },
  dialog: false,
  JSubCategory: {
    loading: false,
    dialog: false,
    form: {
      id: "",
      name: "",
      logo: "",
      j_category_id: null,
      is_active: true,
    },
  },
  JSubCategories: {
    loading: false,
    data: [],
    total: 0,
  },
  JSubCategoriesList: {
    loading: false,
    data: [],
    total: 0,
  },
};

export const JSubCategorySlice = createSlice({
  name: "JSubCategories",
  initialState,
  reducers: {
    setPageDialog: (state, action) => {
      state.dialog = !state.dialog;
    },
    setDialog: (state, action) => {
      state.JSubCategory.dialog = !state.JSubCategory.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.JSubCategories.data = payload.data.data;
      state.JSubCategories.total = payload.data.total;
    },
    setDataList: (state, { payload }) => {
      state.JSubCategoriesList.data = payload.data.data;
    },
    setById: (state, { payload }) => {
      state.JSubCategory.form.id = payload.id;
      state.JSubCategory.form.name = payload.name;
      state.JSubCategory.form.logo = payload.logo;
      state.JSubCategory.form.j_category_id = payload.j_category_id ?? null;
      state.JSubCategory.form.is_active =
        payload.is_active === 1 ? true : false;
    },
    setLoading: (state, action) => {
      state.JSubCategories.loading = !state.JSubCategories.loading;
    },
    resetForm: (state, action) => {
      state.JSubCategory.form = initialState.JSubCategory.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDataList,
  setPageDialog,
} = JSubCategorySlice.actions;

export default JSubCategorySlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("JSubCategory"));
    const res = await JSubCategoryApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("JSubCategory"));
  } catch (err) {
    dispatch(setLoading("JSubCategory"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("JSubCategory"));
    const res = await JSubCategoryApi.getAllList(params);
    dispatch(setDataList(res));
    dispatch(setLoading("JSubCategory"));
  } catch (err) {
    dispatch(setLoading("JSubCategory"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await JSubCategoryApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("JSubCategory"));
    const res = await JSubCategoryApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("JSubCategory"));
  } catch (err) {
    dispatch(setLoading("JSubCategory"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, _SearchBody) => async (dispatch) => {
  try {
    await JSubCategoryApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await JSubCategoryApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const JSubCategories = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  getAllList,
};
