import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  active(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/statistics/active?${params}`);
  },
  revenue(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/statistics/revenue?${params}`);
  },
  topFivePos(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/statistics/top-five?${params}`);
  },
  topFiveItems(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/statistics/top-items?${params}`);
  },
  totalSales(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/statistics/total-sales?${params}`);
  },
  dailyRevenue(data) {
    return api.post(`/api/v1/statistics/daily-revenue`, data);
  },
  totalPosSalesPerCities(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/statistics/total-cities?${params}`);
  },
};
