import Typography from "@mui/material/Typography";
import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import { MdOutlineClear } from "react-icons/md";
import dayjs from "dayjs";
import { SelectPos } from "../../../../../components/Selectors/SelectPos";
import { SelectIssuedCardStatus } from "../../../../../components/Selectors/SelectIssuedCardStatus";
import { ChangeStatus } from "./statusMenu";
import { ExportButton } from "../../../../../components/ExportExcel/ExportButton";
import {
  IssuedCards,
  setDialog,
  setSearchBody,
} from "../../../../../app/slices/issuedCardSlice";
import { setUpdateDialog } from "../../../../../app/slices/issuedCardSlice";
import IssuedOrderIdForm from "../../form/order_issued_number";
import IssuedCardUpdateForm from "../../form/updateForm";

const IssuedCardTable = () => {
  const dispatch = useDispatch();
  const _IssuedCard = useSelector((state) => state.issuedCard.issuedCards);
  const _SearchBody = useSelector((state) => state.issuedCard.searchBody);
  const _PointOfsales = useSelector(
    (state) => state.pointOfsales.posesList.data
  );
  const _IssuedCardStatus = useSelector((state) => state.issuedCard.statusList);
  const _IssuedCardUpdateDialog = useSelector((state) => state.issuedCard.updateForm.dialog);
  const _OrderIssuedCardDialog = useSelector((state) => state.issuedCard.issuedOrderId.dialog);

  const [search, setSearch] = useState({
    name: "",
    pos_id: null,
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  // function CustomFooter() {
  //   return (
  //     <Stack
  //       direction="row"
  //       sx={{ justifyContent: "space-between", alignIssuedCard: "center" }}
  //     >
  //       <Typography color="primary" sx={{ marginX: 2 }}>
  //         القيمة الكلية : {_IssuedCard.total_amount.toLocaleString()} (IQD)
  //       </Typography>
  //       <GridPagination />
  //     </Stack>
  //   );
  // }

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(IssuedCards.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const changeStatus = (id, status) => {
    let info = {
      status_id: status?.id,
    };

    dispatch(IssuedCards.updateStatus(id, info, _SearchBody, false));
  };

  const columns = [
    {
      field: "sequence",
      headerName: "#",
      flex: 0.5,
    },
    {
      field: "id_no",
      headerName: "رقم العملية",
      flex: 1,
    },
    {
      field: "ar_name",
      headerName: "الاسم",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Typography sx={{ lineBreak: "anywhere" }} variant="body">
            {params.row.ar_name}
          </Typography>
          <Typography sx={{ lineBreak: "anywhere" }} variant="body">
            {params.row.en_name}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "mobile",
      headerName: "رقم الهاتف",
      flex: 1,
    },
    {
      field: "created_by_name",
      headerName: "المنشئ",
      flex: 1,
    },
    {
      field: "status",
      headerName: "الحالة",
      flex: 1,
      renderCell: (params) => {
        return (
          <ChangeStatus
            status_id={params.row.status_id}
            id={params.row.id}
            status={params.row.status}
            openDialog={() => {
              dispatch(setDialog({ status_id: 2, id: params.row.id }))
            }}
            onChangeStatus={(item) => {
              changeStatus(params.row.id, item);
            }}
          />
        );
      },
    },
    {
      field: "created_at",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) =>
        dayjs(params.row.created_at).format("YYYY-MM-DD / hh:mm a"),
      valueGetter: (params) =>
        dayjs(params.row.created_at).format("YYYY-MM-DD / hh:mm a"),
    },
    {
      field: "order_issued_id",
      headerName: "رقم طلب الاصدار",
      flex: 1,
    },
    {
      field: "attachments",
      headerName: "المرفقات",
      flex: 2,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          {params.row.attachment.map((attachment) => (
            <Box
              key={attachment.id}
              width={100}
              sx={{ cursor: "pointer", objectFit: "cover" }}
              onClick={() => window.open(attachment?.path, "_blank")}
              component="img"
              src={attachment?.path}
            />
          ))}
        </Stack>
      ),
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => dispatch(IssuedCards.getByIdForUpdate(params.row.id))}
            >
              <BiEdit />
            </IconButton>
            <IconButton onClick={() => handleDeleteClick(params.row)}>
              <BiTrash />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(IssuedCards.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        search: search.search ?? "",
        pos_id: search.pos_id ?? "",
        status_id: search.status_id ?? "",
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize, search]);

  const customToolbar = () => (
    <GridToolbarContainer>
      <ExportButton fileName={" طلبات اصدار البطاقات | نظام دجلة"} />
    </GridToolbarContainer>
  );

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
          {_OrderIssuedCardDialog ? <IssuedOrderIdForm ids={rowSelectionModel} /> : null}
          {_IssuedCardUpdateDialog ? <IssuedCardUpdateForm /> : null}
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2}>
            <SelectPos
              onChange={(event, value) => {
                setSearch({
                  ...search,
                  pos_id: value ? value.id : null,
                });
              }}
              value={
                _PointOfsales.filter((x) => x.id === search.pos_id)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={2}>
            <SelectIssuedCardStatus
              onChange={(event, value) => {
                setSearch({
                  ...search,
                  status_id: value ? value.id : null,
                });
              }}
              value={
                _IssuedCardStatus.filter((x) => x.id === search.status_id)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={search.search}
              onChange={(e) => {
                setSearch({
                  ...search,
                  search: e.target.value,
                });
              }}
              label="بحث عن الاسم "
              fullWidth
            />
          </Grid>
          <Grid item xs={2.5}>
            <Stack
              direction="row"
              spacing={0}
              sx={{ alignIssuedCard: "center" }}
            >
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      search: "",
                      status_id: null,
                      pos_id: null,
                    });
                    setPageSize(15);
                    setCurrentPage(0);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              disabled={rowSelectionModel.length === 0}
              onClick={() => {
                dispatch(setUpdateDialog());
              }}
            >
              تحديث رقم طلب الاصدار
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "80vh", width: "100%" }}>
              {_IssuedCard.data ? (
                <DataGrid
                  rows={_IssuedCard.data.map((x) => {
                    return { ...x, sequence: _IssuedCard.data.indexOf(x) + 1 };
                  })}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_IssuedCard.total}
                  loading={_IssuedCard.loading}
                  rowHeight={100}
                  pagination
                  paginationMode="server"
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                    // Footer: CustomFooter,
                    Toolbar: customToolbar,
                  }}
                  disableSelectionOnClick
                  checkboxSelection
                  selectionModel={rowSelectionModel}
                  onSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default IssuedCardTable;
