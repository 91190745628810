import api from "../../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  jPos(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== "" && data[key] !== undefined) {
          params += `${key}=${data[key]}&`;
        }
      });
    }
    return api.get(`/api/v1/j-pos?${params}`);
  },
  export(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== "" && data[key] !== undefined) {
          params += `${key}=${data[key]}&`;
        }
      });
    }
    return api.get(`/api/v1/j-pos?${params}`, { responseType: "blob" });
  },
};
