import { Box, Card, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import Transactions from "./dejlah";
import JTransactions from "./jomla";
import { useSelector } from "react-redux";
import PosForm from "../../POS/form";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TransactionsTabs = () => {
  const _PointsOfSales = useSelector((state) => state.pointOfsales);
  const isWholeSale = JSON.parse(localStorage.getItem("isWholeSale"));
  const _Role = useSelector((state) => state.user.userInfo.user_roles[0]);

  const [value, setValue] = useState(isWholeSale ?? 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("isWholeSale", newValue);
  };

  return (
    <Box>
      {_Role &&
        (_Role === "wholesale" ? (
          <Card sx={{ mt: 2 }}>
            <JTransactions />
          </Card>
        ) : (
          <Card sx={{ mt: 2 }}>
            {_PointsOfSales.pos.dialog ? <PosForm /> : null}
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs"
                variant="fullWidth"
              >
                {_Role === "wholesale" ? null : (
                  <Tab label="دجلة" {...a11yProps("0")} />
                )}
                <Tab label="الجملة" {...a11yProps("1")} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Transactions />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <JTransactions />
            </TabPanel>
          </Card>
        ))}
    </Box>
  );
};

export default TransactionsTabs;
