import { Grid } from "@mui/material";
import React from "react";
import TotalsHeader from "./home/header";
import TotalsTable from "./home/table";

const TotalsReport = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TotalsHeader />
      </Grid>
      <Grid item xs={12}>
        <TotalsTable />
      </Grid>
    </Grid>
  );
};

export default TotalsReport;
