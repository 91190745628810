import Typography from "@mui/material/Typography";
import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Select,
  MenuItem,
  Button,
  Stack,
  Tooltip,
  Chip,
  FormControl,
  InputLabel,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearchBody,
  OrderRechargeCards,
  setUpdateDialog,
} from "../../../../../app/slices/orderRechargeCardsSlice";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import dayjs from "dayjs";
import DateRangepicker from "../../../../../helpers/dateRangePicker";
import { MdOutlineClear } from "react-icons/md";
import { BsFillFileEarmarkExcelFill } from "react-icons/bs";
import OrderRechargeCardNumberForm from "../../form";

const OrderRechargeCardsTable = () => {
  const dispatch = useDispatch();

  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const _orderRechargeCards = useSelector(
    (state) => state.orderRechargeCards.orderRechargeCards
  );
  const _SearchBody = useSelector(
    (state) => state.orderRechargeCards.searchBody
  );
  const _Role = useSelector((state) => state.user.userInfo.user_roles[0]);

  const [search, setSearch] = useState({
    search: "",
    user_id: null,
    pos_number: null,
    card_id: null,
    amount: "",
    is_send: "",
    send_by_id: "",
    start_date: dayjs("2023-01-01").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
    is_excel: "",
    is_complete: "",
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };
  const handleConfirmDelete = () => {
    dispatch(OrderRechargeCards.deleteById(dialogData.id));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "pos_name",
      headerName: "اسم النقطة",
      flex: 1,
    },
    {
      field: "card_number",
      headerName: "معلومات البطاقة",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            <Typography>{params.row.card_number}</Typography>
            <Typography>{params.row.card_holder_name}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "amount",
      headerName: "المبلغ",
      flex: 0.5,
    },
    {
      field: "revenue",
      headerName: "الربح",
      flex: 0.5,
    },
    {
      field: "created_at",
      headerName: "تاريخ الطلب",
      flex: 0.8,
      renderCell: (params) => {
        return dayjs(params.row.created_at).format("HH:mm / YYYY-MM-DD ");
      },
    },
    {
      field: "order_recharge_number",
      headerName: "رقم طلب الشحن",
      flex: 1,
    },
    {
      field: "order_recharge",
      headerName: "رفع الطلب",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            <Typography>{params.row.send_by}</Typography>
            <Typography>
              {params.row.send_at
                ? dayjs(params.row.send_at).format("HH:mm / YYYY-MM-DD ")
                : ""}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "is_send",
      headerName: "حالة الطلب",
      flex: 0.6,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            variant="outlined"
            label={params.row.is_send ? "تم الارسال" : "غير مرسل"}
            color={params.row.is_send ? "success" : "error"}
          ></Chip>
        );
      },
    },
    {
      field: "is_complete",
      headerName: "حالة الشحن",
      flex: 0.6,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={params.row.is_complete ? "مكتمل" : "غير مكتمل"}
            color={params.row.is_complete ? "success" : "error"}
          ></Chip>
        );
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 0.4,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleDeleteClick(params.row)}>
            <BiTrash />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(OrderRechargeCards.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        search: search.search,
        user_id: search.user_id ? search.user_id.id : "",
        card_id: search.card_id ? search.card_id.id : "",
        amount: search.amount ? search.amount : "",
        send_by_id: search.send_by_id ? search.send_by_id : "",
        start_date: search.start_date,
        end_date: search.end_date,
        is_excel: search.is_excel ? search.is_excel : "",
        pos_number: search.pos_number ? search.pos_number : "",
        is_send: search.is_send,
        is_complete: search.is_complete,
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize, search]);
  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.card_holder_name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
          <OrderRechargeCardNumberForm ids={rowSelectionModel} />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={search.pos_number}
              onChange={(e) => {
                setSearch({
                  ...search,
                  pos_number: e.target.value,
                });
              }}
              label="بحث عن رقم نقطة المبيعات"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={search.card}
              onChange={(e) => {
                setSearch({
                  ...search,
                  amount: e.target.value,
                });
              }}
              label="بحث عن رقم البطاقة"
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={2}>
            <SelectUser
              value={search.user_id}
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  user_id: newValue ? newValue : null,
                });
              }}
            />
          </Grid> */}
          {/* <Grid item xs={2}>
            <SelectCard
              value={search.card_id}
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  card_id: newValue ? newValue : null,
                });
              }}
            />
          </Grid> */}
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>حالة الطلب</InputLabel>
              <Select
                size="small"
                value={search.is_send}
                onChange={(e) => {
                  setSearch({
                    ...search,
                    is_send: e.target.value,
                  });
                }}
                label="حالة الطلب"
                fullWidth
              >
                <MenuItem value={1}> تم الارسال</MenuItem>
                <MenuItem value={0}>غير مرسل</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>حالة الشحن</InputLabel>
              <Select
                size="small"
                value={search.is_complete}
                onChange={(e) => {
                  setSearch({
                    ...search,
                    is_complete: e.target.value,
                  });
                }}
                label="حالة الشحن"
                fullWidth
              >
                <MenuItem value={1}>مكتمل</MenuItem>
                <MenuItem value={0}>غير مكتمل</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].start_date).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].end_date).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.start_date ||
                  updatedFilter.to !== searchDate.end_date
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].start_date).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(searchDate[0].end_date).format(
                      "YYYY-MM-DD"
                    ),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container columnSpacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  disabled={rowSelectionModel.length === 0}
                  onClick={() => {
                    dispatch(setUpdateDialog());
                  }}
                >
                  تحديث رقم طلب الشحن
                </Button>
              </Grid>
              {_Role === "representative" ? null : (
                <Grid item xs={3}>
                  <Button
                    disabled={rowSelectionModel.length === 0}
                    variant="contained"
                    onClick={() =>
                      dispatch(
                        OrderRechargeCards.exportFile({
                          ids: rowSelectionModel,
                        })
                      )
                    }
                  >
                    تصدير الملف CSV
                  </Button>
                </Grid>
              )}
              <Grid item xs={3}>
                <Tooltip title="الغاء الفلتر">
                  <IconButton
                    onClick={() => {
                      setSearch({
                        search: "",
                        user_id: null,
                        card_id: null,
                        pos_number: null,
                        amount: "",
                        is_send: "",
                        send_by_id: "",
                        start_date: dayjs("2023-01-01").format("YYYY-MM-DD"),
                        end_date: dayjs().format("YYYY-MM-DD"),
                        is_excel: "",
                        is_complete: "",
                      });
                      setPageSize(15);
                      setCurrentPage(0);
                    }}
                  >
                    <MdOutlineClear />
                  </IconButton>
                </Tooltip>
                <Tooltip title="تصدير ملف اكسل">
                  <IconButton
                    onClick={() => {
                      dispatch(OrderRechargeCards.exportExcel(_SearchBody));
                    }}
                  >
                    <BsFillFileEarmarkExcelFill />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ height: "63vh", width: "100%" }}>
              {_orderRechargeCards.data ? (
                <DataGrid
                  rows={_orderRechargeCards.data}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_orderRechargeCards.total}
                  loading={_orderRechargeCards.loading}
                  rowHeight={65}
                  pagination
                  paginationMode="server"
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                  checkboxSelection
                  selectionModel={rowSelectionModel}
                  onSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OrderRechargeCardsTable;
