import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
  Divider,
  LinearProgress,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  JOrder,
  resetForm,
  setAddItemToOrderDialog,
} from "../../../../app/slices/jomla/orderSlice";
import { NumericFormat } from "react-number-format";
import { toNumber } from "../../../../helpers/toNumber";
import { SelectJItem } from "../../../../components/Selectors/jomla/SelectJItem";
import { showNotification } from "../../../../app/slices/notificationSlice";
import useDebounce from "../../../../components/hooks/useDebounce";
import { JItems } from "../../../../app/slices/jomla/itemsSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddItemToOrderForm = () => {
  const dispatch = useDispatch();

  const _JOrder = useSelector((state) => state.jOrder.addItemToOrder);
  const _JItem = useSelector((state) => state.JItems.JItemsList.data);
  const _ListSearchBody = useSelector(
    (state) => state.JItems.JItemsList.searchBody
  );

  const [loading, setLoading] = useState(false);
  const [unitPrice, setUnitPrice] = useState(0);

  const { control, reset, handleSubmit, setValue, watch } = useForm({
    defaultValues: _JOrder.form,
  });

  const initialTotalPrice = watch("total_price");
  const initialCount = watch("count");

  useEffect(() => {
    // Calculate the unit price when the component mounts or when initial values change
    if (initialCount > 0) {
      setUnitPrice(initialTotalPrice / initialCount);
    }
  }, [initialTotalPrice, initialCount]);


  const onSubmit = (data) => {
    setLoading(true);
    dispatch(
      JOrder.addItemToOrder(
        {
          j_order_id: parseInt(_JOrder.form.j_order_id),
          j_item_id: data.j_item_id,
          count: data.count,
        },
        "orderDetails"
      )
    )
      .then((x) => setLoading(false))
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    reset({ ..._JOrder.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_JOrder.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialog = () => {
    dispatch(setAddItemToOrderDialog({}));
  };

  const debouncedSearch = useDebounce(_ListSearchBody, 500);

  useEffect(() => {
    dispatch(JItems.getAllList(debouncedSearch));
  }, [debouncedSearch, dispatch]);

  return (
    <Dialog
      open={_JOrder.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="sm"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography variant="h4" color={"purple"} gutterBottom component="div">
          {"اضافة منتج الى الطلب"}
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          {_JOrder.loading && <LinearProgress sx={{ marginBottom: 1 }} />}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"j_item_id"}
                render={({ field }) => (
                  <SelectJItem
                    {...field}
                    required
                    onChange={(e, newValue) => {
                      if (!newValue?.is_no_stock && newValue?.count < 1)
                        dispatch(
                          showNotification({
                            message: "العدد غير كافي لهذا العنصر",
                            type: "error",
                          })
                        );
                      else {
                        setValue("j_item_id", newValue ? newValue.id : null);
                        setValue("count", 1);
                        setValue(
                          "total_price",
                          newValue ? newValue.price * watch("count") : 0
                        );
                      }
                    }}
                    value={
                      _JItem.filter((x) => x.id === field.value)[0] ?? null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"count"}
                render={({ field }) => (
                  <NumericFormat
                    required
                    thousandSeparator
                    customInput={TextField}
                    value={field.value}
                    onChange={(e) => {
                      setValue("count", toNumber(e.target.value));
                      setValue(
                        "total_price",
                        unitPrice * toNumber(e.target.value)
                      );
                    }}
                    valueIsNumericString={true}
                    allowNegative={false}
                    fullWidth
                    label="العدد"
                    size="small"
                  />
                )}
              />
            </Grid>
            {watch("total_price") > 0 ? (
              <Grid item xs={12}>
                <Typography variant="body">السعر الكلي: {watch("total_price")?.toLocaleString()} IQD</Typography>
              </Grid>
            ) : null}
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading ? true : false}
                endIcon={loading ? <CircularProgress size={25} /> : null}
              >
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddItemToOrderForm;
