import { useState } from "react";
import { Menu, MenuItem, Chip, Typography, ListItemText } from "@mui/material";
import { useSelector } from "react-redux";

export function ChangeStatusByChip(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const _JOrderStatus = useSelector(
    (state) => state.jOrder.jOrderStatuses.data
  );

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterdStatus = _JOrderStatus.filter((item) => item.id === props.id)[0];

  return (
    <>
      <Menu
        id={`actions-${props.id}`}
        anchorEl={anchorEl}
        keepMounted
        disableAutoFocusItem
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {_JOrderStatus.map((item, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              props.onChangeStatus(item);
              handleClose();
            }}
            disabled={item.id === 6 || item.id === 7 || item.id === 9}
          >
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Menu>

      <Chip
        variant="outlined"
        size="small"
        label={
          <Typography fontSize={"small"}>{filterdStatus?.name}</Typography>
        }
        onClick={(e) => {
          handleMenuClick(e);
        }}
      />
    </>
  );
}
