import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/transfer/index?${params}`);
  },
  create(data) {
    return api.post(`/api/v1/transfer`, data);
  },
  getAllSms(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/transfer/get-sms?${params}`);
  },
  refunded(data) {
    return api.post(`/api/v1/transfer/refunded`, data);
  },
  cancelRefunded(data) {
    return api.post(`/api/v1/transfer/cancel-refunded`, data);
  },
};
