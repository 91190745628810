import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiEdit } from "react-icons/bi";
import { MdOutlineClear } from "react-icons/md";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SettingsActions,
  setDialog,
  setSearchBody,
} from "../../../../app/slices/settingsSlice";

const SettingsTable = () => {
  const dispatch = useDispatch();

  const _Settings = useSelector((state) => state.settings.settings);
  const loading = useSelector((state) => state.settings.settings.loading);
  const _SearchBody = useSelector((state) => state.settings.searchBody);

  const [search, setSearch] = useState(_SearchBody);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  const columns = [
    {
      field: "index",
      sortable: false,
      headerName: "#",
      flex: 0.5,
    },
    {
      field: "key",
      sortable: false,
      headerName: "العنصر ",
      flex: 1,
    },
    {
      field: "value",
      sortable: false,
      headerName: "القيمة ",
      flex: 1,
      renderCell: (params) => {
        const parsedValue = parseFloat(params.row.value);
        return (
          <Typography variant="body">
            {!isNaN(parsedValue)
              ? parsedValue.toLocaleString()
              : params.row.value}
          </Typography>
        );
      },
    },
    {
      field: "note",
      sortable: false,
      headerName: "الملاحظة",
      flex: 1,
    },
    // {
    //   field: "created_at",
    //   headerName: "التاريخ",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Stack>
    //       <Typography variant="body">
    //         {dayjs(params.row.created_at).format("YYYY-MM-DD ")}
    //       </Typography>
    //       <Typography variant="body">
    //         {dayjs(params.row.created_at).format("hh:mm a")}
    //       </Typography>
    //     </Stack>
    //   ),
    // },
    {
      field: "operations",
      sortable: false,
      headerName: "العمليات",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() =>
                dispatch(
                  setDialog({
                    id: params.row.id,
                    key: params.row.key,
                    value: params.row.value,
                    note: params.row.note,
                  })
                )
              }
            >
              <BiEdit />
            </IconButton>
            {/* <IconButton onClick={() => handleDeleteClick(params.row)}>
              <BiTrash />
            </IconButton> */}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(SettingsActions.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        ...search,
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize, search]);

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              size="small"
              value={search.key}
              onChange={(e) => {
                setSearch({
                  ...search,
                  key: e.target.value,
                });
              }}
              label="بحث حسب العنصر"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      key: "",
                    });
                    setPageSize(15);
                    setCurrentPage(0);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_Settings.data ? (
                <DataGrid
                  rows={_Settings.data.map((x, index) => {
                    return { ...x, index: index + 1 };
                  })}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_Settings.total}
                  loading={loading}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => setCurrentPage(newPage)}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SettingsTable;
