import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import PayCashForm from "./payCash";


const TransactionForm = () => {
  const _User = useSelector((state) => state.user.user.form);
  const _ProductSupplier = useSelector((state) => state.productSupplier.transactions);


  return (
    <Box>
      {_ProductSupplier.dialog && <PayCashForm userData={_User} />}
    </Box>
  );
};

export default TransactionForm;
