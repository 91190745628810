import Typography from "@mui/material/Typography";
import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Divider,
  Box,
} from "@mui/material";
import {
  DataGrid,
  GridPagination,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Wallet } from "../../../../../../app/slices/walletSlice";
import { Supplier } from "../../../../../../app/slices/supplierSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { MdOutlineClear } from "react-icons/md";
import DateRangepicker from "../../../../../../helpers/dateRangePicker";
import { SelectTransactionTypes } from "../../../../../../components/Selectors/SelectTransactionTypes";
import { BiInfoCircle } from "react-icons/bi";

const SupplierTransactionsTable = () => {
  const { supplier_id } = useParams();
  const [page, setPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState({
    name: "",
    type: null,
    date: "",
    startDate: dayjs().format("2023-01-01"),
    endDate: dayjs().format("YYYY-MM-DD"),
    skip: 0,
    transaction_number: "",
    operation_id: "",
  });
  const dispatch = useDispatch();

  const _Loading = useSelector((state) => state.wallet.wallet.loading);
  const _Wallet = useSelector((state) => state.wallet);
  const _Supplier = useSelector((state) => state.supplier.supplier.form);

  const columns = [
    {
      field: "number",
      headerName: "رقم المناقلة",
      flex: 1,
    },
    {
      field: "operation_id",
      headerName: "رقم العملية",
      flex: 1,
      renderCell: (params) =>
        params.row.operation_id ?? params.row.operation_transfer_id,
    },
    {
      field: "supplier_name",
      headerName: "اسم المورد",
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.supplier.name}</>;
      },
    },
    {
      field: "amount",
      headerName: "القيمة (IQD)",
      flex: 1,
      renderCell: (params) => {
        return <Typography>{params.row.amount.toLocaleString()}</Typography>;
      },
    },
    {
      field: "by_user",
      headerName: "بواسطة",
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.by_user.name}</>;
      },
    },
    {
      field: "created_at",
      headerName: "تاريخ العملية",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack spacing={0.5}>
            <Typography variant="body">
              {dayjs(params.row.created_at).format("YYYY-MM-DD")}
            </Typography>
            <Typography variant="body">
              {dayjs(params.row.created_at).format("hh:mm:ss a")}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "current_balance",
      headerName: "الرصيد الحالي",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>
            {parseInt(params.row.current_balance).toLocaleString()}
          </Typography>
        );
      },
    },
    {
      field: "type",
      headerName: "نوع العملية",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <Typography>{params.row.type}</Typography>
            {params.row.note && (
              <Tooltip title={params.row.note}>
                <IconButton size="small">
                  <BiInfoCircle />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
    {
      field: "operation_details",
      headerName: "تفاصيل العملية",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            <Typography variant="caption">
              {params.row.operation_pin_item}
            </Typography>
            <Typography variant="caption">
              {params.row.operation_pin_buying__price}
            </Typography>
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        Wallet.getSupplierTransactions({
          supplier_id: supplier_id,
          skip: currentPage * page,
          take: page,
          type: search.type ? search.type.key : "",
          start_date: search.startDate,
          end_date: search.endDate,
          transaction_number: search.transaction_number,
          operation_id: search.operation_id,
        })
      );
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, supplier_id, _Wallet.reload, currentPage, page]); //search

  useEffect(() => {
    dispatch(Supplier.getByIdData(supplier_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier_id]);

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          القيمة الكلية :{" "}
          {_Wallet.supplierTransactions.total_amount.toLocaleString()} (IQD)
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  const customToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: "تقرير معاملات الموردين | نظام دجلة",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid container spacing={2} sx={{ mx: 1 }}>
            <Grid item xs={3}>
              <Stack spacing={2}>
                <Typography variant="h4">
                  المورد : {_Supplier ? _Supplier.name : ""}
                </Typography>
                <Typography variant="h4">
                  الرصيد:{" "}
                  {_Supplier
                    ? `IQD ${parseInt(_Supplier.balance)?.toLocaleString()}`
                    : ""}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
          </Grid>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={search.transaction_number}
              onChange={(e) => {
                setSearch({
                  ...search,
                  transaction_number: e.target.value,
                });
              }}
              label="بحث عن رقم المناقلة"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <SelectTransactionTypes
              value={search.type}
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  type: newValue ? newValue : null,
                });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={search.operation_id}
              onChange={(e) => {
                setSearch({
                  ...search,
                  operation_id: e.target.value,
                });
              }}
              label="بحث عن رقم العملية"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <DateRangepicker
              startDate={search.startDate}
              endDate={search.endDate}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    startDate: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    endDate: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      name: "",
                      type: null,
                      date: "",
                      startDate: dayjs().format("2023-01-01"),
                      endDate: dayjs().format("YYYY-MM-DD"),
                      skip: 0,
                      take: 15,
                      transaction_number: "",
                      operation_id: "",
                    });
                    setPage(15);
                    setCurrentPage(0);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "57vh", width: "100%" }}>
              <DataGrid
                rows={_Wallet.supplierTransactions.data}
                columns={columns}
                pageSize={page}
                page={currentPage}
                rowCount={_Wallet.supplierTransactions.total}
                loading={_Loading}
                rowHeight={50}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPage(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                  Footer: CustomFooter,
                  Toolbar: customToolbar,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SupplierTransactionsTable;
