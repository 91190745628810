import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/issued-card?${params}`);
  },
  getById(id) {
    return api.get(`/api/v1/issued-card/${id}`);
  },
  updateStatus(id, data) {
    return api.post(`/api/v1/issued-card/issued/${id}`, data);
  },
  update(id, data) {
    return api.post(`/api/v1/issued-card/admin/${id}`, data);
  },
  create(data) {
    return api.post(`/api/v1/issued-card`, data);
  },
  delete(id) {
    return api.delete(`/api/v1/issued-card/${id}`);
  },
  getStatus() {
    return api.get(`/api/v1/issued-card/get-status`);
  },
  updateNumber(data) {
    return api.put(`/api/v1/issued-card/update-order-issuing-number`, data);
  },
};
