import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(data) {
    var team_leader = "";
    // var team_leader = localStorage.getItem('role') =='team_leader' ? '/team':''
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== "" && data[key] !== undefined) {
          params += `${key}=${data[key]}&`;
        }
      });
    }
    return api.get(`/api/v1/pos${team_leader}?${params}`);
  },
  getAllList() {
    return api.post(`/api/v1/pos/list`);
  },
  getById(id) {
    return api.get(`/api/v1/pos/${id}`);
  },
  getByUserId(id) {
    return api.get(`/api/v1/pos/user/${id}`);
  },
  update(id, data) {
    return api.put(`/api/v1/pos/${id}`, data);
  },
  create(data) {
    var team_leader =
      localStorage.getItem("role") == "representative" ? "/create/by-rep" : "";
    return api.post(`/api/v1/pos${team_leader}`, data);
  },
  delete(id) {
    return api.delete(`/api/v1/pos/${id}`);
  },
  export(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/pos?${params}`, { responseType: "blob" });
  },
  getLastOpt(id) {
    return api.get(`/api/v1/user/last-otp/${id}`);
  },
};
