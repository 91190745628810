import { forwardRef, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Box,
  LinearProgress,
  Stack,
  Autocomplete,
  TextField,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  Investors,
  setInvestorsTransactionsDialog,
  setInvestorsTransactionsSearchBody,
} from "../../../../app/slices/investorsSlice";
import { DataGrid, GridPagination } from "@mui/x-data-grid";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InvestorsTransactions = () => {
  const dispatch = useDispatch();

  const _InvestorTransactions = useSelector(
    (state) => state.investors.investorTransactions
  );
  const _SearchBody = useSelector(
    (state) => state.investors.investorTransactions.searchBody
  );
  const loading = useSelector((state) => state.investors.investors.loading);

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState(15);

  const handleDialog = () => {
    dispatch(setInvestorsTransactionsDialog(null));
  };

  const type = [
    {
      value: "investor_deposit",
      key: "إيداع",
    },
    { value: "investor_withdraw", key: "سحب" },
  ];

  const columns = [
    {
      field: "number",
      headerName: "رقم المعاملة",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "المبلغ",
      flex: 1,
      renderCell: (params) => params.row.amount.toLocaleString(),
    },
    {
      field: "investor_balance",
      headerName: "رصيد الممول السابق",
      flex: 1,
      renderCell: (params) => params.row.investor_balance.toLocaleString(),
    },
    {
      field: "current_investor_balance",
      headerName: "رصيد الممول الحالي",
      flex: 1,
      renderCell: (params) =>
        params.row.current_investor_balance.toLocaleString(),
    },
    {
      field: "main_balance",
      headerName: "رصيد الخزنة السابق",
      flex: 1,
      renderCell: (params) => params.row.main_balance.toLocaleString(),
    },
    {
      field: "current_main_balance",
      headerName: "رصيد الخزنة الحالي",
      flex: 1,
      renderCell: (params) => params.row.current_main_balance.toLocaleString(),
    },
    {
      field: "by_user",
      headerName: "بواسطة",
      flex: 1,
    },
    {
      field: "type",
      headerName: "النوع",
      flex: 1,
    },
    // {
    //   field: "created_at",
    //   headerName: "التاريخ",
    //   flex: 1,
    //   renderCell: (params) =>
    //     dayjs(params.row.created_at).format("HH:mm / YYYY-MM-DD "),
    // },
  ];

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          المجموع الكلي : {_InvestorTransactions.total_amount.toLocaleString()}{" "}
          (IQD)
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Investors.getAllInvestorTransactions(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setInvestorsTransactionsSearchBody({
        investor_id: _InvestorTransactions.investor_id,
        type: search ?? "",
        skip: currentPage * page,
        take: page,
      })
    );
  }, [_InvestorTransactions.investor_id, currentPage, dispatch, page, search]);

  return (
    <Dialog
      open={_InvestorTransactions.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"المعاملات"}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Autocomplete
              options={type}
              getOptionLabel={(option) => {
                return option.key;
              }}
              renderInput={(params) => (
                <TextField {...params} label="النوع" size="small" />
              )}
              onChange={(e, newValue) => {
                setSearch(newValue ? newValue.value : null);
              }}
              value={type.filter((x) => x.value === search)[0] ?? null}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={
                  _InvestorTransactions.data?.map((x, index) => ({
                    ...x,
                    index: index + 1,
                  })) ?? []
                }
                getRowId={(row) => row.number}
                columns={columns}
                pageSize={page}
                page={currentPage}
                rowCount={_InvestorTransactions.total}
                loading={loading}
                rowHeight={50}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPage(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                  Footer: CustomFooter,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default InvestorsTransactions;
