import api from "../../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key]) {
          params += `${key}=${data[key]}&`;
        }
      });
    }
    return api.get(`/api/v1/safe?${params}`);
  },
  create(data) {
    return api.post(`/api/v1/safe`, data);
  },
  update(id, data) {
    return api.put(`/api/v1/safe/${id}`, data);
  },

  // ================== safe transfer ==================
  getAllSafeTransfer(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key]) {
          params += `${key}=${data[key]}&`;
        }
      });
    }
    return api.get(`/api/v1/safe-transaction?${params}`);
  },
  createSafeTransfer(data) {
    return api.post(`/api/v1/safe-transaction`, data);
  },
};
