import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
  Divider,
  LinearProgress,
  InputAdornment,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  JTransaction,
  resetForm,
  setDialog,
} from "../../../../../app/slices/jomla/transactionsSlice";
import { NumericFormat } from "react-number-format";
import { SelectJSafe } from "../../../../../components/Selectors/jomla/SelectJSafe";
import { useParams } from "react-router";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JTransactionForm = (props) => {
  const dispatch = useDispatch();
  const { supplier_id } = useParams();

  const _JTransaction = useSelector((state) => state.jTransaction.jTransaction);
  const _SearchBody = useSelector((state) => state.jTransaction.searchBody);
  const type = useSelector((state) => state.jTransaction.jTransaction.type);
  const jSafe = useSelector((state) => state.jSafe.jSafes.data);

  const [loading, setLoading] = useState(false);

  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _JTransaction.form,
  });

  const onSubmit = (data) => {
    setLoading(true);
    if (type === "pay") {
      dispatch(
        JTransaction.pay(
          {
            j_supplier_id: supplier_id,
            amount: data.amount,
            note: data.note,
            safe_id: data.safe_id,
          },
          _SearchBody
        )
      )
        .then((x) => setLoading(false))
        .catch((e) => setLoading(false));
    } else {
      dispatch(
        JTransaction.receive(
          {
            j_supplier_id: supplier_id,
            amount: data.amount,
            note: data.note,
            safe_id: data.safe_id,
          },
          _SearchBody
        )
      )
        .then(() => setLoading(false))
        .catch((e) => setLoading(false));
    }
  };

  useEffect(() => {
    reset({ ..._JTransaction.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_JTransaction.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialog = () => {
    dispatch(setDialog());
  };

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: parseInt(values.value),
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });

  return (
    <Dialog
      open={_JTransaction.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="sm"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {type === "pay" ? "تسديد مبالغ " : "استلام مبالغ "}
        </Typography>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          {_JTransaction.loading && <LinearProgress sx={{ marginBottom: 1 }} />}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="المبلغ"
                    fullWidth
                    {...field}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="safe_id"
                control={control}
                render={({ field }) => (
                  <SelectJSafe
                    {...field}
                    required
                    onChange={(e, newValue) => {
                      setValue("safe_id", newValue ? newValue.id : null);
                    }}
                    value={jSafe.filter((x) => x.id === field.value)[0] ?? null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="ملاحظات"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading ? true : false}
                endIcon={loading ? <CircularProgress size={25} /> : null}
              >
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default JTransactionForm;
