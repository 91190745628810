import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const categoryApi = factory.get("category");
const initialState = {
  searchBody: {},
  category: {
    loading: false,
    dialog: false,
    form: {
      id: "",
      name: "",
      logo: "",
      is_product: false,
      is_transfer: false,
      province_ids: [],
      _method: "PUT",
    },
  },
  categories: {
    loading: false,
    data: [],
    total: 0,
  },
  categoriesList: {
    loading: false,
    data: [],
    total: 0,
  },
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.category.dialog = !state.category.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.category.data = payload.data.data;
      state.category.total = payload.data.total;
    },
    setDatalist: (state, { payload }) => {
      state.categoriesList.data = payload.data.data;
      state.categoriesList.total = payload.data.total;
    },
    setById: (state, { payload }) => {
      state.category.form = payload;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.category.form = initialState.category.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDatalist,
} = categorySlice.actions;

export default categorySlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("category"));
    const res = await categoryApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("category"));
  } catch (err) {
    dispatch(setLoading("category"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("category"));
    const res = await categoryApi.getAllList(params);
    dispatch(setDatalist(res));
    dispatch(setLoading("category"));
  } catch (err) {
    dispatch(setLoading("category"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await categoryApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("category"));
    const res = await categoryApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("category"));
  } catch (err) {
    dispatch(setLoading("category"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await categoryApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await categoryApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const Categories = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  getAllList,
};
