import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const subCategoryApi = factory.get("subCategory");
const initialState = {
  searchBody: {},
  subCategory: {
    loading: false,
    dialog: false,
    form: {
      id: "",
      name: "",
      logo: "",
      digit_length: "",
      category_id: null,
      category: {},
      province_ids: [],
    },
  },
  subCategories: {
    loading: false,
    data: [],
    total: 0,
  },
  subCategoriesList: {
    loading: false,
    data: [],
    total: 0,
  },
};

export const subCategorySlice = createSlice({
  name: "subCategory",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.subCategory.dialog = !state.subCategory.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.subCategory.data = payload.data.data;
      state.subCategory.total = payload.data.total;
    },
    setDataList: (state, { payload }) => {
      state.subCategoriesList.data = payload.data.data;
    },
    setById: (state, { payload }) => {
      state.subCategory.form.id = payload.id;
      state.subCategory.form.name = payload.name;
      state.subCategory.form.digit_length = payload.digit_length;
      state.subCategory.form.logo = payload.logo;
      state.subCategory.form.category_id = payload.category;
      state.subCategory.form.province_ids = payload.provinces ?? [];
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.subCategory.form = initialState.subCategory.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDataList,
} = subCategorySlice.actions;

export default subCategorySlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("subCategory"));
    const res = await subCategoryApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("subCategory"));
  } catch (err) {
    dispatch(setLoading("subCategory"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("subCategory"));
    const res = await subCategoryApi.getAllList(params);
    dispatch(setDataList(res));
    dispatch(setLoading("subCategory"));
  } catch (err) {
    dispatch(setLoading("subCategory"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await subCategoryApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("subCategory"));
    const res = await subCategoryApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("subCategory"));
  } catch (err) {
    dispatch(setLoading("subCategory"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await subCategoryApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await subCategoryApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const SubCategories = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  getAllList,
};
