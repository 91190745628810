import api from "../../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/j-transaction/supplier?${params}`);
  },
  getTypes() {
    return api.get(`/api/v1/j-transaction-type`);
  },
  pay(data) {
    return api.post(`/api/v1/j-transaction/supplier`, data);
  },
  receive(data) {
    return api.post(`/api/v1/j-transaction/received-cash`, data);
  },
};
