import Typography from "@mui/material/Typography";
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  Slide,
  InputAdornment,
} from "@mui/material";
import { forwardRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { Pins, setUpdateExcelDialog } from "../../../app/slices/pinsSlice";
import { Operation } from "../../../app/slices/operationSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateExcelPriceForm = (props) => {
  const dispatch = useDispatch();
  const _Pins = useSelector((state) => state.pins.updateExcelPrice);
  const _SearchBody = useSelector((state) => state.operation.searchBody);

  const { control, reset, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: {},
  });

  const onSubmit = (data) => {
    data.operation_id = _Pins.operation_id
    dispatch(Pins.updateExcelPrice(data)).then((x) => {
      dispatch(setUpdateExcelDialog(null));
      dispatch(Operation.getAll(_SearchBody));
    });
  };

  const handleDialog = () => {
    dispatch(setUpdateExcelDialog(null));
  };

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref,
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });


  return (
    <Dialog
      open={_Pins.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={handleDialog}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {'تعديل السعر'}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="buying_price"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="سعر الشراء للمفرد"
                    fullWidth
                    {...field}
                    sx={{ marginBottom: 1 }}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateExcelPriceForm;
