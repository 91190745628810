import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, Checkbox, Typography, styled } from "@mui/material";
import { useEffect, forwardRef, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import { SubCategories } from "../../app/slices/subcategorySlice";


export const SelectSubCategory = forwardRef((props, ref) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const dispatch = useDispatch();

  const _SubCategory = useSelector((state) => state.subCategories.subCategoriesList);

  useEffect(() => {
    dispatch(SubCategories.getAllList(props.isproduct ? true : 'all'));
  }, []);

  const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    borderBottom: "1px solid #e0e0e0",
    color: "white",
    backgroundColor: theme.palette.primary.main,
  }));
  const GroupItems = styled("ul")({
    padding: 0,
  });

  return (
    <Autocomplete
      ref={ref}
      options={_SubCategory.data || []}
      {...props}
      getOptionLabel={(option) => {
        return option.name
      }}
      renderInput={(params) => (
        <TextField
          required={props.required}
          {...params}
          label={props.isproduct ? "التصنيف" : "الصفحات الثانوية"}
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {_SubCategory.loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderGroup={(params, key) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
});
