import dayjs from "dayjs";
import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const cardsApi = factory.get("cards");
const initialState = {
  searchBody: {},

  reload: false,
  cards: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
  card: {
    loading: false,
    dialog: false,
    form: {
      id: "",
      holder_name: "",
      number: "",
      type: "master",
      order_issued_id: "",
      issued_at: dayjs().format("YYYY-MM-DD"),
    },
  },
};

export const cardsSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {
    setDialog: (state, { payload }) => {
      state.card.dialog = !state.card.dialog;
      if (payload) {
        state.card.form = payload;
      } else {
        state.card.form = initialState.searchBody;
      }
    },
    setDataTable: (state, { payload }) => {
      state.cards.data = payload.data.data;
      state.cards.total = payload.data.total;
      state.cards.total_amount = payload.data.total_amount;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setById: (state, { payload }) => {
      state.card.form = payload;
    },
    resetForm: (state, action) => {
      state.card.form = initialState.card.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDataTable,
  resetForm,
  setSearchBody,
  setDialog,
  setById,
} = cardsSlice.actions;

export default cardsSlice.reducer;

const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("cards"));
    const res = await cardsApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("cards"));
  } catch (err) {
    dispatch(setLoading("cards"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, searchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("cards"));
    await cardsApi.create(data, searchBody);
    dispatch(
      showNotification({
        message: "Card added successfully",
        type: "success",
      })
    );
    dispatch(setDialog(searchBody));
    dispatch(getAll());
    dispatch(setLoading("cards"));
  } catch (err) {
    dispatch(setLoading("cards"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await cardsApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم تعديل المنتج بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("cards"));
    await cardsApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll());
    dispatch(setLoading("cards"));
  } catch (err) {
    dispatch(setLoading("cards"));
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
export const Cards = {
  getAll,
  create,
  update,
  deleteById,
};
