import {
  Grid,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
  IconButton,
  Typography,
  Divider,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridPagination,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Reports } from "../../../../../app/slices/reportsSlice";
import dayjs from "dayjs";
import DateRangepicker from "../../../../../helpers/dateRangePicker";
import { MdOutlineClear } from "react-icons/md";
import { SelectPos } from "../../../../../components/Selectors/SelectPos";
import { SelectProductsSupplier } from "../../../../../components/Selectors/SelectProductsSupplier";
import { ExportButton } from "../../../../../components/ExportExcel/ExportButton";

const PlatformPaymentsTable = () => {
  const _PlatformPayments = useSelector(
    (state) => state.reports.platformPayments
  );
  const loading = useSelector((state) => state.reports.reports.loading);
  const _PointOfsales = useSelector(
    (state) => state.pointOfsales.posesList.data
  );
  const _ProductsSupplier = useSelector(
    (state) => state.productSupplier.productsSupplier.data
  );

  const dispatch = useDispatch();

  const [search, setSearch] = useState({
    startDate: dayjs().format("YYYY-MM-01"),
    endDate: dayjs().format("YYYY-MM-DD"),
    pos: null,
    product_supplier_id: null,
  });

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          القيمة الكلية : {_PlatformPayments.total_amount.toLocaleString()}{" "}
          (IQD)
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  const columns = [
    {
      field: "id",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "pos_name",
      headerName: "اسم النقطة",
      flex: 1,
    },
    {
      field: "pos_number",
      headerName: "رقم النقطة",
      flex: 1,
    },
    {
      field: "total_payment",
      headerName: "المبلغ المدفوع (IQD)",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>
            {parseInt(params.row.total_payment)?.toLocaleString()}
          </Typography>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        Reports.getPlatformPayments({
          start_date: search.startDate,
          end_date: search.endDate,
          pos_id: search.pos ?? "",
          product_supplier_id: search.product_supplier_id ?? "",
        })
      );
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, search]);

  const customToolbar = () => (
    <GridToolbarContainer>
      <ExportButton fileName="تقرير دفعات المنصة | نظام دجلة" />
    </GridToolbarContainer>
  );

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
          </Grid>
          <Grid item xs={2}>
            <SelectPos
              disabled={loading}
              onChange={(event, value) => {
                setSearch({
                  ...search,
                  pos: value ? value.id : null,
                });
              }}
              value={
                _PointOfsales?.filter((x) => search.pos === x.id)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={2}>
            <SelectProductsSupplier
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  product_supplier_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _ProductsSupplier?.filter(
                  (x) => x.id === search.product_supplier_id
                )[0] ?? search.product_supplier_id
              }
            />
          </Grid>
          <Grid item xs={3}>
            <DateRangepicker
              startDate={search.startDate}
              endDate={search.endDate}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    startDate: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    endDate: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    startDate: dayjs().format("YYYY-MM-01"),
                    endDate: dayjs().format("YYYY-MM-DD"),
                    pos: null,
                    product_supplier_id: null,
                  });
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={
                  _PlatformPayments.data?.map((x, index) => ({
                    ...x,
                    id: index + 1,
                  })) ?? []
                }
                columns={columns}
                rowCount={_PlatformPayments.total}
                loading={loading}
                rowHeight={40}
                selectionModel={[]}
                rowsPerPageOptions={[100]}
                components={{
                  LoadingOverlay: LinearProgress,
                  Footer: CustomFooter,
                  Toolbar: customToolbar,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PlatformPaymentsTable;
