import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  Autocomplete,
  LinearProgress,
  InputAdornment,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  SimCard,
} from "../../../../app/slices/simCardSlice";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SimCardForm = () => {
  const _SimCard = useSelector((state) => state.simCard.simCard);
  const _SearchBody = useSelector((state) => state.simCard.searchBody);
  const loading = useSelector((state) => state.simCard.loading);

  const isAdd = _SimCard.form.id ? false : true;
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: _SimCard.form,
  });

  const onSubmit = (data) => {
    data.daily_limit = parseInt(data.daily_limit);
    data.balance = 0;
    if (isAdd) {
      dispatch(SimCard.create(data));
    } else {
      dispatch(SimCard.update(data.id, data, _SearchBody));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setDialog());
  };

  const status = [
    { key: "zain", name: "Zain" },
    { key: "asia", name: "Asia Cell" },
    { key: "korek", name: "Korek" },
  ];

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });

  return (
    <Dialog
      open={_SimCard.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "المعلومات"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          {loading && <LinearProgress sx={{ marginBottom: 1 }} />}
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={status}
                    getOptionLabel={(option) => option.name || ""}
                    value={status.filter((x) => x.key == field.value)[0]}
                    onChange={(e, newValue) => {
                      setValue("type", newValue ? newValue.key : null, {
                        shouldDirty: true,
                      });
                    }}
                    isOptionEqualToValue={(option, value) => option == value}
                    renderInput={(params) => (
                      <TextField {...params} label="النوع" size="small" />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    value={field.value}
                    size="small"
                    label="الاسم"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    value={field.value}
                    size="small"
                    label="رقم الهاتف"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="imei"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    value={field.value}
                    size="small"
                    label="IMEI"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="port"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    value={field.value}
                    size="small"
                    label="البورت"
                    fullWidth
                    type="number"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="code"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    value={field.value}
                    size="small"
                    label="الرمز"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <Controller
                name="balance"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    value={field.value}
                    size="small"
                    label="الرصيد"
                    fullWidth
                    {...field}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={12} md={12}>
              <Controller
                name="daily_limit"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    value={field.value}
                    size="small"
                    label="الحد الاعلى اليومي"
                    fullWidth
                    {...field}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="is_active"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          setValue("is_active", e.target.checked, {
                            shouldDirty: true,
                          });
                        }}
                      />
                    }
                    label="تفعيل !"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                disabled={!isDirty}
                type="submit"
                variant="contained"
                color="primary"
              >
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SimCardForm;
