import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, styled } from "@mui/material";
import { useEffect, forwardRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import { JSupplier } from "../../../app/slices/jomla/supplierSlice";

export const SelectJSupplier = forwardRef((props, ref) => {
    const dispatch = useDispatch();

    const _JSupplier = useSelector(
        (state) => state.jSupplier.jSuppliersList
    );

    useEffect(() => {
        dispatch(JSupplier.getAllList({
            take: 100
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const GroupHeader = styled("div")(({ theme }) => ({
        position: "sticky",
        top: "-8px",
        padding: "4px 10px",
        borderBottom: "1px solid #e0e0e0",
        color: "white",
        backgroundColor: theme.palette.primary.main,
    }));
    const GroupItems = styled("ul")({
        padding: 0,
    });

    return (
        <Autocomplete
            ref={ref}
            options={_JSupplier.data || []}
            {...props}
            getOptionLabel={(option) => {
                return option.name;
            }}
            renderInput={(params) => (
                <TextField
                    required={props.required}
                    {...params}
                    label={"الموردين"}
                    size="small"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {_JSupplier.loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            renderGroup={(params, key) => (
                <li key={params.key}>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                </li>
            )}
        />
    );
});
