import { Card, CardHeader, CardContent, Typography, Grid } from "@mui/material";
import React from "react";
import NotificationHeader from "./home/header";
import NotificationTabel from "./home/table";


const Notification = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <NotificationHeader />
      </Grid>
      <Grid item xs={12}>
        <NotificationTabel />
      </Grid>
    </Grid>
  );
};

export default Notification;
