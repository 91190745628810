import { useState } from "react";
import {
  Menu,
  MenuItem,
  Chip,
  Typography,
  ListItemText,
} from "@mui/material";
import { useSelector } from "react-redux";

export function ChangeStatus(props) {
  const status = useSelector((state) => state.issuedCard.statusList);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filteredStatus = status.filter((item) => item.id === props.status_id)[0];

  function getRandomColor(status) {
    let color = '';
    if (status === "قيد الاصدار") {
      color = "info";
    } else if (status === "انجزت") {
      color = "success";
    } else if (status === "مرفوضة") {
      color = "error";
    } else {
      color = "default";
    }
    return color;
  }


  return (
    <>
      <Menu
        id={`actions-${props.status_id}`}
        anchorEl={anchorEl}
        keepMounted
        disableAutoFocusItem
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {status.map((item, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              if (item.id === 2) {
                props.openDialog()
                // dispatch(setDialog({ status_id: item.id, id: props.id }))
                handleClose();
              } else {
                props.onChangeStatus(item);
                handleClose();
              }
            }}
          >
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Menu>

      <Chip
        color={getRandomColor(filteredStatus?.name)}
        variant="outlined"
        size="small"
        label={
          <Typography fontSize={"small"} color={filteredStatus?.color}>
            {filteredStatus?.name}
          </Typography>
        }
        onClick={(e) => {
          handleMenuClick(e);
        }}
      />
    </>
  );
}
