import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
  FormControlLabel,
  Switch,
  Box,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  Supplier,
} from "../../../../app/slices/supplierSlice";
import { SelectProvince } from "../../../../components/Selectors/SelectProvince";
import { Controller, useForm } from "react-hook-form";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SupplierForm = () => {
  const _Supplier = useSelector((state) => state.supplier.supplier);
  const _SearchBody = useSelector((state) => state.supplier.searchBody);

  const isAdd = _Supplier.form.id ? false : true;
  const { control, reset, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: {
      province: {
        id: _Supplier.form.province_id,
        name: _Supplier.form.province_name,
      },
      ..._Supplier.form,
    },
  });

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        Supplier.create({
          name: data.name,
          province_id: data.province?.id,
        })
      );
    } else {
      dispatch(
        Supplier.update(
          data.id,
          {
            name: data.name,
            province_id: data.province?.id,
          },
          _SearchBody
        )
      );
    }
  };
  useEffect(() => {
    reset({
      ..._Supplier.form,
      province: {
        id: _Supplier.form.province_id,
        name: _Supplier.form.province_name,
      },
    });
  }, [_Supplier.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  const dispatch = useDispatch();

  const handlDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_Supplier.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handlDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الاسم"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="province"
                control={control}
                render={({ field }) => (
                  <SelectProvince
                    required
                    value={field.value}
                    onChange={(e, newValue) =>
                      setValue(
                        "province",
                        newValue
                          ? { id: newValue.id, name: newValue.name }
                          : null
                      )
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SupplierForm;
