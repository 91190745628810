import { useParams } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  CardHeader,
  Divider,
} from "@mui/material";
import {
  Tickets,
  setAddReplyDialog,
} from "../../../../app/slices/ticketsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { IoTicketOutline } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";
import TicketsForm from "../../form";
import { ChangeStatusByChip } from "../table/ChangeStatusByChip";

const TicketDetails = () => {
  const { ticket_id } = useParams();
  const dispatch = useDispatch();
  const _Loading = useSelector((state) => state.tickets.ticket.loading);
  const _Tickets = useSelector((state) => state.tickets);
  const _CurrentTicket = useSelector((state) => state.tickets.ticket);
  const ticket = useSelector((state) => state.tickets.ticket.form);

  const changeTicketStatus = (id, status) => {
    let info = {
      ticket_status_id: status?.status_id,
    };

    dispatch(Tickets.updateStatus(id, info, false));
  };
  useEffect(() => {
    dispatch(Tickets.getById(ticket_id));
  }, [ticket_id, _CurrentTicket.reload]);
  return (
    <Grid container spacing={2}>
      <Grid item>
        <TicketsForm id={ticket_id} />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              <Typography variant="h2" component="div">
                {ticket.title}
              </Typography>
            }
            avatar={<IoTicketOutline size={20} />}
          />
          <CardContent>
            <Box>
              <Typography variant="h4" component="div" marginBottom={1}>
                {`بواسطة: ${ticket.created_by}`}
              </Typography>
              <Typography variant="h4" component="div" marginBottom={1}>
                {`الحالة: `}
                <ChangeStatusByChip
                  id={ticket.ticket_status_id}
                  status={ticket.ticket_status}
                  onChangeStatus={(item) => {
                    changeTicketStatus(ticket_id, item);
                  }}
                />
              </Typography>
              <Typography variant="h4" component="div" marginBottom={1}>
                {`نوع التذكرة: ${ticket.ticket_type}`}
              </Typography>
              <Typography variant="h4" component="div" marginBottom={1}>
                {`الوصف: ${ticket.description}`}
              </Typography>
            </Box>
            <Divider />
            <Typography variant="h4" component="div" marginBottom={1}>
              المرفقات
            </Typography>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                rowGap: { xs: 2, sm: 0 },
                columnGap: { xs: 0, sm: 2 },
              }}
            >
              {ticket.attachments.map((attachment) => (
                <Grid item>
                  <a
                    key={attachment.id}
                    href={attachment.path}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography variant="h5">{`مرفق ${attachment.file_name}`}</Typography>
                  </a>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography variant="h2" component="div">
                  التعليقات
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => {
                    return dispatch(setAddReplyDialog());
                  }}
                >
                  اضافة تعليق
                </Button>
              </Box>
            }
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Grid container rowSpacing={2}>
          {ticket.replies.map((r) => (
            <Grid item xs={12} key={r.id}>
              <Card>
                <CardHeader
                  title={
                    <Typography variant="h4" component="div">
                      {r.created_by}
                    </Typography>
                  }
                  avatar={<AiOutlineUser size={22} />}
                />
                <CardContent>
                  <Box>
                    <Typography variant="h5" marginBottom={1}>
                      {r.content}
                    </Typography>
                    <Typography variant="h5" marginBottom={1}>
                      المرفقات:
                    </Typography>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        rowGap: { xs: 2, sm: 0 },
                        columnGap: { xs: 0, sm: 2 },
                        marginBottom: 1,
                      }}
                    >
                      {r.attachments?.map((attachment) => (
                        <Grid item key={attachment.id}>
                          <a
                            href={attachment.path}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Typography variant="h5">{`مرفق ${attachment.file_name}`}</Typography>
                          </a>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  <Divider />
                  <Typography variant="h5">
                    {new Date(r.created_at).toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TicketDetails;
