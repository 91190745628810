import Typography from "@mui/material/Typography";
import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tickets, setSearchBody } from "../../../../app/slices/ticketsSlice";
import { MdOutlineClear } from "react-icons/md";
import { IoEye } from "react-icons/io5";
import { ChangeStatusByChip } from "./ChangeStatusByChip";
import dayjs from "dayjs";
import { SelectPos } from "../../../../components/Selectors/SelectPos";

const TicketsTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const _Tickets = useSelector((state) => state.tickets.tickets);
  const _SearchBody = useSelector((state) => state.tickets.searchBody);

  const [search, setSearch] = useState({
    title: "",
    ticket_type_id: null,
    ticket_status_id: null,
    created_by_id: null,
    ticket_id: "",
    pos_id: null,
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  const changeTicketStatus = (id, status) => {
    let info = {
      ticket_status_id: status?.status_id,
    };

    dispatch(Tickets.updateStatus(id, info, true, _SearchBody));
  };

  const columns = [
    {
      field: "id",
      headerName: "رقم التذكرة",
      flex: 0.5,
    },
    {
      field: "title",
      headerName: "العنوان",
      flex: 1,
    },
    {
      field: "ticket_type",
      headerName: "النوع",
      flex: 1,
    },
    {
      field: "ticket_status",
      headerName: "الحالة",
      minWidth: 150,
      renderCell: (params) => {
        return (
          <ChangeStatusByChip
            id={params.row.ticket_status_id}
            status={params.row.ticket_status}
            onChangeStatus={(item) => {
              changeTicketStatus(params.row.id, item);
            }}
          />
        );
      },
    },

    {
      field: "description",
      headerName: "الوصف",
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.description} placement="bottom-start">
            <span>{params.row.description}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "created_by",
      headerName: "بواسطة",
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "تاريخ الانشاء",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>
            {dayjs(params.row.created_at).format("YYYY-MM-DD / hh:mm a")}
          </Typography>
        );
      },
    },
    {
      field: "updated_at",
      headerName: "اخر تحديث",
      flex: 1,
    },
    {
      field: "operations",
      headerName: "العمليات",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <Tooltip title="التفاصيل">
            <IconButton
              onClick={() => {
                navigate("/app/tickets/tickets/" + params.row.id);
              }}
            >
              <IoEye />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Tickets.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        search: search.title,
        ticket_type_id: search.ticket_type_id ? search.ticket_type_id : "",
        ticket_status_id: search.ticket_status_id
          ? search.ticket_status_id
          : "",
        created_by_id: search.created_by_id?.id ?? "",
        ticket_id: search.ticket_id?.trim() ?? "",
        pos_id: search.pos_id?.id ?? "",
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize, search]);
  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={search.title}
              onChange={(e) => {
                setSearch({
                  ...search,
                  title: e.target.value,
                });
              }}
              label="بحث عن الاسم "
              fullWidth
            />
          </Grid>
          <Grid item xs={2.5}>
            <TextField
              size="small"
              value={search.ticket_id}
              onChange={(e) => {
                setSearch({
                  ...search,
                  ticket_id: e.target.value.replace(/\D/g, ""),
                });
              }}
              label="بحث عن رقم التذكرة"
              fullWidth
              InputProps={{
                inputProps: {
                  pattern: "[0-9]*",
                },
              }}
            />
          </Grid>
          {/* <Grid item xs={2.5}>
            <SelectUser
              value={search.created_by_id}
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  created_by_id: newValue ? newValue : null,
                });
              }}
            />
          </Grid> */}
          <Grid item xs={2.5}>
            <SelectPos
              value={search.pos_id}
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  pos_id: newValue ? newValue : null,
                });
              }}
            />
          </Grid>

          <Grid item xs={2.5}>
            <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      name: "",
                      sub_category_id: null,
                      group_id: null,
                      province_id: null,
                      ticket_id: null,
                      pos_id: null,
                    });
                    setPageSize(15);
                    setCurrentPage(0);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_Tickets.data ? (
                <DataGrid
                  rows={_Tickets.data}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_Tickets.total}
                  loading={_Tickets.loading}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TicketsTable;
