import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getTeamByLeader(id) {
    return api.get(`/api/v1/team?user_id=${id}`);
  },
  addUserToTeam(data) {
    return api.post(`/api/v1/team`, data);
  },
  removeUserFromTeam(id) {
    return api.delete(`/api/v1/team?user_id=${id}`);
  },
  getAllLeaders() {
    return api.get(`/api/v1/team/leaders`);
  },
}