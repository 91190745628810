import { Card, CardHeader, Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../../app/slices/categorySlice";
import CategoriesForm from "../../form";
import { BsFolder2Open } from "react-icons/bs";




const CategoriesHeader = () => {
  const  categories  = useSelector((state) => state.categories);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
             الصفحات الرئيسية
          </Typography>
        }
        avatar={<BsFolder2Open size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            إضافة صفحة رئيسية
          </Button>
        }
      />
      {categories.category.dialog ? <CategoriesForm /> : null}
    </Card>
  );
};

export default CategoriesHeader;
