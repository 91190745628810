import { Grid } from "@mui/material";
import React from "react";
import AsiaBillsTable from "./home/table";
import AsiaBillsHeader from "./home/header";

const AsiaBills = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AsiaBillsHeader />
      </Grid>
      <Grid item xs={12}>
        <AsiaBillsTable />
      </Grid>
    </Grid>
  );
};

export default AsiaBills;
