import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Stack,
  InputAdornment,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  resetCheckPrice,
  Orders,
  setOrderPinsDialog,
} from "../../../../app/slices/orderSlice";
import { Controller, useForm } from "react-hook-form";
import { SelectSupplier } from "../../../../components/Selectors/SelectSupplier";
import { SelectItem } from "../../../../components/Selectors/SelectItems";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrdersForm = () => {
  const _Orders = useSelector((state) => state.orders.order);
  const _checkPrice = useSelector((state) => state.orders.checkSellingPrice);

  const isAdd = _Orders.form.id ? false : true;
  const { control, reset, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: _Orders.form,
  });


  const onSubmit = (data) => {
    data.item_id = data.item_id.id
    data.supplier_id = data.supplier_id.id
    dispatch(Orders.addSupplierOrder(data))
  };
  useEffect(() => {
    reset({ ..._Orders.form });
  }, [_Orders.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
      dispatch(resetCheckPrice());
    };
  }, []);

  const dispatch = useDispatch();

  const handlDialog = () => {
    dispatch(setDialog());
  };

  const handleCheckPrice = () => {
    dispatch(Orders.checkSellingPrice({
      item_id: control._fields.item_id._f.value.id,
      count: control._fields.count._f.value
    }));
  };

  return (
    <Dialog
      open={_Orders.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handlDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="item_id"
                control={control}
                render={({ field }) => (
                  <SelectItem
                    required
                    value={field.value}
                    onChange={(e, newValue) => {
                      setValue("item_id", newValue);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="supplier_id"
                control={control}
                render={({ field }) => (
                  <SelectSupplier
                    required
                    value={field.value}
                    onChange={(e, newValue) => {
                      setValue("supplier_id", newValue);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="count"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="العدد"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
                <Button onClick={handleCheckPrice} variant="contained" sx={{ width: '50%' }}>
                  فحص السعر
                </Button>
                <TextField
                  size="small"
                  label="سعر الشراء الكلي"
                  fullWidth
                  value={_checkPrice.total?.toLocaleString()}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                  }}
                />
                <TextField
                  size="small"
                  label="سعر الشراء المفرد"
                  fullWidth
                  value={_checkPrice.single?.toLocaleString()}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="price"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="سعر بيع المفرد"
                    fullWidth
                    {...field}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="cash_paid"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="المبلغ المدفوع"
                    fullWidth
                    {...field}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="الملاحظة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default OrdersForm;
