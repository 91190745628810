import { Grid } from "@mui/material";
import React from "react";
import ReportsHeader from "./home/header";
import { useSelector } from "react-redux";
import JCashTable from "./home/table/payments";
import JRepTable from "./home/table/rep";

const JReport = () => {
  const _ReportType = useSelector((state) => state.jReports.reportTypes);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ReportsHeader />
      </Grid>
      {_ReportType[0]?.isSelected && (
        <Grid item xs={12}>
          <JCashTable />
        </Grid>
      )}
      {_ReportType[1]?.isSelected && (
        <Grid item xs={12}>
          <JRepTable />
        </Grid>
      )}
    </Grid>
  );
};

export default JReport;
