import { Card, CardHeader, Typography, Button, Stack } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { CiUser } from "react-icons/ci";
import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import PayCashForm from "../../payCashForm";
import {
  setPayCashSupplierDialog,
  setReceiveCashSupplierDialog,
} from "../../../../../../app/slices/walletSlice";
import ReceiveCashForm from "../../receiveCashForm";

const SupplierTransactionsHeader = () => {
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            كشف الحساب - الموردين
          </Typography>
        }
        avatar={<CiUser size={20} />}
        action={
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<GiPayMoney />}
              onClick={() => dispatch(setPayCashSupplierDialog())}
            >
              تسديد مبالغ
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<GiReceiveMoney />}
              onClick={() => dispatch(setReceiveCashSupplierDialog())}
            >
              استلام حساب الفواتير
            </Button>
          </Stack>
        }
      />
      <PayCashForm />
      <ReceiveCashForm />
    </Card>
  );
};

export default SupplierTransactionsHeader;
