import { Card, CardHeader, CardContent, Typography, Grid } from "@mui/material";
import React from "react";
import ItemsHeader from "./home/header";
import ItemsTabel from "./home/table";

const Items = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ItemsHeader />
      </Grid>
      <Grid item xs={12}>
        <ItemsTabel />
      </Grid>
    </Grid>
  );
}; 

export default Items;
