import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  SettingsActions,
} from "../../../app/slices/settingsSlice";
import { Controller, useForm } from "react-hook-form";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SettingsForm = () => {
  const dispatch = useDispatch();

  const _Setting = useSelector((state) => state.settings.setting);
  const _SearchBody = useSelector((state) => state.settings.searchBody);
  const loading = useSelector((state) => state.settings.settings.loading);

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    defaultValues: _Setting.form,
  });

  const onSubmit = (data) => {
    dispatch(
      SettingsActions.update(
        data.id,
        {
          value: data.value,
          note: data.note,
        },
        _SearchBody
      )
    );
  };

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasError = (fieldName) => {
    return !!errors[fieldName];
  };

  const handleDialog = () => {
    dispatch(setDialog());
  };
  return (
    <Dialog
      open={_Setting.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"تعديل"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="value"
                control={control}
                rules={{
                  pattern: {
                    required: { value: true, message: "الحقل مطلوب" },
                  },
                }}
                render={({ field }) => (
                  <TextField
                    required
                    type="number"
                    size="small"
                    label="القيمة"
                    fullWidth
                    error={hasError("value")}
                    helperText={errors.value ? errors.value.message : ""}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="الملاحظة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "حفظ"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SettingsForm;
