import { Card, CardHeader, Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../app/slices/appNotificationSlice";
import { MdNotificationsActive } from "react-icons/md";
import NotificationForm from "../../from";



const NotificationHeader = () => {
  const _Notification = useSelector((state) => state.appNotification);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
             الاشعارات
          </Typography>
        }
        avatar={<MdNotificationsActive size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            ارسال اشعار 
          </Button>
        }
      />
      {_Notification.notification.dialog ? <NotificationForm /> : null}
    </Card>
  );
};

export default NotificationHeader;
