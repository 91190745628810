import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/ticket?${params}`);
  },
  getById(id) {
    return api.get(`/api/v1/ticket/${id}`);
  },
  reply(data) {
    return api.post(`/api/v1/reply`, data);
  },
  updateStatus(id, data) {
    return api.put(`/api/v1/ticket/status/${id}`, data);
  },
  openTickets() {
    return api.get(`/api/v1/ticket/open`);
  },
};
