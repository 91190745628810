import { Grid } from "@mui/material";
import React from "react";
import PosTypeTable from "./home/table";
import PosFieldHeader from "./home/header";

const PosField = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PosFieldHeader />
      </Grid>
      <Grid item xs={12}>
        <PosTypeTable />
      </Grid>
    </Grid>
  );
};

export default PosField;
