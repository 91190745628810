import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
  Chip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiEdit, BiTrash } from "react-icons/bi";
import { AiOutlineTransaction } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SimCard,
  setSearchBody,
  setTransactionsDialog,
  setSendSmsDialog,
} from "../../../../../app/slices/simCardSlice";
import dayjs from "dayjs";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import SimCardTransactions from "../transactions";
import { BiMessageAdd } from "react-icons/bi";

const SimCardTable = () => {
  const dispatch = useDispatch();

  const _SimCard = useSelector((state) => state.simCard.simCards);
  const loading = useSelector((state) => state.simCard.simCard.loading);
  const _SearchBody = useSelector((state) => state.simCard.searchBody);
  const _TransactionData = useSelector(
    (state) => state.simCard.simCardTransactions.dialog
  );

  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState(15);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [openActive, setOpenActive] = useState(false);
  const [dialogActiveData, setDialogActiveData] = useState({});
  const [reload, setReload] = useState(false);

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(SimCard.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const handleActiveClick = (params) => {
    setOpenActive(true);
    setDialogActiveData(params);
  };

  const handleConfirmActive = () => {
    dispatch(SimCard.changeStatus(dialogActiveData.id)).then((x) => {
      setReload(!reload);
    });
    setOpenActive(false);
  };
  const handleCancelActive = () => {
    setOpenActive(false);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "name",
      headerName: "الاسم",
      flex: 0.8,
    },
    {
      field: "phone",
      headerName: "رقم الهاتف",
      flex: 0.8,
    },
    {
      field: "port",
      headerName: "البورت",
      flex: 0.5,
    },
    {
      field: "type",
      headerName: "النوع",
      flex: 0.5,
    },
    {
      field: "imei",
      headerName: "IMEI",
      flex: 1,
    },
    {
      field: "code",
      headerName: "الرمز",
      flex: 1,
    },
    {
      field: "balance",
      headerName: "الرصيد (IQD)",
      flex: 0.8,
      renderCell: (params) => {
        return parseInt(params.value)?.toLocaleString();
      },
    },
    {
      field: "daily_limit",
      headerName: "الحد الاقصى اليومي (IQD)",
      flex: 1,
      renderCell: (params) => {
        return parseInt(params.value)?.toLocaleString();
      },
    },
    {
      field: "is_active",
      headerName: "حالة الحساب",
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.is_active ? "فعال" : "غير فعال"}
            color={params.row.is_active ? "success" : "error"}
            onClick={() => {
              handleActiveClick(params.row);
            }}
          ></Chip>
        );
      },
    },
    {
      field: "created_at",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => {
        return dayjs(params.row.created_at).format("HH:mm / YYYY-MM-DD ");
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1.3,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="المناقلات">
              <IconButton
                color="success"
                variant="contained"
                onClick={() => {
                  dispatch(setTransactionsDialog(params.row.id));
                  // dispatch(
                  //   SimCard.getAllTransactions({
                  //     sim_card_id: params.row.id,
                  //     type: "",
                  //     startDate: dayjs().format("2023-01-01"),
                  //     endDate: dayjs().format("YYYY-MM-DD"),
                  //   })
                  // );
                }}
              >
                <AiOutlineTransaction />
              </IconButton>
            </Tooltip>
            <Tooltip title="اضافة رسالة">
              <IconButton
                color="warning"
                variant="contained"
                onClick={() => {
                  dispatch(setSendSmsDialog(params.row.port));
                }}
              >
                <BiMessageAdd />
              </IconButton>
            </Tooltip>
            <Tooltip title="تعديل">
              <IconButton
                variant="contained"
                onClick={() => {
                  dispatch(SimCard.getById(params.row));
                }}
              >
                <BiEdit />
              </IconButton>
            </Tooltip>
            <Tooltip title="حذف">
              <IconButton
                color="error"
                onClick={() => handleDeleteClick(params.row)}
              >
                <BiTrash />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(SimCard.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        skip: currentPage * page,
        take: page,
      })
    );
  }, [currentPage, dispatch, page]);

  return (
    <Card>
      <CardContent>
        <Box>
          {_TransactionData && <SimCardTransactions />}
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
          <ConfirmationDialog
            open={openActive}
            title="تاكيد"
            message={
              dialogActiveData.wallet?.is_active
                ? "هل انت متاكد من الغاء تفعيل السيم كارت"
                : "هل انت متاكد من تفعيل السيم كارت"
            }
            name={dialogActiveData.name}
            confirmText="تاكيد"
            cancelText="الغاء"
            onConfirm={() => handleConfirmActive()}
            onCancel={handleCancelActive}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={{}}>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={_SimCard.data.map((x, index) => ({
                  ...x,
                  index: index + 1,
                }))}
                columns={columns}
                pageSize={page}
                rowCount={_SimCard.total}
                loading={loading}
                rowHeight={50}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPage(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SimCardTable;
